import React, { Component, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Container, Button, Box, Avatar, Typography, TextField, Grid, Link, Paper, CircularProgress } from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

import { resetPassword } from '../../services/http.service';
import { ErrorAlertTopCenter } from '../../common/snackbars';
import logger from '../../utils/logger';

const validationSchema = Yup.object({   
    password: Yup.string().required("Password is required.")
        .min(6, "Password must be 6 chars long").max(32, "Password must be more that 32 chars long"),
    confirmPassword: Yup.string().required("Password is required.")
        .min(6, "Password must be 6 chars long").max(32, "Password must be more that 32 chars long")
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    
});

const ResetPassword = () => {	

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { userId } = useParams();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setIsSubmitting(true);
            logger.info(`Submitting password reset for user ${userId}`, values);
            setErrorMsg(null);
            resetPassword(userId, values).then(resp => {
                logger.info(`Password reset successful for user ${userId}`);
                setIsSubmitting(false);
                navigate('/login');
            }).catch(err => {
                logger.error(`Password reset failed for user ${userId}`, err);
                setIsSubmitting(false);
                setErrorMsg(err);
            });
        },
    });

    return (
        <>
            { errorMsg &&
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
            <Container component="main" maxWidth="sm">
                <Paper elevation={10}>
                    <Box
                        padding={2}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockResetIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Reset Password
                        </Typography>
                        <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                id="confirmPassword"
                                autoComplete="new-password"
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {isSubmitting &&
                                    <CircularProgress />
                                }
                                {!isSubmitting &&
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 4, mb: 2 }}
                                    >
                                        Submit
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </>        
    );
}

export default ResetPassword;
import React, { Component, useState, useEffect } from 'react';
import { Box, Button, Typography, Chip, Paper, Grid,
    TextField, Autocomplete, CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import { saveOrUpdateBus, getBus } from '../../../../services/http.service';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';

import { useParams, useNavigate } from "react-router-dom";
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'NewBus';  // Define logTag for this component

const NewBus = () => {

    const [validated, setValidated] = useState(false);
    const [busLoading, setBusLoading] = useState(false);
    const [bus, setBus] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const { busId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        logger.info('NewBus component mounted', { logTag, busId });  // Log component mount
        if (busId) {
            fetchBus(busId);
        }
    }, [busId]);

    // Fetch bus detail for edit
    const fetchBus = (busId) => {
        logger.info('Fetching bus details', { logTag, busId });  // Log fetching bus details
        setBusLoading(true);
        getBus(busId).then(resp => {
            console.log(`Fetch bus - ${JSON.stringify(resp)}`);
            setBus(resp.data.data);
            setBusDetail(resp.data.data);
            setBusLoading(false);
            logger.info('Bus details fetched successfully', { logTag, busId });  // Log success
        }).catch(err => {
            logger.error('Error fetching bus details', err, { logTag, busId });  // Log error
            setBusLoading(false);
        });
    }

    // Set Bus details into state
    const setBusDetail = (respBus) => {
        const newBus = {
            ...bus,
            id: respBus.id,
            brand: respBus.brand,
            manufacturer: respBus.manufacturer,
            model: respBus.model,
            number: respBus.number
        }
        setBus(newBus);
        formik.setValues({ ...newBus }); // get copy of bus
        console.log(`Set bus detail - ${JSON.stringify(newBus)}`);
        logger.info('Bus details set in form', { logTag, bus: newBus });  // Log setting bus details
    }

    /**
     * Handle form submission
     */
    // Formik handle submit 
    const validationSchema = Yup.object({
        number: Yup.string().required("Bus number is required."),
        manufacturer: Yup.string().required("Manufacturer is required."),
        brand: Yup.string().required("Brand is required."),
        model: Yup.string().required("Model is required."),
    });
    const formik = useFormik({
        initialValues: {
            number: '',
            manufacturer: '',
            brand: '',
            model: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            logger.info('Submitting bus details', { logTag, values });  // Log form submit
            const newBus = {
                ...bus,
                number: values.number,
                manufacturer: values.manufacturer,
                brand: values.brand,
                model: values.model,
            }
            setBus(newBus);
            setIsSaving(true);
            setErrorMsg(null);
            setShowSuccessAlert(false);

            saveOrUpdateBus(newBus).then(resp => {
                setIsSaving(false);
                setErrorMsg(null);
                setShowSuccessAlert(true);
                logger.info('Bus saved successfully', { logTag, busId: bus.id });  // Log success
            }).catch(err => {
                setIsSaving(false);
                setErrorMsg(err);
                setShowSuccessAlert(false);
                logger.error('Error saving bus', err, { logTag, busId: bus.id });  // Log error
                console.log(err);
            });
        }
    });

    return (
        <>
            {errorMsg &&
                <ErrorAlertTopCenter
                    errorMsg={errorMsg}
                    onClose={() => {
                        logger.info('Error alert closed', { logTag });  // Log alert close
                        setErrorMsg(null);
                    }}
                />
            }
            {showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"Bus saved successfully!!"}
                    onClose={() => {
                        logger.info('Success alert closed', { logTag });  // Log success alert close
                        setShowSuccessAlert(false);
                    }}
                />
            }
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <Typography component="h2" variant="header">
                    {bus && bus.id ? "Update bus" : "Add new bus"}
                </Typography>
                <Chip
                    onClick={() => {
                        logger.info('Navigating to bus list', { logTag });  // Log navigation to bus list
                        navigate(`../list`);
                    }}
                    icon={<FormatListBulletedIcon />}
                    label="Buses"
                    variant="outlined"
                    color="secondary"
                />
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            fullWidth
                            required
                            name="number"
                            label="Bus number"
                            value={formik.values.number}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.number)}
                            helperText={formik.touched.number && formik.errors.number}
                        />
                         <TextField
                            margin="normal"
                            fullWidth
                            required
                            name="manufacturer"
                            label="Manufacturer"
                            value={formik.values.manufacturer}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.manufacturer)}
                            helperText={formik.touched.manufacturer && formik.errors.manufacturer}
                        />
                         <TextField
                            margin="normal"
                            fullWidth
                            required
                            name="brand"
                            label="Brand"
                            value={formik.values.brand}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.brand)}
                            helperText={formik.touched.brand && formik.errors.brand}
                        />
                         <TextField
                            margin="normal"
                            fullWidth
                            required
                            name="model"
                            label="Model"
                            value={formik.values.model}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.model)}
                            helperText={formik.touched.model && formik.errors.model}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            {isSaving &&
                                <CircularProgress />
                            }
                            {!isSaving &&
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Submit
                                </Button>
                            }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default NewBus;


// import React, { Component, useState, useEffect } from 'react';
// import { Box, Button, Typography, Chip, Paper, Grid,
//     TextField, Autocomplete, CircularProgress } from '@mui/material';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

// import { saveOrUpdateBus, getBus } from '../../../../services/http.service';
// import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';

// import { useParams, useNavigate } from "react-router-dom";



// const NewBus = () => {

//     const [validated, setValidated] = useState(false);
//     const [busLoading, setBusLoading] = useState(false);
//     const [bus, setBus] = useState({});
//     const [isSaving, setIsSaving] = useState(false);
//     const [errorMsg, setErrorMsg] = useState(null);
//     const [showSuccessAlert, setShowSuccessAlert] = useState(false);

//     const { busId } = useParams();
//     const navigate = useNavigate();

//     useEffect(() => {
//         if (busId) {
//             fetchBus(busId);
//         }
//     }, []);

//      // Fetch bus detail for edit
//     const fetchBus = (busId) => {
//         setBusLoading(true);
//         getBus(busId).then(resp => {
//             console.log(`Fetch bus - ${JSON.stringify(resp)}`);
//             setBus(resp.data.data);
//             setBusDetail(resp.data.data);
//             setBusLoading(false);
//         }).catch(err => {
//             console.log(err);
//             setBusLoading(false);
//         })
//     }

//     // Set Bus details into state
//     const setBusDetail = (respBus) => {
//         const newBus = {
//             ...bus,
//             id: respBus.id,
//             brand: respBus.brand,
//             manufacturer: respBus.manufacturer,
//             model: respBus.model,
//             number: respBus.number
//         }
//         setBus(newBus);
//         formik.setValues({ ...newBus }); // get copy of school
//         console.log(`Set bus detail - ${JSON.stringify(newBus)}`);
//     }


//     /**
//      * Handle form submission
//      */
//     // Formik handle submit 
//     const validationSchema = Yup.object({
//         number: Yup.string().required("Name is required."),
//         manufacturer: Yup.string().required("Manufacturer is required."),
//         brand: Yup.string().required("Brand is required."),
//         model: Yup.string().required("Model is required."),
//     });
//     const formik = useFormik({
//         initialValues: {
//             number: '',
//             manufacturer: '',
//             brand: '',
//             model: '',
//         },
//         validationSchema: validationSchema,
//         onSubmit: (values) => {
//             console.log(values);
//             const newBus = {
//                 ...bus,
//                 number: values.number,
//                 manufacturer: values.manufacturer,
//                 brand: values.brand,
//                 model: values.model,
//             }
//             setBus(newBus);
//             setIsSaving(true);
//             setErrorMsg(null);
//             setShowSuccessAlert(false);

//             saveOrUpdateBus(newBus).then(resp => {
//                 setIsSaving(false);
//                 setErrorMsg(null);
//                 setShowSuccessAlert(true);
//             }).catch(err => {
//                 setIsSaving(false);
//                 setErrorMsg(err);
//                 setShowSuccessAlert(false);
//                 console.log(err);
//             })
//         }
//     });


//     // const handleSubmit = (event) => {
//     //     event.preventDefault();
//     //     event.stopPropagation();
//     //     setValidated(true);
//     //     const form = event.currentTarget;
//     //     if (form.checkValidity() === false) {
//     //         console.log(`not valid`);
//     //         return
//     //     } 
        
//     //     setIsSaving(true);
//     //     setErrorMsg(null);
//     //     setShowSuccessAlert(false);

//     //     setBus({
//     //         ...bus,
//     //         number: form[0].value,
//     //         manufacturer: form[1].value,
//     //         brand: form[2].value,
//     //         model: form[3].value,
//     //     });
//     //     console.log(`Bus to save ${JSON.stringify(bus)}`);

//     //     saveOrUpdateBus(bus).then(resp => {
//     //         setIsSaving(false);
//     //         setErrorMsg(null);
//     //         setShowSuccessAlert(true);
//     //     }).catch(err => {
//     //         setIsSaving(false);
//     //         setErrorMsg(err);
//     //         setShowSuccessAlert(false);
//     //         console.log(err);
//     //     })
//     // }

//     return (
//         <>
//             {errorMsg &&
//                 <ErrorAlertTopCenter
//                     errorMsg={errorMsg}
//                     onClose={() => setErrorMsg(null)}
//                 />
//             }
//             {showSuccessAlert &&
//                 <SuccessAlertTopRight
//                     successMsg={"Bus saved successfully!!"}
//                     onClose={() => setShowSuccessAlert(false)}
//                 />
//             }
//             <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//                 <Typography component="h2" variant="header">
//                     {bus && bus.id ? "Update bus" : "Add new bus"}
//                 </Typography>
//                 <Chip
//                     onClick={() => navigate(`../list`)}
//                     icon={<FormatListBulletedIcon />}
//                     label="Buses"
//                     variant="outlined"
//                     color="secondary"
//                 />
//             </Box>
//             <Grid container spacing={2}>
//                 <Grid item xs={12} md={6}>
//                     <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
//                         <TextField
//                             margin="normal"
//                             fullWidth
//                             required
//                             name="number"
//                             label="Bus number"
//                             value={formik.values.number}
//                             onChange={formik.handleChange}
//                             error={formik.touched.name && Boolean(formik.errors.number)}
//                             helperText={formik.touched.number && formik.errors.number}
//                         />
//                          <TextField
//                             margin="normal"
//                             fullWidth
//                             required
//                             name="manufacturer"
//                             label="Manufacturer"
//                             value={formik.values.manufacturer}
//                             onChange={formik.handleChange}
//                             error={formik.touched.name && Boolean(formik.errors.manufacturer)}
//                             helperText={formik.touched.manufacturer && formik.errors.manufacturer}
//                         />
//                          <TextField
//                             margin="normal"
//                             fullWidth
//                             required
//                             name="brand"
//                             label="Brand"
//                             value={formik.values.brand}
//                             onChange={formik.handleChange}
//                             error={formik.touched.name && Boolean(formik.errors.brand)}
//                             helperText={formik.touched.brand && formik.errors.brand}
//                         />
//                          <TextField
//                             margin="normal"
//                             fullWidth
//                             required
//                             name="model"
//                             label="Model"
//                             value={formik.values.model}
//                             onChange={formik.handleChange}
//                             error={formik.touched.name && Boolean(formik.errors.model)}
//                             helperText={formik.touched.model && formik.errors.model}
//                         />
//                         <Box
//                             sx={{
//                                 display: 'flex',
//                                 justifyContent: 'center',
//                             }}
//                         >
//                             {isSaving &&
//                                 <CircularProgress />
//                             }
//                             {!isSaving &&
//                                 <Button
//                                     type="submit"
//                                     variant="contained"
//                                     sx={{ mt: 3, mb: 2 }}
//                                 >
//                                     Submit
//                                 </Button>
//                             }
//                         </Box>
//                     </Box>
//                 </Grid>
//             </Grid>
//         </>
//     );
// }

// export default NewBus;
import React, { Component, useState, useEffect } from 'react';
import { Box, Typography, Grid, Link as MuiLink, Chip, CircularProgress, TextField,
    Button } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { saveOrUpdateSection, getSection } from '../../../../services/http.service';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'NewSection';  // Define logTag for this component

const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
});

const NewSection = () => {

    const [validated, setValidated] = useState(false);
    const [section, setSection] = useState({
        id: null,
        name: ''
    });
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const { sectionId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        logger.info('NewSection component mounted', { logTag, sectionId });  // Log component mount
        if (sectionId) {
            fetchSection(sectionId);
        }
    }, [sectionId]);

    // Fetch section by id from server
    const fetchSection = (id) => {
        logger.info('Fetching section details', { logTag, sectionId: id });  // Log fetching section
        setIsLoading(true);
        getSection(id).then(resp => {
            const section = resp.data.data;
            setIsLoading(false);
            setSection(section);
            formik.setValues({ ...section });  // get copy of section
            logger.info('Section details fetched successfully', { logTag, sectionId: id });  // Log success
        }).catch(err => {
            logger.error('Error fetching section', err, { logTag, sectionId: id });  // Log error
            setIsLoading(false);
        });
    }

    // Formik handle submit
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            logger.info('Submitting section details', { logTag, values });  // Log form submit
            const newSection = {
                id: section?.id,  // in case of update
                name: values.name
            }
            setIsSaving(true);  
            setShowSuccessAlert(false);
            saveOrUpdateSection(newSection).then(resp => {
                setIsSaving(false);
                setShowSuccessAlert(true);
                setErrorMsg(null);
                logger.info('Section saved successfully', { logTag, sectionId: section?.id });  // Log success
                if (!section?.id) {
                    formik.resetForm();
                }
            }).catch(err => {
                logger.error('Error saving section', err, { logTag, sectionId: section?.id });  // Log error
                setIsSaving(false);
                setShowSuccessAlert(false);
                setErrorMsg(err);
            });
        }
    });

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                alignItems: "start",
            }}
        >
            {errorMsg &&
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => {
                        logger.info('Error alert closed', { logTag });  // Log alert close
                        setErrorMsg(null);
                    }} 
                />
            }
            {showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"Section saved successfully!!"}
                    onClose={() => {
                        logger.info('Success alert closed', { logTag });  // Log success alert close
                        setShowSuccessAlert(false);
                    }} 
                />
            }

            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <Typography component="h2" variant="header">
                    {section && section.id ? "Update section" : "Create new section"}
                </Typography>
                <Chip
                    onClick={() => {
                        logger.info('Navigating to section list', { logTag });  // Log navigation to section list
                        navigate(`../list`);
                    }}
                    icon={<FormatListBulletedIcon />}
                    label="Sections"
                    variant="outlined"
                    color="secondary"
                />
            </Box>
            <Grid container spacing={2} sx={{ mt: 3 }}>
                {isLoading &&
                    <Grid container>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12} md={6}>
                    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            fullWidth
                            required
                            name="name"
                            label="Section name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            {isSaving &&
                                <CircularProgress />
                            }
                            {!isSaving &&
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Submit
                                </Button>
                            }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default NewSection;


// import React, { Component, useState, useEffect } from 'react';
// import { Box, Typography, Grid, Link as MuiLink, Chip, CircularProgress, TextField,
//     Button } from '@mui/material';
// import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
// import { useParams, useNavigate } from "react-router-dom";
// import { useFormik } from 'formik';
// import * as Yup from 'yup';

// import { saveOrUpdateSection, getSection } from '../../../../services/http.service';
// import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';

// const validationSchema = Yup.object({
//     name: Yup.string().required("Name is required."),
// });


// const NewSection = () => {

//     const [validated, setValidated] = useState(false);
//     const [section, setSection] = useState({
//         id: null,
//         name: ''
//     });
//     const [isSaving, setIsSaving] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);
//     const [errorMsg, setErrorMsg] = useState(null);
//     const [showSuccessAlert, setShowSuccessAlert] = useState(false);

//     const { sectionId } = useParams();
//     const navigate = useNavigate();

//     useEffect(() => {
//         if (sectionId) {
//             fetchSection(sectionId);
//         }
//     }, []);


//     // Fetch section by id from server
//     const fetchSection = (id) => {
//         setIsLoading(true);
//         getSection(id).then(resp => {
//             const section = resp.data.data;
//             setIsLoading(false);
//             setSection(section);
//             formik.setValues({ ...section }); // get copy of section
//             console.log(section);
//         }).catch(err => {
//             console.log(err);
//             setIsLoading(false);
//         })
//     }

//     // Formik handle submit
//     const formik = useFormik({
//         initialValues: {
//             name: '',
//         },
//         validationSchema: validationSchema,
//         onSubmit: (values) => {
//             const newSection = {
//                 id: section?.id, // in case of update
//                 name: values.name
//             }
//             setIsSaving(true);  
//             setShowSuccessAlert(false);
//             saveOrUpdateSection(newSection).then(resp => {
//                 setIsSaving(false);
//                 setShowSuccessAlert(true);
//                 setErrorMsg(null);
//                 // do not clear in case of update
//                 if (!section?.id) {
//                     formik.resetForm();
//                 }
//             }).catch(err => {
//                 console.log(err);
//                 setIsSaving(false);
//                 setShowSuccessAlert(false);
//                 setErrorMsg(err);
//             });
//         }
//     });

//     // Handle Submit
//     // const handleSubmit = (event) => {
//     //     event.preventDefault();
//     //     event.stopPropagation();
//     //     setValidated(true);
//     //     const form = event.currentTarget;
//     //     if (form.checkValidity() === false) {
//     //         return false;
//     //     }   

//     //     // const section = this.state.section;
//     //     //section.name = form[0].value;
//     //     setSection({
//     //         name: form[0].value
//     //     });
//     //     console.log(section);

//     //     setIsSaving(true);  
//     //     setShowSuccessAlert(false);
//     //     saveOrUpdateSection(section).then(resp => {
//     //         setIsSaving(false);
//     //         setShowSuccessAlert(true);
//     //         setErrorMsg(null);
//     //         // fetch newly added section

//     //     }).catch(err => {
//     //         console.log(err);
//     //         setIsSaving(false);
//     //         setShowSuccessAlert(false);
//     //         setErrorMsg(err);
//     //     })
//     // }

//     return (
//         <Box
//             sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "left",
//                 alignItems: "start",
//             }}
//         >
//             { errorMsg &&
//                 <ErrorAlertTopCenter 
//                     errorMsg={errorMsg}
//                     onClose={() => setErrorMsg(null)} 
//                 />
//             }
//             { showSuccessAlert &&
//                 <SuccessAlertTopRight
//                     successMsg={"Section saved successfully!!"}
//                     onClose={() => setShowSuccessAlert(false)} 
//                 />
//             }

//             <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//                 <Typography component="h2" variant="header">
//                     {section && section.id ? "Update new section" : "Create new section"}
//                 </Typography>
//                 <Chip
//                     onClick={() => navigate(`../list`)}
//                     icon={<FormatListBulletedIcon />}
//                     label="Sections"
//                     variant="outlined"
//                     color="secondary"
//                 />
//             </Box>
//             <Grid container spacing={2} sx={{ mt: 3 }}>
//                 {isLoading &&
//                     <Grid container>
//                         <Grid item>
//                             <CircularProgress />
//                         </Grid>
//                     </Grid>
//                 }
//                 <Grid item xs={12} md={6}>
//                     <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
//                         <TextField
//                             margin="normal"
//                             fullWidth
//                             required
//                             name="name"
//                             label="Section name"
//                             value={formik.values.name}
//                             onChange={formik.handleChange}
//                             error={formik.touched.name && Boolean(formik.errors.name)}
//                             helperText={formik.touched.name && formik.errors.name}
//                         />
//                         <Box
//                             sx={{
//                                 display: 'flex',
//                                 justifyContent: 'center',
//                             }}
//                         >
//                             {isSaving &&
//                                 <CircularProgress />
//                             }
//                             {!isSaving &&
//                                 <Button
//                                     type="submit"
//                                     variant="contained"
//                                     sx={{ mt: 3, mb: 2 }}
//                                 >
//                                     Submit
//                                 </Button>
//                             }
//                         </Box>
//                     </Box>
//                 </Grid>
//             </Grid>
//         </Box>
//     );
// }

// export default NewSection;
import React, { useState, useEffect, useRef } from 'react';

import {
    Button,
    Chip,
    Box,
    Paper,
    Grid,
} from '@mui/material';
import { useSelector } from 'react-redux';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { AlertSnackbar } from '../../../../common/snackbars';
import RouteStudentDialog from '../../../../common/route-student-dialog';
import RouteStudentsTable from './route-students-table';
import { deleteRouteStudent, enableDisableRouteStudent } from '../../../../services/http.service';
import { ModalType } from '../../../../utils/constants';
import { CustomModal } from '../../../../common/modals';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'AddStudents';  // Define logTag for this component

const AddStudents = ({ route, onNext, onBack, onSubmit, onUpdateRoute }) => {
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [isRouteStudentDialogOpen, setRouteStudentDialogOpen] = useState(false);
    const [routeStudentForEdit, setRouteStudentForEdit] = useState(null);
    const [routeStops, setRouteStops] = useState([]);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });
    const [disableJson, setDisableJson] = useState({
        show: false,
        data: {}
    });

    useEffect(() => {
        logger.info('AddStudents component mounted', { logTag, routeId: route.id });  // Log component mount

        if (route && route.stops) {
            const activeStops = route.stops.filter(item => item.isActive);
            setRouteStops(activeStops);
            logger.info('Active stops set for the route', { logTag, activeStops });  // Log active stops
        }
    }, [route]);

    const openRouteStudentDialog = () => {
        logger.info('Opening route student dialog', { logTag });  // Log opening student dialog
        setRouteStudentForEdit(null);  // Reset to null for new
        setRouteStudentDialogOpen(true);
    }

    const closeRouteStudentDialog = () => {
        logger.info('Closing route student dialog', { logTag });  // Log closing student dialog
        setRouteStudentDialogOpen(false);
    }

    const onSaveRouteStudent = (data) => {
        logger.info('Saving route student', { logTag, studentData: data });  // Log saving student
        const updatedRoute = {
            ...route,
            id: data.route_id
        }
        onSubmit(updatedRoute);
    }

    const onUpdateRouteStudent = (data) => {
        logger.info('Updating route student', { logTag, studentData: data });  // Log updating student
    }

    // ################### Route student table methods ######################

    // Handle delete of route student
    const handleDelete = (routeStudent) => {
        logger.info('Delete route student requested', { logTag, studentId: routeStudent.student.id });  // Log delete request
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                routeId: route.id,
                studentId: routeStudent.student.id,
            }
        });
    }

    const onDelete = (data) => {
        logger.info('Confirm delete route student', { logTag, studentId: data.studentId });  // Log confirm delete
        setDeleteJson({
            ...deleteJson,
            show: false
        });
        deleteRouteStudent(data.routeId, data.studentId).then(resp => {
            setSuccessMsg("Deleted successfully");
            setErrorMsg(null);
            logger.info('Route student deleted successfully', { logTag, studentId: data.studentId });  // Log success
            onSubmit(route);  // Trigger fetch route to refresh
        }).catch(err => {
            logger.error('Error deleting route student', err, { logTag, studentId: data.studentId });  // Log error
            setErrorMsg(err);
            setSuccessMsg(null);
        });
    }

    // Handle disable of route stop
    const handleDisable = (routeStudent, isDisabled) => {
        logger.info('Disable/Enable route student requested', { logTag, studentId: routeStudent.student.id, isDisabled });  // Log disable/enable request
        setDisableJson({
            ...disableJson,
            show: true,
            data: {
                routeId: route.id,
                studentId: routeStudent.student.id,
                isDisabled: isDisabled
            }
        });
    }

    const onDisable = (data) => {
        logger.info('Confirm disable/enable route student', { logTag, studentId: data.studentId, isDisabled: data.isDisabled });  // Log confirm disable/enable
        setDisableJson({
            ...disableJson,
            show: false,
        });
        enableDisableRouteStudent(data.routeId, data.studentId, data.isDisabled).then(resp => {
            setSuccessMsg(`${data.isDisabled ? 'Disabled' : 'Enabled'} successfully`);
            setErrorMsg(null);
            logger.info('Route student disabled/enabled successfully', { logTag, studentId: data.studentId, isDisabled: data.isDisabled });  // Log success
            onSubmit(route);  // Trigger fetch route to refresh
        }).catch(err => {
            logger.error('Error disabling/enabling route student', err, { logTag, studentId: data.studentId });  // Log error
            setErrorMsg(err);
            setSuccessMsg(null);
        })
    }

    const handleEdit = (routeStudent) => {
        logger.info('Edit route student requested', { logTag, studentId: routeStudent.student.id });  // Log edit request
        setRouteStudentForEdit(routeStudent);
        setRouteStudentDialogOpen(true);
    }

    // ################### End Route student table methods ##################

    return (
        <>
            {errorMsg || successMsg &&
                <AlertSnackbar 
                    errorMsg={errorMsg}
                    successMsg={successMsg}
                    onClose={() => {
                        logger.info('AlertSnackbar closed', { logTag });  // Log alert snackbar close
                        setErrorMsg(null);
                        setSuccessMsg(null);
                    }}
                />
            }

            {/* Show route student dialog */}
            <RouteStudentDialog
                open={isRouteStudentDialogOpen}
                onClose={closeRouteStudentDialog}
                routeId={route.id}
                routeType={route.type}
                routeStops={routeStops}
                onSubmit={onSaveRouteStudent}
                onUpdate={onUpdateRouteStudent}
                routeStudentForEdit={routeStudentForEdit}
            />
            <RouteStudentsTable
                routeId={route.id}
                routeStudents={route.students}
                onDelete={handleDelete}
                onDisable={handleDisable}
                onEdit={handleEdit}
            />

            {/* Add new student */}
            <Chip
                onClick={openRouteStudentDialog}
                icon={<AddCircleIcon />}
                label="Add Student"
                variant="outlined"
                color="secondary"
                sx={{ mt: 1 }}
            />

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ my: 2 }}>
                    <Button
                        variant="outlined"
                        sx={{ mx: 2 }}
                        startIcon={<ArrowBackIosNewIcon />}
                        onClick={() => {
                            logger.info('Back button clicked', { logTag, routeId: route.id });  // Log back button click
                            onBack();
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{ mx: 2 }}
                        startIcon={<ArrowForwardIosIcon />}
                        onClick={() => {
                            logger.info('Next button clicked', { logTag, routeId: route.id });  // Log next button click
                            onNext();
                        }}
                    >
                        Next
                    </Button>
                </Box>
            </Box>

            {/* Delete modal */}
            <CustomModal
                show={deleteJson.show}
                data={deleteJson.data}
                type={ModalType.CONFIRM_DELETE}
                onOkay={(data) => {
                    logger.info('Delete route student confirmed', { logTag, studentId: deleteJson.data.studentId });  // Log delete confirmation
                    onDelete(data);
                }}
                onHide={() => {
                    logger.info('Delete modal closed', { logTag });  // Log delete modal close
                    setDeleteJson({ ...deleteJson, show: false });
                }}
            />

            {/* Disable modal */}
            <CustomModal
                show={disableJson.show}
                data={disableJson.data}
                type={ModalType.CONFIRM_DISABLE}
                onOkay={(data) => {
                    logger.info('Disable/Enable route student confirmed', { logTag, studentId: disableJson.data.studentId });  // Log disable/enable confirmation
                    onDisable(data);
                }}
                onHide={() => {
                    logger.info('Disable/Enable modal closed', { logTag });  // Log disable/enable modal close
                    setDisableJson({ ...disableJson, show: false });
                }}
            />
        </>
    );
}

export default AddStudents;



// import React, { useState, useEffect, useRef } from 'react';

// import {
//     Button,
//     Chip,
//     Box,
//     Paper,
//     Grid,
// } from '@mui/material';
// import { useSelector } from 'react-redux';

// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// import { AlertSnackbar } from '../../../../common/snackbars';
// import RouteStudentDialog from '../../../../common/route-student-dialog';
// import RouteStudentsTable from './route-students-table';
// import { deleteRouteStudent, enableDisableRouteStudent } from '../../../../services/http.service';
// import { ModalType } from '../../../../utils/constants';
// import { CustomModal } from '../../../../common/modals';

// const AddStudents = ({ route, onNext, onBack, onSubmit, onUpdateRoute }) => {
//     const [errorMsg, setErrorMsg] = useState(null);
//     const [successMsg, setSuccessMsg] = useState(null);
//     const [isRouteStudentDialogOpen, setRouteStudentDialogOpen] = useState(false);
//     const [routeStudentForEdit, setRouteStudentForEdit] = useState(null);
//     const [routeStops, setRouteStops] = useState([]);
//     const [deleteJson, setDeleteJson] = useState({
//         show: false,
//         data: {}
//     });
//     const [disableJson, setDisableJson] = useState({
//         show: false,
//         data: {}
//     });

//     const openRouteStudentDialog = () => {
//         setRouteStudentForEdit(null); // set to null for new 
//         setRouteStudentDialogOpen(true);
//     }

//     const closeRouteStudentDialog = () => {
//         setRouteStudentDialogOpen(false);
//     }

//     const onSaveRouteStudent = (data) => {
//         console.log("onSaveRouteStudent ", data);
//         // Set route it if it's new route
//         const updatedRoute = {
//             ...route,
//             id: data.route_id
//         }
//         onSubmit(updatedRoute);
//     }

//     const onUpdateRouteStudent = (data) => {
//     }

//     useEffect(() => {
//         if (route && route.stops) {
//             const activeStops = route.stops.filter(item => item.isActive);
//             setRouteStops(activeStops);
//         }
//     }, [route]);

//     // ################### Route student table methods ######################

//     // Handle delete of route student
//     const handleDelete = (routeStudent) => {
//         console.log("handleDelete ", routeStudent);
//         setDeleteJson({
//             ...deleteJson,
//             show: true,
//             data: {
//                 routeId: route.id,
//                 studentId: routeStudent.student.id,
//             }
//         });
//     }
//     const onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));
//         // Hide delete confirm modal
//         setDeleteJson({
//             ...deleteJson,
//             show: false
//         });
//         deleteRouteStudent(data.routeId, data.studentId).then(resp => {
//             setSuccessMsg("Deleted successfully");
//             setErrorMsg(null);
//             onSubmit(route); // Trigger fetch route to refresh
//         }).catch(err => {
//             setErrorMsg(err);
//             setSuccessMsg(null);
//         });
//     }


//     // Handle disable of route stop
//     const handleDisable = (routeStudent, isDisabled) => {
//         console.log("handleDisable ", routeStudent);
//         setDisableJson({
//             ...disableJson,
//             show: true,
//             data: {
//                 routeId: route.id,
//                 studentId: routeStudent.student.id,
//                 isDisabled: isDisabled
//             }
//         });
//     }
//     const onDisable = (data) => {
//         console.log('on disable data ' + JSON.stringify(data));
//         // Hide delete confirm modal
//         setDisableJson({
//             ...disableJson,
//             show: false,
//         });
//         enableDisableRouteStudent(data.routeId, data.studentId, data.isDisabled).then(resp => {
//             setSuccessMsg(`${data.isDisabled ? 'Disabled':'Enabled'} successfully`);
//             setErrorMsg(null);
//             onSubmit(route); // Trigger fetch route to refresh
//         }).catch(err => {
//             setErrorMsg(err);
//             setSuccessMsg(null);
//         })
//     }

//     const handleEdit = (routeStudent) => {
//         console.log("handleEdit ", routeStudent);
//         setRouteStudentForEdit(routeStudent);
//         setRouteStudentDialogOpen(true);
//     }

//     // ################### End Route student table methods ##################


//     return (
//         <>
//             {errorMsg || successMsg &&
//                 <AlertSnackbar 
//                     errorMsg={errorMsg}
//                     successMsg={successMsg}
//                     onClose={() => {
//                         setErrorMsg(null);
//                         setSuccessMsg(null);
//                     }}
//                 />
//             }
//             {/* Show route student dialog */}
//             <RouteStudentDialog
//                 open={isRouteStudentDialogOpen}
//                 onClose={closeRouteStudentDialog}
//                 routeId={route.id}
//                 routeType={route.type}
//                 routeStops={routeStops}
//                 onSubmit={onSaveRouteStudent}
//                 onUpdate={onUpdateRouteStudent}
//                 routeStudentForEdit={routeStudentForEdit}
//             />
//             <RouteStudentsTable
//                 routeId={route.id}
//                 routeStudents={route.students}
//                 onDelete={handleDelete}
//                 onDisable={handleDisable}
//                 onEdit={handleEdit}
//             />
//             {/* Add new student */}
//             <Chip
//                 onClick={openRouteStudentDialog}
//                 icon={<AddCircleIcon />}
//                 label="Add Student"
//                 variant="outlined"
//                 color="secondary"
//                 sx={{ mt: 1 }}
//             />
//             <Box sx={{ display: 'flex', flexDirection: 'column' }}>
//                 <Box sx={{ my: 2 }}>
//                     <Button
//                         variant="outlined"
//                         sx={{ mx: 2 }}
//                         startIcon={<ArrowBackIosNewIcon />}
//                         onClick={onBack}
//                     >
//                         Back
//                     </Button>
//                     <Button
//                         variant="outlined"
//                         sx={{ mx: 2 }}
//                         startIcon={<ArrowForwardIosIcon />}
//                         onClick={onNext}
//                     >
//                         Next
//                     </Button>
//                 </Box>
//             </Box>

//             {/* Delete modal */}
//             <CustomModal
//                 show={deleteJson.show}
//                 data={deleteJson.data}
//                 type={ModalType.CONFIRM_DELETE}
//                 onOkay={(data) => { onDelete(data) }}
//                 onHide={ () => {setDeleteJson({...deleteJson, show: false})} }
//             />
//             {/* Disable modal */}
//             <CustomModal
//                 show={disableJson.show}
//                 data={disableJson.data}
//                 type={ModalType.CONFIRM_DISABLE}
//                 onOkay={(data) => { onDisable(data) }}
//                 onHide={ () => {setDisableJson({...disableJson, show: false})} }
//             />
//         </>
//     );
// }

// export default AddStudents;
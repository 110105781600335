import React, { Component, useState, useEffect } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
    Button, Chip, Paper, Grid, TableBody, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { getBuses, deleteBus } from '../../../../services/http.service';
import { CustomModal } from '../../../../common/modals';
import Loader from '../../../../common/loader';
import { ModalType } from '../../../../utils/constants';
import { VertActionMenu } from '../../../../common/action-menu';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'BusList';  // Define logTag for this component

const BusList = () => {

    const [buses, setBuses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });

    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();

    useEffect(() => {
        logger.info('BusList component mounted', { logTag });  // Log component mount
        fetchBuses();
    }, []);

    // Fetch buses
    const fetchBuses = () => {
        logger.info('Fetching buses', { logTag });  // Log fetching buses
        setIsLoading(true);
        getBuses().then(resp => {
            setBuses(resp.data.data);
            setIsLoading(false);
            logger.info('Buses fetched successfully', { logTag, buses: resp.data.data });  // Log success
        }).catch(err => {
            logger.error('Error fetching buses', err, { logTag });  // Log error
            setIsLoading(false);
        });
    }

    // Handle Delete modal   
    const handleDelete = (id) => {
        logger.info('Delete bus requested', { logTag, busId: id });  // Log delete request
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });
    }

    // On Delete of Bus
    const onDelete = (data) => {
        logger.info('Confirm delete bus', { logTag, busId: data.id });  // Log confirm delete
        console.log('on delete data ' + JSON.stringify(data));

        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false
        });
        // Show spinner
        setIsLoading(true);
        deleteBus(data.id).then(resp => {
            setIsLoading(false);
            logger.info('Bus deleted successfully', { logTag, busId: data.id });  // Log success
            fetchBuses();
        }).catch(err => {
            logger.error('Error deleting bus', err, { logTag, busId: data.id });  // Log error
            setIsLoading(false);         
        });
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        Buses
                    </Typography>
                    <Chip
                        onClick={() => {
                            logger.info('Navigating to create new bus', { logTag });  // Log navigation to create bus
                            navigate(`../new`);
                        }}
                        icon={<AddCircleOutlineIcon />}
                        label="New bus"
                        variant="outlined"
                        color="secondary"
                    />
                </Box>
                {isLoading &&
                    <Loader />
                }

                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '.8rem' }}>#</TableCell>
                                        <TableCell>Number</TableCell>
                                        <TableCell>Manufacturer</TableCell>
                                        <TableCell>Model</TableCell>
                                        <TableCell>Brand</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {buses.map((item, index) => (
                                        <TableRow
                                            key={item.id}
                                        >
                                            <TableCell align="left">{index + 1}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.number}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.manufacturer}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.model}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.brand}
                                            </TableCell>
                                            <TableCell align="right">
                                                <VertActionMenu
                                                    onEdit={() => {
                                                        logger.info('Navigating to edit bus', { logTag, busId: item.id });  // Log edit bus
                                                        navigate(`${baseUrl + "/bus/edit/" + item.id}`);
                                                    }}
                                                    onDelete={() => handleDelete(item.id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>

                {/* Delete modal */}
                <CustomModal
                    show={deleteJson.show}
                    data={deleteJson.data}
                    type={ModalType.CONFIRM_DELETE}
                    onOkay={(data) => {
                        logger.info('Delete confirmed for bus', { logTag, busId: data.id });  // Log delete confirmation
                        onDelete(data);
                    }}
                    onHide={() => {
                        logger.info('Delete modal closed', { logTag });  // Log delete modal close
                        setDeleteJson({ ...deleteJson, show: false });
                    }}
                >
                </CustomModal>
            </Box>
        </>
    );
}

export default BusList;


// import React, { Component, useState, useEffect } from 'react';
// import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
//     Button, Chip, Paper, Grid, TableBody, IconButton } from '@mui/material';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { useSelector } from 'react-redux';
// import { useNavigate } from "react-router-dom";

// import { getBuses, deleteBus } from '../../../../services/http.service';
// import { CustomModal } from '../../../../common/modals';
// import Loader from '../../../../common/loader';
// import { ModalType } from '../../../../utils/constants';
// import { VertActionMenu } from '../../../../common/action-menu';


// const BusList = () => {

//     const [buses, setBuses] = useState([]);
//     const [isLoading, setIsLoading] = useState(true);
//     const [deleteJson, setDeleteJson] = useState({
//         show: false,
//         data: {}
//     });

//     const baseUrl = useSelector(state => state.extras.baseUrl);
//     const navigate = useNavigate();

//     useEffect(() => {
//         fetchBuses();
//     }, []);

//     // Fetch buses
//     const fetchBuses = () => {
//         setIsLoading(true);
//         getBuses().then(resp => {
//             setBuses(resp.data.data);
//             setIsLoading(false);
//         }).catch(err => {
//             console.log(err);
//             setIsLoading(false);
//         })
//     }

//     // Handle Delete modal   
//     const handleDelete = (id) => {
//         setDeleteJson({
//             ...deleteJson,
//             show: true,
//             data: {
//                 id: id
//             }
//         });
//         console.log(`delelte - ${JSON.stringify(deleteJson)}`);
//     }

//     // On Delete of Bus
//     const onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));

//         // Hide delete confirm modal
//         setDeleteJson({
//             ...deleteJson,
//             show: false
//         });
//         // Show spinner
//         setIsLoading(true);
//         deleteBus(data.id).then(resp => {
//             setIsLoading(false);
//             fetchBuses();
//         }).catch(err => {
//             setIsLoading(false);         
//         })
//     }

//     return (
//         <>
//             <Box
//                 sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "left",
//                     alignItems: "start",
//                 }}
//             >
//                 <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//                     <Typography component="h2" variant="header">
//                         Buses
//                     </Typography>
//                     <Chip
//                         onClick={() => navigate(`../new`)}
//                         icon={<AddCircleOutlineIcon />}
//                         label="New bus"
//                         variant="outlined"
//                         color="secondary"
//                     />
//                 </Box>
//                 { isLoading &&
//                     <Loader />
//                 }

//                 <Grid container spacing={2} sx={{ mt: 3 }}>
//                     <Grid item xs={12}>
//                         <TableContainer component={Paper}>
//                             <Table aria-label="simple table">
//                                 <TableHead>
//                                     <TableRow>
//                                         <TableCell sx={{ width: '.8rem' }}>#</TableCell>
//                                         <TableCell>Number</TableCell>
//                                         <TableCell>Manufacturer</TableCell>
//                                         <TableCell>Model</TableCell>
//                                         <TableCell>Brand</TableCell>
//                                         <TableCell align="right">Action</TableCell>
//                                     </TableRow>
//                                 </TableHead>
//                                 <TableBody>
//                                     {buses.map((item, index) => (
//                                         <TableRow
//                                             key={item.id}
//                                         >
//                                             <TableCell align="left">{index + 1}</TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.number}
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.manufacturer}
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.model}
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.brand}
//                                             </TableCell>
//                                             <TableCell align="right">
//                                                 <VertActionMenu
//                                                     onEdit={() => navigate(`${baseUrl + "/bus/edit/" + item.id}`)}
//                                                     onDelete={() => handleDelete(item.id)}
//                                                 />
//                                             </TableCell>
//                                         </TableRow>
//                                     ))}
//                                 </TableBody>
//                             </Table>
//                         </TableContainer>
//                     </Grid>
//                 </Grid>
//                 {/* Delete modal */}
//                 <CustomModal
//                     show={deleteJson.show}
//                     data={deleteJson.data}
//                     type={ModalType.CONFIRM_DELETE}
//                     onOkay={(data) => { onDelete(data) }}
//                     onHide={ () => {setDeleteJson({...deleteJson, show: false})} }
//                 >
//                 </CustomModal>
//             </Box>
//         </>
//     );
// }

// export default BusList;
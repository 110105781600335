import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
    Button, Chip, Accordion, AccordionSummary, AccordionDetails, TextField, Paper, Grid, TableBody, IconButton, 
    FormControl, InputLabel, Select, MenuItem, Tabs, TableFooter, TablePagination,
    Tab } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getRoutesWithDistinctRef, getHistoryTrips, getBuses, getDrivers } from '../../../../services/http.service';
import { formatDateTime, convertTripStatus, TripStatus, convertRouteType } from '../../../../utils/constants';
import Loader from '../../../../common/loader';
import CustomPaginationActions from '../../../../utils/pagination';
import { VertActionMenu } from '../../../../common/action-menu';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'HistoryList';  // Define logTag as component name

const HistoryList = () => {

    const [trips, setTrips] = useState([]);
    const [buses, setBuses] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [filter, setFilter] = useState({
        date: null,
        routeRef: null,
        tripStatus: null,
        busId: null,
        driverId: null,
    });
    const [routes, setRoutes] = useState([]);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    let [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const didMount = useRef(true);

    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();


    useEffect(() => {
        logger.info('HistoryList component mounted', { logTag });
        fetchRoutes(); 
        fetchBuses();
        fetchDrivers();
        showTrips();
    }, []);

    // Fetch Routes with only Ref and type
    const fetchRoutes = () => {
        logger.debug('Fetching routes...', { logTag });
        getRoutesWithDistinctRef().then(resp => {
            const respRoutes = resp.data.data;
            const routes = respRoutes;
            setRoutes(routes);
            logger.info('Routes fetched successfully', { routes, logTag });
        }).catch(err => {
            logger.error('Error fetching routes', err, { logTag });
        });
    }

    // Fetch all buses of school
    const fetchBuses = () => {
        logger.debug('Fetching buses...', { logTag });
        getBuses().then(resp => {
            setBuses(resp.data.data);
            logger.info('Buses fetched successfully', { buses: resp.data.data, logTag });
        }).catch(err => {
            logger.error('Error fetching buses', err, { logTag });
        });
    }

    // Fetch all drivers of school
    const fetchDrivers = () => {
        logger.debug('Fetching drivers...', { logTag });
        getDrivers().then(resp => {
            setDrivers(resp.data.data);
            logger.info('Drivers fetched successfully', { drivers: resp.data.data, logTag });
        }).catch(err => {
            logger.error('Error fetching drivers', err, { logTag });
        });
    }

    // Fetch filters based on filter criteria
    const showTrips = () => {
        logger.debug(`Showing trips with filter: ${JSON.stringify(filter)}`, { logTag });
        setIsLoading(true);
        setErrorMsg(null);
        getHistoryTrips(filter, page, rowsPerPage).then(resp => {
            const respData = resp.data.data;
            setIsLoading(false);
            setErrorMsg(null);
            setTrips(respData.trips);
            setTotalCount(respData.total_count);
            logger.info('Trips fetched successfully', { trips: respData.trips, logTag });
        }).catch(err => {
            setIsLoading(false);
            setErrorMsg(err);
            logger.error('Error fetching trips', err, { logTag });
        });
    }

    // ******* Pagination methods *****************

    const handleChangePage = (event, newPage) => {
        logger.info(`Page changed: ${newPage}`, { logTag });
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        logger.info(`Rows per page changed: ${newRowsPerPage}`, { logTag });
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    useEffect(() => {
        // avoid first render
        if (didMount.current) {
            didMount.current = false;
            return;
        }
        logger.info('Page or rowsPerPage changed', { page, rowsPerPage, logTag });
        showTrips();
    }, [page, rowsPerPage]);


    // const onFirst = () => {
    //     console.log(`onFirst`);
    //     page = 0;
    //     setPage(page);
    //     showTrips();   
    // }

    // const onPrevious = () => {
    //     console.log(`onPrevious`);
    //     if (page > 0) {
    //         page -= 1;
    //         setPage(page);
    //         showTrips();
    //     }
    // }

    // const onNext = () => {
    //     console.log(`onNext`);
    //     console.log(`cal count - ${(page * limit)}`)
    //     // check if records on next page is less than the total record count i.e (page + 1)
    //     if (((page + 1) * limit) < totalCount) {
    //         page += 1;
    //         setPage(page);
    //         showTrips();
    //     }
    // }

    // const onLast = () => {
    //     console.log(`onLast`);
    //     // Calculate last page number
    //     // const { page, totalCount, limit } = this.state;
    //     const newPage = Math.floor(totalCount/limit);
    //     console.log(`new page - ${newPage}`);  
    //     page = newPage;
    //     setPage(page);   
    //     showTrips();   
    // }
    // ******* End Pagination *****************


    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        Trip History
                    </Typography>
                    <Box>
                        <Chip
                            onClick={() => navigate(`../todays/list`)}
                            sx={{ mr: 3 }}
                            icon={<HistoryIcon />}
                            label="Todays History"
                            variant="outlined"
                            color="secondary"
                        />
                    </Box>
                </Box>
                <Accordion sx={{ mt: 2, width: '100%' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Filter</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={4}>
                                <MobileDatePicker
                                    label="Date"
                                    value={filter.date}
                                    inputFormat="MM/dd/yyyy"
                                    onChange={(date) => {
                                        setFilter({
                                            ...filter,
                                            date: date,
                                        });
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="route-select">Route number/ref</InputLabel>
                                    <Select
                                        labelId="route-select"
                                        id="route-select"
                                        label="Route ref/number"
                                        onChange={e => {
                                            const refNo = e.target.value;
                                            if (refNo) {
                                                setFilter({
                                                    ...filter,
                                                    routeRef: refNo
                                                });
                                            }
                                        }}
                                        value={filter.routeRef}
                                    >
                                        {routes.map((item, index) => (
                                            <MenuItem key={index} value={item.ref_no}>{item.number} ({item.ref_no})</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="status-select">Trip Status</InputLabel>
                                    <Select
                                        labelId="status-select"
                                        id="status-select"
                                        label="Trip Status"
                                        onChange={e => {
                                            const value = e.target.value;
                                            if (value) {
                                                setFilter({
                                                    ...filter,
                                                    tripStatus: value
                                                });
                                            }
                                        }}
                                        value={filter.tripStatus}
                                    >
                                        {[TripStatus.NEW, TripStatus.FINISHED, TripStatus.ABANDONED].map((item, index) => (
                                            <MenuItem key={index} value={item}>{convertTripStatus(item)}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12} md={6} lg={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="bus-select">Trip Bus</InputLabel>
                                    <Select
                                        labelId="bus-select"
                                        id="bus-select"
                                        label="Trip Bus"
                                        onChange={e => {
                                            const value = e.target.value;
                                            if (value) {
                                                setFilter({
                                                    ...filter,
                                                    busId: value
                                                });
                                            }
                                        }}
                                        value={filter.busId}
                                    >
                                        {buses.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>{item.number}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="driver-select">Bus Driver</InputLabel>
                                    <Select
                                        labelId="driver-select"
                                        id="driver-select"
                                        label="Bus Driver"
                                        onChange={e => {
                                            const value = e.target.value;
                                            if (value) {
                                                setFilter({
                                                    ...filter,
                                                    driverId: value
                                                });
                                            }
                                        }}
                                        value={filter.driverId}
                                    >
                                        {drivers.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>{item.user.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            {!isLoading &&
                                <Button
                                    type="button"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={() => {
                                        page = 0;
                                        setPage(page);
                                        showTrips();
                                    }}
                                >
                                    Filter trips
                                </Button>
                            }
                        </Box>
                    </AccordionDetails>
                </Accordion>
                { isLoading &&
                    <Loader />
                }
                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '.8rem' }}>#</TableCell>
                                        <TableCell>Ref No.</TableCell>
                                        <TableCell>Route No.</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Expected Start Time</TableCell>
                                        <TableCell>Actual Start Time</TableCell>
                                        <TableCell>Bus</TableCell>
                                        <TableCell>Driver</TableCell>
                                        {filter.tripStatus === TripStatus.NEW &&
                                            <TableCell align="right">Action</TableCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {trips.map((item, index) => (
                                        <TableRow
                                            key={item.name}
                                        >
                                            <TableCell align="left">{page * rowsPerPage + (index + 1)}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.ref_no}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.route.number}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {convertRouteType(item.route.type.name)}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {formatDateTime(item.expected_start_time)}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {formatDateTime(item.actual_start_time)}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.bus ? item.bus.number : '' }
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.driver ? item.driver.user.name : '' }
                                            </TableCell>
                                            <TableCell align="right">
                                                <VertActionMenu
                                                    onView={() => navigate(`${baseUrl}/trip/${item.id}/detail-view`)}
                                                />
                                            </TableCell>
                                            
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            colSpan={3}
                                            count={totalCount}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'rows per page',
                                                },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={CustomPaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default HistoryList;





// import React, { Component, useState, useEffect, useRef } from 'react';
// import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
//     Button, Chip, Accordion, AccordionSummary, AccordionDetails, TextField, Paper, Grid, TableBody, IconButton, 
//     FormControl, InputLabel, Select, MenuItem, Tabs, TableFooter, TablePagination,
//     Tab } from '@mui/material';
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { useSelector } from 'react-redux'
// import HistoryIcon from '@mui/icons-material/History';
// import DatePicker from "react-datepicker";
// import { Link } from "react-router-dom";
// import "react-datepicker/dist/react-datepicker.css";
// import { useNavigate } from "react-router-dom";
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';

// import { getRoutesWithDistinctRef, getHistoryTrips, getBuses, getDrivers } from '../../../../services/http.service';
// import { CustomModal } from '../../../../common/modals';

// import { formatDateTime, convertTripStatus, TripStatus, convertRouteType } from '../../../../utils/constants';
// import ListTab from './list-tab';
// import CustomPagination from '../../../../utils/pagination';
// import Loader from '../../../../common/loader';
// import CustomPaginationActions from '../../../../utils/pagination';
// import { VertActionMenu } from '../../../../common/action-menu';


// const HistoryList = () => {

//     const [trips, setTrips] = useState([]);
//     const [buses, setBuses] = useState([]);
//     const [drivers, setDrivers] = useState([]);
//     const [filter, setFilter] = useState({
//         date: null,
//         routeRef: null,
//         tripStatus: null,
//         busId: null,
//         driverId: null,
//     });
//     const [routes, setRoutes] = useState([]);
//     const [deleteJson, setDeleteJson] = useState({
//         show: false,
//         data: {}
//     });
//     const [isLoading, setIsLoading] = useState(false);
//     const [errorMsg, setErrorMsg] = useState(null);
//     let [page, setPage] = useState(0);
//     const [totalCount, setTotalCount] = useState(0);
//     const [rowsPerPage, setRowsPerPage] = useState(10);
//     const didMount = useRef(true);

//     const baseUrl = useSelector(state => state.extras.baseUrl);
//     const navigate = useNavigate();


//     useEffect(() => {
//         fetchRoutes(); 
//         fetchBuses();
//         fetchDrivers();
//         showTrips();
//     }, []);

//     // Fetch Routes with only Ref and type
//     const fetchRoutes = () => {
//         getRoutesWithDistinctRef().then(resp => {
//             const respRoutes = resp.data.data;
//             const routes = respRoutes;
//             setRoutes(routes);            
//         }).catch(err => {
//             console.log(err);
//         })
//     }

//     // Fetch all buses of school
//     const fetchBuses = () => {
//         getBuses().then(resp => {
//             setBuses(resp.data.data);
//         }).catch(err => {
//             console.log(err);
//         })
//     }

//     // Fetch all drivers of school
//     const fetchDrivers = () => {
//         getDrivers().then(resp => {
//             setDrivers(resp.data.data);
//         }).catch(err => {
//             console.log(err);
//         })
//     }

//     // Fetch filters based on filter criteria
//     const showTrips = () => {
//         console.log(`show trips filter - ${JSON.stringify(filter)}`);
//         setIsLoading(true);
//         setErrorMsg(null);
//         getHistoryTrips(filter, page, rowsPerPage).then(resp => {
//             const respData = resp.data.data;
//             setIsLoading(false);
//             setErrorMsg(null);
//             setTrips(respData.trips);
//             setTotalCount(respData.total_count);
//         }).catch(err => {
//             setIsLoading(false);
//             setErrorMsg(err);
//         })
//     }

//     // ******* Pagination methods *****************


//     const handleChangePage = (event, newPage) => {
//         setPage(newPage);
//     };

//     const handleChangeRowsPerPage = (event) => {
//         setRowsPerPage(parseInt(event.target.value, 10));
//         setPage(0);
//     };

//     useEffect(() => {
//         // avoid first render
//         if (didMount.current) {
//             didMount.current = false;
//             return;
//         }
//         showTrips();
//     }, [page, rowsPerPage]);


//     // const onFirst = () => {
//     //     console.log(`onFirst`);
//     //     page = 0;
//     //     setPage(page);
//     //     showTrips();   
//     // }

//     // const onPrevious = () => {
//     //     console.log(`onPrevious`);
//     //     if (page > 0) {
//     //         page -= 1;
//     //         setPage(page);
//     //         showTrips();
//     //     }
//     // }

//     // const onNext = () => {
//     //     console.log(`onNext`);
//     //     console.log(`cal count - ${(page * limit)}`)
//     //     // check if records on next page is less than the total record count i.e (page + 1)
//     //     if (((page + 1) * limit) < totalCount) {
//     //         page += 1;
//     //         setPage(page);
//     //         showTrips();
//     //     }
//     // }

//     // const onLast = () => {
//     //     console.log(`onLast`);
//     //     // Calculate last page number
//     //     // const { page, totalCount, limit } = this.state;
//     //     const newPage = Math.floor(totalCount/limit);
//     //     console.log(`new page - ${newPage}`);  
//     //     page = newPage;
//     //     setPage(page);   
//     //     showTrips();   
//     // }
//     // ******* End Pagination *****************


//     return (
//         <>
//             <Box
//                 sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "left",
//                     alignItems: "start",
//                 }}
//             >
//                 <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//                     <Typography component="h2" variant="header">
//                         Trip History
//                     </Typography>
//                     <Box>
//                         <Chip
//                             onClick={() => navigate(`../todays/list`)}
//                             sx={{ mr: 3 }}
//                             icon={<HistoryIcon />}
//                             label="Todays History"
//                             variant="outlined"
//                             color="secondary"
//                         />
//                     </Box>
//                 </Box>
//                 <Accordion sx={{ mt: 2, width: '100%' }}>
//                     <AccordionSummary
//                         expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel1a-content"
//                         id="panel1a-header"
//                     >
//                         <Typography>Filter</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails>
//                         <Grid container spacing={2}>
//                             <Grid item xs={12} md={6} lg={4}>
//                                 <MobileDatePicker
//                                     label="Date"
//                                     value={filter.date}
//                                     inputFormat="MM/dd/yyyy"
//                                     onChange={(date) => {
//                                         setFilter({
//                                             ...filter,
//                                             date: date,
//                                         });
//                                     }}
//                                     renderInput={(params) => <TextField {...params} />}
//                                 />
//                             </Grid>
//                             <Grid item xs={12} md={6} lg={4}>
//                                 <FormControl fullWidth>
//                                     <InputLabel id="route-select">Route number/ref</InputLabel>
//                                     <Select
//                                         labelId="route-select"
//                                         id="route-select"
//                                         label="Route ref/number"
//                                         onChange={e => {
//                                             const refNo = e.target.value;
//                                             if (refNo) {
//                                                 setFilter({
//                                                     ...filter,
//                                                     routeRef: refNo
//                                                 });
//                                             }
//                                         }}
//                                         value={filter.routeRef}
//                                     >
//                                         {routes.map((item, index) => (
//                                             <MenuItem key={index} value={item.ref_no}>{item.number} ({item.ref_no})</MenuItem>
//                                         ))}
//                                     </Select>
//                                 </FormControl>
//                             </Grid>
//                             <Grid item xs={12} md={6} lg={4}>
//                                 <FormControl fullWidth>
//                                     <InputLabel id="status-select">Trip Status</InputLabel>
//                                     <Select
//                                         labelId="status-select"
//                                         id="status-select"
//                                         label="Trip Status"
//                                         onChange={e => {
//                                             const value = e.target.value;
//                                             if (value) {
//                                                 setFilter({
//                                                     ...filter,
//                                                     tripStatus: value
//                                                 });
//                                             }
//                                         }}
//                                         value={filter.tripStatus}
//                                     >
//                                         {[TripStatus.NEW, TripStatus.FINISHED, TripStatus.ABANDONED].map((item, index) => (
//                                             <MenuItem key={index} value={item}>{convertTripStatus(item)}</MenuItem>
//                                         ))}
//                                     </Select>
//                                 </FormControl>
//                             </Grid>
//                         </Grid>
//                         <Grid container spacing={2} sx={{ mt: 1 }}>
//                             <Grid item xs={12} md={6} lg={4}>
//                                 <FormControl fullWidth>
//                                     <InputLabel id="bus-select">Trip Bus</InputLabel>
//                                     <Select
//                                         labelId="bus-select"
//                                         id="bus-select"
//                                         label="Trip Bus"
//                                         onChange={e => {
//                                             const value = e.target.value;
//                                             if (value) {
//                                                 setFilter({
//                                                     ...filter,
//                                                     busId: value
//                                                 });
//                                             }
//                                         }}
//                                         value={filter.busId}
//                                     >
//                                         {buses.map((item, index) => (
//                                             <MenuItem key={index} value={item.id}>{item.number}</MenuItem>
//                                         ))}
//                                     </Select>
//                                 </FormControl>
//                             </Grid>
//                             <Grid item xs={12} md={6} lg={4}>
//                                 <FormControl fullWidth>
//                                     <InputLabel id="driver-select">Bus Driver</InputLabel>
//                                     <Select
//                                         labelId="driver-select"
//                                         id="driver-select"
//                                         label="Bus Driver"
//                                         onChange={e => {
//                                             const value = e.target.value;
//                                             if (value) {
//                                                 setFilter({
//                                                     ...filter,
//                                                     driverId: value
//                                                 });
//                                             }
//                                         }}
//                                         value={filter.driverId}
//                                     >
//                                         {drivers.map((item, index) => (
//                                             <MenuItem key={index} value={item.id}>{item.user.name}</MenuItem>
//                                         ))}
//                                     </Select>
//                                 </FormControl>
//                             </Grid>
//                         </Grid>
//                         <Box
//                             sx={{
//                                 width: '100%',
//                                 display: 'flex',
//                                 justifyContent: 'center',
//                             }}
//                         >
//                             {!isLoading &&
//                                 <Button
//                                     type="button"
//                                     variant="contained"
//                                     sx={{ mt: 3, mb: 2 }}
//                                     onClick={() => {
//                                         page = 0;
//                                         setPage(page);
//                                         showTrips();
//                                     }}
//                                 >
//                                     Filter trips
//                                 </Button>
//                             }
//                         </Box>
//                     </AccordionDetails>
//                 </Accordion>
//                 { isLoading &&
//                     <Loader />
//                 }
//                 <Grid container spacing={2} sx={{ mt: 3 }}>
//                     <Grid item xs={12}>
//                         <TableContainer component={Paper}>
//                             <Table aria-label="simple table">
//                                 <TableHead>
//                                     <TableRow>
//                                         <TableCell sx={{ width: '.8rem' }}>#</TableCell>
//                                         <TableCell>Ref No.</TableCell>
//                                         <TableCell>Route No.</TableCell>
//                                         <TableCell>Type</TableCell>
//                                         <TableCell>Expected Start Time</TableCell>
//                                         <TableCell>Actual Start Time</TableCell>
//                                         <TableCell>Bus</TableCell>
//                                         <TableCell>Driver</TableCell>
//                                         {filter.tripStatus === TripStatus.NEW &&
//                                             <TableCell align="right">Action</TableCell>
//                                         }
//                                     </TableRow>
//                                 </TableHead>
//                                 <TableBody>
//                                     {trips.map((item, index) => (
//                                         <TableRow
//                                             key={item.name}
//                                         >
//                                             <TableCell align="left">{page * rowsPerPage + (index + 1)}</TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.ref_no}
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.route.number}
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {convertRouteType(item.route.type.name)}
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {formatDateTime(item.expected_start_time)}
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {formatDateTime(item.actual_start_time)}
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.bus ? item.bus.number : ''}
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.driver ? item.driver.user.name : ''}
//                                             </TableCell>
//                                             <TableCell align="right">
//                                                 <VertActionMenu
//                                                     onView={() => navigate(`${baseUrl}/trip/${item.id}/detail-view`)}
//                                                 />
//                                             </TableCell>
                                            
//                                         </TableRow>
//                                     ))}
//                                 </TableBody>
//                                 <TableFooter>
//                                     <TableRow>
//                                         <TablePagination
//                                             rowsPerPageOptions={[5, 10, 25]}
//                                             colSpan={3}
//                                             count={totalCount}
//                                             rowsPerPage={rowsPerPage}
//                                             page={page}
//                                             SelectProps={{
//                                                 inputProps: {
//                                                     'aria-label': 'rows per page',
//                                                 },
//                                                 native: true,
//                                             }}
//                                             onPageChange={handleChangePage}
//                                             onRowsPerPageChange={handleChangeRowsPerPage}
//                                             ActionsComponent={CustomPaginationActions}
//                                         />
//                                     </TableRow>
//                                 </TableFooter>
//                             </Table>
//                         </TableContainer>
//                     </Grid>
//                 </Grid>
//             </Box>
//         </>
//     );
// }

// export default HistoryList;
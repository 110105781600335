import React, { Component, useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Card, Accordion, AccordionSummary, AccordionDetails, Button, Grid, TableBody, IconButton, 
    Dialog, AppBar, Toolbar } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PageviewIcon from '@mui/icons-material/Pageview';
import CloseIcon from '@mui/icons-material/Close';

import { fetchSchool as fetchSchoolHttp, getTrip, getTripLocations } from '../../../../services/http.service';
import GooogleMap from '../../../../common/googlemap/google-map';
import TripReplayGoogleMap from '../../../../common/googlemap/trip-replay-google-map';
import { TripStatus, formatDateTimeRev, convertMetersToKMsText, convertSecsToHms, convertSecsToHm,
    convertRouteType, TripStudentStatus, convertTripStudentStatus } from '../../../../utils/constants';
import PaginationBasic from '../../../../utils/pagination-basic';
import Loader from '../../../../common/loader';
import schoolIcon from '../../../../assets/images/school_icon.png';
import stopIcon from '../../../../assets/images/stop_icon.png';
import RoutableMap from '../../../../common/googlemap/routable-map';
import logger from '../../../../utils/logger';  // Import your logger here

import { useParams } from "react-router-dom";

const logTag = 'TripDetailView';  // Define logTag for this component

const TripDetailView = () => {

    const [tripDetailLoading, setTripDetailLoading] = useState(true);
    const [tripLocationsLoading, setTripLocationsLoading] = useState(true);
    const [studentPickupDropoffLoading, setStudentPickupDropoffLoading] = useState(false);
    let [school, setSchool] = useState(null);
    const [locations, setLocations] = useState([]);
    const [trip, setTrip] = useState(null);
    const [tripSummary, setTripSummary] = useState({});
    const [showBusTravelledMap, setShowBusTravelledMap] = useState(false);
    const [allLocations, setAllLocations] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [students, setStudents] = useState([]);
    const [stopLocationsForMap, setStopLocationsForMap] = useState([]);

    const schoolId = useSelector(state => state.extras.schoolId);

    // Child component Refs
    const googleMapElement = useRef();
    const busTravelledMap = useRef();
    const routableMapRef = useRef(null);

    const mapsRef = useRef();

    const { tripId } = useParams();

    useEffect(() => {
        logger.info('TripDetailView component mounted', { logTag, tripId });  // Log component mount
        fetchTripLocations(tripId);
    }, [page, limit]);

    useEffect(() => {
        fetchSchool(tripId);
    }, []);

    // Fetch school details from server - after that fetch trips and other details
    const fetchSchool = (tripId) => {
        logger.info('Fetching school details', { logTag, tripId });  // Log fetching school details
        fetchSchoolHttp(schoolId).then(resp => {
            school = resp.data.school;
            setSchool(school);
            fetchTrip(tripId);
            fetchTripLocations(tripId);
        }).catch(err => {
            logger.error('Error fetching school', err, { logTag, tripId });  // Log error
            console.log(err);
        });
    }

    // Fetch Trip detail
    const fetchTrip = (tripId) => {
        setTripDetailLoading(true);
        logger.info('Fetching trip details', { logTag, tripId });  // Log fetching trip details
        getTrip(tripId).then(resp => {
            const respTrip = resp.data.data;
            setTripDetailLoading(false);
            if (respTrip) {
                const tripStudents = respTrip.trip_students.map(item => {
                    const student = buildTripStudent(item, respTrip);
                    student.order = item.order;
                    return student;
                });
                const newTrip = {
                    id: respTrip.id,
                    refNo: respTrip.ref_no,
                    route: respTrip.route,
                    students: tripStudents,
                    type: respTrip.route.type.name,
                    bus: respTrip.bus,
                    driver: respTrip.driver,
                    expectedStartTime: respTrip.expected_start_time,
                    expectedEndTime: respTrip.expected_end_time,
                    stops: respTrip.trip_stops.map(item => {
                        return {
                            id: item.id,
                            routeId: item.route_id,
                            order: item.order,
                            isActive: item.is_active,
                            stop: {
                                id: item.stop.id,
                                name: item.stop.name,
                                pickupTime: item.stop.pickup_time,
                                dropoffTime: item.stop.dropoff_time,
                                location: item.stop.location,
                            }
                        }
                    }),
                };

                setTrip(newTrip);
            }

            setTripSummaryDetail(respTrip);
        }).catch(err => {
            setTripDetailLoading(false);
            logger.error('Error fetching trip details', err, { logTag, tripId });  // Log error
        });
    }

    // Set Trip Summary
    const setTripSummaryDetail = (trip) => {
        let actualDurationMillis = new Date(trip.actual_end_time).getTime() - new Date(trip.actual_start_time).getTime();
        let actualDuration = convertSecsToHm(actualDurationMillis / 1000);

        const summary = {
            totalStudents: trip.trip_students.length,
            busNumber: trip.bus ? trip.bus.number : '',
            driver: trip.driver ? trip.driver.user.name : '',
            estimatedDuration: '',
            estimatedDistance: '',
            actualDuration: actualDuration,
            actualDistance: '',
            expectedStartTime: formatDateTimeRev(trip.expected_start_time),
            expectedEndTime: formatDateTimeRev(trip.expected_end_time),
            actualStartTime: formatDateTimeRev(trip.actual_start_time),
            actualEndTime: formatDateTimeRev(trip.actual_end_time),
        };
        logger.info('Setting trip summary', { logTag, tripId: trip.id, summary });  // Log setting trip summary
        setTripSummary(summary);
    }

    // Build Trip student from student object
    const buildTripStudent = (tripStudent, trip) => {
        let location = null;
        let actualLocation = null;
        let actualTime = null;
        let studentTripStatus = null;
        if (trip.route.type.name === 'DROPOFF') {
            location = tripStudent.student.dropoff;
            actualLocation = tripStudent.dropped_off_location;
            actualTime = formatDateTimeRev(tripStudent.dropped_off_time);
            studentTripStatus = TripStudentStatus.DROPPED_OFF;
        } else {
            location = tripStudent.student.pickup;
            actualLocation = tripStudent.picked_up_location;
            actualTime = formatDateTimeRev(tripStudent.picked_up_time);
            studentTripStatus = TripStudentStatus.PICKED_UP;
        }

        const response = {
            id: tripStudent.student.id,
            name: tripStudent.student.name,
            rollNo: tripStudent.student.roll_no,
            sclass: tripStudent.student.sclass,
            section: tripStudent.student.section,
            latitude: location ? location.latitude : 0,
            longitude: location ? location.longitude : 0,
            location: location? location.location : "",
            actualLocation: actualLocation,
            actualTime: actualTime,
            status: tripStudent.status,
            studentTripStatus
        };
        logger.info('Building trip student', { logTag, tripId: trip.id, studentId: tripStudent.student.id });  // Log building trip student
        return response;
    }

    // Fetch Trip locations
    const fetchTripLocations = (tripId) => {
        setTripLocationsLoading(true);
        logger.info('Fetching trip locations', { logTag, tripId, page, limit });  // Log fetching trip locations
        getTripLocations(tripId, page, limit).then(resp => {

            const respLocations = resp.data.data.locations;

            if (respLocations) {
                const newLocations = respLocations.map(item => {
                    return {
                        location: item.location,
                        latitude: item.latitude,
                        longitude: item.longitude,
                        time: item.time,
                    }
                });
                setLocations(newLocations);
                logger.info('Trip locations fetched', { logTag, tripId, newLocations });  // Log fetched locations
            }
            setTotalCount(resp.data.data.total_count);
            setTripLocationsLoading(false);
        }).then(err => {
            logger.error('Error fetching trip locations', err, { logTag, tripId });  // Log error fetching locations
            setTripLocationsLoading(false);
        })
    }

    // Show location on map
    const showLocationOnMap = (location) => {
        logger.info('Showing location on map', { logTag, location });  // Log showing location on map
        console.log(`Show location on map - ${JSON.stringify(location)}`);
        if (!location) {
            return;
        }
        googleMapElement.current.showLocationOnMap(location);
    }

    // Show Bus travelled map
    const showTravelledMap = () => {
        setShowBusTravelledMap(true);
        logger.info('Fetching all trip locations for map', { logTag, tripId });  // Log fetching all trip locations for map
        getTripLocations(tripId, 0, 100000).then(resp => {
            const respData = resp.data;
            setAllLocations(respData.data.locations);
        }).catch(err => {
            logger.error('Error fetching all trip locations', err, { logTag, tripId });  // Log error
            console.log(`Error in fetching all trip location - ${err}`);
        })
    }

    useEffect(() => {
        if (trip && trip.stops && trip.stops.length > 0) {
            const sortedStops = [...trip.stops].sort((a, b) => a.order - b.order);
            console.log("sortedStops - ", sortedStops);
            // Extract stop locations for map
            const stopLocations = sortedStops.filter(stop => stop.isActive).map(tripStop => tripStop.stop.location);
            console.log("stop locations for map ", JSON.stringify(stopLocations));
            const schoolLocation = {
                latitude: school.latitude,
                longitude: school.longitude,
                location: school.address,
            }
            stopLocations.unshift(schoolLocation);
            setStopLocationsForMap(stopLocations);
            logger.info('Stop locations prepared for map', { logTag, stopLocations });  // Log preparing stop locations for map
        }
    }, [trip]);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: 'center' }}>
                    <Typography component="h2" variant="header">
                        Trip #{trip?.refNo} ({convertRouteType(trip?.type)})
                    </Typography>
                </Box>

                <Card variant="outlined" sx={{ width: '100%', mt: 5, padding: 1 }}>
                    <Typography variant='h3' component={'h3'}>
                        Summary
                    </Typography>
                    {tripDetailLoading ? (
                        <Box sx={{ width: '100%', padding: 2 }}>
                            <Loader />
                        </Box>
                    ) : (
                        <Grid container spacing={2} sx={{ mt: 0.5 }}>
                            <Grid item xs={12} md={6} lg={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant='h5' component={'h5'}>
                                            Total students:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='h5' component={'h5'}>
                                            {tripSummary.totalStudents}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant='h5' component={'h5'}>
                                            Bus number:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='h5' component={'h5'}>
                                            {tripSummary.busNumber}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant='h5' component={'h5'}>
                                            Driver:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='h5' component={'h5'}>
                                            {tripSummary.driver}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant='h5' component={'h5'}>
                                            Estimated time taken:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='h5' component={'h5'}>
                                            {tripSummary.estimatedDuration}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant='h5' component={'h5'}>
                                            Estimated distance:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='h5' component={'h5'}>
                                            {tripSummary.estimatedDistance}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant='h5' component={'h5'}>
                                            Actual time taken:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='h5' component={'h5'}>
                                            {tripSummary.actualDuration}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant='h5' component={'h5'}>
                                            Actual distance:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='h5' component={'h5'}>
                                            N/A
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant='h5' component={'h5'}>
                                            Expected Start Time:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='h5' component={'h5'}>
                                            {tripSummary.expectedStartTime}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant='h5' component={'h5'}>
                                            Expected End Time:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='h5' component={'h5'}>
                                            {tripSummary.expectedEndTime}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant='h5' component={'h5'}>
                                            Actual Start Time:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='h5' component={'h5'}>
                                            {tripSummary.actualStartTime}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant='h5' component={'h5'}>
                                            Actual End Time:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='h5' component={'h5'}>
                                            {tripSummary.actualEndTime}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Card>
            </Box>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6} lg={6}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant='h5'>Bus Travelled Locations</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {tripLocationsLoading ? (
                                <Box sx={{ width: '100%', padding: 2 }}>
                                    <Loader />
                                </Box>
                            ) : (
                                <>
                                    <Button
                                        variant="outlined"
                                        onClick={showTravelledMap}
                                        sx={{ mb: 2 }}
                                    >
                                        Show on Map
                                    </Button>
                                    {locations.map((item, index) => (
                                        <Box
                                            sx={{ display: 'flex', p: 1, bgcolor: 'background.paper', borderRadius: 1 }}
                                        >
                                            <Box sx={{ flexGrow: 1 }}>
                                                <Typography variant='body2' component='div'>
                                                    {formatDateTimeRev(item.time)}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <IconButton aria-label="show" color="primary"
                                                    onClick={() => {
                                                        logger.info('Show location clicked', { logTag, location: item });  // Log show location click
                                                        showLocationOnMap(item);
                                                    }}
                                                >
                                                    <PageviewIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    ))}
                                </>
                            )}
                            <Box sx={{ mt: 2, width: '100%' }}>
                                <PaginationBasic
                                    totalCount={totalCount}
                                    limit={limit}
                                    onChange={(page) => {
                                        logger.info('Page changed in pagination', { logTag, page });  // Log page change
                                        setPage(page - 1);
                                    }}
                                />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant='h5'>
                                Students {trip ? convertRouteType(trip.type) : ''} Location
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {trip &&
                                <Box sx={{ padding: 2 }}>
                                    {trip.students.map((item, index) => (
                                        <Box
                                            sx={{ display: 'flex', p: 1, bgcolor: 'background.paper', borderRadius: 1 }}
                                        >
                                            <Box sx={{ flexGrow: 1 }}>
                                                <Typography variant='body2' component='div'>
                                                    {item.name}
                                                </Typography>
                                                {item.status !== TripStudentStatus.ABSENT &&
                                                    <>
                                                        <Typography variant='caption' component='div'>
                                                            <strong>{convertTripStudentStatus(item.studentTripStatus)} at: </strong> {item.actualLocation ? item.actualLocation.location : ''}
                                                        </Typography>
                                                        <Typography variant='caption' component='div'>
                                                            <strong>At time: </strong>{item.actualTime}
                                                        </Typography>
                                                        <Typography variant='caption' component='div'>
                                                            <strong>Expected Location: </strong>{item.location}
                                                        </Typography>
                                                    </>
                                                }
                                                {item.status === TripStudentStatus.ABSENT &&
                                                    <Typography variant='caption' component='div'>
                                                        <strong>ABSENT</strong>
                                                    </Typography>
                                                }
                                            </Box>
                                            <Box>
                                                <IconButton aria-label="show" color="primary"
                                                    onClick={() => {
                                                        logger.info('Show student location clicked', { logTag, studentId: item.id, location: item.actualLocation });  // Log show student location click
                                                        showLocationOnMap(item.actualLocation);
                                                    }}
                                                >
                                                    <PageviewIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            }
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} md={6} lg={6} sx={{ minHeight: '500px' }}>
                    {trip &&
                        <RoutableMap
                            locations={stopLocationsForMap}
                            originIcon={schoolIcon}
                            otherIcon={stopIcon}
                            ref={routableMapRef}
                        />
                    }
                </Grid>
            </Grid>
            {/* Bus Travelled map */}
            {showBusTravelledMap &&
                <BusTravelledMap
                    show={showBusTravelledMap}
                    onHide={() => {
                        logger.info('Close bus travelled map modal', { logTag });  // Log close bus travelled map modal
                        setShowBusTravelledMap(false);
                    }}
                    allLocations={allLocations}
                />
            }
        </>
    );
}

export default TripDetailView;

const BusTravelledMap = (props) => {

    const tripReplayGoogleMapElement = useRef();

    const drawPath = () => {
        console.log('drawPath' + tripReplayGoogleMapElement);
    }

    useEffect(() => {
        console.log(`tripReplayGoogleMapElement - ${tripReplayGoogleMapElement.current}`);
        let locations = props.allLocations;
        locations = locations.reverse();
    }, [props.allLocations]);

    let moveBusTimer = null;
    let moveBusIndex = 0;

    // Start Move bus on map
    const startMoveBusOnMap = () => {
        if (moveBusTimer != null) {
            return;
        }
        let locations = props.allLocations;
        moveBusTimer = setInterval(() => {
            if (moveBusIndex === locations.length) {
                console.log(`i ==== locations.length ${moveBusIndex} === ${locations.length}`)
                clearInterval(moveBusTimer);
                return;
            }
            let location = locations[moveBusIndex];
            console.log(`moving to location ${JSON.stringify(location)} index - ${moveBusIndex}`);
            moveBusOnMap(location);
            moveBusIndex++;
        },
            500
        );
    }

    // Pause move on bus
    const pauseMoveBusOnMap = () => {
        clearInterval(moveBusTimer);
        moveBusTimer = null;
    }

    // Stop move on bus
    const stopMoveBusOnMap = () => {
        pauseMoveBusOnMap();
        moveBusIndex = 0;
    }

    const moveBusOnMap = (location) => {
        console.log('moving bus on map');
        tripReplayGoogleMapElement.current.moveBusOnMap(location);
    }

    // On close of modal
    const handleClose = () => {
        props.onHide();
        if (moveBusTimer) {
            clearInterval(moveBusTimer);
        }
    }

    return (
        <Dialog
            fullScreen
            open={props.show}
            onClose={handleClose}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        size="large"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography
                        sx={{ color: 'white' }}
                        variant='h3' component='h3'>
                        Bus travelled map
                    </Typography>
                </Toolbar>
            </AppBar>

            <Box sx={{ padding: 2 }}>
                <Button
                    variant='outlined'
                    onClick={() => startMoveBusOnMap()}
                    sx={{ mr: 5 }}
                    color="primary"
                >Play</Button>
                <Button
                    variant='outlined'
                    onClick={() => pauseMoveBusOnMap()}
                    sx={{ mr: 5 }}
                    color="success"
                >Pause</Button>
                <Button
                    variant='outlined'
                    onClick={() => stopMoveBusOnMap()}
                    color="error"
                >Stop</Button>
            </Box>
            <Box sx={{ padding: 2 }}>
                <TripReplayGoogleMap ref={tripReplayGoogleMapElement}
                    locations={props.allLocations}
                />
            </Box>
        </Dialog>
    )
};


// import React, { Component, useRef, useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { Box, Typography,Card, Accordion, AccordionSummary, AccordionDetails, Button, Grid, TableBody, IconButton, 
//     Dialog, AppBar, Toolbar } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import PageviewIcon from '@mui/icons-material/Pageview';
// import CloseIcon from '@mui/icons-material/Close';

// import { fetchSchool as fetchSchoolHttp, getTrip, getTripLocations } from '../../../../services/http.service';
// import  GooogleMap  from '../../../../common/googlemap/google-map';
// import  TripReplayGoogleMap  from '../../../../common/googlemap/trip-replay-google-map';
// import { TripStatus, formatDateTimeRev, convertMetersToKMsText, convertSecsToHms, convertSecsToHm,
//             convertRouteType, TripStudentStatus, convertTripStudentStatus } from '../../../../utils/constants';
// import PaginationBasic from '../../../../utils/pagination-basic';
// import Loader from '../../../../common/loader';
// import schoolIcon from '../../../../assets/images/school_icon.png';
// import stopIcon from '../../../../assets/images/stop_icon.png';
// import RoutableMap from '../../../../common/googlemap/routable-map';

// import { useParams } from "react-router-dom";


// const TripDetailView = () => {

//     const [tripDetailLoading, setTripDetailLoading] = useState(true);
//     const [tripLocationsLoading, setTripLocationsLoading] = useState(true);
//     const [studentPickupDropoffLoading, setStudentPickupDropoffLoading] = useState(false);
//     let [school, setSchool] = useState(null);
//     const [locations, setLocations] = useState([]);
//     //const [tripId, setTripId] = useState(null);
//     const [trip, setTrip] = useState(null);
//     const [tripSummary, setTripSummary] = useState({});
//     const [showBusTravelledMap, setShowBusTravelledMap] = useState(false);
//     const [allLocations, setAllLocations] = useState([]);
//     const [page, setPage] = useState(0);
//     const [limit, setLimit] = useState(10);
//     const [totalCount, setTotalCount] = useState(0);
//     const [isLoading, setIsLoading] = useState(false);
//     const [students, setStudents] = useState([]);
//     const [stopLocationsForMap, setStopLocationsForMap] = useState([]);

//     const schoolId = useSelector(state => state.extras.schoolId);

//     // Child component Refs
//     const googleMapElement = useRef();
//     const busTravelledMap = useRef();
//     const routableMapRef = useRef(null);

//     const mapsRef = useRef();


//     const { tripId } = useParams();

//     useEffect(() => {   
//         fetchTripLocations(tripId);  
//     }, [page, limit]);

//     useEffect(() => {   
//         fetchSchool(tripId);  
//     }, []);


//     // Fetch school details from server - after that fetch trips and other details
//     const fetchSchool = (tripId) => {
//         fetchSchoolHttp(schoolId).then(resp => {
//             school = resp.data.school; // to change school first object
//             setSchool(school);
//             fetchTrip(tripId);
//             fetchTripLocations(tripId);
//             // this.setState({ school: resp.data.school}, () => {
//             //     this.fetchTrip(tripId);
//             //     this.fetchTripLocations(tripId);
//             // })            
//         }).catch(err => {
//             console.log(err);
//         });
//     }

//     // Fetch Trip detail
//     const fetchTrip = (tripId) => {
//         setTripDetailLoading(true);
//         getTrip(tripId).then(resp => {
//             const respTrip = resp.data.data;
//             setTripDetailLoading(false);
//             if (respTrip) {
//                 // Set route details
//                 const tripStudents = respTrip.trip_students.map(item => {
//                     const student = buildTripStudent(item, respTrip);
//                     //console.log(`build student - ${JSON.stringify(student)}`);
//                     student.order = item.order;
//                     return student;
//                 });
//                 //console.log(`trip students - ${tripStudents}`);
//                 const newTrip = {
//                     id: respTrip.id,
//                     refNo: respTrip.ref_no,
//                     route: respTrip.route,
//                     students: tripStudents,
//                     type: respTrip.route.type.name,
//                     bus: respTrip.bus,
//                     driver: respTrip.driver,
//                     expectedStartTime: respTrip.expected_start_time,
//                     expectedEndTime: respTrip.expected_end_time,
//                     stops: respTrip.trip_stops.map(item => {
//                         return {
//                             id: item.id,
//                             routeId: item.route_id,
//                             order: item.order,
//                             isActive: item.is_active,
//                             stop: {
//                                 id: item.stop.id,
//                                 name: item.stop.name,
//                                 pickupTime: item.stop.pickup_time,
//                                 dropoffTime: item.stop.dropoff_time,
//                                 location: item.stop.location,
//                             }
//                         }
//                     }),
//                 };

//                 setTrip(newTrip);
//             }
            
//             // Set trip summary
//             setTripSummaryDetail(respTrip);
//         }).catch(err => {
//             setTripDetailLoading(false);
//         });
//     }

//     // Set Trip Summary
//     const setTripSummaryDetail = (trip) => {  
//         let actualDurationMillis = new Date(trip.actual_end_time).getTime() -  new Date(trip.actual_start_time).getTime();
//         let actualDuration = convertSecsToHm(actualDurationMillis/1000);

//         const summary = {
//             totalStudents: trip.trip_students.length,
//             busNumber: trip.bus? trip.bus.number : '',
//             driver: trip.driver? trip.driver.user.name : '',
//             estimatedDuration: '',
//             estimatedDistance: '',
//             actualDuration: actualDuration,
//             actualDistance: '',
//             expectedStartTime: formatDateTimeRev(trip.expected_start_time),
//             expectedEndTime: formatDateTimeRev(trip.expected_end_time),
//             actualStartTime: formatDateTimeRev(trip.actual_start_time),
//             actualEndTime: formatDateTimeRev(trip.actual_end_time),
//         }
//         console.log(`setting trip summary of ${JSON.stringify(summary)}`);
//         setTripSummary(summary);

//         // Set estimated Distance & Duration
//         //calculateDistanceAndDuration(trip);
//     }

//     // Build Trip student from student object
//     const buildTripStudent = (tripStudent, trip) => {
//         //console.log(`build trip student - ${JSON.stringify(student)}`);
//         let location = null;
//         let actualLocation = null;
//         let actualTime = null;
//         let studentTripStatus = null;
//         if (trip.route.type.name === 'DROPOFF') {
//             location = tripStudent.student.dropoff;
//             actualLocation = tripStudent.dropped_off_location;
//             actualTime = formatDateTimeRev(tripStudent.dropped_off_time);
//             studentTripStatus = TripStudentStatus.DROPPED_OFF;
//         } else {
//             location = tripStudent.student.pickup;
//             actualLocation = tripStudent.picked_up_location;
//             actualTime = formatDateTimeRev(tripStudent.picked_up_time);
//             studentTripStatus = TripStudentStatus.PICKED_UP;
//         }

//         const response = {
//             id: tripStudent.student.id,
//             name: tripStudent.student.name,
//             rollNo: tripStudent.student.roll_no,
//             sclass: tripStudent.student.sclass,
//             section: tripStudent.student.section,
//             latitude: location.latitude,
//             longitude: location.longitude,
//             location: location.location,
//             actualLocation: actualLocation,
//             actualTime: actualTime,
//             status: tripStudent.status,
//             studentTripStatus            
//         }
//         return response;
//     }

//     // Fetch Trip locations
//     const fetchTripLocations = (tripId) => {
//         // const { page, limit } = this.state;
//         setTripLocationsLoading(true);
//         getTripLocations(tripId, page, limit).then(resp => {  
            
//             const respLocations = resp.data.data.locations;

//             if (respLocations) {
//                 const newLocations = respLocations.map(item => {
//                                 return {
//                                     location: item.location,
//                                     latitude: item.latitude,
//                                     longitude: item.longitude,
//                                     time: item.time,
//                                 }
//                             });
//                 setLocations(newLocations);
//             }
//             setTotalCount(resp.data.data.total_count);
//             setTripLocationsLoading(false);
//         }).then(err => {
//             console.error("Error in loadin trip locations", err);
//             setTripLocationsLoading(false);
//         })
//     }

//     // Show location on map
//     const showLocationOnMap = (location) => {
//         console.log(`Show location on map - ${JSON.stringify(location)}`);
//         if (!location) {
//             return;
//         }
//         googleMapElement.current.showLocationOnMap(location);        
//     }


//     // ******** Calculate Estimated distance and duration ************
//     const onMaps = (maps) => {
//         console.log(`on maps receive - ${maps}`);
//         mapsRef.current = maps;
//         calculateDistanceAndDuration(trip);
//     }

//     const calculateDistanceAndDuration = (trip) => {
//         // const { school } = this.state;
//         // const { google } = this.props;
//         let origins = [];
//         let destinations = [];

//         console.log(`school - ${JSON.stringify(school)}`);

//         // Get all latLngs in order for destinations
//         trip.students.forEach(tripStudent => {
//             // let location = null;
//             // if (trip.route.type.name === 'DROPOFF') {
//             //     location = tripStudent.student.dropoff;
//             // } else {
//             //     location = tripStudent.student.pickup;
//             // }
//             // destinations.push(new mapsRef.current.LatLng(location.latitude, location.longitude));
//             destinations.push(new mapsRef.current.LatLng(tripStudent.latitude, tripStudent.longitude));
//         });

//         // School lat/lng for origin
//         origins.push(new mapsRef.current.LatLng(school.latitude, school.longitude));
//         console.log(`origins - destinations - ${JSON.stringify(origins)} - ${JSON.stringify(destinations)}`);
//         var service = new mapsRef.current.DistanceMatrixService();
//         service.getDistanceMatrix(
//             {
//                 origins: origins,
//                 destinations: destinations,
//                 travelMode: 'DRIVING',
//             }, (response, status) => {                
//                 if (status === 'OK') {
//                     //console.log(`result - ${status} - ${JSON.stringify(response.rows[0].elements)}`);
//                     let distance = 0;
//                     let duration = 0;
//                     response.rows[0].elements.forEach((item, index) => {
//                         distance += item.distance.value;
//                         duration += item.duration.value;
//                     });
//                     console.log(`Total Distance - ${distance} -- Total Duration - ${duration}`);
//                     setTripSummary({
//                         ...tripSummary,
//                         estimatedDuration: convertSecsToHm(duration),
//                         estimatedDistance: convertMetersToKMsText(distance),
//                     });
//                 }
//             });
//     }
//     // ******** End of Calculate Estimated distance and duration ************


//     // Fetch students Pickup/Dropoff locations and show location on map on mouse click
//     // Get student Pickup location if trip is for Pickup route else Dropoff


//     // Show Bus travelled map
//     const showTravelledMap = () => {
//         // const { tripId } = this.state;
//         setShowBusTravelledMap(true);
//         // Fetch all the locations from server
//         getTripLocations(tripId, 0, 100000).then(resp => {  
//             const respData = resp.data;
//             //console.log(`All location data - ${JSON.stringify(respData.data)}`);
//             setAllLocations(respData.data.locations);
//         }).catch(err => {
//             console.log(`Error in fetching all trip location - ${err}`);
//         })
//     }

//     useEffect(() => {
//         if (trip && trip.stops && trip.stops.length > 0) {
//             const sortedStops = [...trip.stops].sort((a, b) => a.order - b.order);
//             console.log("sortedStops - ", sortedStops);
//             // Extract stop locations for map
//             const stopLocations = sortedStops.filter(stop => stop.isActive).map(tripStop => tripStop.stop.location);
//             console.log("stop locations for map ", JSON.stringify(stopLocations));
//             const schoolLocation = {
//                 latitude: school.latitude,
//                 longitude: school.longitude,
//                 location: school.address,
//             }
//             stopLocations.unshift(schoolLocation);
//             setStopLocationsForMap(stopLocations);
//         }
//     }, [trip]);

//     return (
//         <>  
//             <Box
//                 sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "left",
//                     alignItems: "start",
//                 }}
//             >   
//                 <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: 'center' }}>
//                     <Typography component="h2" variant="header">
//                         Trip #{trip?.refNo} ({convertRouteType(trip?.type)})
//                     </Typography>
//                 </Box>
                
//                 <Card variant="outlined" sx={{ width: '100%', mt: 5, padding: 1 }}>
//                     <Typography variant='h3' component={'h3'}>
//                         Summary
//                     </Typography>
//                     {tripDetailLoading ? (
//                         <Box sx={{ width: '100%', padding: 2 }}>
//                             <Loader />
//                         </Box>
//                     ) : (
//                         <Grid container spacing={2} sx={{ mt: 0.5 }}>
//                             <Grid item xs={12} md={6} lg={6}>
//                                 <Grid container spacing={2}>
//                                     <Grid item xs={4}>
//                                         <Typography variant='h5' component={'h5'}>
//                                             Total students:
//                                         </Typography>
//                                     </Grid>
//                                     <Grid item>
//                                         <Typography variant='h5' component={'h5'}>
//                                             {tripSummary.totalStudents}
//                                         </Typography>
//                                     </Grid>
//                                 </Grid>
//                                 <Grid container spacing={2}>
//                                     <Grid item xs={4}>
//                                         <Typography variant='h5' component={'h5'}>
//                                             Bus number:
//                                         </Typography>
//                                     </Grid>
//                                     <Grid item>
//                                         <Typography variant='h5' component={'h5'}>
//                                             {tripSummary.busNumber}
//                                         </Typography>
//                                     </Grid>
//                                 </Grid>
//                                 <Grid container spacing={2}>
//                                     <Grid item xs={4}>
//                                         <Typography variant='h5' component={'h5'}>
//                                             Driver:
//                                         </Typography>
//                                     </Grid>
//                                     <Grid item>
//                                         <Typography variant='h5' component={'h5'}>
//                                             {tripSummary.driver}
//                                         </Typography>
//                                     </Grid>
//                                 </Grid>
//                                 <Grid container spacing={2}>
//                                     <Grid item xs={4}>
//                                         <Typography variant='h5' component={'h5'}>
//                                             Estimated time taken:
//                                         </Typography>
//                                     </Grid>
//                                     <Grid item>
//                                         <Typography variant='h5' component={'h5'}>
//                                             {tripSummary.estimatedDuration}
//                                         </Typography>
//                                     </Grid>
//                                 </Grid>
//                                 <Grid container spacing={2}>
//                                     <Grid item xs={4}>
//                                         <Typography variant='h5' component={'h5'}>
//                                             Estimated distance:
//                                         </Typography>
//                                     </Grid>
//                                     <Grid item>
//                                         <Typography variant='h5' component={'h5'}>
//                                             {tripSummary.estimatedDistance}
//                                         </Typography>
//                                     </Grid>
//                                 </Grid>
//                                 <Grid container spacing={2}>
//                                     <Grid item xs={4}>
//                                         <Typography variant='h5' component={'h5'}>
//                                             Actual time take:
//                                         </Typography>
//                                     </Grid>
//                                     <Grid item>
//                                         <Typography variant='h5' component={'h5'}>
//                                             {tripSummary.actualDuration}
//                                         </Typography>
//                                     </Grid>
//                                 </Grid>
//                                 <Grid container spacing={2}>
//                                     <Grid item xs={4}>
//                                         <Typography variant='h5' component={'h5'}>
//                                             Actual distance:
//                                         </Typography>
//                                     </Grid>
//                                     <Grid item>
//                                         <Typography variant='h5' component={'h5'}>
//                                             N/A
//                                         </Typography>
//                                     </Grid>
//                                 </Grid>
//                             </Grid>
//                             <Grid item xs={12} md={6} lg={6}>
//                                 <Grid container spacing={2}>
//                                     <Grid item xs={4}>
//                                         <Typography variant='h5' component={'h5'}>
//                                             Expected Start Time:
//                                         </Typography>
//                                     </Grid>
//                                     <Grid item>
//                                         <Typography variant='h5' component={'h5'}>
//                                             {tripSummary.expectedStartTime}
//                                         </Typography>
//                                     </Grid>
//                                 </Grid>
//                                 <Grid container spacing={2}>
//                                     <Grid item xs={4}>
//                                         <Typography variant='h5' component={'h5'}>
//                                             Expected End Time:
//                                         </Typography>
//                                     </Grid>
//                                     <Grid item>
//                                         <Typography variant='h5' component={'h5'}>
//                                             {tripSummary.expectedEndTime}
//                                         </Typography>
//                                     </Grid>
//                                 </Grid>
//                                 <Grid container spacing={2}>
//                                     <Grid item xs={4}>
//                                         <Typography variant='h5' component={'h5'}>
//                                             Actual Start Time:
//                                         </Typography>
//                                     </Grid>
//                                     <Grid item>
//                                         <Typography variant='h5' component={'h5'}>
//                                             {tripSummary.actualStartTime}
//                                         </Typography>
//                                     </Grid>
//                                 </Grid>
//                                 <Grid container spacing={2}>
//                                     <Grid item xs={4}>
//                                         <Typography variant='h5' component={'h5'}>
//                                             Actual End Time:
//                                         </Typography>
//                                     </Grid>
//                                     <Grid item>
//                                         <Typography variant='h5' component={'h5'}>
//                                             {tripSummary.actualEndTime}
//                                         </Typography>
//                                     </Grid>
//                                 </Grid>
//                             </Grid>
//                         </Grid>
//                     )}
//                 </Card>
//             </Box>
//             <Grid container spacing={2} sx={{ mt: 2 }}>
//                 <Grid item xs={12} md={6} lg={6}>
//                     <Accordion>
//                         <AccordionSummary
//                             expandIcon={<ExpandMoreIcon />}
//                             aria-controls="panel1a-content"
//                             id="panel1a-header"
//                         >
//                             <Typography variant='h5'>Bus Travelled Locations</Typography>
//                         </AccordionSummary>
//                         <AccordionDetails>
//                             {tripLocationsLoading ? (
//                                 <Box sx={{ width: '100%', padding: 2 }}>
//                                     <Loader />
//                                 </Box>
//                             ) : (
//                                 <>
//                                     <Button 
//                                         variant="outlined" 
//                                         onClick={showTravelledMap}
//                                         sx={{ mb: 2 }}
//                                     >
//                                         Show on Map
//                                     </Button>
//                                     {locations.map((item, index) => (
//                                         <Box
//                                             sx={{ display: 'flex', p: 1, bgcolor: 'background.paper', borderRadius: 1 }}
//                                         >
//                                             <Box sx={{ flexGrow: 1 }}>
//                                                 <Typography variant='body2' component='div'>
//                                                     {formatDateTimeRev(item.time)}
//                                                 </Typography>
//                                                 {/* <Typography variant='caption' component='div'>
//                                                     {item.location || 'No location'}
//                                                 </Typography> */}
//                                             </Box>
//                                             <Box>
//                                                 <IconButton aria-label="show" color="primary"
//                                                     onClick={() => { showLocationOnMap(item) }}
//                                                 >
//                                                     <PageviewIcon />
//                                                 </IconButton>
//                                             </Box>
//                                         </Box>
//                                     ))}
//                                 </>
//                             )}
//                             <Box sx={{ mt: 2, width: '100%' }}>
//                                 <PaginationBasic
//                                     totalCount={totalCount}
//                                     limit={limit}
//                                     onChange={(page) => {
//                                         setPage(page - 1)
//                                     }}
//                                 />
//                             </Box>
//                         </AccordionDetails>
//                     </Accordion>
//                     <Accordion>
//                         <AccordionSummary
//                             expandIcon={<ExpandMoreIcon />}
//                             aria-controls="panel1a-content"
//                             id="panel1a-header"
//                         >
//                             <Typography variant='h5'>
//                                 Students {trip? convertRouteType(trip.type) : ''} Location
//                             </Typography>
//                         </AccordionSummary>
//                         <AccordionDetails>
//                             {trip && 
//                                 <Box sx={{ padding: 2 }}>
//                                     {trip.students.map((item, index) => (
//                                         <Box
//                                             sx={{ display: 'flex', p: 1, bgcolor: 'background.paper', borderRadius: 1 }}
//                                         >
//                                             <Box sx={{ flexGrow: 1 }}>
//                                                 <Typography variant='body2' component='div'>
//                                                     {item.name}
//                                                 </Typography>
//                                                 {item.status != TripStudentStatus.ABSENT &&
//                                                     <>
//                                                         <Typography variant='caption' component='div'>
//                                                             <strong>{convertTripStudentStatus(item.studentTripStatus)} at: </strong> {item.actualLocation ? item.actualLocation.location : ''}
//                                                         </Typography>
//                                                         <Typography variant='caption' component='div'>
//                                                             <strong>At time: </strong>{item.actualTime}
//                                                         </Typography>
//                                                         <Typography variant='caption' component='div'>
//                                                             <strong>Expected Location: </strong>{item.location}
//                                                         </Typography>
//                                                     </>
//                                                 }
//                                                 {item.status == TripStudentStatus.ABSENT &&
//                                                     <Typography variant='caption' component='div'>
//                                                         <strong>ABSENT</strong>
//                                                     </Typography>
//                                                 }
//                                             </Box>
//                                             <Box>
//                                                 <IconButton aria-label="show" color="primary"
//                                                     onClick={() => { showLocationOnMap(item.actualLocation) }}
//                                                 >
//                                                     <PageviewIcon />
//                                                 </IconButton>
//                                             </Box>
//                                         </Box>
//                                     ))}
//                                 </Box>
//                             }
//                         </AccordionDetails>
//                     </Accordion>
//                 </Grid>
//                 <Grid item xs={12} md={6} lg={6} sx={{ minHeight: '500px'}}>
//                     {trip &&
//                         <RoutableMap
//                             locations={stopLocationsForMap}
//                             originIcon={schoolIcon}
//                             otherIcon={stopIcon}
//                             ref={routableMapRef}
//                         />
//                         // <GooogleMap ref={googleMapElement}
//                         //     students={trip.students}
//                         //     school={school}
//                         //     onMaps={onMaps}
//                         //     onMap={() => { }}
//                         // >
//                         // </GooogleMap>
//                     }
//                 </Grid>
//             </Grid>
//             {/* Bus Travelled map */}
//             {showBusTravelledMap &&
//                 <BusTravelledMap
//                     show={showBusTravelledMap}
//                     onHide={() => setShowBusTravelledMap(false)}
//                     allLocations={allLocations}
//                 />
//             }
//         </>
//     );
// }

// export default TripDetailView;

// const BusTravelledMap = (props) =>  {

//     const tripReplayGoogleMapElement = useRef();

//     const drawPath = () => {
//         console.log('drawPath' + tripReplayGoogleMapElement);        
//         //simpleGoogleMapElement.current.drawPath(props.allLocations);
//     }

//     useEffect(() => {
//         console.log(`tripReplayGoogleMapElement - ${tripReplayGoogleMapElement.current}`);
//         // reverse locations
//         let locations = props.allLocations;
//         locations = locations.reverse();
//         //tripReplayGoogleMapElement.current.drawPath(locations);

//         // move bus on map

//     }, [props.allLocations]);

//     let moveBusTimer = null;
//     let moveBusIndex = 0;

//     // Start Move bus on map
//     const startMoveBusOnMap = () => {
//         if (moveBusTimer != null) {
//             return;
//         }
//         let locations = props.allLocations;
//         moveBusTimer = setInterval(() => {
//             if (moveBusIndex === locations.length) {
//                 console.log(`i ==== locations.length ${moveBusIndex} === ${locations.length}`)
//                 clearInterval(moveBusTimer);
//                 return;
//             }
//             let location = locations[moveBusIndex];
//             console.log(`moving to location ${JSON.stringify(location)} index - ${moveBusIndex}`);
//             moveBusOnMap(location);
//             moveBusIndex++;
//         },
//             500
//         );
//     }

//     // Pause move on bus
//     const pauseMoveBusOnMap = () => {
//         clearInterval(moveBusTimer);
//         moveBusTimer = null;
//     }

//     // Stop move on bus
//     const stopMoveBusOnMap = () => {
//         pauseMoveBusOnMap();
//         moveBusIndex = 0;
//     }

//     const moveBusOnMap = (location) => {
//         console.log('moving bus on map');
//         tripReplayGoogleMapElement.current.moveBusOnMap(location);
//     }

//     // On close of modal
//     const handleClose = () => {
//         props.onHide();
//         if (moveBusTimer) {
//             clearInterval(moveBusTimer);
//         }
//     }

//     return (   
//         <Dialog
//             fullScreen
//             open={props.show}
//             onClose={handleClose}
//         >
//             <AppBar sx={{ position: 'relative' }}>
//                 <Toolbar>
//                     <IconButton
//                         edge="start"
//                         color="inherit"
//                         size="large"
//                         onClick={handleClose}
//                         aria-label="close"
//                     >
//                         <CloseIcon />
//                     </IconButton>
//                     <Typography 
//                         sx={{ color: 'white' }}
//                         variant='h3' component='h3'>
//                         Bus travalled map
//                     </Typography>
//                 </Toolbar>
//             </AppBar>

//             <Box sx={{ padding: 2 }}>
//                 <Button 
//                     variant='outlined'
//                     onClick={() => startMoveBusOnMap()}
//                     sx={{ mr: 5 }} 
//                     color="primary"
//                 >Play</Button>
//                  <Button 
//                     variant='outlined' 
//                     onClick={() => pauseMoveBusOnMap()}
//                     sx={{ mr: 5 }} 
//                     color="success"
//                 >Pause</Button>
//                 <Button 
//                     variant='outlined' 
//                     onClick={() => stopMoveBusOnMap()}
//                     color="error"
//                 >Stop</Button>
//             </Box>
//             <Box sx={{ padding: 2 }}>
//                 <TripReplayGoogleMap ref={tripReplayGoogleMapElement}
//                     locations={props.allLocations}
//                 />
//             </Box>
//         </Dialog>
//     )
// };

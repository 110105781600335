import React, { Component, useState } from 'react';
//import { Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Box, Avatar, Typography, TextField, FormControlLabel, 
    Checkbox, Grid, Paper, Snackbar, Alert, CircularProgress } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from "react-router-dom";

import { login, getAccessToken } from '../../services/auth.service';
import { ErrorAlertTopCenter } from '../../common/snackbars';
import logger from '../../utils/logger';

const Login = (props) => {

    const [validated, setValidated] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');

    let navigate = useNavigate();

    const handleSubmit = (event) => {
        console.log('handle submit');
        event.preventDefault();
        setValidated(true);
        //const form = event.currentTarget;
        const data = new FormData(event.currentTarget);

        const mobile = data.get('mobile');
        const password = data.get('password');

        logger.info('Login form submitted', { mobile });

        setIsSaving(true);
        login(mobile, password).then(resp => {
            setErrorMsg(null);
            setIsSaving(false);
            logger.info(`Login successful for user: ${mobile}`, resp.data);
            if (resp.data.user && !resp.data.user.is_verified) {
                logger.info(`Redirecting to OTP verification for user: ${resp.data.user.id}`);
                navigate('/otp-verification/' + resp.data.user.id);
            } else {
                logger.info('Redirecting to school list');
                navigate('/school/list');
            }
        }).catch(err => {
            logger.error(`Login failed for user: ${mobile}`, err, { stack: err.stack });
            setErrorMsg(err);
            setIsSaving(false);
        })
    }

    return (
        <>
            {errorMsg &&
                <ErrorAlertTopCenter
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)}
                />
            }
            <Container component="main" maxWidth="xs">
                <Paper elevation={10}>
                    <Box
                        padding={2}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign In
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Mobile/Email"
                                name="mobile"
                                autoComplete="mobile"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {isSaving &&
                                    <CircularProgress />
                                }
                                {!isSaving &&
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Sign In
                                    </Button>
                                }
                            </Box>
                            <Grid container>
                                <Grid item xs>
                                    <Link to="/forgot-password" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link to="/register" variant="body2">
                                        Don't have an account? Sign up
                                    </Link>
                                </Grid>
                            </Grid>
                            <Box>
                                <Link to="/admin-login" variant="body2">
                                    Admin Login?
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </>
    );
}

export default Login;



import React, { Component, useState, useEffect, useRef } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
    Button, Chip, Paper, Grid, TableBody, IconButton, TableFooter, TablePagination, TextField } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { getStudents, deleteStudent } from '../../../../services/http.service';
import { CustomModal } from '../../../../common/modals';
import { VertActionMenu } from '../../../../common/action-menu';
import { ModalType } from '../../../../utils/constants';
import Loader from '../../../../common/loader';
import CustomPaginationActions from '../../../../utils/pagination';
import StudentDetailDialog from '../../../../common/student-detail-dialog';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'StudentList';  // Define logTag for this component

const StudentList = () =>  {

    const [students, setStudents] = useState([]);
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });
    let [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [isStudentDetailDialogOpen, setIsStudentDetailDialogOpen] = useState(false);

    const didMount = useRef(true);

    let studentSearchKey = null;
    
    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();

    useEffect(() => {
        logger.info('StudentList component mounted', { logTag });  // Log component mount
        fetchStudents();
    }, []);

    // Fetch students
    const fetchStudents = () => {
        logger.info('Fetching students', { logTag, page, rowsPerPage, searchKey: studentSearchKey });  // Log fetching students
        setIsLoading(true);
        const filter = {
            'searchKey': studentSearchKey
        }
        getStudents(filter, page, rowsPerPage).then(resp => {
            const respData = resp.data.data;
            setStudents(respData.students);
            setTotalCount(respData.total_count);
            setIsLoading(false);
            logger.info('Students fetched successfully', { logTag, students: respData.students });  // Log success
        }).catch(err => {
            logger.error('Error fetching students', err, { logTag });  // Log error
            setIsLoading(false);
        });
    }

    // Handle Delete modal   
    const handleDelete = (id) => {
        logger.info('Delete student requested', { logTag, studentId: id });  // Log delete request
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });
        console.log(`delete - ${JSON.stringify(deleteJson)}`);
    }

    // On Delete of Student
    const onDelete = (data) => {
        logger.info('Confirm delete student', { logTag, studentId: data.id });  // Log confirm delete
        console.log('on delete data ' + JSON.stringify(data));

        setDeleteJson({
            ...deleteJson,
            show: false,
        });
        setIsLoading(true);
        deleteStudent(data.id).then(resp => {
            setIsLoading(false);
            logger.info('Student deleted successfully', { logTag, studentId: data.id });  // Log success
            fetchStudents();
        }).catch(err => {
            logger.error('Error deleting student', err, { logTag, studentId: data.id });  // Log error
            setIsLoading(false);        
        })
    }

    // on search students
    const onStudentSearch = (event) => {
        const val = event.target.value;
        logger.info('Student search triggered', { logTag, searchValue: val });  // Log search input
        studentSearchKey = val;
        setPage(0); 
        if (page === 0) {
            fetchStudents();
        }
    }

    // ****************************** Pagination ******************************

    const handleChangePage = (event, newPage) => {
        logger.info('Page change triggered', { logTag, newPage });  // Log page change
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        logger.info('Rows per page change triggered', { logTag, rowsPerPage: event.target.value });  // Log rows per page change
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (didMount.current) {
            didMount.current = false;
            return;
        }
        fetchStudents();
    }, [page, rowsPerPage]);

    return (
        <>
             <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        Students
                    </Typography>
                    <Chip
                        onClick={() => {
                            logger.info('Navigating to add new student', { logTag });  // Log navigation to add student
                            navigate(`../new`);
                        }}
                        icon={<AddCircleOutlineIcon />}
                        label="Add student"
                        variant="outlined"
                        color="secondary"
                    />
                </Box>
                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12}>
                        <TextField 
                            id="outlined-search" 
                            label="Search students" 
                            type="search" 
                            sx={{ mb: 3, width: '20rem' }}
                            onChange={onStudentSearch}
                        />
                        {isLoading &&
                            <Box sx={{ my: 2 }}><Loader /></Box>
                        }
                        <TableContainer component={Paper}>
                            <Table aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '.8rem' }}>#</TableCell>
                                        <TableCell sx={{ width: '40%' }}>Name</TableCell>
                                        <TableCell sx={{ width: '20%' }}>Roll no.</TableCell>
                                        <TableCell sx={{ width: '10%' }}>Class</TableCell>
                                        <TableCell sx={{ width: '10%' }}>Section</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {students.map((item, index) => (
                                        <TableRow
                                            key={item.rollNo}
                                        >
                                            <TableCell align="left">{page * rowsPerPage + (index + 1)}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.name}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.roll_no}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.sclass ? item.sclass.name : ''}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.section ? item.section.name : ''}
                                            </TableCell>
                                            <TableCell align="right">
                                                <VertActionMenu 
                                                    onView={() => {
                                                        logger.info('Viewing student details', { logTag, studentId: item.id });  // Log view student
                                                        setSelectedStudentId(item.id);
                                                        setIsStudentDetailDialogOpen(true);
                                                    }}
                                                    onEdit={() => {
                                                        logger.info('Navigating to edit student', { logTag, studentId: item.id });  // Log edit navigation
                                                        navigate(`${baseUrl + "/student/edit/" + item.id}`);
                                                    }}
                                                    onDelete={() => handleDelete(item.id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            colSpan={3}
                                            count={totalCount}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'rows per page',
                                                },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={CustomPaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
            {deleteJson.show &&
                <CustomModal 
                    show={deleteJson.show}
                    data={deleteJson.data}
                    type={ModalType.CONFIRM_DELETE}
                    onOkay={(data) => {
                        logger.info('Delete confirmed for student', { logTag, studentId: deleteJson.data.id });  // Log delete confirmation
                        onDelete(deleteJson.data);
                        setDeleteJson({
                            ...deleteJson,
                            show: false,
                        });
                    }}
                    onHide={() => {
                        logger.info('Delete modal closed', { logTag });  // Log modal close
                        setDeleteJson({
                            ...deleteJson,
                            show: false,
                        });
                    }}
                />
            }

            <StudentDetailDialog 
                open={isStudentDetailDialogOpen} 
                onClose={() => {
                    logger.info('Student detail dialog closed', { logTag });  // Log student detail dialog close
                    setIsStudentDetailDialogOpen(false);
                }}
                studentId={selectedStudentId}
            />

        </>
    );
}

export default StudentList;


// import React, { Component, useState, useEffect, useRef } from 'react';
// import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
//     Button, Chip, Paper, Grid, TableBody, IconButton, TableFooter, TablePagination, TextField } from '@mui/material';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import { useSelector } from 'react-redux';
// import { useNavigate } from "react-router-dom";

// import { getStudents, deleteStudent } from '../../../../services/http.service';
// import { CustomModal } from '../../../../common/modals';
// import { VertActionMenu } from '../../../../common/action-menu';
// import { ModalType } from '../../../../utils/constants';
// import Loader from '../../../../common/loader';
// import CustomPaginationActions from '../../../../utils/pagination';
// import StudentDetailDialog from '../../../../common/student-detail-dialog';


// const StudentList = () =>  {

//     const [students, setStudents] = useState([]);
//     const [selectedStudentId, setSelectedStudentId] = useState(null);
//     const [isLoading, setIsLoading] = useState(false);
//     const [deleteJson, setDeleteJson] = useState({
//         show: false,
//         data: {}
//     });
//     let [page, setPage] = useState(0);
//     const [rowsPerPage, setRowsPerPage] = useState(10);
//     const [totalCount, setTotalCount] = useState(0);
//     const [isStudentDetailDialogOpen, setIsStudentDetailDialogOpen] = useState(false);

//     const didMount = useRef(true);

//     let studentSearchKey = null;
    
//     const baseUrl = useSelector(state => state.extras.baseUrl);
//     const navigate = useNavigate();


//     useEffect(() => {
//         console.log('fetch students');
//         fetchStudents();
//     }, []);

//     // Fetch students
//     const fetchStudents = () => {
//         setIsLoading(true);
//         const filter = {
//             'searchKey': studentSearchKey
//         }
//         console.log(`new page ${page} --- row per page - ${rowsPerPage}`);
//         getStudents(filter, page, rowsPerPage).then(resp => {
//             const respData = resp.data.data;
//             setStudents(respData.students);
//             setTotalCount(respData.total_count);
//             setIsLoading(false);
//         }).catch(err => {
//             console.log(err);
//             setIsLoading(false);
//         })
//     }

//     // Handle Delete modal   
//     const handleDelete = (id) => {
//         setDeleteJson({
//             ...deleteJson,
//             show: true,
//             data: {
//                 id: id
//             }
//         });
//         console.log(`delelte - ${JSON.stringify(deleteJson)}`);
//     }

//     // On Delete of School
//     const onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));

//         // Hide delete confirm modal
//         setDeleteJson({
//             ...deleteJson,
//             show: false,
//         });
//         // Show spinner
//         setIsLoading(true);
//         deleteStudent(data.id).then(resp => {
//             setIsLoading(false);
//             fetchStudents();
//         }).catch(err => {
//             setIsLoading(false);        
//         })
//     }

//      // on search stduents
//      const onStudentSearch = (event) => {
//          const val = event.target.value;
//         console.log(`on student search ${val}`);
//         studentSearchKey = val;
//         setPage(0); 
//         // page is not changed then it doesnt trigger fetch students in useEffect so call it here
//         if (page === 0) {
//             fetchStudents();
//         }
//     }

//     // ****************************** Pagination ******************************

//     const handleChangePage = (event, newPage) => {
//         setPage(newPage);
//     };

//     const handleChangeRowsPerPage = (event) => {
//         setRowsPerPage(parseInt(event.target.value, 10));
//         setPage(0);
//     };

//     useEffect(() => {
//         // avoid first render
//         if (didMount.current) {
//             didMount.current = false;
//             return;
//         }
//         fetchStudents();
//     }, [page, rowsPerPage]);


//     // const onFirst = () => {
//     //     console.log(`onFirst`);
//     //     page = 0;
//     //     setPage(page);
//     //     fetchStudents();  
//     // }

//     // const onPrevious = () => {
//     //     console.log(`onPrevious`);
//     //     if (page > 0) {
//     //         page -= 1;
//     //         setPage(page);
//     //         fetchStudents();
//     //     }
//     // }

//     // const onNext = () => {
//     //     console.log(`onNext`);
//     //     console.log(`cal count - ${(page * limit)}`)
//     //     // check if records on next page is less than the total record count i.e (page + 1)
//     //     if (((page + 1) * limit) < totalCount) {
//     //         page += 1;
//     //         setPage(page);
//     //         fetchStudents();
//     //     }
//     // }

//     // const onLast = () => {
//     //     console.log(`onLast`);
//     //     // Calculate last page number
//     //     // const { page, totalCount, limit } = this.state;
//     //     const newPage = Math.floor(totalCount/limit);
//     //     console.log(`new page - ${newPage}`);  
//     //     page = newPage;
//     //     setPage(page);   
//     //     fetchStudents();  
//     // }

//     // ************************** End Pagination ******************************
    

//     return (
//         <>
//              <Box
//                 sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "left",
//                     alignItems: "start",
//                 }}
//             >
//                 <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//                     <Typography component="h2" variant="header">
//                         Students
//                     </Typography>
//                     <Chip
//                         onClick={() => navigate(`../new`)}
//                         icon={<AddCircleOutlineIcon />}
//                         label="Add student"
//                         variant="outlined"
//                         color="secondary"
//                     />
//                 </Box>
//                 <Grid container spacing={2} sx={{ mt: 3 }}>
//                     <Grid item xs={12}>
//                         <TextField 
//                             id="outlined-search" 
//                             label="Search students" 
//                             type="search" 
//                             sx={{ mb: 3, width: '20rem' }}
//                             onChange={onStudentSearch}
//                         />
//                         { isLoading &&
//                             <Box sx={{ my: 2 }}><Loader /></Box>
//                         }
//                         <TableContainer component={Paper}>
//                             <Table aria-label="custom pagination table">
//                                 <TableHead>
//                                     <TableRow>
//                                         <TableCell sx={{ width: '.8rem' }}>#</TableCell>
//                                         <TableCell sx={{ width: '40%' }}>Name</TableCell>
//                                         <TableCell sx={{ width: '20%' }}>Roll no.</TableCell>
//                                         <TableCell sx={{ width: '10%' }}>Class</TableCell>
//                                         <TableCell sx={{ width: '10%' }}>Section</TableCell>
//                                         <TableCell align="right">Action</TableCell>
//                                     </TableRow>
//                                 </TableHead>
//                                 <TableBody>
//                                     {students.map((item, index) => (
//                                         <TableRow
//                                             key={item.rollNo}
//                                         >
//                                             <TableCell align="left">{page * rowsPerPage + (index + 1)}</TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.name}
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.roll_no}
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.sclass ? item.sclass.name : ''}
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.section ? item.section.name : ''}
//                                             </TableCell>
//                                             <TableCell align="right">
//                                                 {/* <Link to={`${baseUrl + "/student/" + item.id + "/detail-view"}`} target="_blank">
//                                                     <IconButton aria-label="preview" color="primary">
//                                                         <PreviewIcon fontSize="inherit" />
//                                                     </IconButton>
//                                                 </Link>

//                                                 <IconButton aria-label="edit" color="secondary"
//                                                     onClick={() => navigate(`${baseUrl + "/student/edit/" + item.id}`)}
//                                                 >
//                                                     <EditIcon fontSize="inherit" />
//                                                 </IconButton>
//                                                 <IconButton aria-label="delete" color="error"
//                                                     onClick={() => handleDelete(item.id)}
//                                                 >
//                                                     <DeleteIcon fontSize="inherit" />
//                                                 </IconButton> */}

//                                                 <VertActionMenu 
//                                                     onView={() => {
//                                                         setSelectedStudentId(item.id);
//                                                         setIsStudentDetailDialogOpen(true);
//                                                     }}
//                                                     onEdit={() => navigate(`${baseUrl + "/student/edit/" + item.id}`)}
//                                                     onDelete={() => handleDelete(item.id)}
//                                                 />

                                               


//                                             </TableCell>
//                                         </TableRow>
//                                     ))}
//                                 </TableBody>
//                                 <TableFooter>
//                                     <TableRow>
//                                         <TablePagination
//                                             rowsPerPageOptions={[5, 10, 25]}
//                                             colSpan={3}
//                                             count={totalCount}
//                                             rowsPerPage={rowsPerPage}
//                                             page={page}
//                                             SelectProps={{
//                                                 inputProps: {
//                                                     'aria-label': 'rows per page',
//                                                 },
//                                                 native: true,
//                                             }}
//                                             onPageChange={handleChangePage}
//                                             onRowsPerPageChange={handleChangeRowsPerPage}
//                                             ActionsComponent={CustomPaginationActions}
//                                         />
//                                     </TableRow>
//                                 </TableFooter>
//                             </Table>
//                         </TableContainer>
//                     </Grid>
//                 </Grid>
//             </Box>
//             {deleteJson.show &&
//                 <CustomModal 
//                     show={deleteJson.show}
//                     data={deleteJson.data}
//                     type={ModalType.CONFIRM_DELETE}
//                     onOkay={(data) => {
//                         onDelete(deleteJson.data);
//                         setDeleteJson({
//                             ...deleteJson,
//                             show: false,
//                         });
//                     }}
//                     onHide={() => {
//                         setDeleteJson({
//                             ...deleteJson,
//                             show: false,
//                         });
//                     }}
//                 >
//                 </CustomModal>
//             }

//             <StudentDetailDialog 
//                 open={isStudentDetailDialogOpen} 
//                 onClose={() => setIsStudentDetailDialogOpen(false)}
//                 studentId={selectedStudentId}
//             />

//         </>
//     );
// }

// export default StudentList;
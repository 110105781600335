import React, { useState, useRef, useCallback } from 'react';
import SingleMarkerMap from './single-marker-map';
import logger from '../../utils/logger';  // Assuming your custom logger is imported from a utils folder

const DraggableMap = ({ 
    center, 
    zoom, 
    onMapReady,
    onLocation,
}) => {
    const logTag = 'DraggableMap';
    const geocoderRef = useRef(null);

    const handleMapReady = (map, maps) => {
        try {
            onMapReady(map, maps);
            geocoderRef.current = new window.google.maps.Geocoder();
        } catch (error) {
            logger.error('Error during map initialization', { logTag, stack: error.stack });
        }
    };

    const handleMarkerDragged = useCallback((latLng) => {
        if (geocoderRef.current) {
            geocoderRef.current.geocode({ location: latLng }, (results, status) => {
                if (status === 'OK' && results[0]) {
                    logger.debug('Geocode successful', { logTag, address: results[0].formatted_address });
                    if (onLocation) {
                        onLocation({
                            "address": results[0].formatted_address,
                            "lat": latLng.lat(),
                            "lng": latLng.lng(),
                        });
                    }
                } else {
                    logger.error('Geocoder failed due to status', { logTag, status });
                }
            });
        } else {
            logger.error('Geocoder is not initialized', { logTag });
        }
    }, [onLocation]);

    return (
        <SingleMarkerMap
            zoom={zoom}
            center={center}
            isDraggable={true}
            onMarkerDragged={handleMarkerDragged}
            onMapReady={handleMapReady}
        />
    );
};

export default DraggableMap;



// import React, { useState, useRef, useCallback } from 'react';
// import SingleMarkerMap from './single-marker-map';

// const DraggableMap = ({ 
//     center, 
//     zoom, 
//     onMapReady,
//     onLocation,
// }) => {
//     const geocoderRef = useRef(null);

//     const handleMapReady = (map, maps) => {
//         onMapReady(map, maps);
//         geocoderRef.current = new window.google.maps.Geocoder();
//     }

//     const handleMarkerDragged = useCallback((latLng) => {
//         if (geocoderRef.current) {
//             geocoderRef.current.geocode({ location: latLng }, (results, status) => {
//                 if (status === 'OK' && results[0]) {
//                     if (onLocation) {
//                         onLocation({
//                             "address": results[0].formatted_address,
//                             "lat": latLng.lat(),
//                             "lng": latLng.lng(),
//                         });
//                     }
//                 } else {
//                     console.error('Geocoder failed due to: ' + status);
//                 }
//             });
//         } else {
//             console.error('Geocoder is not initialized');
//         }
//     }, [onLocation]);

//     return (
//         <SingleMarkerMap
//             zoom={zoom}
//             center={center}
//             isDraggable={true}
//             onMarkerDragged={handleMarkerDragged}
//             onMapReady={handleMapReady}
//         />
//     );
// };

// export default DraggableMap;

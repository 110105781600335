import React, { Component, useEffect, useState } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
    Button, Chip, Paper, Grid, TableBody, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { getDrivers, deleteDriver } from '../../../../services/http.service';
import { CustomModal } from '../../../../common/modals';
import Loader from '../../../../common/loader';
import { ModalType } from '../../../../utils/constants';
import { VertActionMenu } from '../../../../common/action-menu';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'DriverList';  // Define logTag for this component

const DriverList = () => {

    const [drivers, setDrivers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });

    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();

    useEffect(() => {
        logger.info('DriverList component mounted', { logTag });  // Log component mount
        fetchDrivers();
    }, []);

    // Fetch drivers
    const fetchDrivers = () => {
        logger.info('Fetching drivers', { logTag });  // Log fetching drivers
        setIsLoading(true);
        getDrivers().then(resp => {
            setDrivers(resp.data.data);
            setIsLoading(false);
            logger.info('Drivers fetched successfully', { logTag, drivers: resp.data.data });  // Log success
        }).catch(err => {
            logger.error('Error fetching drivers', err, { logTag });  // Log error
            setIsLoading(false);
        })
    }

    // Handle Delete modal   
    const handleDelete = (id) => {
        logger.info('Delete driver requested', { logTag, driverId: id });  // Log delete request
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });
        console.log(`delete - ${JSON.stringify(deleteJson)}`);
    }

    // On Delete of Driver
    const onDelete = (data) => {
        logger.info('Confirm delete driver', { logTag, driverId: data.id });  // Log confirm delete
        console.log('on delete data ' + JSON.stringify(data));

        setDeleteJson({
            ...deleteJson,
            show: false
        });
        // Show spinner
        setIsLoading(true);
        deleteDriver(data.id).then(resp => {
            setIsLoading(false);
            logger.info('Driver deleted successfully', { logTag, driverId: data.id });  // Log success
            fetchDrivers();
        }).catch(err => {
            logger.error('Error deleting driver', err, { logTag, driverId: data.id });  // Log error
            setIsLoading(false);          
        })
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        School drivers
                    </Typography>
                    <Chip
                        onClick={() => {
                            logger.info('Navigating to add new driver', { logTag });  // Log navigation to create new driver
                            navigate(`../new`);
                        }}
                        icon={<AddCircleOutlineIcon />}
                        label="Add new driver"
                        variant="outlined"
                        color="secondary"
                    />
                </Box>

                {isLoading &&
                    <Loader />
                }

                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '.8rem' }}>#</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Mobile</TableCell>
                                        <TableCell>License</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {drivers.map((item, index) => (
                                        <TableRow
                                            key={item.id}
                                        >
                                            <TableCell align="left">{index + 1}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.user.name}
                                                {!item.user.is_verified &&
                                                    <>
                                                        <br/>
                                                        <Chip label="Not verified" size="small" color="error" variant="outlined" />
                                                    </>
                                                }
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.user.mobile}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.license_number}
                                            </TableCell>
                                            <TableCell align="right">
                                                <VertActionMenu
                                                    onVerify={() => {
                                                        logger.info('Verify driver clicked', { logTag, driverId: item.id });  // Log verify driver
                                                        navigate(`${baseUrl}/driver/${item.id}/verify`);
                                                    }}
                                                    onPhoto={() => {
                                                        logger.info('Driver photo clicked', { logTag, driverId: item.id });  // Log photo action
                                                        navigate(`${baseUrl}/driver/${item.id}/photo`);
                                                    }}
                                                    onEdit={() => {
                                                        logger.info('Edit driver clicked', { logTag, driverId: item.id });  // Log edit action
                                                        navigate(`${baseUrl + "/driver/edit/" + item.id}`);
                                                    }}
                                                    onDelete={() => handleDelete(item.id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>

            {/* Delete modal */}
            <CustomModal
                show={deleteJson.show}
                data={deleteJson.data}
                type={ModalType.CONFIRM_DELETE}
                onOkay={(data) => {
                    logger.info('Delete confirmed for driver', { logTag, driverId: data.id });  // Log delete confirmation
                    onDelete(data);
                }}
                onHide={() => {
                    logger.info('Delete modal closed', { logTag });  // Log modal close
                    setDeleteJson({ ...deleteJson, show: false });
                }}
            ></CustomModal>
        </>
    );
}

export default DriverList;


// import React, { Component, useEffect, useState } from 'react';
// import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
//     Button, Chip, Paper, Grid, TableBody, IconButton } from '@mui/material';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { connect, useSelector } from 'react-redux'
// import { useNavigate } from "react-router-dom";

// import { getDrivers, deleteDriver } from '../../../../services/http.service';
// import { CustomModal } from '../../../../common/modals';
// import Loader from '../../../../common/loader';
// import { ModalType } from '../../../../utils/constants';
// import { VertActionMenu } from '../../../../common/action-menu';


// const DriverList = () => {

//     const [drivers, setDrivers] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);
//     const [deleteJson, setDeleteJson] = useState({
//         show: false,
//         data: {}
//     });

//     const baseUrl = useSelector(state => state.extras.baseUrl);
//     const navigate = useNavigate();

//     useEffect(() => {
//         fetchDrivers();
//     }, []);

//     // Fetch drivers
//     const fetchDrivers = () => {
//         setIsLoading(true);
//         getDrivers().then(resp => {
//             setDrivers(resp.data.data);
//             setIsLoading(false);
//         }).catch(err => {
//             console.log(err);
//             setIsLoading(false);
//         })
//     }

//     // Handle Delete modal   
//     const handleDelete = (id) => {
//         setDeleteJson({
//             ...deleteJson,
//             show: true,
//             data: {
//                 id: id
//             }
//         });
//         console.log(`delelte - ${JSON.stringify(deleteJson)}`);
//     }

//     // On Delete of School
//     const onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));

//         // Hide delete confirm modal
//         setDeleteJson({
//             ...deleteJson,
//             show: false
//         });
//         // Show spinner
//         setIsLoading(true);
//         deleteDriver(data.id).then(resp => {
//             setIsLoading(false);
//             fetchDrivers();
//         }).catch(err => {
//             setIsLoading(false);          
//         })
//     }

//     return (
//         <>
//             <Box
//                 sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "left",
//                     alignItems: "start",
//                 }}
//             >
//                 <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//                     <Typography component="h2" variant="header">
//                         School drivers
//                     </Typography>
//                     <Chip
//                         onClick={() => navigate(`../new`)}
//                         icon={<AddCircleOutlineIcon />}
//                         label="Add new driver"
//                         variant="outlined"
//                         color="secondary"
//                     />
//                 </Box>

//                 { isLoading &&
//                     <Loader />
//                 }

//                 <Grid container spacing={2} sx={{ mt: 3 }}>
//                     <Grid item xs={12}>
//                         <TableContainer component={Paper}>
//                             <Table aria-label="simple table">
//                                 <TableHead>
//                                     <TableRow>
//                                         <TableCell sx={{ width: '.8rem' }}>#</TableCell>
//                                         <TableCell>Name</TableCell>
//                                         <TableCell>Mobile</TableCell>
//                                         <TableCell>License</TableCell>
//                                         <TableCell align="right">Action</TableCell>
//                                     </TableRow>
//                                 </TableHead>
//                                 <TableBody>
//                                     {drivers.map((item, index) => (
//                                         <TableRow
//                                             key={item.id}
//                                         >
//                                             <TableCell align="left">{index + 1}</TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.user.name}
//                                                 { !item.user.is_verified &&
//                                                     <>
//                                                         <br/>
//                                                         <Chip label="Not verified" size="small" color="error" variant="outlined" />
//                                                     </>
//                                                 }
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.user.mobile}
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.license_number}
//                                             </TableCell>
//                                             <TableCell align="right">
//                                                 <VertActionMenu
//                                                     onVerify={() => navigate(`${baseUrl}/driver/${item.id}/verify`)}
//                                                     onPhoto={()=> navigate(`${baseUrl}/driver/${item.id}/photo`)}
//                                                     onEdit={() => navigate(`${baseUrl + "/driver/edit/" + item.id}`)}
//                                                     onDelete={() => handleDelete(item.id)}
//                                                 />

//                                                 {/* <IconButton aria-label="edit" color="secondary"
//                                                     onClick={() => navigate(`${baseUrl + "/driver/edit/" + item.id}`)}
//                                                 >
//                                                     <EditIcon fontSize="inherit" />
//                                                 </IconButton>
//                                                 <IconButton aria-label="delete" color="error"
//                                                     onClick={() => handleDelete(item.id)}
//                                                 >
//                                                     <DeleteIcon fontSize="inherit" />
//                                                 </IconButton> */}

//                                             </TableCell>
//                                         </TableRow>
//                                     ))}
//                                 </TableBody>
//                             </Table>
//                         </TableContainer>
//                     </Grid>
//                 </Grid>
//             </Box>
//             {/* Delete modal */}
//             <CustomModal
//                 show={deleteJson.show}
//                 data={deleteJson.data}
//                 type={ModalType.CONFIRM_DELETE}
//                 onOkay={(data) => { onDelete(data) }}
//                 onHide={ () => {setDeleteJson({...deleteJson, show: false})} }
//             ></CustomModal>
//         </>
//     );
// }

// export default DriverList;

import React, { useState, useEffect, useRef } from 'react';

import {
    Button,
    Chip,
    Box,
    Paper,
    Grid,
} from '@mui/material';
import { useSelector } from 'react-redux';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import RouteStopDialog from '../../../../common/route-stop-dialog';
import RoutableMap from '../../../../common/googlemap/routable-map';
import { DEFAULT_MAP_LOCATION } from '../../../../utils/constants';
import RouteStopsTable from './route-stops-table';
import { deleteRouteStop, enableDisableRouteStop } from '../../../../services/http.service';
import { AlertSnackbar } from '../../../../common/snackbars';
import { ModalType } from '../../../../utils/constants';
import { CustomModal } from '../../../../common/modals';
import schoolIcon from '../../../../assets/images/school_icon.png';
import stopIcon from '../../../../assets/images/stop_icon.png';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'AddRouteStops';  // Define logTag for this component

const AddRouteStops = ({ route, onNext, onBack, onSubmit, onUpdateRoute }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [isRouteStopDialogOpen, setRouteStopDialogOpen] = useState(false);
    const [initialCenter, setInitialCenter] = useState({ lat: DEFAULT_MAP_LOCATION.latitude, lng: DEFAULT_MAP_LOCATION.longitude });
    const [stopForEdit, setStopForEdit] = useState(null);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });
    const [disableJson, setDisableJson] = useState({
        show: false,
        data: {}
    });
    const [sortedRouteStops, setSortedRouteStops] = useState([]);
    const [stopLocationsForMap, setStopLocationsForMap] = useState([]);

    const routableMapRef = useRef(null);
    const school = useSelector(state => state.school);

    const openRouteStopDialog = () => {
        setStopForEdit(null);  // Reset for adding new stop
        logger.info('Opening route stop dialog', { logTag });  // Log opening stop dialog
        setRouteStopDialogOpen(true);
    }

    const closeRouteStopDialog = () => {
        logger.info('Closing route stop dialog', { logTag });  // Log closing stop dialog
        setRouteStopDialogOpen(false);
    }

    const onSaveRouteStop = (data) => {
        logger.info('Route stop saved', { logTag, routeStop: data });  // Log saving stop
        const updatedRoute = {
            ...route,
            id: data.route_id
        }
        onSubmit(updatedRoute);
    }

    const onUpdateRouteStop = (data) => {
        logger.info('Route stop updated', { logTag, routeStop: data });  // Log updating stop
        const updatedRoute = {
            ...route,
            id: data.routeId
        }
        onSubmit(updatedRoute);
    }

    const onStopOrderChange = (stops) => {
        logger.info('Stop order changed', { logTag, stops });  // Log order change
        const newOrdersMap = stops.reduce((map, item) => {
            map[item.id] = item.order;
            return map;
        }, {});

        const updatedRouteStops = route.stops.map(stop => {
            if (newOrdersMap.hasOwnProperty(stop.id)) {
                return {
                    ...stop,
                    order: newOrdersMap[stop.id]
                };
            }
            return stop;
        });

        const newRoute = {
            ...route,
            stops: updatedRouteStops
        }
        onUpdateRoute(newRoute);
    }

    // Handle delete of route stop
    const handleDelete = (routeStop) => {
        logger.info('Delete route stop requested', { logTag, stopId: routeStop.stop.id });  // Log delete request
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: routeStop.stop.id
            }
        });
    }
    const onDelete = (data) => {
        logger.info('Confirm delete route stop', { logTag, stopId: data.id });  // Log confirm delete
        setDeleteJson({
            ...deleteJson,
            show: false
        });
        deleteRouteStop(data.id).then(resp => {
            setSuccessMsg("Deleted successfully");
            setErrorMsg(null);
            onSubmit(route);  // Trigger fetch route to refresh
        }).catch(err => {
            logger.error('Error deleting route stop', err, { logTag, stopId: data.id });  // Log error
            setErrorMsg(err);
            setSuccessMsg(null);
        });
    }

    // Handle disable of route stop
    const handleDisable = (routeStop, isDisabled) => {
        logger.info('Disable/Enable route stop requested', { logTag, stopId: routeStop.stop.id, isDisabled });  // Log disable/enable request
        setDisableJson({
            ...disableJson,
            show: true,
            data: {
                id: routeStop.stop.id,
                isDisabled: isDisabled
            }
        });
    }
    const onDisable = (data) => {
        logger.info('Confirm disable/enable route stop', { logTag, stopId: data.id, isDisabled: data.isDisabled });  // Log confirm disable/enable
        setDisableJson({
            ...disableJson,
            show: false,
        });
        enableDisableRouteStop(data.id, data.isDisabled).then(resp => {
            setSuccessMsg(`${data.isDisabled ? 'Disabled' : 'Enabled'} successfully`);
            setErrorMsg(null);
            onSubmit(route);  // Trigger fetch route to refresh
        }).catch(err => {
            logger.error('Error disabling/enabling route stop', err, { logTag, stopId: data.id });  // Log error
            setErrorMsg(err);
            setSuccessMsg(null);
        });
    }

    // Handle edit of route stop
    const handleEdit = (routeStop) => {
        logger.info('Edit route stop requested', { logTag, stopId: routeStop.stop.id });  // Log edit request
        setStopForEdit(routeStop.stop);
        setRouteStopDialogOpen(true);
    }

    // Handle show location on map
    const handleViewLocationOnMap = (routeStop) => {
        if (routeStop) {
            logger.info('View location on map for stop', { logTag, stopId: routeStop.stop.id });  // Log view location
            if (routableMapRef.current) {
                routableMapRef.current.showInfoWindow(routeStop.stop.location);
            }
        }
    }

    useEffect(() => {
        logger.info('AddRouteStops component mounted', { logTag });  // Log component mount

        if (route.stops && route.stops.length > 0) {
            const sortedStops = [...route.stops].sort((a, b) => a.order - b.order);
            setSortedRouteStops(sortedStops);
            const stopLocations = sortedStops.filter(stop => stop.isActive).map(routeStop => routeStop.stop.location);
            const schoolLocation = {
                latitude: school.latitude,
                longitude: school.longitude,
                location: school.address,
            }
            stopLocations.unshift(schoolLocation);  // Add at the top for origin
            setStopLocationsForMap(stopLocations);
        }
    }, [route.stops]);

    return (
        <>
            {errorMsg || successMsg &&
                <AlertSnackbar 
                    errorMsg={errorMsg}
                    successMsg={successMsg}
                    onClose={() => {
                        logger.info('AlertSnackbar closed', { logTag });  // Log alert snackbar close
                        setErrorMsg(null);
                        setSuccessMsg(null);
                    }}
                />
            }

            {/* Delete modal */}
            <CustomModal
                show={deleteJson.show}
                data={deleteJson.data}
                type={ModalType.CONFIRM_DELETE}
                onOkay={(data) => {
                    logger.info('Delete route stop confirmed', { logTag, stopId: deleteJson.data.id });  // Log delete confirmation
                    onDelete(data);
                }}
                onHide={() => {
                    logger.info('Delete modal closed', { logTag });  // Log delete modal close
                    setDeleteJson({ ...deleteJson, show: false });
                }}
            />
            {/* Disable modal */}
            <CustomModal
                show={disableJson.show}
                data={disableJson.data}
                type={ModalType.CONFIRM_DISABLE}
                onOkay={(data) => {
                    logger.info('Disable/Enable route stop confirmed', { logTag, stopId: disableJson.data.id });  // Log disable/enable confirmation
                    onDisable(data);
                }}
                onHide={() => {
                    logger.info('Disable/Enable modal closed', { logTag });  // Log disable/enable modal close
                    setDisableJson({ ...disableJson, show: false });
                }}
            />
            
            {/* Show route detail dialog */}
            <RouteStopDialog
                open={isRouteStopDialogOpen}
                onClose={closeRouteStopDialog}
                routeId={route.id}
                routeType={route.type}
                onSubmit={onSaveRouteStop}
                onUpdate={onUpdateRouteStop}
                stopForEdit={stopForEdit}
            />

            <Grid container spacing={2} sx={{ padding: 2, mt: 2 }} component={Paper}>
                <Grid item xs={12} md={6}>
                    <RouteStopsTable
                        routeId={route.id}
                        routeStops={sortedRouteStops}
                        handleStopOrderChange={onStopOrderChange}
                        onDelete={handleDelete}
                        onDisable={handleDisable}
                        onEdit={handleEdit}
                        onViewLocationOnMap={handleViewLocationOnMap}
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ minHeight: '500px' }}>
                    {stopLocationsForMap.length > 0 &&
                        <RoutableMap
                            locations={stopLocationsForMap}
                            originIcon={schoolIcon}
                            otherIcon={stopIcon}
                            ref={routableMapRef}
                        />
                    }
                </Grid>
            </Grid>

            {/* Add new stop */}
            <Chip
                onClick={openRouteStopDialog}
                icon={<AddCircleIcon />}
                label="Add Stop"
                variant="outlined"
                color="secondary"
                sx={{ mt: 1 }}
            />

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ my: 2 }}>
                    <Button
                        variant="outlined"
                        sx={{ mx: 2 }}
                        startIcon={<ArrowBackIosNewIcon />}
                        onClick={() => {
                            logger.info('Back button clicked', { logTag, routeId: route.id });  // Log back button click
                            onBack();
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{ mx: 2 }}
                        startIcon={<ArrowForwardIosIcon />}
                        onClick={() => {
                            logger.info('Next button clicked', { logTag, routeId: route.id });  // Log next button click
                            onNext();
                        }}
                    >
                        Next
                    </Button>
                </Box>
            </Box>
        </>
    );
}

export default AddRouteStops;



// import React, { useState, useEffect, useRef } from 'react';

// import {
//     Button,
//     Chip,
//     Box,
//     Paper,
//     Grid,
// } from '@mui/material';
// import { useSelector } from 'react-redux';


// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


// import RouteStopDialog from '../../../../common/route-stop-dialog';
// import RoutableMap from '../../../../common/googlemap/routable-map';
// import { DEFAULT_MAP_LOCATION } from '../../../../utils/constants';
// import RouteStopsTable from './route-stops-table';
// import { deleteRouteStop, enableDisableRouteStop } from '../../../../services/http.service';
// import { AlertSnackbar } from '../../../../common/snackbars';
// import { ModalType } from '../../../../utils/constants';
// import { CustomModal } from '../../../../common/modals';
// import schoolIcon from '../../../../assets/images/school_icon.png';
// import stopIcon from '../../../../assets/images/stop_icon.png';

// const AddRouteStops = ({ route, onNext, onBack, onSubmit, onUpdateRoute }) => {
//     const [isSaving, setIsSaving] = useState(false);
//     const [errorMsg, setErrorMsg] = useState(null);
//     const [successMsg, setSuccessMsg] = useState(null);
//     const [isRouteStopDialogOpen, setRouteStopDialogOpen] = useState(false);
//     const [initialCenter, setInitialCenter] = useState({lat: DEFAULT_MAP_LOCATION.latitude, lng: DEFAULT_MAP_LOCATION.longitude});
//     const [stopForEdit, setStopForEdit] = useState(null);
//     const [deleteJson, setDeleteJson] = useState({
//         show: false,
//         data: {}
//     });
//     const [disableJson, setDisableJson] = useState({
//         show: false,
//         data: {}
//     });
//     const [sortedRouteStops, setSortedRouteStops] = useState([]);
//     const [stopLocationsForMap, setStopLocationsForMap] = useState([]);

//     const routableMapRef = useRef(null);

//     const school = useSelector(state => state.school);

//     const openRouteStopDialog = () => {
//         setStopForEdit(null); // reset else adding new stop will consider it as update
//         setRouteStopDialogOpen(true);
//     }

//     const closeRouteStopDialog = () => {
//         setRouteStopDialogOpen(false);
//     }

//     const onSaveRouteStop = (data) => {
//         console.log("onSaveRouteStop ", data);
//         // Set route it if it's new route
//         const updatedRoute = {
//             ...route,
//             id: data.route_id
//         }
//         onSubmit(updatedRoute);
//     }

//     const onUpdateRouteStop = (data) => {
//         console.log("onUpdateRouteStop ", data);
//         // Set route it if it's new route
//         const updatedRoute = {
//             ...route,
//             id: data.routeId
//         }
//         // TODO - instead of fetching complete route - only update the single stop in the route
//         onSubmit(updatedRoute);
//     }

//     const onStopOrderChange = (stops) => {
//         console.log("AddRouteStops ", JSON.stringify(stops));
//         console.log("current route stops", JSON.stringify(route.stops));

//         // Create a map for quick access to new orders by stop id
//         const newOrdersMap = stops.reduce((map, item) => {
//             map[item.id] = item.order;
//             return map;
//         }, {});

//         // Update the order in the current route stops based on the new orders
//         const updatedRouteStops = route.stops.map(stop => {
//             if (newOrdersMap.hasOwnProperty(stop.id)) {
//                 return {
//                     ...stop,
//                     order: newOrdersMap[stop.id]
//                 };
//             }
//             return stop;
//         });
//         console.log("updatedRouteStops ", updatedRouteStops);

//         const newRoute = {
//             ...route,
//             stops: updatedRouteStops
//         }
//         onUpdateRoute(newRoute);
//     }

//     // Handle delete of route stop
//     const handleDelete = (routeStop) => {
//         console.log("handleDelete ", routeStop);
//         setDeleteJson({
//             ...deleteJson,
//             show: true,
//             data: {
//                 id: routeStop.stop.id
//             }
//         });
//     }
//     const onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));
//         // Hide delete confirm modal
//         setDeleteJson({
//             ...deleteJson,
//             show: false
//         });
//         deleteRouteStop(data.id).then(resp => {
//             setSuccessMsg("Deleted successfully");
//             setErrorMsg(null);
//             onSubmit(route); // Trigger fetch route to refresh
//         }).catch(err => {
//             setErrorMsg(err);
//             setSuccessMsg(null);
//         });
//     }


//     // Handle disable of route stop
//     const handleDisable = (routeStop, isDisabled) => {
//         console.log("handleDisable ", routeStop);
//         setDisableJson({
//             ...disableJson,
//             show: true,
//             data: {
//                 id: routeStop.stop.id,
//                 isDisabled: isDisabled
//             }
//         });
//     }
//     const onDisable = (data) => {
//         console.log('on disable data ' + JSON.stringify(data));
//         // Hide delete confirm modal
//         setDisableJson({
//             ...disableJson,
//             show: false,
//         });
//         enableDisableRouteStop(data.id, data.isDisabled).then(resp => {
//             setSuccessMsg(`${data.isDisabled ? 'Disabled':'Enabled'} successfully`);
//             setErrorMsg(null);
//             onSubmit(route); // Trigger fetch route to refresh
//         }).catch(err => {
//             setErrorMsg(err);
//             setSuccessMsg(null);
//         })
//     }


//     // Handle edit of route stop
//     const handleEdit = (routeStop) => {
//         console.log("handleEdit ", routeStop);
//         setStopForEdit(routeStop.stop);
//         setRouteStopDialogOpen(true);
//     }

//     // Handle show location on map
//     const handleViewLocationOnMap = (routeStop) => {
//         if (routeStop) {
//             console.log("handleViewLocationOnMap ", routeStop);
//             if (routableMapRef.current) {
//                 routableMapRef.current.showInfoWindow(routeStop.stop.location);
//             }
//         }
//     }

//     useEffect(() => {
//         if (route.stops && route.stops.length > 0) {
//             const sortedStops = [...route.stops].sort((a, b) => a.order - b.order);
//             console.log("sortedStops - ", sortedStops);
//             setSortedRouteStops(sortedStops);
//             // Extract stop locations for map
//             const stopLocations = sortedStops.filter(stop => stop.isActive).map(routeStop => routeStop.stop.location);
//             console.log("stop locations for map ", JSON.stringify(stopLocations));
//             const schoolLocation = {
//                 latitude: school.latitude,
//                 longitude: school.longitude,
//                 location: school.address,
//             }
//             stopLocations.unshift(schoolLocation); // Add at the top for origin
//             setStopLocationsForMap(stopLocations);
//         }
//     }, [route.stops]);

//     return (
//         <>
//             {errorMsg || successMsg &&
//                 <AlertSnackbar 
//                     errorMsg={errorMsg}
//                     successMsg={successMsg}
//                     onClose={() => {
//                         setErrorMsg(null);
//                         setSuccessMsg(null);
//                     }}
//                 />
//             }

//             {/* Delete modal */}
//             <CustomModal
//                 show={deleteJson.show}
//                 data={deleteJson.data}
//                 type={ModalType.CONFIRM_DELETE}
//                 onOkay={(data) => { onDelete(data) }}
//                 onHide={ () => {setDeleteJson({...deleteJson, show: false})} }
//             />
//             {/* Disable modal */}
//             <CustomModal
//                 show={disableJson.show}
//                 data={disableJson.data}
//                 type={ModalType.CONFIRM_DISABLE}
//                 onOkay={(data) => { onDisable(data) }}
//                 onHide={ () => {setDisableJson({...disableJson, show: false})} }
//             />
            
             
//              {/* Show route detail dialog */}
//              <RouteStopDialog
//                 open={isRouteStopDialogOpen}
//                 onClose={closeRouteStopDialog}
//                 routeId={route.id}
//                 routeType={route.type}
//                 onSubmit={onSaveRouteStop}
//                 onUpdate={onUpdateRouteStop}
//                 stopForEdit={stopForEdit}
//             />

//             <Grid container spacing={2} sx={{ padding: 2, mt: 2 }} component={Paper}>
//                 <Grid item xs={12} md={6}>
//                     <RouteStopsTable
//                         routeId={route.id}
//                         routeStops={sortedRouteStops}
//                         handleStopOrderChange={onStopOrderChange}
//                         onDelete={handleDelete}
//                         onDisable={handleDisable}
//                         onEdit={handleEdit}
//                         onViewLocationOnMap={handleViewLocationOnMap}
//                     />
//                 </Grid>
//                 <Grid item xs={12} md={6} sx={{ minHeight: '500px'}}>
//                     {stopLocationsForMap.length > 0 &&
//                         <RoutableMap
//                             locations={stopLocationsForMap}
//                             originIcon={schoolIcon}
//                             otherIcon={stopIcon}
//                             ref={routableMapRef}
//                         />
//                     }
//                 </Grid>
//             </Grid>

//             {/* Add new stop */}
//             <Chip
//                 onClick={openRouteStopDialog}
//                 icon={<AddCircleIcon />}
//                 label="Add Stop"
//                 variant="outlined"
//                 color="secondary"
//                 sx={{ mt: 1 }}
//             />

//             <Box sx={{ display: 'flex', flexDirection: 'column' }}>
//                 <Box sx={{ my: 2 }}>
//                     <Button
//                         variant="outlined"
//                         sx={{ mx: 2 }}
//                         startIcon={<ArrowBackIosNewIcon />}
//                         onClick={onBack}
//                     >
//                         Back
//                     </Button>
//                     <Button
//                         variant="outlined"
//                         sx={{ mx: 2 }}
//                         startIcon={<ArrowForwardIosIcon />}
//                         onClick={onNext}
//                     >
//                         Next
//                     </Button>
//                 </Box>
//             </Box>
            
//         </>
//     );
// }

// export default AddRouteStops;
import React, { Component, useState } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
    Button, Chip, Paper, Grid, TableBody, IconButton } from '@mui/material';
import { useNavigate } from "react-router-dom";

import { CustomModal } from '../../../../common/modals';
import { ModalType, formatDateTimeRev, convertRouteType, TripStatus } from '../../../../utils/constants';
import { VertActionMenu } from '../../../../common/action-menu';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'ListTab';  // Define logTag for this component

const ListTab = ({ trips, baseUrl, onTripDelete }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });

    const navigate = useNavigate();

    // Handle Delete modal   
    const handleDelete = (id) => {
        logger.info('Delete button clicked', { logTag, tripId: id });  // Log delete button click
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        })
        console.log(`delete - ${JSON.stringify(deleteJson)}`);
    }

    // On Delete of Trip
    const onDelete = (data) => {
        logger.info('Confirm delete', { logTag, tripId: data.id });  // Log confirm delete action
        console.log('on delete data ' + JSON.stringify(data));

        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false
        })
        onTripDelete(data.id);
    }

    return (
        <>
            <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '.8rem' }}>#</TableCell>
                                    <TableCell>Ref No.</TableCell>
                                    <TableCell>Route No.</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Expected Start Time</TableCell>
                                    <TableCell>Actual Start Time</TableCell>
                                    <TableCell>Bus</TableCell>
                                    <TableCell>Driver</TableCell>
                                    <TableCell align="right">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {trips.map((item, index) => (
                                    <TableRow key={item.name}>
                                        <TableCell align="left">{index + 1}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {item.ref_no}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {item.route.number}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {convertRouteType(item.route.type.name)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {formatDateTimeRev(item.expected_start_time)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {formatDateTimeRev(item.actual_start_time)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {item.bus ? item.bus.number : ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {item.driver ? item.driver.user.name : ''}
                                        </TableCell>
                                        <TableCell align="right">
                                            {(item.status === TripStatus.ON_RUN || item.status === TripStatus.FINISHED) &&
                                                <VertActionMenu
                                                    onView={() => {
                                                        logger.info('View running trip clicked', { logTag, tripId: item.id });  // Log view running trip
                                                        navigate(`${baseUrl}/trip/${item.id}/running/map-view`);
                                                    }}
                                                />
                                            }
                                            {item.status === TripStatus.NEW &&
                                                <VertActionMenu
                                                    onView={() => {
                                                        logger.info('View trip details clicked', { logTag, tripId: item.id });  // Log view trip details
                                                        navigate(`${baseUrl}/trip/${item.id}/detail-view`);
                                                    }}
                                                    onEdit={() => {
                                                        logger.info('Edit trip clicked', { logTag, tripId: item.id });  // Log edit trip
                                                        navigate(`${baseUrl + "/trip/edit/" + item.id}`);
                                                    }}
                                                    onDelete={() => handleDelete(item.id)}
                                                />
                                            }
                                            {item.status === TripStatus.ABANDONED &&
                                                <VertActionMenu
                                                    onDelete={() => handleDelete(item.id)}
                                                />
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            {/* Delete modal */}
            <CustomModal
                show={deleteJson.show}
                data={deleteJson.data}
                type={ModalType.CONFIRM_DELETE}
                onOkay={(data) => {
                    logger.info('Confirm delete modal okay clicked', { logTag, tripId: data.id });  // Log delete confirmation
                    onDelete(data);
                }}
                onHide={() => {
                    logger.info('Delete modal closed', { logTag });  // Log modal close
                    setDeleteJson({...deleteJson, show: false});
                }}
            >
            </CustomModal>
        </>
    );
}

export default ListTab;


// import React, { Component, useState } from 'react';
// import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
//     Button, Chip, Paper, Grid, TableBody, IconButton } from '@mui/material';
// import { useNavigate } from "react-router-dom";

// import { CustomModal } from '../../../../common/modals';
// import { ModalType, formatDateTimeRev, convertRouteType, TripStatus } from '../../../../utils/constants';
// import { VertActionMenu } from '../../../../common/action-menu';


// const ListTab = ({ trips, baseUrl, onTripDelete }) => {

//     //const [trips, setTrips] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);
//     const [deleteJson, setDeleteJson] = useState({
//         show: false,
//         data: {}
//     });

//     const navigate = useNavigate();

//     // Handle Delete modal   
//     const handleDelete = (id) => {
//         setDeleteJson({
//             ...deleteJson,
//             show: true,
//             data: {
//                 id: id
//             }
//         })
//         console.log(`delelte - ${JSON.stringify(deleteJson)}`);
//     }

//     // On Delete of School
//     const onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));

//         // Hide delete confirm modal
//         setDeleteJson({
//             ...deleteJson,
//             show: false
//         })
//         onTripDelete(data.id);
//     }

//     return (
//         <>
//             <Grid container spacing={2} sx={{ mt: 3 }}>
//                 <Grid item xs={12}>
//                     <TableContainer component={Paper}>
//                         <Table aria-label="simple table">
//                             <TableHead>
//                                 <TableRow>
//                                     <TableCell sx={{ width: '.8rem' }}>#</TableCell>
//                                     <TableCell>Ref No.</TableCell>
//                                     <TableCell>Route No.</TableCell>
//                                      <TableCell>Type</TableCell>
//                                      <TableCell>Expected Start Time</TableCell>
//                                      <TableCell>Actual Start Time</TableCell>
//                                      <TableCell>Bus</TableCell>
//                                      <TableCell>Driver</TableCell>
//                                     <TableCell align="right">Action</TableCell>
//                                 </TableRow>
//                             </TableHead>
//                             <TableBody>
//                                 {trips.map((item, index) => (
//                                     <TableRow
//                                         key={item.name}
//                                     >
//                                         <TableCell align="left">{index + 1}</TableCell>
//                                         <TableCell component="th" scope="row">
//                                             {item.ref_no}
//                                         </TableCell>
//                                         <TableCell component="th" scope="row">
//                                             {item.route.number}
//                                         </TableCell>
//                                         <TableCell component="th" scope="row">
//                                             {convertRouteType(item.route.type.name)}
//                                         </TableCell>
//                                         <TableCell component="th" scope="row">
//                                             {formatDateTimeRev(item.expected_start_time)}
//                                         </TableCell>
//                                         <TableCell component="th" scope="row">
//                                             {formatDateTimeRev(item.actual_start_time)}
//                                         </TableCell>
//                                         <TableCell component="th" scope="row">
//                                             {item.bus ? item.bus.number : ''}
//                                         </TableCell>
//                                         <TableCell component="th" scope="row">
//                                             {item.driver ? item.driver.user.name : ''}
//                                         </TableCell>
//                                         <TableCell align="right">
//                                             {(item.status === TripStatus.ON_RUN || item.status == TripStatus.FINISHED) &&
//                                                 <VertActionMenu
//                                                     onView={() => navigate(`${baseUrl}/trip/${item.id}/running/map-view`)}
//                                                 />
//                                             }
//                                             {item.status == TripStatus.NEW &&
//                                                 <VertActionMenu
//                                                     onView={() => navigate(`${baseUrl}/trip/${item.id}/detail-view`)}
//                                                     onEdit={() => navigate(`${baseUrl + "/trip/edit/" + item.id}`)}
//                                                     onDelete={() => handleDelete(item.id)}
//                                                 />
//                                             }
//                                             {item.status == TripStatus.ABANDONED &&
//                                                 <VertActionMenu
//                                                     onDelete={() => handleDelete(item.id)}
//                                                 />
//                                             }
                                            
//                                         </TableCell>
//                                     </TableRow>
//                                 ))}
//                             </TableBody>
//                         </Table>
//                     </TableContainer>
//                 </Grid>
//             </Grid>
//             {/* Delete modal */}
//             <CustomModal
//                 show={deleteJson.show}
//                 data={deleteJson.data}
//                 type={ModalType.CONFIRM_DELETE}
//                 onOkay={(data) => { onDelete(data) }}
//                 onHide={ () => {setDeleteJson({...deleteJson, show: false})} }
//             >
//             </CustomModal>
//         </>
//     );
// }
// export default ListTab;

import React, { useState, useEffect, useRef } from 'react';

import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Chip,
    Collapse,
    Box,
    Grid,
    Typography,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { VertActionMenu } from '../../../../common/action-menu';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'RouteStudentsTable';  // Define logTag for this component

const RouteStudentsTable = ({ 
    routeId, 
    routeStudents, 
    onDelete, 
    onDisable, 
    onEdit,
}) => {

    useEffect(() => {
        logger.info('RouteStudentsTable component mounted', { logTag, routeId });  // Log component mount
    }, [routeId]);

    console.log("routeStudents ", routeStudents);

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 500 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Stop</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {routeStudents && routeStudents.map((row, index) => (
                                <Row
                                    key={row.id}
                                    row={row}
                                    onDelete={onDelete}
                                    onDisable={onDisable}
                                    onEdit={onEdit}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}

const Row = ({ row, onDisable, onDelete, onEdit }) => {
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        logger.info('Row component mounted for student', { logTag, studentId: row.student.id });  // Log row component mount
    }, [row.student.id]);

    return (
        <>
            <TableRow key={row.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell sx={{ width: '10px' }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            logger.info('Row expanded/collapsed', { logTag, studentId: row.student.id, expanded: !open });  // Log row expand/collapse
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {row.student.name}
                    {!row.isActive && <Chip sx={{ ml: 1 }} label="Disabled" color="error" variant="outlined" />}
                </TableCell>
                <TableCell>
                    {row.stop ? row.stop.name : 'N/A'}
                </TableCell>
                <TableCell align="right">
                    <VertActionMenu
                        onEdit={() => {
                            logger.info('Edit student clicked', { logTag, studentId: row.student.id });  // Log edit click
                            onEdit(row);
                        }}
                        onDisable={() => {
                            logger.info('Disable student clicked', { logTag, studentId: row.student.id });  // Log disable click
                            onDisable(row, true);
                        }}
                        onEnable={() => {
                            logger.info('Enable student clicked', { logTag, studentId: row.student.id });  // Log enable click
                            onDisable(row, false);
                        }}
                        onDelete={() => {
                            logger.info('Delete student clicked', { logTag, studentId: row.student.id });  // Log delete click
                            onDelete(row);
                        }}
                        isDisabled={row.isActive ? false : true}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <Typography component='div' variant='h5'>
                                        Roll No.
                                    </Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    {row.student.rollNo}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <Typography component='div' variant='h5'>
                                        Class
                                    </Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    {row.student.class.name}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <Typography component='div' variant='h5'>
                                        Section
                                    </Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    {row.student.section.name}
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default RouteStudentsTable;



// import React, { useState, useEffect, useRef } from 'react';

// import {
//     Paper,
//     TableContainer,
//     Table,
//     TableHead,
//     TableRow,
//     TableCell,
//     TableBody,
//     IconButton,
//     Chip,
//     Collapse,
//     Box,
//     Grid,
//     Typography,
// } from '@mui/material';

// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// import { VertActionMenu } from '../../../../common/action-menu';

// const RouteStudentsTable = ({ 
//     routeId, 
//     routeStudents, 
//     onDelete, 
//     onDisable, 
//     onEdit,
// }) => {

//     console.log("routeStudents ", routeStudents);

//     return (
//         <>
//             <Paper sx={{ width: '100%', overflow: 'hidden' }}>
//                 <TableContainer sx={{ maxHeight: 500 }}>
//                     <Table stickyHeader>
//                         <TableHead>
//                             <TableRow>
//                                 <TableCell />
//                                 <TableCell align="left">Name</TableCell>
//                                 <TableCell align="left">Stop</TableCell>
//                                 <TableCell align="right">Actions</TableCell>
//                             </TableRow>
//                         </TableHead>
//                         <TableBody>
//                             {routeStudents && routeStudents.map((row, index) => (
//                                 <Row
//                                     key={row.id}
//                                     row={row}
//                                     onDelete={onDelete}
//                                     onDisable={onDisable}
//                                     onEdit={onEdit}
//                                 />
//                             ))}
//                         </TableBody>
//                     </Table>
//                 </TableContainer>
//             </Paper>
//         </>
//     );
// }

// const Row = ({ row, onDisable, onDelete, onEdit }) => {
//     const [open, setOpen] = React.useState(false);

//     return (
//         <>
//             <TableRow key={row.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
//                 <TableCell sx={{ width: '10px' }}>
//                     <IconButton
//                         aria-label="expand row"
//                         size="small"
//                         onClick={() => setOpen(!open)}
//                     >
//                         {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
//                     </IconButton>
//                 </TableCell>
//                 <TableCell>
//                     {row.student.name}
//                     {!row.isActive && <Chip sx={{ ml: 1 }} label="Disabled" color="error" variant="outlined" />}
//                 </TableCell>
//                 <TableCell>
//                     {row.stop ? row.stop.name : 'N/A'}
//                 </TableCell>
//                 <TableCell align="right">
//                     <VertActionMenu
//                         onEdit={() => onEdit(row)}
//                         onDisable={() => onDisable(row, true)}
//                         onEnable={() => onDisable(row, false)}
//                         onDelete={() => onDelete(row)}
//                         isDisabled={row.isActive ? false : true}
//                     />
//                 </TableCell>
//             </TableRow>
//             <TableRow>
//                 <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
//                     <Collapse in={open} timeout="auto" unmountOnExit>
//                         <Box sx={{ margin: 1 }}>
//                             <Grid container spacing={2}>
//                                 <Grid item xs={2}>
//                                     <Typography component='div' variant='h5'>
//                                         Roll No.
//                                     </Typography>
//                                 </Grid>
//                                 <Grid item xs={10}>
//                                     {row.student.rollNo}
//                                 </Grid>
//                             </Grid>
//                             <Grid container spacing={2}>
//                                 <Grid item xs={2}>
//                                     <Typography component='div' variant='h5'>
//                                         Class
//                                     </Typography>
//                                 </Grid>
//                                 <Grid item xs={10}>
//                                     {row.student.class.name}
//                                 </Grid>
//                             </Grid>
//                             <Grid container spacing={2}>
//                                 <Grid item xs={2}>
//                                     <Typography component='div' variant='h5'>
//                                         Section
//                                     </Typography>
//                                 </Grid>
//                                 <Grid item xs={10}>
//                                     {row.student.section.name}
//                                 </Grid>
//                             </Grid>
//                         </Box>
//                     </Collapse>
//                 </TableCell>
//             </TableRow>
//         </>
//     );
// }

// export default RouteStudentsTable;
import React, { useRef, useEffect, useState, useCallback } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { GOOGLE_MAP_API_KEY } from '../../utils/constants';
import logger from '../../utils/logger';  // Assuming your custom logger is imported from a utils folder

const loadScript = (url, callback) => {
    const logTag = 'GoogleMapCustom::loadScript';
    //logger.info(`Loading script from URL: ${url}`, { logTag });
    
    const existingScript = document.querySelector(`script[src="${url}"]`);
    
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        script.defer = true;
        script.onload = () => {
            //logger.info(`Script loaded successfully: ${url}`, { logTag });
            callback();
        };
        document.head.appendChild(script);
    } else {
        //logger.debug(`Script already exists in the DOM: ${url}`, { logTag });
        if (existingScript.hasAttribute('async') && existingScript.hasAttribute('defer')) {
            callback();
        } else {
            existingScript.async = true;
            existingScript.defer = true;
            existingScript.onload = callback;
        }
    }
};

const GoogleMapCustom = React.memo(({ 
    onMapReady, 
    children,
    zoom = 8,
    center = {lat: null, lng: null},
    ...props 
}) => {
    const logTag = 'GoogleMapCustom';
    const mapRef = useRef(null);
    const mapInstanceRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [isMapReady, setIsMapReady] = useState(false);

    const initializeMap = useCallback(() => {
        //logger.info('Initializing Google Map', { logTag });
        
        if (!window.google) {
            //logger.error('Google Maps JavaScript API script not loaded', { logTag });
            return;
        }

        try {
            const map = new window.google.maps.Map(mapRef.current, {
                center: { lat: 28.4650447, lng: 77.5084151 },
                zoom,
                ...props,
            });
            mapInstanceRef.current = map;
            //logger.info('Google Map initialized successfully', { logTag });
            setLoading(false);
            setIsMapReady(true);
            if (onMapReady) {
                //logger.debug('Map is ready, invoking onMapReady callback', { logTag });
                onMapReady(map, window.google.maps);
            }
        } catch (error) {
            //logger.error('Error initializing Google Map', { logTag, stack: error.stack });
        }
    }, [onMapReady, zoom, center, props]);

    useEffect(() => {
        //logger.info('GoogleMapCustom component mounted', { logTag });

        if (window.google && window.google.maps) {
            initializeMap();
        } else {
            window.initMap = initializeMap;
            const apiKey = GOOGLE_MAP_API_KEY;
            //logger.debug('Loading Google Maps API script', { logTag });
            loadScript(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap`, initializeMap);
        }

        return () => {
            //logger.info('GoogleMapCustom component unmounted', { logTag });
            window.initMap = null;
        };
    }, [initializeMap]);

    useEffect(() => {
        if (isMapReady) {
            //logger.debug('Updating map center and zoom', { logTag });
            mapInstanceRef.current.setCenter(center);
            mapInstanceRef.current.setZoom(zoom);
        }
    }, [center, zoom, isMapReady]);

    return (
        <div style={{ height: '100%', width: '100%', position: 'relative' }}>
            {loading && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        zIndex: 999,
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            <div ref={mapRef} style={{ height: '100%', width: '100%' }}>
                {children && React.Children.map(children, (child) => {
                    if (React.isValidElement(child)) {
                        //logger.debug('Rendering child element inside GoogleMapCustom', { logTag });
                        return React.cloneElement(child, { map: mapInstanceRef.current });
                    }
                    return child;
                })}
            </div>
        </div>
    );
}, (prevProps, nextProps) => {
    const logTag = 'GoogleMapCustom::memoComparison';
    const isZoomEqual = prevProps.zoom === nextProps.zoom;
    const isCenterEqual =
        prevProps.center?.lat === nextProps.center?.lat &&
        prevProps.center?.lng === nextProps.center?.lng;
    const isChildrenEqual = prevProps.children === nextProps.children;

    // logger.debug('Checking if props have changed for GoogleMapCustom', {
    //     logTag,
    //     isZoomEqual, 
    //     isCenterEqual, 
    //     isChildrenEqual
    // });

    return isZoomEqual && isCenterEqual && isChildrenEqual;
});

export default GoogleMapCustom;


// import React, { useRef, useEffect, useState, useCallback } from 'react';
// import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';
// import { GOOGLE_MAP_API_KEY } from '../../utils/constants';

// const loadScript = (url, callback) => {
//     const existingScript = document.querySelector(`script[src="${url}"]`);
//     if (!existingScript) {
//         const script = document.createElement('script');
//         script.src = url;
//         script.async = true;
//         script.defer = true;
//         script.onload = callback;
//         document.head.appendChild(script);
//     } else {
//         if (existingScript.hasAttribute('async') && existingScript.hasAttribute('defer')) {
//             callback();
//         } else {
//             existingScript.async = true;
//             existingScript.defer = true;
//             existingScript.onload = callback;
//         }
//     }
// };

// const GoogleMapCustom = React.memo(({ 
//     onMapReady, 
//     children,
//     zoom = 8,
//     center = {lat: null, lat: null},
//     ...props 
// }) => {
//     const mapRef = useRef(null);
//     const mapInstanceRef = useRef(null);
//     const [loading, setLoading] = useState(true);
//     const [isMapReady, setIsMapReady] = useState(false);

//     const initializeMap = useCallback(() => {
//         if (!window.google) {
//             console.error("Google Maps JavaScript API script not loaded");
//             return;
//         }

//         const map = new window.google.maps.Map(mapRef.current, {
//             center: { lat: 28.4650447, lng: 77.5084151 },
//             zoom,
//             ...props,
//         });

//         mapInstanceRef.current = map;
//         setLoading(false); // Map has finished loading
//         setIsMapReady(true); // Indicate that the map is ready
//         if (onMapReady) {
//             onMapReady(map, window.google.maps);
//         }
//     }, [onMapReady, zoom, center, props]);

//     useEffect(() => {
//         if (window.google && window.google.maps) {
//             initializeMap();
//         } else {
//             window.initMap = initializeMap;
//             const apiKey = GOOGLE_MAP_API_KEY;
//             loadScript(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap`, initializeMap);
//         }

//         return () => {
//             window.initMap = null;
//         };
//     }, [initializeMap]);

//     useEffect(() => {
//         if (isMapReady) {
//             mapInstanceRef.current.setCenter(center);
//             mapInstanceRef.current.setZoom(zoom);
//         }
//     }, [center, zoom, isMapReady]);

//     return (
//         <div style={{ height: '100%', width: '100%', position: 'relative' }}>
//             {loading && (
//                 <Box
//                     sx={{
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         height: '100%',
//                         width: '100%',
//                         position: 'absolute',
//                         top: 0,
//                         left: 0,
//                         backgroundColor: 'rgba(255, 255, 255, 0.7)',
//                         zIndex: 999,
//                     }}
//                 >
//                     <CircularProgress />
//                 </Box>
//             )}
//             <div ref={mapRef} style={{ height: '100%', width: '100%' }}>
//                 {children && React.Children.map(children, (child) => {
//                     if (React.isValidElement(child)) {
//                         return React.cloneElement(child, { map: mapInstanceRef.current });
//                     }
//                     return child;
//                 })}
//             </div>
//         </div>
//     );
// }, (prevProps, nextProps) => {
//     const isZoomEqual = prevProps.zoom === nextProps.zoom;
//     const isCenterEqual =
//         prevProps.center?.lat === nextProps.center?.lat &&
//         prevProps.center?.lng === nextProps.center?.lng;
//     const isChildrenEqual = prevProps.children === nextProps.children;

//     return isZoomEqual && isCenterEqual && isChildrenEqual;
// });

// export default GoogleMapCustom;

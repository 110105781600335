import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, Chip, Paper, Grid,
    TextField, Autocomplete, CircularProgress, List, ListItem, ListItemText, ListItemAvatar, Avatar,
    IconButton, Divider } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { saveOrUpdateRouteDriver, deleteRouteDriver } from '../../../../services/http.service';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import { CustomModal } from '../../../../common/modals';
import { ModalType } from '../../../../utils/constants';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'AssignRouteDriver';  // Define logTag for this component

const AssignRouteDriver = ({ availableDrivers, route, onNext, onBack, onSubmit }) => {

    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });

    useEffect(() => {
        logger.info('AssignRouteDriver component mounted', { logTag, routeId: route.id });  // Log component mount
        console.log("availableDrivers - ", availableDrivers);
        setSelectedDriver(route.driver);
    }, [route]);

    // Save assigned driver
    const handleSubmit = () => {
        logger.info('Submitting assigned driver', { logTag, routeId: route.id, driverId: selectedDriver?.id });  // Log submit
        if (!selectedDriver) {
            return;
        }
        setIsSaving(true);
        setErrorMsg(null);
        setShowSuccessAlert(false);

        const data = {
            id: route.id,
            type: route.type,
            driver_id: selectedDriver.id
        }

        saveOrUpdateRouteDriver(data).then(resp => { 
            setIsSaving(false);
            setErrorMsg(null);
            setShowSuccessAlert(true);
            const respData = resp.data.data;
            if (respData) {
                const newRoute = {
                    ...route,
                    driver: selectedDriver,
                    id: respData.route_id,  // set back id to update for the same in next section
                }
                logger.info('Driver assigned to route successfully', { logTag, newRoute });  // Log success
                onSubmit(newRoute);
            }
        }).catch(err => {
            logger.error('Error assigning driver to route', err, { logTag, routeId: route.id });  // Log error
            setIsSaving(false);
            setErrorMsg(err);
            setShowSuccessAlert(false);
        });
    }

    // Delete driver from route
    const deleteDriver = (id) => {
        logger.info('Delete driver from route requested', { logTag, routeId: id });  // Log delete request
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });
    }

    // On Delete of Route driver
    const onDelete = (data) => {
        logger.info('Confirm delete driver from route', { logTag, routeId: data.id });  // Log confirm delete
        setDeleteJson({
            ...deleteJson,
            show: false,
        });
        setIsSaving(true);
        deleteRouteDriver(data.id).then(resp => {
            setIsSaving(false);
            logger.info('Driver removed from route successfully', { logTag, routeId: data.id });  // Log success
            onSubmit(route);
        }).catch(err => {
            logger.error('Error deleting driver from route', err, { logTag, routeId: data.id });  // Log error
            setIsSaving(false);          
        });
    }

    return (
        <>
            {errorMsg &&
                <ErrorAlertTopCenter
                    errorMsg={errorMsg}
                    onClose={() => {
                        logger.info('Error alert closed', { logTag });  // Log alert close
                        setErrorMsg(null);
                    }}
                />
            }
            {showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"Route students saved successfully!!"}
                    onClose={() => {
                        logger.info('Success alert closed', { logTag });  // Log success alert close
                        setShowSuccessAlert(false);
                    }}
                />
            }

            <Box>
                <Box sx={{ mt: 1 }}>
                    <Autocomplete
                        id="available-drivers"
                        options={availableDrivers}
                        sx={{ width: '25rem' }}
                        value={selectedDriver}
                        isOptionEqualToValue={(option, value) => {
                            if (value) {
                                return option.id === value.id
                            }
                            return false;
                        }}
                        getOptionLabel={(option) => option.user.name}
                        onChange={(event, newValue) => {
                            logger.info('Driver selected', { logTag, driverId: newValue?.id });  // Log driver selection
                            if (newValue) {
                                setSelectedDriver(newValue);
                            }
                        }}
                        renderInput={(params) => <TextField {...params} label="Assign Driver" />}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    mt: 2,
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center'
                }}
            >
                {isSaving &&
                    <CircularProgress />
                }
                {!isSaving &&
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ my: 2 }}>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ mx: 2 }}
                                onClick={handleSubmit}
                            >
                                Save
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{ mx: 2 }}
                                startIcon={<ArrowBackIosNewIcon />}
                                onClick={() => {
                                    logger.info('Back button clicked', { logTag, routeId: route.id });  // Log back button click
                                    onBack();
                                }}
                            >
                                Back
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{ mx: 2 }}
                                startIcon={<ArrowForwardIosIcon />}
                                onClick={() => {
                                    logger.info('Next button clicked', { logTag, routeId: route.id });  // Log next button click
                                    onNext();
                                }}
                            >
                                Next
                            </Button>
                        </Box>
                        {selectedDriver &&
                            <Box sx={{ my: 1 }}>
                                <Button 
                                    size="small" 
                                    color="error"
                                    onClick={() => {
                                        logger.info('Remove driver from route button clicked', { logTag, routeId: route.id });  // Log remove driver
                                        deleteDriver(route.id);
                                    }}>
                                    Remove Driver
                                </Button>
                            </Box>
                        }
                    </Box>
                }
            </Box>

            {/* For delete confirmation */}
            {deleteJson.show &&
                <CustomModal 
                    show={deleteJson.show}
                    data={deleteJson.data}
                    type={ModalType.CONFIRM_DELETE}
                    onOkay={(data) => {
                        logger.info('Delete driver from route confirmed', { logTag, routeId: deleteJson.data.id });  // Log delete confirmation
                        onDelete(deleteJson.data);
                        setDeleteJson({
                            ...deleteJson,
                            show: false,
                        });
                    }}
                    onHide={() => {
                        logger.info('Delete modal closed', { logTag });  // Log delete modal close
                        setDeleteJson({
                            ...deleteJson,
                            show: false,
                        });
                    }}
                >
                </CustomModal>
            }
        </>
    );

}

export default AssignRouteDriver;



// import React, { useState, useEffect, useRef } from 'react';
// import { useSelector } from 'react-redux';
// import { Link, useParams, useNavigate } from 'react-router-dom';
// import { Box, Button, Typography, Chip, Paper, Grid,
//     TextField, Autocomplete, CircularProgress, List, ListItem, ListItemText, ListItemAvatar, Avatar,
//     IconButton, Divider } from '@mui/material';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import { saveOrUpdateRouteDriver, deleteRouteDriver } from '../../../../services/http.service';
// import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
// import { CustomModal } from '../../../../common/modals';
// import { ModalType } from '../../../../utils/constants';

// const AssignRouteDriver = ({ availableDrivers, route, onNext, onBack, onSubmit }) => {

//     const [isSaving, setIsSaving] = useState(false);
//     const [errorMsg, setErrorMsg] = useState(null);
//     const [showSuccessAlert, setShowSuccessAlert] = useState(false);
//     const [selectedDriver, setSelectedDriver] = useState(null);
//     const [deleteJson, setDeleteJson] = useState({
//         show: false,
//         data: {}
//     });

//     useEffect(() => {
//         console.log("availableDrivers - ", availableDrivers);
//         setSelectedDriver(route.driver);
//     }, [route]);

//     // Save assinged bus
//     const handleSubmit = () => {
//         console.log("Selected Driver - ", selectedDriver);
//         if (!selectedDriver) {
//             return;
//         }
//         setIsSaving(true);
//         setErrorMsg(null);
//         setShowSuccessAlert(false);

//         const data = {
//             id: route.id,
//             type: route.type,
//             driver_id: selectedDriver.id
//         }

//         saveOrUpdateRouteDriver(data).then(resp => { 
//             setIsSaving(false);
//             setErrorMsg(null);
//             setShowSuccessAlert(true);
//             const respData = resp.data.data;
//             if (respData) {
//                 const newRoute = {
//                     ...route,
//                     driver: selectedDriver,
//                     id: respData.route_id, // set back id to update for the same in next section
//                 }
//                 onSubmit(newRoute);
//             }
//         }).catch(err => {
//             console.log(err);
//             setIsSaving(false);
//             setErrorMsg(err);
//             setShowSuccessAlert(false);
//         });
//     }

//     // Delete bus from route
//     const deleteDriver = (id) => {
//         setDeleteJson({
//             ...deleteJson,
//             show: true,
//             data: {
//                 id: id
//             }
//         });
//     }
//     // On Delete of Route driver
//     const onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));
//         // Hide delete confirm modal
//         setDeleteJson({
//             ...deleteJson,
//             show: false,
//         });
//         // Show spinner
//         setIsSaving(true);
//         deleteRouteDriver(data.id).then(resp => {
//             setIsSaving(false);
//             onSubmit(route);
//         }).catch(err => {
//             console.log(`Error in deleting driver from route :: ${JSON.stringify(err)}`);
//             setIsSaving(false);          
//         })
//     }

//     return (
//         <>
//             {errorMsg &&
//                 <ErrorAlertTopCenter
//                     errorMsg={errorMsg}
//                     onClose={() => setErrorMsg(null)}
//                 />
//             }
//             {showSuccessAlert &&
//                 <SuccessAlertTopRight
//                     successMsg={"Route students saved successfully!!"}
//                     onClose={() => setShowSuccessAlert(false)}
//                 />
//             }

//             <Box>
//                 <Box sx={{ mt: 1 }}>
//                     <Autocomplete
//                         id="available-drivers"
//                         options={availableDrivers}
//                         sx={{ width: '25rem' }}
//                         value={selectedDriver}
//                         isOptionEqualToValue={(option, value) => {
//                             if (value) {
//                                 return option.id === value.id
//                             }
//                             return false;
//                         }}
//                         getOptionLabel={(option) => option.user.name}
//                         onChange={(event, newValue) => {
//                             if (newValue) {
//                                 setSelectedDriver(newValue);
//                             }
//                         }}
//                         renderInput={(params) => <TextField {...params} label="Assign Driver" />}
//                     />
//                 </Box>
//             </Box>

//             <Box
//                 sx={{
//                     mt: 2,
//                     display: 'flex',
//                     justifyContent: 'start',
//                     alignItems: 'center'
//                 }}
//             >
//                 {isSaving &&
//                     <CircularProgress />
//                 }
//                 {!isSaving &&
//                     <Box sx={{ display: 'flex', flexDirection: 'column' }}>
//                         <Box sx={{ my: 2 }}>
//                             <Button
//                                 type="submit"
//                                 variant="contained"
//                                 sx={{ mx: 2 }}
//                                 onClick={handleSubmit}
//                             >
//                                 Save
//                             </Button>
//                             <Button
//                                 variant="outlined"
//                                 sx={{ mx: 2 }}
//                                 startIcon={<ArrowBackIosNewIcon />}
//                                 onClick={onBack}
//                             >
//                                 Back
//                             </Button>
//                             <Button
//                                 variant="outlined"
//                                 sx={{ mx: 2 }}
//                                 startIcon={<ArrowForwardIosIcon />}
//                                 onClick={onNext}
//                             >
//                                 Next
//                             </Button>
//                         </Box>
//                         { selectedDriver &&
//                             <Box sx={{ my: 1 }}>
//                                 <Button 
//                                     size="small" 
//                                     color="error"
//                                     onClick={() => deleteDriver(route.id)}>
//                                     Remove Driver
//                                 </Button>
//                             </Box>
//                         }
//                     </Box>
//                 }
//             </Box>
//             {/* For delete confirmation */}
//             {deleteJson.show &&
//                 <CustomModal 
//                     show={deleteJson.show}
//                     data={deleteJson.data}
//                     type={ModalType.CONFIRM_DELETE}
//                     onOkay={(data) => {
//                         onDelete(deleteJson.data);
//                         setDeleteJson({
//                             ...deleteJson,
//                             show: false,
//                         });
//                     }}
//                     onHide={() => {
//                         setDeleteJson({
//                             ...deleteJson,
//                             show: false,
//                         });
//                     }}
//                 >
//                 </CustomModal>
//             }
//         </>
//     );

// }

// export default AssignRouteDriver;
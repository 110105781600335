import React, { Component, useState, useEffect } from 'react';
import { Box, Typography, Grid, Link as MuiLink, Chip, CircularProgress, TextField,
    Button } from '@mui/material';
import { useParams, useNavigate } from "react-router-dom";
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ImageIcon from '@mui/icons-material/Image';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { useSelector } from 'react-redux';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { fetchSchoolUsers, getDriverByUserId, saveOrUpdateDriver, getDriver } from '../../../../services/http.service';
import { Role } from '../../../../utils/constants';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'NewDriver';  // Define logTag for this component

const NewDriver = () => {

    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [drivers, setDrivers] = useState([]);
    const [driver, setDriver] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const { driverId } = useParams();
    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();

    useEffect(() => {
        logger.info('NewDriver component mounted', { logTag, driverId });  // Log component mount
        if (driverId) {
            fetchDriver(driverId);
        }
    }, [driverId]);

    // Fetch driver detail for edit
    const fetchDriver = (driverId) => {
        logger.info('Fetching driver details', { logTag, driverId });  // Log fetching driver details
        setIsLoading(true);
        getDriver(driverId).then(resp => {
            console.log(`Fetch driver - ${JSON.stringify(resp)}`);
            setDriver(resp.data.data);
            setDriverDetail(resp.data.data);
            setIsLoading(false);
            logger.info('Driver details fetched successfully', { logTag, driverId });  // Log success
        }).catch(err => {
            logger.error('Error fetching driver details', err, { logTag, driverId });  // Log error
            setIsLoading(false);
        });
    }

    // Set Driver details into state
    const setDriverDetail = (respDriver) => {
        formik.setFieldValue("name", respDriver.user.name);
        formik.setFieldValue("mobile", respDriver.user.mobile);
        formik.setFieldValue("email", respDriver.user.email);
        formik.setFieldValue("password", "******"); // 6 digit default password on edit of driver
        formik.setFieldValue("licenseNumber", respDriver.license_number);

        setDriver({
            ...driver,
            id: respDriver.id,
            user: respDriver.user,
            licenseNumber: respDriver.license_number
        });

        logger.info('Driver details set in form', { logTag, driver: respDriver });  // Log setting driver details
    }

    /**
     * Handle form submission
     */
    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required."),
        mobile: Yup.string().required("Mobile is required."),
        email: Yup.string().email("Invalid email."),
        licenseNumber: Yup.string().required("License number is required."),
        password: Yup.string().required("Password is required.")
            .min(6, "Password must be 6 chars long").max(32, "Password must be more than 32 chars long"),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            mobile: '',
            email: '',
            password: '',
            licenseNumber: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            logger.info('Saving driver details', { logTag, values });  // Log saving driver details
            setIsSaving(true);
            setErrorMsg(null);
            setShowSuccessAlert(false);
            const newDriver = {
                ...driver,
                name: values.name,
                mobile: values.mobile,
                email: values.email,
                password: values.password,
                licenseNumber: values.licenseNumber,
            }

            console.log(`Saving driver - ${JSON.stringify(newDriver)}`);
            saveOrUpdateDriver(newDriver).then(resp => {
                setIsSaving(false);
                setErrorMsg(null);
                setShowSuccessAlert(true);
                logger.info('Driver saved successfully', { logTag, driverId: driver.id });  // Log success
            }).catch(err => {
                setIsSaving(false);
                setErrorMsg(err);
                setShowSuccessAlert(false);
                logger.error('Error saving driver', err, { logTag, driverId: driver.id });  // Log error
                console.log(err);
            });
        },
        onReset: (values, { resetForm }) => resetForm(),
    });

    return (
        <>
            {errorMsg &&
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => {
                        logger.info('Error alert closed', { logTag });  // Log alert close
                        setErrorMsg(null);
                    }} 
                />
            }
            {showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"Driver saved successfully!!"}
                    onClose={() => {
                        logger.info('Success alert closed', { logTag });  // Log success alert close
                        setShowSuccessAlert(false);
                    }} 
                />
            }
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                    width: '100%',
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        { driver && driver.id ? "Update driver" : "Add new driver"}
                    </Typography>
                    {isLoading && <CircularProgress />}
                    <Box>
                        {driver.user &&
                            <>
                                <Chip
                                    onClick={() => {
                                        logger.info('Verify driver clicked', { logTag, driverId: driverId });  // Log verify driver click
                                        navigate(`${baseUrl}/driver/${driverId}/verify`);
                                    }}
                                    icon={<HowToRegIcon />}
                                    label="Verify"
                                    variant="outlined"
                                    color="secondary"
                                    sx={{ mx: 2 }}
                                />
                                <Chip
                                    onClick={() => {
                                        logger.info('Driver photo clicked', { logTag, driverId: driverId });  // Log driver photo click
                                        navigate(`${baseUrl}/driver/${driverId}/photo`);
                                    }}
                                    icon={<ImageIcon />}
                                    label="Photo"
                                    variant="outlined"
                                    color="secondary"
                                    sx={{ mx: 2 }}
                                />
                            </>
                        }
                        <Chip
                            onClick={() => {
                                logger.info('Navigating to driver list', { logTag });  // Log navigation to driver list
                                navigate(`../list`);
                            }}
                            icon={<FormatListBulletedIcon />}
                            label="Drivers"
                            variant="outlined"
                            color="secondary"
                            sx={{ mx: 2 }}
                        />
                    </Box>
                </Box>

                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12} md={6}>
                        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                name="name"
                                label="Driver name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                name="email"
                                label="Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                name="mobile"
                                label="Mobile"
                                value={formik.values.mobile}
                                onChange={formik.handleChange}
                                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                                helperText={formik.touched.mobile && formik.errors.mobile}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                name="password"
                                label="Password"
                                type="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                name="licenseNumber"
                                label="License number"
                                value={formik.values.licenseNumber}
                                onChange={formik.handleChange}
                                error={formik.touched.licenseNumber && Boolean(formik.errors.licenseNumber)}
                                helperText={formik.touched.licenseNumber && formik.errors.licenseNumber}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {isSaving &&
                                    <CircularProgress />
                                }
                                {!isSaving &&
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        {driver && driver.id ? 'Update' : 'Save'}
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default NewDriver;



// import React, { Component, useState, useEffect } from 'react';
// import { Box, Typography, Grid, Link as MuiLink, Chip, CircularProgress, TextField,
//     Button } from '@mui/material';
// import { useParams, useNavigate } from "react-router-dom";
// import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
// import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import ImageIcon from '@mui/icons-material/Image';
// import HowToRegIcon from '@mui/icons-material/HowToReg';
// import { useSelector } from 'react-redux';

// import { useFormik } from 'formik';
// import * as Yup from 'yup';

// import { fetchSchoolUsers, getDriverByUserId, saveOrUpdateDriver, getDriver } from '../../../../services/http.service';
// import { Role } from '../../../../utils/constants';



// const NewDriver = () => {

//     const [validated, setValidated] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);
//     const [drivers, setDrivers] = useState([]);
//     const [driver, setDriver] = useState({});
//     const [isSaving, setIsSaving] = useState(false);
//     const [errorMsg, setErrorMsg] = useState(null);
//     const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	
//     const { driverId } = useParams();
//     const baseUrl = useSelector(state => state.extras.baseUrl);
//     const navigate = useNavigate();

//     useEffect(() => {
//         if (driverId) {
//             fetchDriver(driverId);
//         }
//     }, []);

//     // Fetch driver detail for edit
//     const fetchDriver = (driverId) => {
//         setIsLoading(true);
//         getDriver(driverId).then(resp => {
//             console.log(`Fetch driver - ${JSON.stringify(resp)}`);
//             setDriver(resp.data.data);
//             setDriverDetail(resp.data.data);
//             setIsLoading(false);
//         }).catch(err => {
//             console.log(err);
//             setIsLoading(false);
//         })
//     }

//     // Set Driver details into state
//     const setDriverDetail = (respDriver) => {
//         // set formik field
//         formik.setFieldValue("name", respDriver.user.name);
//         formik.setFieldValue("mobile", respDriver.user.mobile);
//         formik.setFieldValue("email", respDriver.user.email);
//         formik.setFieldValue("password", "******"); // 6 digit default password on edit of driver
//         formik.setFieldValue("licenseNumber", respDriver.license_number);

//         setDriver({
//             ...driver,
//             id: respDriver.id,
//             user: respDriver.user,
//             licenseNumber: respDriver.license_number
//         });
        
//         console.log(`Set driver detail - ${JSON.stringify(driver)}`);
//     }

//     /**
//      * Handle form submission
//      */
//      const validationSchema = Yup.object({
//         name: Yup.string().required("Name is required."),
//         mobile: Yup.string().required("Mobile is required."),
//         email: Yup.string().email("Invalid email."),
//         licenseNumber: Yup.string().required("License number is required."),
//         password: Yup.string().required("Password is required.")
//             .min(6, "Password must be 6 chars long").max(32, "Password must be more that 32 chars long"),
//         });
//     const formik = useFormik({
//         initialValues: {
//             name: '',
//             mobile: '',
//             email: '',
//             password: '',
//             licenseNumber: ''
//         },
//         validationSchema: validationSchema,
//         onSubmit: (values, { resetForm }) => {
//             console.log("saving driver - ", values);
//             setIsSaving(true);
//             setErrorMsg(null);
//             setShowSuccessAlert(false);
//             const newDriver = {
//                 ...driver,
//                 name: values.name,
//                 mobile: values.mobile,
//                 email: values.email,
//                 password: values.password,
//                 licenseNumber: values.licenseNumber,
//             }
            
//             console.log(`Saving driver - ${JSON.stringify(newDriver)}`);
//             saveOrUpdateDriver(newDriver).then(resp => {
//                 setIsSaving(false);
//                 setErrorMsg(null);
//                 setShowSuccessAlert(true);
//             }).catch(err => {
//                 setIsSaving(false);
//                 setErrorMsg(err);
//                 setShowSuccessAlert(false);
//                 console.log(err);
//             });
//         },
//         onReset: (values, { resetForm }) => resetForm(),
//     });



//     // const handleSubmit = (event) => {
//     //     event.preventDefault();
//     //     event.stopPropagation();
//     //     setValidated(true);
//     //     const form = event.currentTarget;
//     //     if (form.checkValidity() === false) {
//     //         console.log(`not valid`);
//     //         return
//     //     } 
//     //     // const driver = this.state.driver;
//     //     setIsSaving(true);
//     //     setErrorMsg(null);
//     //     setShowSuccessAlert(false);
//     //     setDriver({
//     //         ...driver,
//     //         licenseNumber: form[0].value
//     //     });
//     //     // driver.licenseNumber = form[0].value;
//     //     console.log(`Saving driver - ${JSON.stringify(driver)}`);
//     //     saveOrUpdateDriver(driver).then(resp => {
//     //         setIsSaving(false);
//     //         setErrorMsg(null);
//     //         setShowSuccessAlert(true);
//     //     }).catch(err => {
//     //         setIsSaving(false);
//     //         setErrorMsg(err);
//     //         setShowSuccessAlert(false);
//     //         console.log(err);
//     //     })
//     // }

//     return (
//         <>
//             { errorMsg &&
//                 <ErrorAlertTopCenter 
//                     errorMsg={errorMsg}
//                     onClose={() => setErrorMsg(null)} 
//                 />
//             }
//             { showSuccessAlert &&
//                 <SuccessAlertTopRight
//                     successMsg={"Driver saved successfully!!"}
//                     onClose={() => setShowSuccessAlert(false)} 
//                 />
//             }
//             <Box
//                 sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "left",
//                     alignItems: "start",
//                     width: '100%',
//                 }}
//             >
//                 <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//                     <Typography component="h2" variant="header">
//                         { driver && driver.id ? "Update driver" : "Add new driver"}
//                     </Typography>
//                     { isLoading &&
//                         <CircularProgress />
//                     }
//                     <Box>
//                         { driver.user &&
//                             <>
//                                 <Chip
//                                     onClick={() => navigate(`${baseUrl}/driver/${driverId}/verify`)}
//                                     icon={<HowToRegIcon />}
//                                     label="Verify"
//                                     variant="outlined"
//                                     color="secondary"
//                                     sx={{ mx: 2 }}
//                                 />
//                                 <Chip
//                                     onClick={() => navigate(`${baseUrl}/driver/${driverId}/photo`)}
//                                     icon={<ImageIcon />}
//                                     label="Photo"
//                                     variant="outlined"
//                                     color="secondary"
//                                     sx={{ mx: 2 }}
//                                 />
//                             </>
//                         }
//                         <Chip
//                             onClick={() => navigate(`../list`)}
//                             icon={<FormatListBulletedIcon />}
//                             label="Drivers"
//                             variant="outlined"
//                             color="secondary"
//                             sx={{ mx: 2 }}
//                         />
//                     </Box>
//                 </Box>

//                 <Grid container spacing={2} sx={{ mt: 3 }}>
//                     <Grid item xs={12} md={6}>
//                         <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1, }}>
//                             <TextField
//                                 margin="normal"
//                                 fullWidth
//                                 required
//                                 name="name"
//                                 label="Driver name"
//                                 value={formik.values.name}
//                                 onChange={formik.handleChange}
//                                 error={formik.touched.name && Boolean(formik.errors.name)}
//                                 helperText={formik.touched.name && formik.errors.name}
//                             />
//                             <TextField
//                                 margin="normal"
//                                 fullWidth
//                                 name="email"
//                                 label="Email"
//                                 value={formik.values.email}
//                                 onChange={formik.handleChange}
//                                 error={formik.touched.email && Boolean(formik.errors.email)}
//                                 helperText={formik.touched.email && formik.errors.email}
//                             />
//                             <TextField
//                                 margin="normal"
//                                 fullWidth
//                                 required
//                                 name="mobile"
//                                 label="Mobile"
//                                 value={formik.values.mobile}
//                                 onChange={formik.handleChange}
//                                 error={formik.touched.mobile && Boolean(formik.errors.mobile)}
//                                 helperText={formik.touched.mobile && formik.errors.mobile}
//                             />
//                             <TextField
//                                 margin="normal"
//                                 fullWidth
//                                 required
//                                 name="password"
//                                 label="Password"
//                                 type="password"
//                                 value={formik.values.password}
//                                 onChange={formik.handleChange}
//                                 error={formik.touched.password && Boolean(formik.errors.password)}
//                                 helperText={formik.touched.password && formik.errors.password}
//                             />
//                                <TextField
//                                 margin="normal"
//                                 fullWidth
//                                 required
//                                 name="licenseNumber"
//                                 label="License number"
//                                 value={formik.values.licenseNumber}
//                                 onChange={formik.handleChange}
//                                 error={formik.touched.licenseNumber && Boolean(formik.errors.licenseNumber)}
//                                 helperText={formik.touched.licenseNumber && formik.errors.licenseNumber}
//                             />
//                             <Box
//                                 sx={{
//                                     display: 'flex',
//                                     justifyContent: 'center',
//                                 }}
//                             >
//                                 {isSaving &&
//                                     <CircularProgress />
//                                 }
//                                 {!isSaving &&
//                                     <Button
//                                         type="submit"
//                                         variant="contained"
//                                         sx={{ mt: 3, mb: 2 }}
//                                     >
//                                         {driver && driver.id ? 'Update' : 'Save'}
//                                     </Button>
//                                 }
//                             </Box>
//                         </Box>
//                     </Grid>
//                 </Grid>
//             </Box>
//         </>
//     );
// }

// export default NewDriver;

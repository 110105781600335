import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import RoutableMap from './routable-map';
import schoolBusIcon from '../../assets/images/school_bus_map_icon.png';
import logger from '../../utils/logger';  // Assuming your custom logger is imported from a utils folder

const LiveLocationMap = forwardRef(({ 
    locations,
    currentLocation,    
    originIcon,
    otherIcon,
}, ref) => {
    const logTag = 'LiveLocationMap';
    const [map, setMap] = useState(null);
    const [maps, setMaps] = useState(null);
    const [currentLocationMarker, setCurrentLocationMarker] = useState(null);

    const routableMapRef = useRef(null);

    const handleMapReady = (map, maps) => {
        //logger.info('Map is ready', { logTag });
        setMap(map);
        setMaps(maps);
    };

    // ************ External Calls ******************
    useImperativeHandle(ref, () => ({
        showInfoWindow
    }));

    const showInfoWindow = (location) => {
        //logger.info('Showing info window', { logTag, location });
        routableMapRef.current.showInfoWindow(location);
    };

    // ************ End // External Calls ******************

    useEffect(() => {
        //logger.info('Updating current location', { logTag, currentLocation });
        
        if (currentLocationMarker) {
            //logger.debug('Removing previous current location marker', { logTag });
            currentLocationMarker.setMap(null);
            setCurrentLocationMarker(null);
        }

        if (currentLocation && maps && map) {
            try {
                let latLng = {
                    lat: parseFloat(currentLocation.latitude),
                    lng: parseFloat(currentLocation.longitude),
                };
                //logger.debug(`Setting new marker at location: ${JSON.stringify(latLng)}`, { logTag });

                let marker = new maps.Marker({
                    draggable: false,
                    position: latLng,
                    map: map,
                    icon: {
                        url: schoolBusIcon,
                        scaledSize: new maps.Size(30, 30), // Customize size here
                    },
                });

                setCurrentLocationMarker(marker);
                //logger.info('New current location marker set', { logTag, latLng });
            } catch (error) {
                logger.error('Error updating current location', { logTag, stack: error.stack });
            }
        } else {
            logger.debug('Current location, maps, or map is not available yet', { logTag });
        }
    }, [currentLocation, maps, map]);

    return (
        <RoutableMap
            locations={locations}
            otherIcon={otherIcon}
            originIcon={originIcon}
            ref={routableMapRef}
            onMapReady={handleMapReady}
        />
    );
});

export default LiveLocationMap;


// import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';

// import RoutableMap from './routable-map';
// import schoolBusIcon from '../../assets/images/school_bus_map_icon.png';

// const LiveLocationMap = forwardRef(({ 
//     locations,
//     currentLocation,    
//     originIcon,
//     otherIcon,
// }, ref) => {
//     const [map, setMap] = useState(null);
//     const [maps, setMaps] = useState(null);
//     const [currentLocationMarker, setCurrentLocationMarker] = useState(null);

//     const routableMapRef = useRef(null);

//     const handleMapReady = (map, maps) => {
//         setMap(map);
//         setMaps(maps);
//     }


//     // ************ External Calls ******************
//     useImperativeHandle(ref, () => ({
//         showInfoWindow
//     }));

//     const showInfoWindow = (location) => {
//         routableMapRef.current.showInfoWindow(location);
//     }

//     // ************ End // External Calls ******************

//     useEffect(() => {
//         console.log("current location - ", currentLocation);
//         if (currentLocationMarker) {
//             currentLocationMarker.setMap(null);
//             setCurrentLocationMarker(null);
//         }
//         // Add latest
//         let latLng = {
//             lat: parseFloat(currentLocation.latitude),
//             lng: parseFloat(currentLocation.longitude)
//         };
//         console.log(`updating current location ${JSON.stringify(latLng)}`);
//         if (maps) {
//             let marker = new maps.Marker({
//                 draggable: false,
//                 position: latLng,
//                 map: map,
//                 icon: {
//                     url: schoolBusIcon,
//                     scaledSize: new maps.Size(30, 30), // Customize size here
//                 },
//             });
//             setCurrentLocationMarker(marker);
//         }

//     }, [currentLocation, maps, map]);
    
//     return (
//         <RoutableMap
//             locations={locations}
//             otherIcon={otherIcon}
//             originIcon={originIcon}
//             ref={routableMapRef}
//             onMapReady={handleMapReady}
//         />
//     );
// });

// export default LiveLocationMap;
import React, { useState, useEffect, useCallback } from 'react';
import {
    Button,
    Slide,
    AppBar,
    Dialog,
    Toolbar,
    IconButton,
    Typography,
    Box,
    Grid,
    TextField,
    Autocomplete,
    styled,
    Paper,
    CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import debounce from 'lodash.debounce';

import { AlertSnackbar } from '../common/snackbars';
import { searchStudent, saveRouteStudentStop } from '../services/http.service';
import { IMAGES_URL } from '../utils/constants';
import logger from '../utils/logger'; // Assuming you have a custom logger

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CustomPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: '#f0f0f0'
}));

const validationSchema = Yup.object({
    student: Yup.object().required('Student is required.').nullable(),
    routeStop: Yup.object().required('Stop is required.').nullable(),
});

const RouteStudentDialog = ({
    open,
    onClose,
    routeId,
    routeType,
    routeStops,
    routeStudentForEdit,
    onSubmit,
    onUpdate
}) => {
    const logTag = 'RouteStudentDialog';
    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [studentInputValue, setStudentInputValue] = useState('');

    const formik = useFormik({
        initialValues: {
            routeStop: null,
            student: null,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            logger.info('Submitting form', { logTag, values });
            const { student, routeStop } = values;
            setIsSaving(true);
            saveRouteStudentStop(routeId, routeType, routeStop.stop.id, student.id).then(resp => {
                logger.info('Saved successfully', { logTag, student, routeStop });
                setIsSaving(false);
                setSuccessMsg("Saved successfully!");
                setErrorMsg(null);
                onSubmit(resp.data.data);
                onClose(true);
            }).catch(err => {
                logger.error('Error saving route student stop', { logTag, error: err.message });
                setIsSaving(false);
                setErrorMsg(err.message);
                setSuccessMsg(null);
            });
        }
    });

    useEffect(() => {
        if (open) {
            logger.info('Dialog opened', { logTag });
            formik.resetForm();
            if (routeStudentForEdit) {
                formik.setFieldValue('student', routeStudentForEdit.student || null);
            }
        }
    }, [open, routeStudentForEdit]);

    const handleClose = (event, reason) => {
        logger.info('Dialog closed', { logTag, reason });
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return;
        }
        onClose(false);
    };

    // ################# Search students ##########################
    const searchStudents = (query) => {
        if (query.length < 3) return;
        logger.info(`Searching for students: ${query}`, { logTag });

        setLoading(true);
        searchStudent(query)
            .then(resp => {
                const students = resp.data.data;
                logger.debug(`Search results received: ${JSON.stringify(students)}`, { logTag });
                const newStudents = students.map(student => buildStudent(student));
                setLoading(false);
                setStudents(newStudents);
            })
            .catch(error => {
                logger.error(`Error searching for students: ${error.message}`, { logTag });
                setLoading(false);
            });
    }

    const buildStudent = (student) => {
        const newStudent = {
            id: student.id,
            name: student.name,
            rollNo: student.roll_no,
            sclass: student.sclass,
            section: student.section,
            profilePhoto: student.profile_photo,
        };
        logger.debug(`Built student: ${JSON.stringify(newStudent)}`, { logTag });
        return newStudent;
    }

    const debouncedSearchStudents = useCallback(debounce(searchStudents, 300), []);

    useEffect(() => {
        if (studentInputValue.length >= 3) {
            debouncedSearchStudents(studentInputValue);
        }
    }, [studentInputValue, debouncedSearchStudents]);

    // ################# End // Search students ###################

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={handleClose}
            disableEscapeKeyDown={true}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
                        Add/Update student
                    </Typography>
                </Toolbar>
            </AppBar>
            {(errorMsg || successMsg) &&
                <AlertSnackbar 
                    errorMsg={errorMsg}
                    successMsg={successMsg}
                    onClose={() => {
                        setErrorMsg(null);
                        setSuccessMsg(null);
                    }}
                />
            }
            <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1, padding: 5 }}>

                {routeStudentForEdit && // Show stop in case of update
                    <Box
                        sx={{ display: 'flex' }}
                    >
                        <Typography component={'div'} fontWeight={'bold'}>
                            Current stop name:
                        </Typography>
                        <Typography sx={{ ml: 2 }} component={'div'}>
                            {routeStudentForEdit.stop ? routeStudentForEdit.stop.name : 'N/A'}
                        </Typography>
                    </Box>
                }
                
                {!routeStudentForEdit &&  // Show only for new - not for update stop
                    <Autocomplete
                        options={students}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                            logger.debug('Student selected', { logTag, student: value });
                            formik.setFieldValue('student', value);
                        }}
                        PaperComponent={(props) => <CustomPaper {...props} />}
                        loading={loading}
                        renderOption={(props, option) => (
                            <Box component="div"
                                sx={{
                                    '& > img': { mr: 2, flexShrink: 0 },
                                    my: 1, display: 'flex', alignItems: 'flex-start'
                                }}
                                {...props}>
                                <img
                                    loading="lazy"
                                    width="50"
                                    src={option.profilePhoto ? `${IMAGES_URL.STUDENT}/${option.profilePhoto}` : `${IMAGES_URL.STUDENT}/default_profile.png`}
                                    alt=""
                                />
                                <Box>
                                    <Typography variant="h5" component="h5">{option.name}</Typography>
                                    <Typography variant="subtitle2" component="span" sx={{ '& > span': { mr: 1 } }}>
                                        <span><strong>Roll No: </strong> {option.rollNo},</span>
                                        <span><strong>Class: </strong> {option.sclass && option.sclass.name}</span>
                                        <span><strong>Section: </strong> {option.section && option.section.name}</span>
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Student (Type at least 3 characters)"
                                variant="outlined"
                                error={formik.touched.student && Boolean(formik.errors.student)}
                                helperText={formik.touched.student && formik.errors.student ? formik.errors.student : ''}
                                onBlur={formik.handleBlur}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                                onChange={(event) => {
                                    setStudentInputValue(event.target.value);
                                }}
                            />
                        )}
                    />
                }

                <Autocomplete
                    sx={{ mt: 2 }}
                    options={routeStops}
                    getOptionLabel={(option) => option.stop.name}
                    onChange={(event, value) => {
                        logger.debug('Route stop selected', { logTag, routeStop: value });
                        formik.setFieldValue('routeStop', value);
                    }}
                    PaperComponent={(props) => <CustomPaper {...props} />}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            <div>
                                <strong>{option.stop.name}</strong>
                                <br />
                                <span style={{ fontWeight: 'bold' }}>Address:</span> {option.stop.location.location}
                                <br />
                                <span style={{ fontWeight: 'bold' }}>Latitude:</span> {option.stop.location.latitude}, <span style={{ fontWeight: 'bold' }}>Longitude:</span> {option.stop.location.longitude}
                            </div>
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Stop"
                            variant="outlined"
                            error={formik.touched.routeStop && Boolean(formik.errors.routeStop)}
                            helperText={formik.touched.routeStop && formik.errors.routeStop ? formik.errors.routeStop.stop : ''}
                            onBlur={formik.handleBlur}
                        />
                    )}
                />
                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {isSaving ? (
                        <CircularProgress />
                    ) : (
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Submit
                        </Button>
                    )}
                </Box>
            </Box>
        </Dialog>
    );
}

export default RouteStudentDialog;



// import React, { useState, useEffect, useCallback } from 'react';

// import {
//     Button,
//     Slide,
//     AppBar,
//     Dialog,
//     Toolbar,
//     IconButton,
//     Typography,
//     Box,
//     Grid,
//     TextField,
//     Autocomplete,
//     styled,
//     Paper,
//     CircularProgress,
//  } from '@mui/material';

//  import CloseIcon from '@mui/icons-material/Close';

// import { useFormik } from 'formik';
// import * as Yup from 'yup';

// import debounce from 'lodash.debounce';

// import { AlertSnackbar } from '../common/snackbars';
// import { searchStudent, saveRouteStudentStop } from '../services/http.service';
// import { IMAGES_URL } from '../utils/constants';

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });
// const CustomPaper = styled(Paper)(({ theme }) => ({
//     backgroundColor: '#f0f0f0'
// }));

// const validationSchema = Yup.object({
//     student: Yup.object().required('Student is required.').nullable(),
//     routeStop: Yup.object().required('Stop is required.').nullable(),
// });

// const RouteStudentDialog = ({
//     open,
//     onClose,
//     routeId,
//     routeType,
//     routeStops,
//     routeStudentForEdit,
//     onSubmit,
//     onUpdate
// }) => {
//     const [isSaving, setIsSaving] = useState(false);
//     const [errorMsg, setErrorMsg] = useState(null);
//     const [successMsg, setSuccessMsg] = useState(null);
//     const [students, setStudents] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [studentInputValue, setStudentInputValue] = useState('');

//     const formik = useFormik({
//         initialValues: {
//             routeStop: null,
//             student: null,
//         },
//         validationSchema: validationSchema,
//         onSubmit: (values) => {
//             console.log("route student add submit values ", values);
//             const { student, routeStop } = values;
//             setIsSaving(true);
//             saveRouteStudentStop(routeId, routeType, routeStop.stop.id, student.id).then(resp => {
//                 setIsSaving(false);
//                 setSuccessMsg("Saved successfully!");
//                 setErrorMsg(null);
//                 onSubmit(resp.data.data);
//                 onClose(true);
//             }).catch(err => {
//                 setIsSaving(false);
//                 setErrorMsg(err);
//                 setSuccessMsg(null);
//             });
//         }
//     });

//     console.log("RouteStudentDialog - route stops ", JSON.stringify(routeStops));
    
//     // Reset form when dialog is open
//     useEffect(() => {
//         if (open) {
//             formik.resetForm();
//             if (routeStudentForEdit) {
//                 formik.setFieldValue('student', routeStudentForEdit.student || null);
//                 //formik.setFieldValue('stop', routeStudentForEdit.stop || null);
//             }
//         }
//     }, [open, routeStudentForEdit]);

//     const handleClose = (event, reason) => {
//         console.log("reason", reason);
//         if (reason === "backdropClick" || reason === "escapeKeyDown") {
//             return;
//         }
//         onClose(false);
//     };


//     // ################# Search students ##########################
//     const searchStudents = (query) => {
//         if (query.length < 3) return;
        
//         console.log(query);
//         // setStudentSearchKey(val);
//         setLoading(true);
//         searchStudent(query)
//             .then(resp => {
//                 //console.log("Search Students :: " + JSON.stringify(students));
//                 const students = resp.data.data;
//                 console.log("Search Students users :: " + JSON.stringify(students));
//                 // process student list
//                 const newStudents = students.map(student => {
//                     return buildStudent(student);
//                 })
//                 setLoading(false);
//                 setStudents(newStudents);
//             })
//             .catch(error => {
//                 console.log(`Error in Search student - ${JSON.stringify(error)}`);
//                 setLoading(false);
//             });
//     }

//     // Build route student from student object
//     const buildStudent = (student) => {
//         const newStudent = {
//             id: student.id,
//             name: student.name,
//             rollNo: student.roll_no,
//             sclass: student.sclass,
//             section: student.section && student.section,
//             profilePhoto: student.profile_photo
//         }
//         console.log(`Built route student - ${JSON.stringify(newStudent)}`);
//         return newStudent;
//     }

//      // Debounced fetch function
//      const debouncedSearchStudents = useCallback(debounce(searchStudents, 300), []);

//     useEffect(() => {
//         if (studentInputValue.length >= 3) {
//             debouncedSearchStudents(studentInputValue);
//         }
//       }, [studentInputValue, debouncedSearchStudents]);

//     // ################# End // Search students ###################


   
    

//     return (
//         <Dialog
//             fullWidth
//             maxWidth="sm"
//             open={open}
//             onClose={handleClose}
//             disableEscapeKeyDown={true}
//             TransitionComponent={Transition}
//         >
//             <AppBar sx={{ position: 'relative' }}>
//                 <Toolbar>
//                     <IconButton
//                         edge="start"
//                         color="inherit"
//                         onClick={handleClose}
//                         aria-label="close"
//                     >
//                         <CloseIcon />
//                     </IconButton>
//                     <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
//                         Add/Update student
//                     </Typography>
//                 </Toolbar>
//             </AppBar>
//             {(errorMsg || successMsg) &&
//                 <AlertSnackbar 
//                     errorMsg={errorMsg}
//                     successMsg={successMsg}
//                     onClose={() => {
//                         setErrorMsg(null);
//                         setSuccessMsg(null);
//                     }}
//                 />
//             }
//             <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1, padding: 5 }}>

//                 {routeStudentForEdit && // Show stop in case of update
//                     <Box
//                         sx={{ display: 'flex' }}
//                     >
//                         <Typography component={'div'} fontWeight={'bold'}>
//                             Current stop name:
//                         </Typography>
//                         <Typography sx={{ ml: 2 }} component={'div'}>
//                             { routeStudentForEdit.stop ? routeStudentForEdit.stop.name : 'N/A' }
//                         </Typography>
//                     </Box>
//                 }
                
//                 {!routeStudentForEdit &&  // Show only for new - not for update stop
//                     <Autocomplete
//                         options={students}
//                         getOptionLabel={(option) => option.name}
//                         onChange={(event, value) => {
//                             formik.setFieldValue('student', value);
//                         }}
//                         PaperComponent={(props) => <CustomPaper {...props} />}
//                         loading={loading}
//                         renderOption={(props, option) => (
//                             <Box component="div"
//                                 sx={{
//                                     '& > img': { mr: 2, flexShrink: 0 },
//                                     my: 1, display: 'flex', alignItems: 'flex-start'
//                                 }}
//                                 {...props}>
//                                 <img
//                                     loading="lazy"
//                                     width="50"
//                                     src={option.profilePhoto ? `${IMAGES_URL.STUDENT}/${option.profilePhoto}` : `${IMAGES_URL.STUDENT}/default_profile.png`}
//                                     alt=""
//                                 />
//                                 <Box>
//                                     <Typography variant="h5" component="h5">{option.name}</Typography>
//                                     <Typography variant="subtitle2" component="span" sx={{ '& > span': { mr: 1 } }}>
//                                         <span><strong>Roll No: </strong> {option.rollNo},</span>
//                                         <span><strong>Class: </strong> {option.sclass && option.sclass.name}</span>
//                                         <span><strong>Section: </strong> {option.section && option.section.name}</span>
//                                     </Typography>
//                                 </Box>
//                             </Box>
//                         )}
//                         renderInput={(params) => (
//                             <TextField
//                                 {...params}
//                                 label="Select Student (Type at least 3 characters)"
//                                 variant="outlined"
//                                 error={formik.touched.student && Boolean(formik.errors.student)}
//                                 helperText={formik.touched.student && formik.errors.student ? formik.errors.student : ''}
//                                 onBlur={formik.handleBlur}
//                                 InputProps={{
//                                     ...params.InputProps,
//                                     endAdornment: (
//                                         <>
//                                             {loading ? <CircularProgress color="inherit" size={20} /> : null}
//                                             {params.InputProps.endAdornment}
//                                         </>
//                                     ),
//                                 }}
//                                 onChange={(event) => {
//                                     //setInputValue(event.target.value);
//                                     if (event.target.value.length >= 3) {
//                                         debouncedSearchStudents(event.target.value);
//                                     }
//                                 }}
//                             />
//                         )}
//                     />
//                 }


//                 <Autocomplete
//                     sx={{ mt: 2 }}
//                     options={routeStops}
//                     getOptionLabel={(option) => option.stop.name}
//                     onChange={(event, value) => {
//                         formik.setFieldValue('routeStop', value)
//                     }}
//                     PaperComponent={(props) => <CustomPaper {...props} />}
//                     renderOption={(props, option) => (
//                         <li {...props} key={option.id}>
//                             <div>
//                                 <strong>{option.stop.name}</strong>
//                                 <br />
//                                 <span style={{ fontWeight: 'bold' }}>Address:</span> {option.stop.location.location}
//                                 <br />
//                                 <span style={{ fontWeight: 'bold' }}>Latitude:</span> {option.stop.location.latitude}, <span style={{ fontWeight: 'bold' }}>Longitude:</span> {option.stop.location.longitude}
//                             </div>
//                         </li>
//                     )}
//                     renderInput={(params) => (
//                         <TextField
//                             {...params}
//                             label="Select Stop"
//                             variant="outlined"
//                             error={formik.touched.routeStop && Boolean(formik.errors.routeStop)}
//                             helperText={formik.touched.routeStop && formik.errors.routeStop ? formik.errors.routeStop.stop : ''}
//                             onBlur={formik.handleBlur}
//                         />
//                     )}
//                 />
//                 <Box
//                     sx={{
//                         mt: 2,
//                         display: 'flex',
//                         justifyContent: 'center',
//                     }}
//                 >
//                     {isSaving &&
//                         <CircularProgress />
//                     }
//                     {!isSaving &&
//                         <Button
//                             type="submit"
//                             fullWidth
//                             variant="contained"
//                             sx={{ mt: 3, mb: 2 }}
//                         >
//                             {/* {school.id ? 'Update':'Continue'} */}
//                             Submit
//                         </Button>
//                     }
//                 </Box>
//             </Box>
//         </Dialog>
//     );
// }

// export default RouteStudentDialog;
import React, { useState, useEffect, useRef } from 'react';
import { Box, CircularProgress, Chip } from '@mui/material';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import CommonProfilePhoto from '../../../../common/profile-photo';
import { IMAGES_URL } from '../../../../utils/constants';
import { uploadDriverProfilePhoto, getDriver } from '../../../../services/http.service';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'DriverPhoto';  // Define logTag for this component

const DriverPhoto = () => {

    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [driver, setDriver] = useState(null);
    const [profilePhotoUrl, setProfilePhotoUrl] = useState(null);
    
    const { driverId } = useParams();
    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();

    useEffect(() => {
        logger.info('DriverPhoto component mounted', { logTag, driverId });  // Log component mount
        fetchDriver(driverId);
    }, [driverId]);

    useEffect(() => {
        if (driver) {
            const url = driver.user?.profile_photo ? `${IMAGES_URL.USER}/${driver.user.profile_photo}` : `${IMAGES_URL.USER}/default_profile.png`
            setProfilePhotoUrl(url);
            logger.info('Profile photo URL set', { logTag, profilePhotoUrl: url });  // Log profile photo URL set
        }
    }, [driver]);

    // Fetch driver detail for edit
    const fetchDriver = (driverId) => {
        logger.info('Fetching driver details', { logTag, driverId });  // Log fetching driver details
        setIsLoading(true);
        getDriver(driverId).then(resp => {
            setIsLoading(false);
            setErrorMsg(null);
            console.log(`Fetch driver - ${JSON.stringify(resp)}`);
            setDriver(resp.data.data);
            logger.info('Driver details fetched successfully', { logTag, driverId });  // Log success
        }).catch(err => {
            logger.error('Error fetching driver details', err, { logTag, driverId });  // Log error
            setIsLoading(false);
            setErrorMsg(err);
        });
    }

    // update photo
    const upload = (image) => {
        logger.info('Uploading profile photo', { logTag, image });  // Log upload request
        if (!image) {
            setIsSaving(false);
            setErrorMsg("Please select image to upload");
            setShowSuccessAlert(false);
            return;
        }

        setIsSaving(true);
        setErrorMsg(null);
        setShowSuccessAlert(false);

        uploadDriverProfilePhoto(driver.id, image).then(resp => {
            setIsSaving(false);
            setErrorMsg(null);
            setShowSuccessAlert(true);
            const newDriver = {
                ...driver,
                user: {
                    ...driver.user,
                    profile_photo: resp.data.filename
                }
            }
            setDriver(newDriver);
            logger.info('Profile photo uploaded successfully', { logTag, driverId: driver.id });  // Log success
        }).catch(err => {
            logger.error('Error uploading profile photo', err, { logTag, driverId: driver.id });  // Log error
            setIsSaving(false);
            setErrorMsg(err);
            setShowSuccessAlert(false);
        });
    }

    return (
        <>
           {errorMsg && 
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => {
                        logger.info('Error alert closed', { logTag });  // Log alert close
                        setErrorMsg(null);
                    }} 
                />
            }
            {showSuccessAlert && 
                <SuccessAlertTopRight
                    successMsg={'Uploaded successfully'}
                    onClose={() => {
                        logger.info('Success alert closed', { logTag });  // Log success alert close
                        setShowSuccessAlert(false);
                    }} 
                />
            }
            <Chip 
                icon={<ArrowBackIosIcon />} 
                label="Back" 
                variant="outlined" 
                size="small"
                onClick={() => {
                    logger.info('Navigating back', { logTag });  // Log back navigation
                    navigate(-1);
                }}
            />
            {isSaving && 
                <CircularProgress />
            }
            <Box  
                sx={{ 
                    mt: 4, 
                    display: "flex", 
                    flexDirection: "column", 
                    justifyContent: "start",
                    alignItems: "start"
                }}
            >
                {driver &&
                    <CommonProfilePhoto
                        profilePhotoUrl={profilePhotoUrl}
                        altName={driver.name}
                        onSubmit={upload}
                    />
                }
            </Box>
        </>
    );
}

export default DriverPhoto;



// import React, { useState, useEffect, useRef } from 'react';
// import { Box, CircularProgress, Chip } from '@mui/material';
// import { useParams, useNavigate } from "react-router-dom";
// import { useSelector } from 'react-redux'
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
// import CommonProfilePhoto from '../../../../common/profile-photo';
// import { IMAGES_URL } from '../../../../utils/constants';
// import { uploadDriverProfilePhoto } from '../../../../services/http.service';

// import { getDriver } from '../../../../services/http.service';

// const DriverPhoto = () => {

//     const [isSaving, setIsSaving] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);
//     const [errorMsg, setErrorMsg] = useState(null);
//     const [showSuccessAlert, setShowSuccessAlert] = useState(false);
//     const [driver, setDriver] = useState(null);
//     const [profilePhotoUrl, setProfilePhotoUrl] = useState(null);
    
//     const { driverId } = useParams();
//     const baseUrl = useSelector(state => state.extras.baseUrl);
//     const navigate = useNavigate();

//     useEffect(() => {
//         fetchDriver(driverId);
//     }, []);

//     useEffect(() => {
//         if (driver) {
//             const url = driver.user?.profile_photo ? `${IMAGES_URL.USER}/${driver.user.profile_photo}` : `${IMAGES_URL.USER}/default_profile.png`
//             setProfilePhotoUrl(url);
//         }
//     }, [driver]);

//     // Fetch driver detail for edit
//     const fetchDriver = (driverId) => {
//         setIsLoading(true);
//         getDriver(driverId).then(resp => {
//             setIsLoading(false);
//             setErrorMsg(null);
//             console.log(`Fetch driver - ${JSON.stringify(resp)}`);
//             setDriver(resp.data.data);
//             //setDriverDetail(resp.data.data);
//         }).catch(err => {
//             console.log(err);
//             setIsLoading(false);
//             setErrorMsg(err);
//         })
//     }

//     // update photo
//     const upload = (image) => {
//         console.log(`Uploading profile photo - ${JSON.stringify(image)}`);

//         // Show error if image is not selected
//         if (!image) {
//             setIsSaving(false);
//             setErrorMsg("Please select image to upload");
//             setShowSuccessAlert(false);
//             return;
//         }
//         // Reset flags
//         setIsSaving(true);
//         setErrorMsg(null);
//         setShowSuccessAlert(false);

//         uploadDriverProfilePhoto(driver.id, image).then(resp => {
//             console.log(`Uploaded successfully`);
//             setIsSaving(false);
//             setErrorMsg(null);
//             setShowSuccessAlert(true);
//             const newDriver = {
//                 ...driver,
//                 user: {
//                     ...driver.user,
//                     profile_photo: resp.data.filename
//                 }
//             }
//             console.log("new driver - ", newDriver);
//             //setProfilePhotoUrl(`${IMAGES_URL.USER}/${resp.data.filename}`);
//             setDriver(newDriver);
//         }).catch(err => {
//             console.error(err);
//             setIsSaving(false);
//             setErrorMsg(err);
//             setShowSuccessAlert(false);
//         });
//     }

//     return (
//         <>
//            { errorMsg && 
//                 <ErrorAlertTopCenter 
//                     errorMsg={errorMsg}
//                     onClose={() => setErrorMsg(null)} 
//                 />
//             }
//             { showSuccessAlert && 
//                 <SuccessAlertTopRight
//                     successMsg={'Uploaded successfully'}
//                     onClose={() => setShowSuccessAlert(false)} 
//                 />
//             }
//              <Chip 
//                 icon={<ArrowBackIosIcon />} 
//                 label="Back" 
//                 variant="outlined" 
//                 size="small"
//                 onClick={() => navigate(-1)}
//             />
//             { isSaving && 
//                 <CircularProgress />
//             }
//             <Box  
//                 sx={{ 
//                     mt: 4, 
//                     display: "flex", 
//                     flexDirection: "column", 
//                     justifyContent: "start",
//                     alignItems: "start"
//                 }}
//             >
//                 {driver &&
//                     <CommonProfilePhoto
//                         profilePhotoUrl={profilePhotoUrl}
//                         altName={driver.name}
//                         onSubmit={upload}
//                     />
//                 }
//             </Box>
//         </>
//     );
// }


// export default DriverPhoto;
import React, { Component, useState, useEffect } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
    Button, Chip, Paper, Grid, TableBody, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { getRoutes, deleteRoute, getRouteByRefAndType, disableEnableRoute } from '../../../../services/http.service';
import { CustomModal } from '../../../../common/modals';
import { RouteType, ModalType } from '../../../../utils/constants';
import { VertActionMenu } from '../../../../common/action-menu';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'RouteList';  // Define logTag for this component

const RouteList = () => {

    const [routes, setRoutes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });
    const [disableJson, setDisableJson] = useState({
        show: false,
        data: {}
    });
    const [alert, setAlert] = useState({
        show: false,
        body: '',
    });

    const navigate = useNavigate();
    const baseUrl = useSelector(state => state.extras.baseUrl);

    useEffect(() => {
        logger.info('RouteList component mounted', { logTag });  // Log component mount
        fetchRoutes();
    }, []);

    // Fetch routes
    const fetchRoutes = () => {
        logger.info('Fetching routes', { logTag });  // Log route fetching
        setIsLoading(true);
        getRoutes().then(resp => {
            setRoutes(resp.data.data);
            setIsLoading(false);
            logger.info('Routes fetched successfully', { logTag, routes: resp.data.data });  // Log success
        }).catch(err => {
            logger.error('Error fetching routes', err, { logTag });  // Log error
            setIsLoading(false);
        });
    }

    // Handle Delete modal   
    const handleDelete = (id) => {
        logger.info('Delete route requested', { logTag, routeId: id });  // Log delete request
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });
    }

    // On Delete of Route
    const onDelete = (data) => {
        logger.info('Confirm delete route', { logTag, routeId: data.id });  // Log confirm delete
        console.log('on delete data ' + JSON.stringify(data));

        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false,
        });
        // Show spinner
        setIsLoading(true);
        deleteRoute(data.id).then(resp => {
            setIsLoading(false);
            logger.info('Route deleted successfully', { logTag, routeId: data.id });  // Log success
            fetchRoutes();
        }).catch(err => {
            logger.error('Error deleting route', err, { logTag, routeId: data.id });  // Log error
            setIsLoading(false);
        });
    }

    // Handle Disable modal   
    const handleDisable = (id, isDisabled) => {
        logger.info('Disable/Enable route requested', { logTag, routeId: id, isDisabled });  // Log disable/enable request
        setDisableJson({
            ...disableJson,
            show: true,
            data: {
                id: id,
                isDisabled: isDisabled
            }
        });
    }

    // On Disable of route
    const onDisable = (data) => {
        logger.info('Confirm disable/enable route', { logTag, routeId: data.id, isDisabled: data.isDisabled });  // Log confirm disable/enable
        console.log('on disable data ' + JSON.stringify(data));

        // Hide disable/enable confirm modal
        setDisableJson({
            ...disableJson,
            show: false,
        });
        // Show spinner
        setIsLoading(true);
        disableEnableRoute(data.id, data.isDisabled).then(resp => {
            setIsLoading(false);
            logger.info('Route disabled/enabled successfully', { logTag, routeId: data.id, isDisabled: data.isDisabled });  // Log success
            fetchRoutes();
        }).catch(err => {
            logger.error('Error disabling/enabling route', err, { logTag, routeId: data.id });  // Log error
            setIsLoading(false);
        });
    }

    // Fetch route by ref no and its type - to view in modal and then Navigate to edit route screen in case of editing 
    const fetchRouteByRefAndType = (refNo) => {
        logger.info('Fetching route by reference and type', { logTag, refNo });  // Log fetching route by ref and type
        getRouteByRefAndType(refNo, RouteType.DROPOFF).then(resp => {
            const dropoffRoute = resp.data.data;
            console.log(`Show dropoff route - ${JSON.stringify(dropoffRoute)}`);
            if (dropoffRoute && dropoffRoute.id) {
                navigate(`${baseUrl}/route/edit/${resp.data.data.id}`);
            }
            else {
                setAlert({
                    ...alert,
                    show: true,
                    body: "Drop Off route is not created yet. Please edit the route to create one",
                });
                logger.warn('Dropoff route not found', { logTag, refNo });  // Log route not found
            }
        }).catch(err => {
            logger.error('Error fetching route by reference and type', err, { logTag, refNo });  // Log error
        });
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        Routes
                    </Typography>
                    <Chip
                        onClick={() => {
                            logger.info('Navigating to create new route', { logTag });  // Log navigation to create new route
                            navigate(`../new`);
                        }}
                        icon={<AddCircleOutlineIcon />}
                        label="New route"
                        variant="outlined"
                        color="secondary"
                    />
                </Box>

                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '.8rem' }}>#</TableCell>
                                        <TableCell>Ref No</TableCell>
                                        <TableCell>Route No</TableCell>
                                        <TableCell sx={{ width: '40%' }}>Driver</TableCell>
                                        <TableCell>Bus number</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {routes.map((item, index) => (
                                        <TableRow key={item.ref_no}>
                                            <TableCell align="left">{index + 1}</TableCell>
                                            <TableCell component="th" scope="row">
                                                <Typography component="h5" variant='h5'>
                                                    {item.ref_no}
                                                    {!item.is_active &&
                                                        <Chip
                                                            sx={{ ml: 1 }}
                                                            label="Disabled"
                                                            size="small" color="error" variant="outlined" />
                                                    }
                                                </Typography>
                                                <Typography component="h6" variant='caption'>
                                                    {item.route_students.length} Students
                                                </Typography>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.number}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.driver ? item.driver.user.name : ''}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.bus ? item.bus.number : ''}
                                            </TableCell>
                                            <TableCell align="right">
                                                <VertActionMenu
                                                    onDropOff={() => {
                                                        logger.info('Fetching dropoff route', { logTag, routeRef: item.ref_no });  // Log fetching dropoff route
                                                        fetchRouteByRefAndType(item.ref_no);
                                                    }}
                                                    onEdit={() => {
                                                        logger.info('Navigating to edit route', { logTag, routeId: item.id });  // Log navigation to edit route
                                                        navigate(`${baseUrl}/route/edit/${item.id}`);
                                                    }}
                                                    onDisable={() => handleDisable(item.id, true)}
                                                    onEnable={() => handleDisable(item.id, false)}
                                                    onDelete={() => handleDelete(item.id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>

            {/* Delete modal */}
            <CustomModal
                show={deleteJson.show}
                data={deleteJson.data}
                type={ModalType.CONFIRM_DELETE}
                onOkay={(data) => {
                    logger.info('Delete confirmed for route', { logTag, routeId: data.id });  // Log delete confirmation
                    onDelete(data);
                }}
                onHide={() => {
                    logger.info('Delete modal closed', { logTag });  // Log delete modal close
                    setDeleteJson({ ...deleteJson, show: false });
                }}
            ></CustomModal>

            {/* Disable modal */}
            <CustomModal
                show={disableJson.show}
                data={disableJson.data}
                type={ModalType.CONFIRM_DISABLE}
                onOkay={(data) => {
                    logger.info('Disable/Enable confirmed for route', { logTag, routeId: data.id });  // Log disable/enable confirmation
                    onDisable(data);
                }}
                onHide={() => {
                    logger.info('Disable/Enable modal closed', { logTag });  // Log disable/enable modal close
                    setDisableJson({ ...disableJson, show: false });
                }}
            ></CustomModal>
        </>
    );
}

export default RouteList;



// import React, { Component, useState, useEffect } from 'react';
// import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
//     Button, Chip, Paper, Grid, TableBody, IconButton } from '@mui/material';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { useSelector } from 'react-redux'
// import { useNavigate } from "react-router-dom";

// import { getRoutes, deleteRoute, getRouteByRefAndType, disableEnableRoute } from '../../../../services/http.service';
// import { CustomModal } from '../../../../common/modals';
// import { RouteType, ModalType } from '../../../../utils/constants';
// import { VertActionMenu } from '../../../../common/action-menu';



// const RouteList = () => {

//     const [routes, setRoutes] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);
//     const [deleteJson, setDeleteJson] = useState({
//         show: false,
//         data: {}
//     });
//     const [disableJson, setDisableJson] = useState({
//         show: false,
//         data: {}
//     });
//     const [alert, setAlert] = useState({
//         show: false,
//         body: '',
//     });

//     const navigate = useNavigate();
//     const baseUrl = useSelector(state => state.extras.baseUrl);

//     useEffect(() => {
//         fetchRoutes();
//     }, []);

//     // Fetch routes
//     const fetchRoutes = () => {
//         setIsLoading(true);
//         getRoutes().then(resp => {
//             setRoutes(resp.data.data);
//             setIsLoading(false);
//         }).catch(err => {
//             console.log(err);
//             setIsLoading(false);
//         })
//     }

//     // Handle Delete modal   
//     const handleDelete = (id) => {
//         setDeleteJson({
//             ...deleteJson,
//             show: true,
//             data: {
//                 id: id
//             }
//         });
//         console.log(`delelte - ${JSON.stringify(deleteJson)}`);
//     }

//     // On Delete of School
//     const onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));

//         // Hide delete confirm modal
//         setDeleteJson({
//             ...deleteJson,
//             show: false,
//         });
//         // Show spinner
//         setIsLoading(true);
//         deleteRoute(data.id).then(resp => {
//             setIsLoading(false);
//             fetchRoutes();
//         }).catch(err => {
//             console.log(`Error in deleting route id - ${data.id} - ${err}`);
//             setIsLoading(false); 
//         })
//     }

//     // Handle Disable modal   
//     const handleDisable = (id, isDisabled) => {
//         setDisableJson({
//             ...disableJson,
//             show: true,
//             data: {
//                 id: id,
//                 isDisabled: isDisabled
//             }
//         });
//         console.log(`disable - ${JSON.stringify(disableJson)}`);
//     }

//     // On Disable of route
//     const onDisable = (data) => {
//         console.log('on disable data ' + JSON.stringify(data));
//         // Hide delete confirm modal
//         setDisableJson({
//             ...disableJson,
//             show: false,
//         });
//         // Show spinner
//         setIsLoading(true);
//         disableEnableRoute(data.id, data.isDisabled).then(resp => {
//             setIsLoading(false);
//             fetchRoutes();
//         }).catch(err => {
//             console.log(`Error in disabling/enabling route id - ${data.id} - ${err}`);
//             setIsLoading(false); 
//         })
//     }

//     // Fetch route by ref no and its type - to view in modal and then Navigate to edit route screen in case of editing 
//     const fetchRouteByRefAndType = (refNo) => {
//         getRouteByRefAndType(refNo, RouteType.DROPOFF).then(resp => {
//             const dropoffRoute = resp.data.data;
//             console.log(`Show dropoff route - ${JSON.stringify(dropoffRoute)}`);
//             if (dropoffRoute && dropoffRoute.id) {
//                 navigate(`${baseUrl}/route/edit/${resp.data.data.id}`);
//             }
//             else {
//                 //alert("Drop Off route is not create yet.");
//                 setAlert({
//                     ...alert,
//                     show: true,
//                     body: "Drop Off route is not create yet. Please edit route to create one",
//                 });
//             }
//         }).catch(err => {
//             console.log(err);
//         })
//     }

//     return (
//         <>
//             <Box
//                 sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "left",
//                     alignItems: "start",
//                 }}
//             >
//                 <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//                     <Typography component="h2" variant="header">
//                         Routes
//                     </Typography>
//                     <Chip
//                         onClick={() => navigate(`../new`)}
//                         icon={<AddCircleOutlineIcon />}
//                         label="New route"
//                         variant="outlined"
//                         color="secondary"
//                     />
//                 </Box>

//                 <Grid container spacing={2} sx={{ mt: 3 }}>
//                     <Grid item xs={12}>
//                         <TableContainer component={Paper}>
//                             <Table aria-label="simple table">
//                                 <TableHead>
//                                     <TableRow>
//                                         <TableCell sx={{ width: '.8rem' }}>#</TableCell>
//                                         <TableCell>Ref No</TableCell>
//                                         <TableCell>Route No</TableCell>
//                                         <TableCell sx={{ width: '40%'}}>Driver</TableCell>
//                                         <TableCell>Bus number</TableCell>
//                                         <TableCell align="right">Action</TableCell>
//                                     </TableRow>
//                                 </TableHead>
//                                 <TableBody>
//                                     {routes.map((item, index) => (
//                                         <TableRow
//                                             key={item.ref_no}
//                                         >
//                                             <TableCell align="left">{index + 1}</TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 <Typography component="h5" variant='h5'>
//                                                     {item.ref_no} 
//                                                     {!item.is_active &&
//                                                         <Chip
//                                                             sx={{ ml: 1 }}
//                                                             label="Disabled"
//                                                             size="small" color="error" variant="outlined" />
//                                                     }
//                                                 </Typography>
//                                                 <Typography component="h6" variant='caption'>
//                                                     {item.route_students.length} Students
//                                                 </Typography>
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.number}
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.driver ? item.driver.user.name : ''}
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.bus ? item.bus.number : ''}
//                                             </TableCell>
//                                             <TableCell align="right">
//                                                 <VertActionMenu
//                                                     onDropOff={() => fetchRouteByRefAndType(item.ref_no)}
//                                                     onEdit={() => navigate(`${baseUrl + "/route/edit/" + item.id}`)}
//                                                     onDisable={() => handleDisable(item.id, true)}
//                                                     onEnable={() => handleDisable(item.id, false)}
//                                                     onDelete={() => handleDelete(item.id)}
//                                                 />
//                                             </TableCell>
//                                         </TableRow>
//                                     ))}
//                                 </TableBody>
//                             </Table>
//                         </TableContainer>
//                     </Grid>
//                 </Grid>
//             </Box>
//             {/* Delete modal */}
//             <CustomModal
//                 show={deleteJson.show}
//                 data={deleteJson.data}
//                 type={ModalType.CONFIRM_DELETE}
//                 onOkay={(data) => { onDelete(data) }}
//                 onHide={ () => {setDeleteJson({...deleteJson, show: false})} }
//             ></CustomModal>
//             {/* Disable modal */}
//             <CustomModal
//                 show={disableJson.show}
//                 data={disableJson.data}
//                 type={ModalType.CONFIRM_DISABLE}
//                 onOkay={(data) => { onDisable(data) }}
//                 onHide={ () => {setDisableJson({...disableJson, show: false})} }
//             ></CustomModal>
//         </>
//     );
// }
// export default RouteList;
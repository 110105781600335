import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Chip, Paper, Grid,
    TextField, Autocomplete, CircularProgress, List, ListItem, ListItemText, ListItemAvatar, Avatar,
    IconButton, Divider } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { createDropOffRoute } from '../../../../services/http.service';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'CreateDropOffRoute';  // Define logTag for this component

const CreateDropOffRoute = ({ availableBuses, route, onNext, onBack, onSubmit }) => {

    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const navigate = useNavigate();
    const baseUrl = useSelector(state => state.extras.baseUrl);

    useEffect(() => {
        logger.info('CreateDropOffRoute component mounted', { logTag, routeId: route.id });  // Log component mount
    }, [route]);

    // Create/Update dropoff route - after creating dropoff, nav to dropoff route edit screen
    const handleSubmit = () => {
        logger.info('Submitting dropoff route', { logTag, routeId: route.id });  // Log submit
        // Reset Flags
        setIsSaving(true);
        setErrorMsg(null);
        setShowSuccessAlert(false);

        console.log(`Create/update dropoff - ${JSON.stringify(route)}`);
        // Validate data
        // Check if student was added
        let isValid = true;
        let errorMsg = null;
        if (!route || !route.id) {
            isValid = false;
            errorMsg = 'Route is empty';
        }
        // TODO - add this check if school has enabled route stop
        else if (!route.stops || route.stops.length === 0) {
            isValid = false;
            errorMsg = 'Stops are empty';
        }
        else if (!route.students || route.students.length === 0) {
            isValid = false;
            errorMsg = 'Students are empty';
        }
        else if (!route.number || route.number.trim().length === 0) {
            isValid = false;
            errorMsg = 'Route number is empty';
        }
        else if (!route.bus || !route.bus.id) {
            isValid = false;
            errorMsg = 'Bus is empty';
        }
        else if (!route.driver || !route.driver.id) {
            isValid = false;
            errorMsg = 'Driver is empty';
        }

        // Check if all the students have pickup and drop off
        // TODO - use route stops check instead of students for now - later we decide to use this
        // for (const student of route.students) {
        //     if (!student?.student?.pickup) {
        //         isValid = false;
        //         errorMsg = `${student?.student?.name} doesn't have Pickup location set`;
        //         break;
        //     } else if (!student?.student?.dropoff) {
        //         isValid = false;
        //         errorMsg = `${student?.student?.name} doesn't have Drop-off location set`;
        //         break;
        //     }
        // }

        if (!isValid) {
            logger.warn('Validation failed', { logTag, errorMsg });  // Log validation failure
            setIsSaving(false);
            setErrorMsg(errorMsg);
            setShowSuccessAlert(false);
            return;
        }

        createDropOffRoute(route.id).then(resp => { 
            setIsSaving(false);
            setErrorMsg(null);
            setShowSuccessAlert(true);

            const respData = resp.data.data;
            logger.info('Dropoff route created successfully', { logTag, dropoffRouteId: respData.dropoff_route_id });  // Log success

            navigate(`${baseUrl}/route/edit/${route.id}`);  // Redirect to pickup route and show link for dropoff
            window.location.reload();
        }).catch(err => {
            logger.error('Error creating dropoff route', err, { logTag, routeId: route.id });  // Log error
            setIsSaving(false);
            setErrorMsg(null);
            setShowSuccessAlert(false);
        });
    }

    return (
        <>
            {errorMsg &&
                <ErrorAlertTopCenter
                    errorMsg={errorMsg}
                    onClose={() => {
                        logger.info('Error alert closed', { logTag });  // Log alert close
                        setErrorMsg(null);
                    }}
                />
            }
            {showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"Drop off route successfully!!"}
                    onClose={() => {
                        logger.info('Success alert closed', { logTag });  // Log success alert close
                        setShowSuccessAlert(false);
                    }}
                />
            }

            <Box
                sx={{
                    mt: 2,
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center'
                }}
            >
                {isSaving &&
                    <CircularProgress />
                }
                {!isSaving &&
                    <Box sx={{ my: 2 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mx: 2 }}
                            onClick={handleSubmit}
                        >
                            Create pickup and drop-off route
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{ mx: 2 }}
                            startIcon={<ArrowBackIosNewIcon />}
                            onClick={() => {
                                logger.info('Back button clicked', { logTag, routeId: route.id });  // Log back button click
                                onBack();
                            }}
                        >
                            Back
                        </Button>
                    </Box>
                }
            </Box>
        </>
    );

}

export default CreateDropOffRoute;



// import React, { useState, useEffect, useRef } from 'react';
// import { useSelector } from 'react-redux';
// import { Link, useParams, useNavigate } from 'react-router-dom';
// import { Box, Button, Typography, Chip, Paper, Grid,
//     TextField, Autocomplete, CircularProgress, List, ListItem, ListItemText, ListItemAvatar, Avatar,
//     IconButton, Divider } from '@mui/material';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import { createDropOffRoute } from '../../../../services/http.service';
// import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';


// const CreateDropOffRoute = ({ availableBuses, route, onNext, onBack, onSubmit }) => {

//     const [isSaving, setIsSaving] = useState(false);
//     const [errorMsg, setErrorMsg] = useState(null);
//     const [showSuccessAlert, setShowSuccessAlert] = useState(false);

//     const navigate = useNavigate();
//     const baseUrl = useSelector(state => state.extras.baseUrl);

//     // Create/Update dropoff route - after creating dropoff, nav to dropoff route edit screen
//     const handleSubmit = () => {
//         // Reset Flags
//         setIsSaving(true);
//         setErrorMsg(null);
//         setShowSuccessAlert(false);

//         console.log(`Create/update dropoff - ${JSON.stringify(route)}`);
//         // Validate data
//         // Check if student was added
//         let isValid = true;
//         let errorMsg = null;
//         if (!route || !route.id) {
//             isValid = false;
//             errorMsg = 'Route is empty';
//         }
//         // TODO - add this check if school has enabled route stop
//         else if (!route.stops || route.stops.length === 0) {
//             isValid = false;
//             errorMsg = 'Stops are empty';
//         }
//         else if (!route.students || route.students.length === 0) {
//             isValid = false;
//             errorMsg = 'Students are empty';
//         }
//         else if (!route.number || route.number.trim().length === 0) {
//             isValid = false;
//             errorMsg = 'Route number is empty';
//         }
//         else if (!route.bus || !route.bus.id) {
//             isValid = false;
//             errorMsg = 'Bus is empty';
//         }
//         else if (!route.driver || !route.driver.id) {
//             isValid = false;
//             errorMsg = 'Driver is empty';
//         }

//         // Check if all the students have pickup and drop off
//         // TODO - use route stops check instead of students for now - later we decide to use this
//         // for (const student of route.students) {
//         //     if (!student?.student?.pickup) {
//         //         isValid = false;
//         //         errorMsg = `${student?.student?.name} doesn't have Pickup location set`;
//         //         break;
//         //     } else if (!student?.student?.dropoff) {
//         //         isValid = false;
//         //         errorMsg = `${student?.student?.name} doesn't have Drop-off location set`;
//         //         break;
//         //     }
//         // }

//         if (!isValid) {
//             setIsSaving(false);
//             setErrorMsg(errorMsg);
//             setShowSuccessAlert(false);
//             return;
//         }

//         createDropOffRoute(route.id).then(resp => { 
//             setIsSaving(false);
//             setErrorMsg(null);
//             setShowSuccessAlert(true);

//             const respData = resp.data.data;
//             //if (respData.dropoff_route_id) {
//                 console.log(`Drop off route id - ${respData.dropoff_route_id}`)
//                 //this.props.history.push(`${this.props.baseUrl}/route/edit/${resp.data.data.dropoff_route_id}`);
//                 //navigate(`${baseUrl}/route/edit/${resp.data.data.dropoff_route_id}`);
//                 navigate(`${baseUrl}/route/edit/${route.id}`); // should redirect to pickup route and show link for dropoff
//                 window.location.reload();
//             //}
//         }).catch(err => {
//             console.log(err);  
//             setIsSaving(true);
//             setErrorMsg(null);
//             setShowSuccessAlert(false);
//         })
//     }

//     return (
//         <>
//             {errorMsg &&
//                 <ErrorAlertTopCenter
//                     errorMsg={errorMsg}
//                     onClose={() => setErrorMsg(null)}
//                 />
//             }
//             {showSuccessAlert &&
//                 <SuccessAlertTopRight
//                     successMsg={"Drop off route successfully!!"}
//                     onClose={() => setShowSuccessAlert(false)}
//                 />
//             }
//             <Box
//                 sx={{
//                     mt: 2,
//                     display: 'flex',
//                     justifyContent: 'start',
//                     alignItems: 'center'
//                 }}
//             >
//                 {isSaving &&
//                     <CircularProgress />
//                 }
//                 {!isSaving &&
//                     <Box sx={{ my: 2 }}>
//                         <Button
//                             type="submit"
//                             variant="contained"
//                             sx={{ mx: 2 }}
//                             onClick={handleSubmit}
//                         >
//                             Create pickup and drop-off route
//                         </Button>
//                         <Button
//                             variant="outlined"
//                             sx={{ mx: 2 }}
//                             startIcon={<ArrowBackIosNewIcon />}
//                             onClick={onBack}
//                         >
//                             Back
//                         </Button>
//                     </Box>
//                 }
//             </Box>
//         </>
//     );

// }

// export default CreateDropOffRoute;
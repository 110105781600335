import React, { Component, useState, useEffect } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
    Button, Chip, Paper, Grid, TableBody, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { connect, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";

import { getClasses, deleteClass } from '../../../../services/http.service';
import { CustomModal } from '../../../../common/modals';
import Loader from '../../../../common/loader';
import { ModalType } from '../../../../utils/constants';
import { VertActionMenu } from '../../../../common/action-menu';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'ClassList';  // Define logTag for this component

const ClassList = () => {

    const [classes, setClasses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });

    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();

    useEffect(() => {
        logger.info('ClassList component mounted', { logTag });  // Log component mount
        fetchClasses();
    }, []);

    // Fetch classes
    const fetchClasses = () => {
        logger.info('Fetching classes', { logTag });  // Log fetching classes
        setIsLoading(true);
        getClasses().then(resp => {
            setClasses(resp.data.data);
            setIsLoading(false);
            logger.info('Classes fetched successfully', { logTag, classes: resp.data.data });  // Log success
        }).catch(err => {
            logger.error('Error fetching classes', err, { logTag });  // Log error
            setIsLoading(false);
        })
    }

    // Handle Delete modal   
    const handleDelete = (id) => {
        logger.info('Delete class requested', { logTag, classId: id });  // Log delete request
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });
        console.log(`delete - ${JSON.stringify(deleteJson)}`);
    }

    // On Delete of Class
    const onDelete = (data) => {
        logger.info('Confirm delete class', { logTag, classId: data.id });  // Log confirm delete
        console.log('on delete data ' + JSON.stringify(data));

        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false,
        });
        // Show spinner
        setIsLoading(true);
        deleteClass(data.id).then(resp => {
            setIsLoading(false);
            logger.info('Class deleted successfully', { logTag, classId: data.id });  // Log success
            fetchClasses();
        }).catch(err => {
            logger.error('Error deleting class', err, { logTag, classId: data.id });  // Log error
            setIsLoading(false);         
        })
    }

    // Get comma-separated sections
    const getCommaSeparatedSections = (sections) => {
        const arr = sections.map(section => section.name);
        console.log(arr);
        logger.info('Formatting sections as comma-separated string', { logTag, sections: arr });  // Log section formatting
        return arr.join(", ");
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                 <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        Classes
                    </Typography>
                    <Chip
                        onClick={() => {
                            logger.info('Navigating to create new class', { logTag });  // Log navigation to create class
                            navigate(`../new`);
                        }}
                        icon={<AddCircleOutlineIcon />}
                        label="New class"
                        variant="outlined"
                        color="secondary"
                    />
                </Box>

                { isLoading &&
                    <Loader />
                }

                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12} md={10}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '.8rem' }}>#</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Sections</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {classes.map((item, index) => (
                                        <TableRow
                                            key={item.name}
                                        >
                                            <TableCell align="left">{index + 1}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.name}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {getCommaSeparatedSections(item.sections)}
                                            </TableCell>
                                            <TableCell align="right">
                                                <VertActionMenu
                                                    onEdit={() => {
                                                        logger.info('Navigating to edit class', { logTag, classId: item.id });  // Log edit navigation
                                                        navigate(`${baseUrl + "/class/edit/" + item.id}`);
                                                    }}
                                                    onDelete={() => handleDelete(item.id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>

            {/* Delete modal */}
            <CustomModal
                show={deleteJson.show}
                data={deleteJson.data}
                type={ModalType.CONFIRM_DELETE}
                onOkay={(data) => {
                    logger.info('Delete confirmed for class', { logTag, classId: data.id });  // Log delete confirmation
                    onDelete(data);
                }}
                onHide={() => {
                    logger.info('Delete modal closed', { logTag });  // Log modal close
                    setDeleteJson({ ...deleteJson, show: false });
                }}
            ></CustomModal>
        </>
    );
}

export default ClassList;


// import React, { Component, useState, useEffect } from 'react';
// import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
//     Button, Chip, Paper, Grid, TableBody, IconButton } from '@mui/material';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { connect, useSelector } from 'react-redux';
// import { Link, useNavigate } from "react-router-dom";

// import { getClasses, deleteClass } from '../../../../services/http.service';
// import { CustomModal } from '../../../../common/modals';
// import Loader from '../../../../common/loader';
// import { ModalType } from '../../../../utils/constants';
// import { VertActionMenu } from '../../../../common/action-menu';


// const ClassList = () => {

//     const [classes, setClasses] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);
//     const [deleteJson, setDeleteJson] = useState({
//         show: false,
//         data: {}
//     });

//     const baseUrl = useSelector(state => state.extras.baseUrl);
//     const navigate = useNavigate();

//     useEffect(() => {
//         fetchClasses();
//     }, []);

//     // Fetch classes
//     const fetchClasses = () => {
//         setIsLoading(true);
//         getClasses().then(resp => {
//             setClasses(resp.data.data);
//             setIsLoading(false);
//         }).catch(err => {
//             console.log(err);
//             setIsLoading(false);
//         })
//     }

//     // Handle Delete modal   
//     const handleDelete = (id) => {
//         setDeleteJson({
//             ...deleteJson,
//             show: true,
//             data: {
//                 id: id
//             }
//         });
//         console.log(`delelte - ${JSON.stringify(deleteJson)}`);
//     }

//     // On Delete of School
//     const onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));

//         // Hide delete confirm modal
//         setDeleteJson({
//             ...deleteJson,
//             show: false,
//         });
//         // Show spinner
//         setIsLoading(true);
//         deleteClass(data.id).then(resp => {
//             setIsLoading(false);
//             fetchClasses();
//         }).catch(err => {
//             setIsLoading(false);         
//         })
//     }

//     // Get comma separated sections
//     const getCommaSeparatedSections = (sections) => {
//         const arr = sections.map(section => section.name);
//         console.log(arr);
//         return arr.join(", ");
//     }

//     return (
//         <>
//             <Box
//                 sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "left",
//                     alignItems: "start",
//                 }}
//             >
//                  <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//                     <Typography component="h2" variant="header">
//                         Classes
//                     </Typography>
//                     <Chip
//                         onClick={() => navigate(`../new`)}
//                         icon={<AddCircleOutlineIcon />}
//                         label="New class"
//                         variant="outlined"
//                         color="secondary"
//                     />
//                 </Box>

//                 { isLoading &&
//                     <Loader />
//                 }

//                 <Grid container spacing={2} sx={{ mt: 3 }}>
//                     <Grid item xs={12} md={10}>
//                         <TableContainer component={Paper}>
//                             <Table aria-label="simple table">
//                                 <TableHead>
//                                     <TableRow>
//                                         <TableCell sx={{ width: '.8rem' }}>#</TableCell>
//                                         <TableCell>Name</TableCell>
//                                         <TableCell>Sections</TableCell>
//                                         <TableCell align="right">Action</TableCell>
//                                     </TableRow>
//                                 </TableHead>
//                                 <TableBody>
//                                     {classes.map((item, index) => (
//                                         <TableRow
//                                             key={item.name}
//                                         >
//                                             <TableCell align="left">{index + 1}</TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.name}
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {getCommaSeparatedSections(item.sections)}
//                                             </TableCell>
//                                             <TableCell align="right">
//                                                 <VertActionMenu
//                                                     onEdit={() => navigate(`${baseUrl + "/class/edit/" + item.id}`)}
//                                                     onDelete={() => handleDelete(item.id)}
//                                                 />
//                                             </TableCell>
//                                         </TableRow>
//                                     ))}
//                                 </TableBody>
//                             </Table>
//                         </TableContainer>
//                     </Grid>
//                 </Grid>
//             </Box>
//             {/* Delete modal */}
//             <CustomModal
//                 show={deleteJson.show}
//                 data={deleteJson.data}
//                 type={ModalType.CONFIRM_DELETE}
//                 onOkay={(data) => { onDelete(data) }}
//                 onHide={ () => {setDeleteJson({...deleteJson, show: false})} }
//             ></CustomModal>
//         </>
//     );
// }

// export default ClassList;
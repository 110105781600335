import React from 'react';
import { Outlet, Link } from "react-router-dom";

const Driver = () => {
    return (
        <>
            <Outlet />
        </>
    );
}

export default Driver;
import React, { useState, useEffect, useRef } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Grid,
    Chip,
    Box,
    Typography,
    Collapse,
    Paper,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { changeRouteStopOrder } from '../../../../services/http.service';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import { VertActionMenu } from '../../../../common/action-menu';
import { convertTimeToAMPM } from '../../../../utils/constants';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'TripStopsTable';  // Define logTag for this component

const TripStopsTable = ({ 
    tripId, 
    tripStops, 
    handleStopOrderChange, 
    onDelete, 
    onDisable, 
    onEdit,
    onViewLocationOnMap,
}) => {
    useEffect(() => {
        logger.info('TripStopsTable component mounted', { logTag, tripId });  // Log component mount
    }, [tripId]);

    return (
        <>  
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 500 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Order</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tripStops.map((row, index) => (
                                <Row
                                    key={row.id}
                                    row={row}                                    
                                    onViewLocationOnMap={onViewLocationOnMap}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    )
}

const Row = ({ row, onViewLocationOnMap }) => {

    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        logger.info('Row component mounted for stop', { logTag, stopId: row.id });  // Log row component mount
    }, [row.id]);

    return (
        <>
            <TableRow key={row.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell sx={{ width: '10px'}}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            logger.info('Row expanded/collapsed', { logTag, stopId: row.id, expanded: !open });  // Log row expansion/collapse
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {row.stop.name}
                    {!row.isActive && <Chip sx={{ ml: 1 }} label="Disabled" color="error" variant="outlined" />}
                </TableCell>
                <TableCell>
                    <Chip
                        label={row.order}
                        color={'primary'}
                    />
                </TableCell>
                <TableCell align="right">
                    <VertActionMenu                        
                        onViewLocation={() => {
                            logger.info('View stop location clicked', { logTag, stopId: row.id });  // Log view stop location
                            onViewLocationOnMap(row);
                        }}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography component='div' variant='h5'>
                                        Location
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {row.stop.location.location}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography component='div' variant='h5'>
                                        Pickup Time
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {convertTimeToAMPM(row.stop.pickupTime)}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography component='div' variant='h5'>
                                        Dropoff Time
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {convertTimeToAMPM(row.stop.dropoffTime)}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography component='div' variant='h5'>
                                        Latitude
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {row.stop.location.latitude}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography component='div' variant='h5'>
                                        Longitude
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {row.stop.location.longitude}
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default TripStopsTable;


// import React, { useState, useEffect, useRef } from 'react';
// import {
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableHead,
//     TableRow,
//     IconButton,
//     Grid,
//     Chip,
//     Box,
//     Typography,
//     Collapse,
//     Paper,
// } from '@mui/material';

// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';

// import { changeRouteStopOrder } from '../../../../services/http.service';
// import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
// import { VertActionMenu } from '../../../../common/action-menu';
// import { convertTimeToAMPM } from '../../../../utils/constants';


// const TripStopsTable = ({ 
//     tripId, 
//     tripStops, 
//     handleStopOrderChange, 
//     onDelete, 
//     onDisable, 
//     onEdit,
//     onViewLocationOnMap,
// }) => {

//     return (
//         <>  
//             <Paper sx={{ width: '100%', overflow: 'hidden' }}>
//                 <TableContainer sx={{ maxHeight: 500 }}>
//                     <Table stickyHeader>
//                         <TableHead>
//                             <TableRow>
//                                 <TableCell />
//                                 <TableCell align="left">Name</TableCell>
//                                 <TableCell align="left">Order</TableCell>
//                                 <TableCell align="right">Actions</TableCell>
//                             </TableRow>
//                         </TableHead>
//                         <TableBody>
//                             {tripStops.map((row, index) => (
//                                 <Row
//                                     key={row.id}
//                                     row={row}                                    
//                                     onViewLocationOnMap={onViewLocationOnMap}
//                                 />
//                             ))}
//                         </TableBody>
//                     </Table>
//                 </TableContainer>
//             </Paper>
//         </>
//     )
// }

// const Row = ({ row, onViewLocationOnMap }) => {

//     const [open, setOpen] = React.useState(false);

//     console.log("row -- ", JSON.stringify(row));

//     return (
//         <>
//             <TableRow key={row.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
//                 <TableCell sx={{ width: '10px'}}>
//                     <IconButton
//                         aria-label="expand row"
//                         size="small"
//                         onClick={() => setOpen(!open)}
//                     >
//                         {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
//                     </IconButton>
//                 </TableCell>
//                 <TableCell>
//                     {row.stop.name}
//                     {!row.isActive && <Chip sx={{ ml: 1 }} label="Disabled" color="error" variant="outlined" />}
//                 </TableCell>
//                 <TableCell>
//                     <Chip
//                         label={row.order}
//                         color={'primary'}
//                     />
//                 </TableCell>
//                 <TableCell align="right">
//                     <VertActionMenu                        
//                         onViewLocation={() => onViewLocationOnMap(row)}
//                     />
//                 </TableCell>
//             </TableRow>
//             <TableRow>
//                 <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
//                     <Collapse in={open} timeout="auto" unmountOnExit>
//                         <Box sx={{ margin: 1 }}>
//                             <Grid container spacing={2}>
//                                 <Grid item xs={3}>
//                                     <Typography component='div' variant='h5'>
//                                         Location
//                                     </Typography>
//                                 </Grid>
//                                 <Grid item>
//                                     {row.stop.location.location}
//                                 </Grid>
//                             </Grid>
//                             <Grid container spacing={2}>
//                                 <Grid item xs={3}>
//                                     <Typography component='div' variant='h5'>
//                                         Pickup Time
//                                     </Typography>
//                                 </Grid>
//                                 <Grid item>
//                                     {convertTimeToAMPM(row.stop.pickupTime)}
//                                 </Grid>
//                             </Grid>
//                             <Grid container spacing={2}>
//                                 <Grid item xs={3}>
//                                     <Typography component='div' variant='h5'>
//                                         Dropoff Time
//                                     </Typography>
//                                 </Grid>
//                                 <Grid item>
//                                     {convertTimeToAMPM(row.stop.dropoffTime)}
//                                 </Grid>
//                             </Grid>
//                             <Grid container spacing={2}>
//                                 <Grid item xs={3}>
//                                     <Typography component='div' variant='h5'>
//                                         Latitude
//                                     </Typography>
//                                 </Grid>
//                                 <Grid item>
//                                     {row.stop.location.latitude}
//                                 </Grid>
//                             </Grid>
//                             <Grid container spacing={2}>
//                                 <Grid item xs={3}>
//                                     <Typography component='div' variant='h5'>
//                                         Longitude
//                                     </Typography>
//                                 </Grid>
//                                 <Grid item>
//                                     {row.stop.location.longitude}
//                                 </Grid>
//                             </Grid>
//                         </Box>
//                     </Collapse>
//                 </TableCell>
//             </TableRow>
//         </>
//     )
// }



// export default TripStopsTable;




import React, { Component, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Box, Typography, Grid, Link as MuiLink, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Link, useNavigate } from "react-router-dom";

import { CustomModal } from '../../../common/modals';
import Loader from '../../../common/loader';
import { schools, deleteSchool } from '../../../services/http.service';
import { convertRole } from '../../../utils/constants';
import { openModal } from '../../../actions/modals';
import { ModalTypes, ModalType, SCHOOL } from '../../../utils/constants';
import { ErrorAlertTopRight } from '../../../common/snackbars';
import MainCard from '../../../common/cards/main-card';
import logger from '../../../utils/logger';  // Import your logger here

const logTag = 'SchoolList';  // Define logTag for this component

const SchoolList = () => {

    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });
    const [schoolUsers, setSchoolUsers] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showContent, setShowContent] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // run on component init
    useEffect(() => {
        logger.info('SchoolList component mounted', { logTag });  // Log component mount
        // remove school id stored in the cache
        SCHOOL.id = null;
        setShowContent(false);
        fetchSchools();
    }, []); // runs once, similar to componentDidMount

    // Handle Delete modal   
    const handleDelete = (id) => {
        logger.info(`Delete requested for school ID: ${id}`, { logTag });  // Log delete action
        setDeleteJson({
            ...deleteJson, 
            show: true,
            data: {
                id: id
            }
        });
    }

    // On Delete of School
    const onDelete = (data) => {
        logger.info(`Deleting school ID: ${data.id}`, { logTag });  // Log the school delete action
        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson, 
            show: false,
        });
        // Show spinner
        setIsLoading(true);
        deleteSchool(data.id).then(resp => {
            logger.info(`School ID: ${data.id} deleted successfully`, { logTag });  // Log success
            setIsLoading(false);
            fetchSchools();
        }).catch(err => {
            logger.error(`Error deleting school ID: ${data.id}`, err, { logTag });  // Log error
            setIsLoading(false);
            dispatch(openModal(ModalTypes.ERROR, err, null, null));
        });
    }

    // Fetch Schools
    const fetchSchools = () => {
        logger.debug('Fetching schools...', { logTag });  // Log fetching schools
        setIsLoading(true);
        schools().then(resp => {
            logger.info('Schools fetched successfully', { logTag });  // Log success
            setSchoolUsers(resp.data.school_users);
            setIsLoading(false);
            setShowContent(true);
        }).catch(err => {
            logger.error('Error fetching schools', err, { logTag });  // Log error
            setIsLoading(false);
            setErrorMsg(err);
        });
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                {isLoading &&
                    <Loader />
                }
                {errorMsg &&
                    <ErrorAlertTopRight 
                        errorMsg={errorMsg}
                        onClose={() => setErrorMsg(null)} 
                    />
                }
                { showContent &&
                    <SchoolListContent 
                        schoolUsers={schoolUsers} 
                        navigate={navigate} 
                        handleDelete={handleDelete} 
                    />
                }
            </Box>
            {/* Delete modal */}
            <CustomModal 
                show={deleteJson.show}
                data={deleteJson.data}
                type={ModalType.CONFIRM_DELETE}
                onOkay={(data) => {onDelete(data)}}
                onHide={ () => {setDeleteJson({...deleteJson, show: false})} }
            >
            </CustomModal>
        </>
    );
}

export default (SchoolList);

const SchoolListContent = ({ schoolUsers, navigate, handleDelete }) => {
    const logTag = 'SchoolListContent';  // Define logTag for this sub-component

    logger.info('Rendering SchoolListContent', { logTag });  // Log render of content

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <Typography component="h2" variant="header">
                    Your Schools
                </Typography>
                <Chip
                    onClick={() => {
                        logger.info('Navigating to Create New School', { logTag });  // Log navigation to create new school
                        navigate(`/school/new`);
                    }}
                    icon={<AddCircleOutlineIcon />}
                    label="Create new"
                    variant="outlined"
                    color="secondary"
                />
            </Box>
            {(!schoolUsers || schoolUsers.length === 0) &&
                <Box sx={{
                    mt: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                }}>
                    <Typography component="h2" variant="info" color="secondary">
                        You have no schools created yet. Please <a href="/school/new">create</a>.
                    </Typography>
                </Box>
            }
            <Box sx={{ mt: 2, width: "100%" }}>
                {(schoolUsers && schoolUsers.length > 0) &&
                    <>
                        {schoolUsers.map((item, index) => (
                            <MainCard key={item.school.id} sx={{ my: 3 }}>
                                <Grid container spacing={3} alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Grid container direction="column">
                                            <Link to={"/school/" + item.school.id + "/admin/trip/today/list"}>
                                                <Typography component="h4" variant="h4" color="secondary">
                                                    {item.school.name}
                                                </Typography>
                                            </Link>
                                            <Typography component="span" variant="subtitle2">
                                                {item.school.address}
                                            </Typography>
                                            <Typography component="span" variant="subtitle2">
                                                ID number:
                                                <Typography component="span" variant="subtitle2" sx={{ ml: 1, fontWeight: 'bold' }}>
                                                    {item.school.unique_number}
                                                </Typography>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item justifyContent="end">
                                        <MuiLink component="button" underline="none" onClick={() => {
                                            logger.info(`Navigating to Edit School ID: ${item.school.id}`, { logTag });  // Log navigation to edit school
                                            navigate("/school/edit/" + item.school.id);
                                        }}>
                                            <EditIcon sx={{ color: 'primary.main' }} />
                                        </MuiLink>
                                        <MuiLink component="button" underline="none" onClick={() => {
                                            logger.info(`Delete requested for school ID: ${item.school.id}`, { logTag });  // Log delete request
                                            handleDelete(item.school.id);
                                        }}>
                                            <DeleteIcon sx={{ ml: 3, color: 'error.light' }} />
                                        </MuiLink>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        ))}
                    </>
                }
            </Box>
        </>
    );
}


// import React, { Component, useState, useEffect } from 'react';
// import { connect, useDispatch } from 'react-redux';
// import { Box, Typography, Grid, Link as MuiLink, Chip } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import { Link, useNavigate } from "react-router-dom";

// import { CustomModal } from '../../../common/modals';
// import Loader from '../../../common/loader';
// import { schools, deleteSchool } from '../../../services/http.service' 
// import { convertRole } from '../../../utils/constants';
// import { openModal } from '../../../actions/modals';
// import { ModalTypes, ModalType, SCHOOL } from '../../../utils/constants';
// import { ErrorAlertTopRight } from '../../../common/snackbars';
// import MainCard from '../../../common/cards/main-card';


// const SchoolList = () => {

//     const [deleteJson, setDeleteJson] = useState({
//         show: false,
//         data: {}
//     });
//     const [schoolUsers, setSchoolUsers] = useState(null);
//     const [isLoading, setIsLoading] = useState(false);
//     const [errorMsg, setErrorMsg] = useState(null);
//     const [showContent, setShowContent] = useState(false);

//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     // run on compnonent init
//     useEffect(() => {
//         // remove school id stored in the cache
//         SCHOOL.id = null;
//         setShowContent(false);
//         fetchSchools();
//     }, []); // runs once similar to componentDidMount

//     // Handle Delete modal   
//     const handleDelete = (id) => {
//         console.log('handle delete - ' + id);
//         setDeleteJson({
//             ...deleteJson, 
//             show: true,
//             data: {
//                 id: id
//             }
//         });

//         console.log(`delelte - ${JSON.stringify(deleteJson)}`);
//     }

//     // On Delete of School
//     const onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));

//         // Hide delete confirm modal
//         setDeleteJson({
//             ...deleteJson, 
//             show: false,
//         });
//         // Show spinner
//         setIsLoading(true);
//         deleteSchool(data.id).then(resp => {
//             setIsLoading(false);
//             fetchSchools();
//         }).catch(err => {
//             setIsLoading(false);
//             //this.props.onError(err); // TODO convert to v6
//             dispatch(openModal(ModalTypes.ERROR, err, null, null));
//             //console.log(err);
//         })
//     }

//     // Fetch Schools
//     const fetchSchools = () => {
//         setIsLoading(true);
//         schools().then(resp => {
//             console.log(resp);
//             setSchoolUsers(resp.data.school_users);
//             setIsLoading(false);
//             setShowContent(true);
//         }).catch(err => {
//             console.log(err);
//             setIsLoading(false);
//             setErrorMsg(err);
//         })
//     }

//     return (
//         <>
//             <Box
//                 sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "left",
//                     alignItems: "start",
//                 }}
//             >
//                 {isLoading &&
//                     <Loader />
//                 }
//                 {errorMsg &&
//                     <ErrorAlertTopRight 
//                         errorMsg={errorMsg}
//                         onClose={() => setErrorMsg(null)} 
//                     />
//                 }
//                 { showContent &&
//                     <SchoolListContent 
//                         schoolUsers={schoolUsers} 
//                         navigate={navigate} 
//                         handleDelete={handleDelete} 
//                     />
//                 }
                
//             </Box>
//             {/* Delete modal */}
//             <CustomModal 
//                 show={deleteJson.show}
//                 data={deleteJson.data}
//                 type={ModalType.CONFIRM_DELETE}
//                 onOkay={(data) => {onDelete(data)}}
//                 onHide={ () => {setDeleteJson({...deleteJson, show: false})} }
//             >
//             </CustomModal>
//         </>
//     );
// }

// export default (SchoolList);

// const SchoolListContent = ({ schoolUsers, navigate, handleDelete }) => {
//     return (
//         <>
//             <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//                 <Typography component="h2" variant="header">
//                     Your Schools
//                 </Typography>
//                 <Chip
//                     onClick={() => navigate(`/school/new`)}
//                     icon={<AddCircleOutlineIcon />}
//                     label="Create new"
//                     variant="outlined"
//                     color="secondary"
//                 />
//             </Box>
//             {(!schoolUsers || schoolUsers.length === 0) &&
//                 <Box sx={{
//                     mt: 10,
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     width: "100%",
//                 }}>
//                     <Typography component="h2" variant="info" color="secondary">
//                         You have no schools created yet. Please <a href="/school/new">create</a>.
//                     </Typography>
//                 </Box>
//             }
//             <Box sx={{ mt: 2, width: "100%" }}>
//                 {(schoolUsers && schoolUsers.length > 0) &&
//                     <>
//                         {schoolUsers.map((item, index) => (
//                             <MainCard sx={{ my: 3 }}>
//                                 <Grid container spacing={3} alignItems="center" justifyContent="space-between">
//                                     <Grid item>
//                                         <Grid container direction="column">
//                                             <Link to={"/school/" + item.school.id + "/admin/trip/today/list"}>
//                                                 <Typography component="h4" variant="h4" color="secondary">
//                                                     {item.school.name}
//                                                 </Typography>
//                                             </Link>
//                                             <Typography component="span" variant="subtitle2">
//                                                 {item.school.address}
//                                             </Typography>
//                                             <Typography component="span" variant="subtitle2">
//                                                 ID number:
//                                                 <Typography component="span" variant="subtitle2" sx={{ ml: 1, fontWeight: 'bold' }}>
//                                                     {item.school.unique_number}
//                                                 </Typography>
//                                             </Typography>
//                                         </Grid>
//                                     </Grid>
//                                     <Grid item justifyContent="end">
//                                         <MuiLink component="button" underline="none" onClick={() => navigate("/school/edit/" + item.school.id)}>
//                                             <EditIcon sx={{ color: 'primary.main' }} />
//                                         </MuiLink>
//                                         <MuiLink component="button" underline="none" onClick={() => handleDelete(item.school.id)}>
//                                             <DeleteIcon sx={{ ml: 3, color: 'error.light' }} />
//                                         </MuiLink>
//                                     </Grid>
//                                 </Grid>
//                             </MainCard>
//                         ))}
//                     </>
//                 }
//             </Box>
//         </>
//     );
// }
    
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
    Button, Chip, Paper, Grid, TableBody, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getAdmins, getAdmin, deleteAdmin } from '../../../services/http.service';
import SearchBox from '../../../common/search-box';
import { Role } from '../../../utils/constants';
import CustomPagination from '../../../utils/pagination';
import Loader from '../../../common/loader';
import { CustomModal } from '../../../common/modals';
import { ModalType } from '../../../utils/constants';
import { VertActionMenu } from '../../../common/action-menu';


const AdminList = () => {

    const [admins, setAdmins] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });

    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();

    useEffect(() => {
        fetchAdmins();
    }, []);

     // Fetch admins
    const fetchAdmins = () => {
        setIsLoading(true);
        getAdmins().then(resp => {
            setAdmins(resp.data.data);
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    // Handle Delete modal   
    const handleDelete = (id) => {
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });
        console.log(`delelte - ${JSON.stringify(deleteJson)}`);
    }

    // On Delete of School
    const onDelete = (data) => {
        console.log('on delete data ' + JSON.stringify(data));

        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false
        });
        // Show spinner
        setIsLoading(true);
        deleteAdmin(data.id).then(resp => {
            setIsLoading(false);
            fetchAdmins();
        }).catch(err => {
            setIsLoading(false);          
        })
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        Admins
                    </Typography>
                    <Chip
                        onClick={() => navigate(`../new`)}
                        icon={<AddCircleOutlineIcon />}
                        label="Add admin"
                        variant="outlined"
                        color="secondary"
                    />
                </Box>
                { isLoading &&
                    <Loader />
                }
                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '.8rem' }}>#</TableCell>
                                        <TableCell sx={{ width: '25%' }}>Name</TableCell>
                                        <TableCell sx={{ width: '30%' }}>Email</TableCell>
                                        <TableCell sx={{ width: '10%' }}>Verified</TableCell>
                                        <TableCell>Mobile</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {admins.map((item, index) => (
                                        <TableRow
                                            key={item.id}
                                        >
                                            <TableCell align="left">{index + 1}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.user.name}
                                                {!item.user.is_verified &&
                                                    <>
                                                        <br />
                                                        <Chip label="Not verified" size="small" color="error" variant="outlined" />
                                                    </>
                                                }
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.user.email}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.user.is_verified ? 'Yes' : 'No'}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.user.mobile}
                                            </TableCell>
                                            <TableCell align="right">
                                                <VertActionMenu
                                                    onVerify={() => navigate(`${baseUrl}/${item.id}/verify`)}
                                                    onPhoto={()=> navigate(`${baseUrl}/${item.id}/photo`)}
                                                    onEdit={() => navigate(`${baseUrl + "/edit/" + item.id}`)}
                                                    onDelete={() => handleDelete(item.id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
            {/* Delete modal */}
            <CustomModal
                show={deleteJson.show}
                data={deleteJson.data}
                type={ModalType.CONFIRM_DELETE}
                onOkay={(data) => { onDelete(data) }}
                onHide={ () => {setDeleteJson({...deleteJson, show: false})} }
            ></CustomModal>
        </>
    );
}
export default AdminList;
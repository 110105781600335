import React, { Component, useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux'
import { Box, Typography, Grid, TextField, CircularProgress, Button } from '@mui/material';
import GoogleMapReact from 'google-map-react';

import { saveSchool, saveOrUpdateSchool, fetchSchool } from '../../../services/http.service';
import { openModal } from '../../../actions/modals';
import { ModalTypes, DEFAULT_MAP_LOCATION } from '../../../utils/constants';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../common/snackbars';
import { createMapOptions } from '../../../utils/google-map-helper';
import logger from '../../../utils/logger';  // Import your logger here

const logTag = 'NewSchool';  // Define logTag for this component

const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
    address: Yup.string().required("Address is required."),
    zipcode: Yup.string().required("Zipcode is required."),
    phone: Yup.string().required("Phone is required."),
});


const NewSchool = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [initialCenter, setInitialCenter] = useState({lat: DEFAULT_MAP_LOCATION.latitude, lng: DEFAULT_MAP_LOCATION.longitude});
    const [center, setCenter] = useState({lat: DEFAULT_MAP_LOCATION.latitude, lng: DEFAULT_MAP_LOCATION.longitude});
    const [zoom, setZoom] = useState(14);
    let [school, setSchool] = useState({
        id: null,
        name: '',
        address: '',
        zipcode: '',
        phone: '',
        website: '',
        latitude: 0,
        longitude: 0
    });
    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [validated, setValidated] = useState(false);

    // useRef for school as callback functions do not get updated object done by set states
    const schoolRef = useRef(school);

    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();
    
    let mapCache = null; // store for later use
    let mapsCache = null; // store for later use
    let markerCache = null;

    // run on component init
    useEffect(() => {
        logger.info('NewSchool component mounted', { logTag });  // Log component mount
        setSchool({
            ...school,
            id: id,
        });
    }, []); // runs once similar to componentDidMount
   

    /**
     * Handle Submit of form
     */
     const formik = useFormik({
        initialValues: {
            name: '',
            address: '',
            phone: '',
            website: '',
            zipcode: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            logger.info('Form submitted', { logTag, values });  // Log form submission
            const updatedSchool = {
                ...school,
                name: values.name,
                phone: values.phone,
                website: values.website,
                zipcode: values.zipcode,
            }
            setIsSaving(true);
            saveOrUpdateSchool(updatedSchool).then(resp => {
                logger.info('School saved or updated successfully', { logTag });  // Log success
                setIsSaving(false);
                setShowSuccessAlert(true);
                setErrorMsg(null);
                
                // set school id if its new
                if (!school.id) {
                    setSchool({...school, id: resp.data.data.school_id});
                }
            }).catch(err => {
                logger.error('Error saving school', err, { logTag });  // Log error
                setIsSaving(false);
                setErrorMsg(err);
                setShowSuccessAlert(false);
            });
        },
    });

    // Load school
    const loadSchool = () => {
        const id = school.id;
        if (!id) {
            return;
        }
        setIsLoading(true);
        fetchSchool(id).then(resp => {
            const school = resp.data.school;
            setSchool(school);
            schoolRef.current = school; // update ref to be used in other function
            formik.setValues({ ...school }); // get copy of school
            setIsLoading(false);
            setCenter({lat: school.latitude, lng: school.longitude});
            // Remove previous marker
            if (markerCache !== null) {
                markerCache.setMap(null);
                markerCache = null;
            }
            const position =  {lat: school.latitude, lng: school.longitude};       
            const schoolMarker = new mapsCache.Marker({
                draggable: true,
                position: position,
                map: mapCache,
                title: school.name
            });
            mapCache.setCenter(position);
            markerCache = schoolMarker;
            setOnMarkerDrag();
            logger.info('School data loaded successfully', { logTag, schoolId: id });  // Log successful school data load
        }).catch(err => {
            logger.error('Error loading school', err, { logTag });  // Log error
            setIsLoading(false);
        });
    }

    // ------- Google map apis ----------------

    const handleApiLoaded = (map, maps) => {
        logger.info('Google Map loaded', { logTag });  // Log Google Map load event
        mapCache = map; // set for later use
        mapsCache = maps; // set for later use
        
        // Load school for Edit
        loadSchool();
        
        // Add default marker
        const defaultMarker = new maps.Marker({
            draggable: true,
            position: initialCenter,
            map,
            title: 'School Address'
        });
        markerCache = defaultMarker; // for later use

        // Add Autocomplete input       
        const autocomplete = new maps.places.Autocomplete(document.getElementById('address'));
        autocomplete.bindTo('bounds', map);
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (!place.geometry) {
                logger.error('No details available for input', { logTag, placeName: place.name });  // Log error when no geometry
                window.alert("No details available for input: '" + place.name + "'");
                return;
            }
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(17);  // Why 17? Because it looks good.
            }

            const updatedSchool = schoolRef.current; // get from school ref
            logger.info('Updating school address from Google Places', { logTag, address: place.formatted_address });  // Log Google Places address update
            setSchool({
                ...updatedSchool,
                address: place.formatted_address,
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
            });
            schoolRef.current = updatedSchool; // update it back
            
            // Remove previous marker
            if (markerCache !== null) {
                markerCache.setMap(null);
                markerCache = null;
            }
            // Add new Marker            
            const newMarker = new maps.Marker({
                draggable: true,
                position: place.geometry.location,
                map: map,
                title: 'School Address'
            });
            markerCache = newMarker;
            
            // On Marker Drag get location
            setOnMarkerDrag();

        });
    };

    // Set OnMarker Drag event
    const setOnMarkerDrag = () => {
        const geocoder = new mapsCache.Geocoder();
        mapsCache.event.addListener(markerCache, 'dragend', (marker) => {
            logger.info('Marker dragged to new location', { logTag, newLocation: marker.latLng });  // Log marker drag
            geocoder.geocode({'latLng': marker.latLng}, (results, status) => {
                if (status === mapsCache.GeocoderStatus.OK) {
                    logger.info('Marker drag resulted in new address', { logTag, newAddress: results[0].formatted_address });  // Log new address from marker drag
                    const updatedSchool = schoolRef.current;
                    setSchool({
                        ...updatedSchool,
                        address: results[0].formatted_address,
                        latitude: marker.latLng.lat(),
                        longitude: marker.latLng.lng()
                    });
                }
            });
        });
    }

    // ------- End google map api -----------

    return (
        <>
            { errorMsg &&
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
            { showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"School saved successfully!!"}
                    onClose={() => setShowSuccessAlert(false)} 
                />
            }
            <Typography component="h2" variant="header">
                Create new school
            </Typography>
            <Grid container spacing={2} sx={{ mt: 3 }}>
                {isLoading &&
                    <Grid container>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12} md={6}>
                    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            fullWidth
                            required
                            name="name"
                            label="School name"
                            value={formik.values.name}
                            onChange={(e) => {
                                const val = e.target.value;
                                setSchool({...school, name: val});
                                schoolRef.current.name = val; // update it to be used in functions
                                formik.handleChange(e);
                            }}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            required
                            id="address" // to be used for address suggestions
                            name="address"
                            label="Address"
                            value={school.address}
                            onChange={(e) => {
                                setSchool({...school, address: e.target.value});
                                formik.handleChange(e);
                            }}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                        />
                         <TextField
                            margin="normal"
                            required
                            name="zipcode"
                            label="Zipcode (Pincode)"
                            value={school.zipcode}
                            onChange={(e) => {
                                setSchool({...school, zipcode: e.target.value});
                                formik.handleChange(e);
                            }}
                            error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
                            helperText={formik.touched.zipcode && formik.errors.zipcode}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Phone"
                            name="phone"
                            required
                            value={formik.values.phone}
                            onChange={(e) => {
                                const val = e.target.value;
                                setSchool({...school, phone: val});
                                schoolRef.current.phone = val; // update it to be used in functions
                                formik.handleChange(e);
                            }}
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Website"
                            name="website"
                            value={formik.values.website}
                            onChange={(e) => {
                                const val = e.target.value;
                                setSchool({...school, website: val});
                                schoolRef.current.website = val; // update it to be used in functions
                                formik.handleChange(e);
                            }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            {isSaving &&
                                <CircularProgress />
                            }
                            {!isSaving &&
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    {school.id ? 'Update':'Continue'}
                                </Button>
                            }
                        </Box>
                        {school.id &&
                            <Box sx={{ mt: 3, display: 'flex', justifyContent: "space-between" }}>
                                <Link to={`/school/${school.id}/logo`}>Update Logo</Link>
                            </Box>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ height: "500px" }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyCdw-yRVWwWV4Hfpje_ykGgiCILFp01TDs', libraries: ['places', 'geometry'], }}
                            defaultCenter={center}
                            defaultZoom={zoom}
                            options={createMapOptions}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                        ></GoogleMapReact>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
export default (NewSchool);




// import React, { Component, useState, useEffect, useRef } from 'react';
// import { connect, useDispatch } from 'react-redux'
// import { Box, Typography, Grid, TextField, CircularProgress, Button } from '@mui/material';
// import GoogleMapReact from 'google-map-react';

// import { saveSchool, saveOrUpdateSchool, fetchSchool } from '../../../services/http.service';
// import { openModal } from '../../../actions/modals';
// import { ModalTypes, DEFAULT_MAP_LOCATION } from '../../../utils/constants';
// import { Link, useNavigate, useParams } from 'react-router-dom';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';

// import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../common/snackbars';
// import { createMapOptions } from '../../../utils/google-map-helper';

// const validationSchema = Yup.object({
//     name: Yup.string().required("Name is required."),
//     address: Yup.string().required("Address is required."),
//     zipcode: Yup.string().required("Zipcode is required."),
//     phone: Yup.string().required("Phone is required."),
// });


// const NewSchool = (props) => {
//     const [isLoading, setIsLoading] = useState(false);
//     const [initialCenter, setInitialCenter] = useState({lat: DEFAULT_MAP_LOCATION.latitude, lng: DEFAULT_MAP_LOCATION.longitude});
//     const [center, setCenter] = useState({lat: DEFAULT_MAP_LOCATION.latitude, lng: DEFAULT_MAP_LOCATION.longitude});
//     const [zoom, setZoom] = useState(14);
//     let [school, setSchool] = useState({
//         id: null,
//         name: '',
//         address: '',
//         zipcode: '',
//         phone: '',
//         website: '',
//         latitude: 0,
//         longitude: 0
//     });
//     const [isSaving, setIsSaving] = useState(false);
//     const [errorMsg, setErrorMsg] = useState(null);
//     const [showSuccessAlert, setShowSuccessAlert] = useState(false);
//     const [validated, setValidated] = useState(false);

//     // useRef for school as callback functions do not get updated object done by set states
//     const schoolRef = useRef(school);

//     const navigate = useNavigate();
//     const { id } = useParams();
//     const dispatch = useDispatch();
    
//     let mapCache = null; // store for later use
//     let mapsCache = null; // store for later use
//     let markerCache = null;

//     // run on compnonent init
//     useEffect(() => {
//         console.log("useEffect init");
//         setSchool({
//             ...school,
//             id: id,
//         })
//     }, []); // runs once similar to componentDidMount
   

//     /**
//      * Handle Submit of form
//      */
//      const formik = useFormik({
//         // enableReinitialize: true,
//         // initialValues: {
//         //     name: schoolRef.current.name,
//         //     address: schoolRef.current.address,
//         //     phone: schoolRef.current.phone,
//         //     website: schoolRef.current.website,
//         // },
//         //initialValues: {schoolRef.current},
//         initialValues: {
//             name: '',
//             address: '',
//             phone: '',
//             website: '',
//             zipcode: '',
//         },
//         validationSchema: validationSchema,
//         onSubmit: (values) => {
//             const updatedSchool = {...school,
//                 name: values.name,
//                 //address: values.address, // do not set address as it doesnt get from google suggestion
//                 phone: values.phone,
//                 website: values.website,
//                 zipcode: values.zipcode,
//             }
//             setIsSaving(true);
//             saveOrUpdateSchool(updatedSchool).then(resp => {
//                 setIsSaving(false);
//                 setShowSuccessAlert(true);
//                 setErrorMsg(null);
                
//                 // set school id if its new
//                 if (!school.id) {
//                     setSchool({...school, id: resp.data.data.school_id});
//                 }

//                 // Navigate to adding School Logo screen
//                 // if (resp.data.data) {
//                 //     navigate(`/school/${resp.data.data.school_id}/logo`);
//                 // }
                
//             }).catch(err => {
//                 setIsSaving(false);
//                 setErrorMsg(err);
//                 setShowSuccessAlert(false);
//                 //dispatch(openModal(ModalTypes.ERROR, err, null, null));
//             });
//         },
//     });

//     // Load school
//     const loadSchool = () => {
//         const id = school.id;
//         if (!id) {
//             return;
//         }
//         setIsLoading(true);
//         fetchSchool(id).then(resp => {
//             const school = resp.data.school;
//             setSchool(school);
//             schoolRef.current = school; // update ref to be used in other function
//             formik.setValues({ ...school }); // get copy of school
//             setIsLoading(false);
//             // Set Initial center
//             setCenter({lat: school.latitude, lng: school.longitude});
//             // Remove previous marker
//             if (markerCache !== null) {
//                 markerCache.setMap(null);
//                 //setMarker(null);
//                 markerCache = null;
//             }
//             // Add new Marker    
//             const postion =  {lat: school.latitude, lng: school.longitude};       
//             const schoolMarker = new mapsCache.Marker({
//                 draggable: true,
//                 position: postion,
//                 map: mapCache,
//                 title: school.name
//             });
//             mapCache.setCenter(postion);
            
//             console.log(`marker in loadSchool - ${mapCache}`)
//             //setMarker(marker);
//             markerCache = schoolMarker;
//             // On Marker Drag get location
//             setOnMarkerDrag();
//         }).catch(err => {
//             console.log(err);
//             setIsLoading(false);
//         });
//     }


//     // ------- Google map apis ----------------

//     const handleApiLoaded = (map, maps) => {
//         console.log("On google map ready");
//         mapCache = map; // set for later use
//         mapsCache = maps; // set for later use
//         //this.setState({google: this.props});
        
//         // Load school for Edit
//         loadSchool();
        
//         // Add default marker
//         const defaultMarker = new maps.Marker({
//             draggable: true,
//             position: initialCenter,
//             map,
//             title: 'School Address'
//         });
//         //setMarker(defaultMarker);
//         markerCache = defaultMarker; // for later use

//         // Add Autocomplete input       
// 		const autocomplete = new maps.places.Autocomplete(document.getElementById('address'));
//         autocomplete.bindTo('bounds', map);
//         autocomplete.addListener('place_changed', () => {
//             var place = autocomplete.getPlace();
//             if (!place.geometry) {
//                 // User entered the name of a Place that was not suggested and
//                 // pressed the Enter key, or the Place Details request failed.
//                 window.alert("No details available for input: '" + place.name + "'");
//                 return;
//             }
//             if (place.geometry.viewport) {
//                 map.fitBounds(place.geometry.viewport);
//             } else {
//                 map.setCenter(place.geometry.location);
//                 map.setZoom(17);  // Why 17? Because it looks good.
//             }
//             //setSchool({...school, name: "fdfdd"});


//             // Set address into school
//             const updatedSchool = schoolRef.current; // get from school ref
//             console.log(JSON.stringify(schoolRef.current));
//             setSchool({
//                 ...updatedSchool,
//                 address: place.formatted_address,
//                 latitude: place.geometry.location.lat(),
//                 longitude: place.geometry.location.lng(),
//             });
//             schoolRef.current = updatedSchool; // update it back
//             console.log(JSON.stringify(schoolRef.current));
            
//             // Remove previous marker
//             if (markerCache !== null) {
//                 markerCache.setMap(null);
//                 //setMarker(null);
//                 markerCache = null;
//             }
//             // Add new Marker            
//             const newMarker = new maps.Marker({
//                 draggable: true,
//                 position: place.geometry.location,
//                 map: map,
//                 title: 'School Address'
//             });
//             //setMarker(newMarker);
//             markerCache = newMarker;
            
//             // On Marker Drag get location
//             setOnMarkerDrag();

//         });
//     };

//     // Set OnMarker Drag event
//     const setOnMarkerDrag = () => {
//         const geocoder = new mapsCache.Geocoder();
        
//         mapsCache.event.addListener(markerCache, 'dragend', (marker) => {
//             console.log("Marker drag new Location :: " + JSON.stringify(marker.latLng));
//             geocoder.geocode({'latLng': marker.latLng}, (results, status) => {
//                 if (status === mapsCache.GeocoderStatus.OK) {
//                     console.log("Marker drag Geocode Address :: " + results[0].formatted_address);
//                     // Update school object
//                     const updatedSchool = schoolRef.current;
//                     setSchool({
//                         ...updatedSchool,
//                         address: results[0].formatted_address,
//                         latitude: marker.latLng.lat(),
//                         longitude: marker.latLng.lng()
//                     });
//                 }
//             });
//         });
//     }

//     // ------- End google map api -----------

//     return (
//         <>
//             { errorMsg &&
//                 <ErrorAlertTopCenter 
//                     errorMsg={errorMsg}
//                     onClose={() => setErrorMsg(null)} 
//                 />
//             }
//             { showSuccessAlert &&
//                 <SuccessAlertTopRight
//                     successMsg={"Student saved successfully!!"}
//                     onClose={() => setShowSuccessAlert(false)} 
//                 />
//             }
//             <Typography component="h2" variant="header">
//                 Create new school
//             </Typography>
//             <Grid container spacing={2} sx={{ mt: 3 }}>
//                 {isLoading &&
//                     <Grid container>
//                         <Grid item>
//                             <CircularProgress />
//                         </Grid>
//                     </Grid>
//                 }
//                 <Grid item xs={12} md={6}>
//                     <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
//                         <TextField
//                             margin="normal"
//                             fullWidth
//                             required
//                             name="name"
//                             label="School name"
//                             value={formik.values.name}
//                             onChange={(e) => {
//                                 const val = e.target.value;
//                                 setSchool({...school, name: val});
//                                 schoolRef.current.name = val; // update it to be used in functions
//                                 formik.handleChange(e);
//                             }}
//                             error={formik.touched.name && Boolean(formik.errors.name)}
//                             helperText={formik.touched.name && formik.errors.name}
//                         />
//                         <TextField
//                             margin="normal"
//                             fullWidth
//                             required
//                             id="address" // to be used for address suggestions
//                             name="address"
//                             label="Address"
//                             value={school.address}
//                             onChange={(e) => {
//                                 setSchool({...school, address: e.target.value});
//                                 formik.handleChange(e);
//                             }}
//                             error={formik.touched.address && Boolean(formik.errors.address)}
//                             helperText={formik.touched.address && formik.errors.address}
//                         />
//                          <TextField
//                             margin="normal"
//                             required
//                             name="zipcode"
//                             label="Zipcode (Pincode)"
//                             value={school.zipcode}
//                             onChange={(e) => {
//                                 setSchool({...school, zipcode: e.target.value});
//                                 formik.handleChange(e);
//                             }}
//                             error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
//                             helperText={formik.touched.zipcode && formik.errors.zipcode}
//                         />
//                         <TextField
//                             margin="normal"
//                             fullWidth
//                             label="Phone"
//                             name="phone"
//                             required
//                             value={formik.values.phone}
//                             onChange={(e) => {
//                                 console.log(JSON.stringify(school));
//                                 const val = e.target.value;
//                                 setSchool({...school, name: val});
//                                 schoolRef.current.name = val; // update it to be used in functions
//                                 formik.handleChange(e);
//                             }}
//                             error={formik.touched.phone && Boolean(formik.errors.phone)}
//                             helperText={formik.touched.phone && formik.errors.phone}
//                         />
//                         <TextField
//                             margin="normal"
//                             fullWidth
//                             label="Website"
//                             name="website"
//                             value={formik.values.website}
//                             onChange={(e) => {
//                                 console.log(JSON.stringify(school));
//                                 const val = e.target.value;
//                                 setSchool({...school, name: val});
//                                 schoolRef.current.name = val; // update it to be used in functions
//                                 formik.handleChange(e);
//                             }}
//                         />
//                         <Box
//                             sx={{
//                                 display: 'flex',
//                                 justifyContent: 'center',
//                             }}
//                         >
//                             {isSaving &&
//                                 <CircularProgress />
//                             }
//                             {!isSaving &&
//                                 <Button
//                                     type="submit"
//                                     fullWidth
//                                     variant="contained"
//                                     sx={{ mt: 3, mb: 2 }}
//                                 >
//                                     {school.id ? 'Update':'Continue'}
//                                 </Button>
//                             }
//                         </Box>
//                         {school.id &&
//                             <Box sx={{ mt: 3, display: 'flex', justifyContent: "space-between" }}>
//                                 <Link to={`/school/${school.id}/logo`}>Update Logo</Link>
//                                 {/* <Link to={`/school/${school.id}/subscription`}>Change Subscription</Link> */}
//                             </Box>
//                         }
//                     </Box>
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                     <Box sx={{ height: "500px" }}>
//                         <GoogleMapReact
//                             bootstrapURLKeys={{ key: 'AIzaSyCdw-yRVWwWV4Hfpje_ykGgiCILFp01TDs', libraries: ['places', 'geometry'], }}
//                             defaultCenter={center}
//                             defaultZoom={zoom}
//                             options={createMapOptions}
//                             yesIWantToUseGoogleMapApiInternals
//                             onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
//                         ></GoogleMapReact>
//                     </Box>
//                 </Grid>
//             </Grid>
//         </>
//     );
// }
// export default (NewSchool);
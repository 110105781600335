import React, { useState, useEffect, useRef, useCallback } from 'react';
import GoogleMapCustom from './google-map-custom'; // Import your GoogleMapCustom component
import logger from '../../utils/logger';  // Assuming your custom logger is imported from a utils folder

const SingleMarkerMap = ({ 
    center, 
    zoom,
    isDraggable = false,
    onMapReady,
    onMarkerDragged,
}) => {
    const logTag = 'SingleMarkerMap';
    const [map, setMap] = useState(null);
    const [maps, setMaps] = useState(null);
    const [marker, setMarker] = useState(null);

    const handleMapReady = (map, maps) => {
        // logger.info('Map is ready', { logTag, center, zoom, isDraggable });
        setMap(map);
        setMaps(maps);
        if (onMapReady) {
            // logger.debug('Invoking onMapReady callback', { logTag });
            onMapReady(map, maps);
        }
    };

    useEffect(() => {
        if (!map || !center) return; // Avoid running if map or center is not ready

        if (marker) {
            // logger.debug('Removing old marker', { logTag });
            marker.setMap(null);
        }

        if (center && map) {
            // logger.info('Adding new marker to the map', { logTag, center });
            const newMarker = new window.google.maps.Marker({
                position: center,
                map,
                draggable: isDraggable,
                title: 'Selected Location',
            });
            setMarker(newMarker);
            // logger.debug('New marker set', { logTag });
        }
    }, [center, map, isDraggable]);

    useEffect(() => {
        if (maps && marker) {
            // logger.info('Adding dragend event listener to the marker', { logTag });
            maps.event.addListener(marker, 'dragend', (event) => {
                // logger.info('Marker dragged to new position', { logTag, lat: event.latLng.lat(), lng: event.latLng.lng() });
                if (onMarkerDragged) {
                    // logger.debug('Invoking onMarkerDragged callback', { logTag });
                    onMarkerDragged(event.latLng);
                }
            });
        } else {
            // logger.warn('Maps or marker is not ready for dragend event', { logTag });
        }
    }, [marker, maps, onMarkerDragged]);

    return (
        <GoogleMapCustom
            center={center}
            zoom={zoom}
            onMapReady={handleMapReady}
        />
    );
};

export default SingleMarkerMap;



// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import GoogleMapCustom from './google-map-custom'; // Import your GoogleMapCustom component

// const SingleMarkerMap = ({ 
//     center, 
//     zoom,
//     isDraggable = false,
//     onMapReady,
//     onMarkerDragged,
// }) => {
//     const [map, setMap] = useState(null);
//     const [maps, setMaps] = useState(null);
//     const [marker, setMarker] = useState(null);
//     //const markerRef = useRef(null);

//     const handleMapReady = (map, maps) => {
//         setMap(map);
//         setMaps(maps);
//         onMapReady(map, maps);
//     }

//     useEffect(() => {
//         // Remove old markers if any
//         if (marker) {
//             marker.setMap(null);
//         }
//         if (center && map) {
//             // Add new marker
//             const newMarker = new window.google.maps.Marker({
//                 position: center,
//                 map,
//                 draggable: isDraggable,
//                 title: 'Selected Location',
//             });
//             setMarker(newMarker);
//         }
//     }, [center, map, maps]);

//     useEffect(() => {
//         // Handle dragend event
//         if (maps) {
//             maps.event.addListener(marker, 'dragend', (event) => {
//                 console.log("SingleMarkerMap ", event.latLng);
//                 if (onMarkerDragged) {
//                     onMarkerDragged(event.latLng);
//                 }
//             });
//         }
//     }, [marker]);


//     return (
//         <GoogleMapCustom
//             center={center}
//             zoom={zoom}
//             onMapReady={handleMapReady}
//         />
//     );
// };

// export default SingleMarkerMap;

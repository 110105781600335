import React, { Component, useState, useEffect } from 'react';
import { Box, Typography, Grid, Link as MuiLink, Chip, CircularProgress, TextField,
    Button, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Paper } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes } from 'date-fns';
import { useParams, useNavigate } from "react-router-dom";
import TimePicker from 'react-time-picker';

import { getRoutesWithDistinctRef, getRouteByRefAndType, getTripFrequencyTypes, getTripFrequencies,
    saveOrUpdateTripMetadata, getTripMetadata } 
    from '../../../../services/http.service';
import { convertFrequencyType, FrequencyType, convertFrequency, 
    RouteType, convertRouteType, convertTimeObjToDate, convert24HourTo12Hour } from '../../../../utils/constants';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import RouteDetailDialog from '../../../../common/route-detail-dialog';
import TimeSelector from '../../../../common/time-selector';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'TripMetadataNew';  // Define logTag for this component

const TripMetadataNew = () => {

    const [validated, setValidated] = useState(false);
    const [routeLoading, setRouteLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [routes, setRoutes] = useState([]);
    const [selectedRoute, setSelectedRoute] = useState({});
    const [route, setRoute] = useState({});
    const [tripFrequencyTypes, setTripFrequencyTypes] = useState([]);
    const [tripFrequencies, setTripFrequencies] = useState([]);
    const [tripMetadata, setTripMetadata] = useState({
        id: null,
        routeRef: '', // do not use null as it overlaps value in select dropdown
        routeTypes: [], // pickup/dropoff
        frequencyType: '', // Recurring/OneTime // do not use null as it overlaps value in select dropdown
        frequencies: [], // Sunday, Monday .....
        pickupStartTime: {
            hours: '07',
            minutes: '00',
            period: 'AM',
        },
        pickupEndTime: {
            hours: '08',
            minutes: '00',
            period: 'AM',
        },
        dropoffStartTime: {
            hours: '02',
            minutes: '00',
            period: 'PM',
        },
        dropoffEndTime: {
            hours: '03',
            minutes: '00',
            period: 'PM',
        },
        maxDate: null,
    });
    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [trip, setTrip] = useState({});
    const [selectedRouteType, setSelectedRouteType] = useState(null);
    const [isRouteDetailDialogOpen, setIsRouteDetailDialogOpen] = useState(false);

    const { tripMetaDataId } = useParams();
    const navigate = useNavigate();
    
    useEffect(() => {
        logger.info('TripMetadataNew component mounted', { logTag });  // Log component mount
        fetchRoutes();
        fetchTripFrequencyTypes();
        fetchTripFrequencies();
        if (tripMetaDataId) {
            fetchTripMetadata(tripMetaDataId);
        }
    }, []);

    // Fetch Trip Metadata for Edit
    const fetchTripMetadata = (id) => {        
        setIsLoading(true);
        logger.info('Fetching trip metadata for edit', { logTag, tripMetadataId: id });  // Log fetching metadata
        getTripMetadata(id).then(resp => {
            setIsLoading(false);
            const respMetadata = resp.data.data;
            const newTripMetadata = {
                id: respMetadata.id,
                routeRef: respMetadata.route_ref,
                routeTypes: respMetadata.route_types,
                frequencyType: respMetadata.frequency_type,
                frequencies: respMetadata.frequencies, 
                pickupStartTime: respMetadata.pickup_start_time ? convert24HourTo12Hour(respMetadata.pickup_start_time) : tripMetadata.pickupStartTime,
                pickupEndTime: respMetadata.pickup_end_time ? convert24HourTo12Hour(respMetadata.pickup_end_time) : tripMetadata.pickupEndTime,
                dropoffStartTime: respMetadata.dropoff_start_time ? convert24HourTo12Hour(respMetadata.dropoff_start_time) : tripMetadata.dropoffStartTime,
                dropoffEndTime: respMetadata.dropoff_end_time ? convert24HourTo12Hour(respMetadata.dropoff_end_time) : tripMetadata.dropoffEndTime,
            }

            setTripMetadata(newTripMetadata);
            logger.info('Trip metadata loaded for editing', { logTag, tripMetadata: newTripMetadata });  // Log metadata loaded
        }).catch(err => {
            logger.error('Error fetching trip metadata', err, { logTag, tripMetadataId: id });  // Log error
            setIsLoading(false);
        });
    }

    // Fetch Routes with only Ref and type
    const fetchRoutes = () => {
        logger.info('Fetching routes', { logTag });  // Log route fetching
        getRoutesWithDistinctRef().then(resp => {
            const respRoutes = resp.data.data;
            const routes = respRoutes;
            setRoutes(routes);            
            logger.info('Routes fetched successfully', { logTag, routes });  // Log success
        }).catch(err => {
            logger.error('Error fetching routes', err, { logTag });  // Log error
        });
    }

    // Select route from dropdown box
    const selectRoute = (e) => {
        const value = e.target.value;
        logger.info('Route selected', { logTag, routeRef: value });  // Log route selection
        if (value && value.trim() !== '') {
            setTripMetadata({
                ...tripMetadata,
                routeRef: value,
            });
            setSelectedRoute(value);
        } else {
            setTripMetadata({
                ...tripMetadata,
                routeRef: null,
            });
        }
    }

    // Fetch Route
    const fetchRoute = (selectedRoute) => {
        logger.info('Fetching route details', { logTag, selectedRoute });  // Log fetching route
        getRouteByRefAndType(selectedRoute.ref_no, selectedRoute.type.name).then(resp => {
            const respRoute = resp.data.data;
            setTrip({
                ...trip,
                route: {
                    id: respRoute.id,
                    type: respRoute.type.name,
                    refNo: respRoute.ref_no
                }
            });
        }).catch(err => {
            logger.error('Error fetching route', err, { logTag, selectedRoute });  // Log error
        });
    }

    // Fetch Trip Frequency types
    const fetchTripFrequencyTypes = () => {
        logger.info('Fetching trip frequency types', { logTag });  // Log fetching frequency types
        getTripFrequencyTypes().then(resp => {
            const frequencyTypes = resp.data.data;
            setTripFrequencyTypes(frequencyTypes);
            logger.info('Trip frequency types fetched successfully', { logTag, frequencyTypes });  // Log success
        }).catch(err => {
            logger.error('Error fetching trip frequency types', err, { logTag });  // Log error
        });
    }

    // Fetch Trip Frequencies
    const fetchTripFrequencies = () => {
        logger.info('Fetching trip frequencies', { logTag });  // Log fetching frequencies
        getTripFrequencies().then(resp => {
            const frequencies = resp.data.data;
            setTripFrequencies(frequencies);
            logger.info('Trip frequencies fetched successfully', { logTag, frequencies });  // Log success
        }).catch(err => {
            logger.error('Error fetching trip frequencies', err, { logTag });  // Log error
        });
    }

    // Select trip frequency type
    const selectFrequencyType = (e) => {
        const value = e.target.value;
        logger.info('Frequency type selected', { logTag, frequencyType: value });  // Log frequency type selection
        if (!value || value.trim() === '') {
            tripMetadata.frequencyType = null;
            setTripMetadata({ ...tripMetadata });
            return;
        }  
        tripMetadata.frequencyType = value;

        // Set max date in case of Recurring frequency type
        let maxDate = null;
        if (value === FrequencyType.RECURRING) {
            maxDate = new Date();
        }
        tripMetadata.maxDate = maxDate;
        setTripMetadata({ ...tripMetadata });
    }

    // Select trip frequency
    const selectFrequency = (e) => {
        const value = e.target.value;
        if (!value || value === "") {
            return;
        }
        logger.info('Frequency selected', { logTag, frequency: value });  // Log frequency selection

        if (e.target.checked) {
            tripMetadata.frequencies.push(value);
        } else {
            tripMetadata.frequencies = tripMetadata.frequencies.filter(item => item !== value);          
        }
        setTripMetadata({ ...tripMetadata });
    }

    // Select route type
    const selectRouteType = (e) => {
        const value = e.target.value;
        if (!value || value.trim() === '') {
            return;
        }
        logger.info('Route type selected', { logTag, routeType: value });  // Log route type selection

        if (e.target.checked) {
            tripMetadata.routeTypes.push(value);
        } else {
            tripMetadata.routeTypes = tripMetadata.routeTypes.filter(item => item !== value);          
        }
        setTripMetadata({ ...tripMetadata });
    }

    // Submit to server
    const handleSubmit = () => {
        logger.info('Submitting trip metadata', { logTag, tripMetadata });  // Log submit metadata
        setIsSaving(true);
        setErrorMsg(null);
        setShowSuccessAlert(false);

        let isValid = true;
        let errorMsg = null;

        // Check if route is selected
        if (!tripMetadata.routeRef || tripMetadata.routeRef.trim() === '') {
            isValid = false;
            errorMsg = 'Route is not selected';
        }
        // Check Frequency is selected
        else if (!tripMetadata.frequencyType || tripMetadata.frequencyType.trim() === '') {
            isValid = false;
            errorMsg = 'Frequency Type is not selected';
        }
        // Check Frequencies added - in case of RECURRING
        else if (tripMetadata.frequencyType === FrequencyType.RECURRING 
            && (!tripMetadata.frequencies || tripMetadata.frequencies.length === 0)) {
            isValid = false;
            errorMsg = 'Frequencies are not added';
        }
        // Check Route Types
        else if (tripMetadata.routeTypes.length === 0) {
            isValid = false;
            errorMsg = 'Route types are not chosen';
        }

        // Convert time object to Date for validation
        const pickupStartTime = convertTimeObjToDate(tripMetadata.pickupStartTime);
        const pickupEndTime = convertTimeObjToDate(tripMetadata.pickupEndTime);
        const dropoffStartTime = convertTimeObjToDate(tripMetadata.dropoffStartTime);
        const dropoffEndTime = convertTimeObjToDate(tripMetadata.dropoffEndTime);

        // If Route Type Pickup is chosen then check its start & end time
        if (tripMetadata.routeTypes.indexOf(RouteType.PICKUP) > -1) {
            if (!tripMetadata.pickupStartTime) {
                isValid = false;
                errorMsg = 'Pickup Start Time is not given';
            } else if (!tripMetadata.pickupEndTime) {
                isValid = false;
                errorMsg = 'Pickup End Time is not given';
            }
            // Check End time is not less than Start time
            else if (pickupEndTime <= pickupStartTime) {
                isValid = false;
                errorMsg = 'Pickup End Time must be greater than Pickup Start Time';
            }
        }

        // If Route Type Dropoff is chosen then check its start & end time
        if (tripMetadata.routeTypes.indexOf(RouteType.DROPOFF) > -1) {
            if (!tripMetadata.dropoffStartTime) {
                isValid = false;
                errorMsg = 'Drop Off Start Time is not given';
            } else if (!tripMetadata.dropoffEndTime) {
                isValid = false;
                errorMsg = 'Drop Off End Time is not given';
            }
            // Check End time is not less than Start time
            else if (dropoffEndTime <= dropoffStartTime) {
                isValid = false;
                errorMsg = 'Dropoff End Time must be greater than Dropoff Start Time';
            }
        }

        if (!isValid) {
            logger.warn('Validation failed', { logTag, errorMsg });  // Log validation failure
            setErrorMsg(errorMsg);
            setIsSaving(false);
            return false;
        }

        // Send start and end time as per route type checked
        if (tripMetadata.routeTypes.indexOf(RouteType.PICKUP) === -1) {
            tripMetadata.pickupStartTime = null;
            tripMetadata.pickupEndTime = null;
        }
        if (tripMetadata.routeTypes.indexOf(RouteType.DROPOFF) === -1) {
            tripMetadata.dropoffStartTime = null;
            tripMetadata.dropoffEndTime = null;
        }

        // Get the client timezone
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const newTripMetadata = {
            ...tripMetadata,
            timezone: userTimezone
        }

        logger.info('Submitting trip metadata to server', { logTag, newTripMetadata });  // Log submit to server
        setIsSaving(true);
        saveOrUpdateTripMetadata(newTripMetadata).then(resp => {
            logger.info('Trip metadata saved successfully', { logTag });  // Log success
            setIsSaving(false);
            setShowSuccessAlert(true);
            setErrorMsg(null);
        }).catch(err => {
            logger.error('Error saving trip metadata', err, { logTag });  // Log error
            setIsSaving(false);
            setShowSuccessAlert(false);
            setErrorMsg(err);
        });
    }

    return (
        <>
            {errorMsg &&
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
            {showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"Trip metadata saved successfully!!"}
                    onClose={() => setShowSuccessAlert(false)} 
                />
            }

            <Box
                sx={{
                    display: "flex",
                    width: '100%',
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        New trip metadata
                    </Typography>
                    <Chip
                        onClick={() => {
                            logger.info('Navigating to trip metadata list', { logTag });  // Log navigation to trip metadata list
                            navigate(`../list`);
                        }}
                        icon={<FormatListBulletedIcon />}
                        label="Trip metadata"
                        variant="outlined"
                        color="secondary"
                    />
                </Box>
                <Grid container spacing={2} sx={{ mt: 3 }}>
                    {isLoading &&
                        <Grid container>
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={12} md={4}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <FormControl fullWidth>
                                <InputLabel id="route-select">Select route number/ref</InputLabel>
                                <Select
                                    labelId="route-select"
                                    id="route-select"
                                    label="Route"
                                    onChange={selectRoute}
                                    value={tripMetadata.routeRef}
                                >
                                    {routes.map((item, index) => (
                                        <MenuItem key={index} value={item.ref_no}>{item.number} ({item.ref_no})</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {tripMetadata.routeRef &&
                                <>
                                    <Button size="small"
                                        onClick={() => {
                                            logger.info('Viewing pickup route details', { logTag, routeRef: tripMetadata.routeRef });  // Log view pickup route
                                            setSelectedRouteType('PICKUP');
                                            setIsRouteDetailDialogOpen(true);
                                        }}
                                    >
                                        Pickup
                                    </Button>
                                    <Button size="small"
                                        onClick={() => {
                                            logger.info('Viewing dropoff route details', { logTag, routeRef: tripMetadata.routeRef });  // Log view dropoff route
                                            setSelectedRouteType('DROPOFF');
                                            setIsRouteDetailDialogOpen(true);
                                        }}
                                    >
                                        Dropoff
                                    </Button>
                                </>
                            }
                        </Box>

                        <FormControl fullWidth sx={{ mt: 3 }}>
                            <InputLabel id="route-select-label">Select frequency type</InputLabel>
                            <Select
                                labelId="route-select-label"
                                id="route-select"
                                label="Frequency Type"
                                onChange={selectFrequencyType}
                                value={tripMetadata.frequencyType}
                            >
                                {tripFrequencyTypes
                                    .filter(item => { 
                                        if (!tripMetadata.id) {
                                            return true;
                                        }
                                        return item.name === FrequencyType.RECURRING;
                                    }).map((item, index) => ( 
                                        <MenuItem value={item.name}>{convertFrequencyType(item.name)}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                
                {tripMetadata.frequencyType === 'RECURRING' &&
                    <Grid container spacing={2} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={2} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography component="h5" variant="h5">
                                Choose frequency
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            {tripFrequencies.map((item, index) => (
                                <FormControlLabel control={
                                    <Checkbox
                                        value={item.name}
                                        checked={tripMetadata.frequencies.indexOf(item.name) > -1 ? true : false}
                                        onChange={selectFrequency}
                                    />
                                } label={convertFrequency(item.name)} />
                            ))}  
                        </Grid> 
                    </Grid>
                }

                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12} md={2} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography component="h5" variant="h5">
                            Trip type
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        {[RouteType.PICKUP, RouteType.DROPOFF].map(type => (
                            <FormControlLabel control={
                                <Checkbox
                                    value={type}
                                    checked={tripMetadata.routeTypes.indexOf(type) > -1 ? true : false}
                                    onChange={selectRouteType}
                                />
                            } label={convertRouteType(type)} />
                        ))}
                    </Grid>
                </Grid>

                {tripMetadata.routeTypes.indexOf(RouteType.PICKUP) > -1 &&
                    <Paper sx={{ width: '40rem', padding: 2, mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
                                <Typography component="h5" variant="h5">
                                    Expected pickup start time
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TimeSelector
                                    name="pickupStartTime"
                                    values={tripMetadata.pickupStartTime}
                                    setFieldValue={(field, value) => {
                                        const updatedTripMetadata = {
                                            ...tripMetadata,
                                            pickupStartTime: {
                                                ...tripMetadata.pickupStartTime,
                                                [field]: value,
                                            },
                                        };
                                        setTripMetadata(updatedTripMetadata);
                                    }}
                                    validate={false}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
                                <Typography component="h5" variant="h5">
                                    Expected pickup end time
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TimeSelector
                                    name="pickupEndTime"
                                    values={tripMetadata.pickupEndTime}
                                    setFieldValue={(field, value) => {
                                        const updatedTripMetadata = {
                                            ...tripMetadata,
                                            pickupEndTime: {
                                                ...tripMetadata.pickupEndTime,
                                                [field]: value,
                                            },
                                        };
                                        setTripMetadata(updatedTripMetadata);
                                    }}
                                    validate={false}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                }

                {tripMetadata.routeTypes.indexOf(RouteType.DROPOFF) > -1 &&
                    <Paper sx={{ width: '40rem', padding: 2, mt: 2 }}>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
                                <Typography component="h5" variant="h5">
                                    Expected dropoff start time
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TimeSelector
                                    name="dropoffStartTime"
                                    values={tripMetadata.dropoffStartTime}
                                    setFieldValue={(field, value) => {
                                        const updatedTripMetadata = {
                                            ...tripMetadata,
                                            dropoffStartTime: {
                                                ...tripMetadata.dropoffStartTime,
                                                [field]: value,
                                            },
                                        };
                                        setTripMetadata(updatedTripMetadata);
                                    }}
                                    validate={false}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
                                <Typography component="h5" variant="h5">
                                    Expected dropoff end time
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TimeSelector
                                    name="dropoffEndTime"
                                    values={tripMetadata.dropoffEndTime}
                                    setFieldValue={(field, value) => {
                                        const updatedTripMetadata = {
                                            ...tripMetadata,
                                            dropoffEndTime: {
                                                ...tripMetadata.dropoffEndTime,
                                                [field]: value,
                                            },
                                        };
                                        setTripMetadata(updatedTripMetadata);
                                    }}
                                    validate={false}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                }
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {isSaving &&
                        <CircularProgress />
                    }
                    {!isSaving &&
                        <Button
                            type="button"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    }
                </Box>
            </Box>

            {/* Show route detail dialog */}
            <RouteDetailDialog
                open={isRouteDetailDialogOpen}
                onClose={() => setIsRouteDetailDialogOpen(false)}
                routeRef={tripMetadata.routeRef}
                routeType={selectedRouteType}
            />
        </>
    );
}

export default TripMetadataNew;



// import React, { Component, useState, useEffect } from 'react';
// import { Box, Typography, Grid, Link as MuiLink, Chip, CircularProgress, TextField,
//     Button, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Paper } from '@mui/material';
// import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { setHours, setMinutes } from 'date-fns';
// import { useParams, useNavigate } from "react-router-dom";
// import TimePicker from 'react-time-picker';

// import { getRoutesWithDistinctRef, getRouteByRefAndType, getTripFrequencyTypes, getTripFrequencies,
//     saveOrUpdateTripMetadata, getTripMetadata } 
//     from '../../../../services/http.service';
// import { convertFrequencyType, FrequencyType, convertFrequency, 
//     RouteType, convertRouteType, convertTimeObjToDate, convert24HourTo12Hour } from '../../../../utils/constants';
// import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
// import RouteDetailDialog from '../../../../common/route-detail-dialog';
// import TimeSelector from '../../../../common/time-selector';


// const TripMetadataNew = () => {

//     const [validated, setValidated] = useState(false);
//     const [routeLoading, setRouteLoading] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);
//     const [routes, setRoutes] = useState([]);
//     const [selectedRoute, setSelectedRoute] = useState({});
//     const [route, setRoute] = useState({});
//     const [tripFrequencyTypes, setTripFrequencyTypes] = useState([]);
//     const [tripFrequencies, setTripFrequencies] = useState([]);
//     const [tripMetadata, setTripMetadata] = useState({
//         id: null,
//         routeRef: '', // do not use null as it overlaps value in select dropdown
//         routeTypes: [], // pickup/dropoff
//         frequencyType: '', // Recurring/OneTime // do not use null as it overlaps value in select dropdown
//         frequencies: [], // Sunday, Monday .....
//         pickupStartTime: {
//             hours: '07',
//             minutes: '00',
//             period: 'AM',
//         },
//         pickupEndTime: {
//             hours: '08',
//             minutes: '00',
//             period: 'AM',
//         },
//         dropoffStartTime: {
//             hours: '02',
//             minutes: '00',
//             period: 'PM',
//         },
//         dropoffEndTime: {
//             hours: '03',
//             minutes: '00',
//             period: 'PM',
//         },
//         maxDate: null,
//     });
//     const [isSaving, setIsSaving] = useState(false);
//     const [errorMsg, setErrorMsg] = useState(null);
//     const [showSuccessAlert, setShowSuccessAlert] = useState(false);
//     const [trip, setTrip] = useState({});
//     const [selectedRouteType, setSelectedRouteType] = useState(null);
//     const [isRouteDetailDialogOpen, setIsRouteDetailDialogOpen] = useState(false);

//     const { tripMetaDataId } = useParams();
//     const navigate = useNavigate();
	
// 	useEffect(() => {
//         fetchRoutes();
//         fetchTripFrequencyTypes();
//         fetchTripFrequencies();
//         if (tripMetaDataId) {
//             fetchTripMetadata(tripMetaDataId);
//         }
//     }, []);

//     // Fetch Trip Metadata for Edit
//     const fetchTripMetadata = (id) => {        
//         setIsLoading(true);
//         getTripMetadata(id).then(resp => {
//             setIsLoading(false);
//             const respMetadata = resp.data.data;
//             const newTripMetadata = {
//                 id: respMetadata.id,
//                 routeRef: respMetadata.route_ref,
//                 routeTypes: respMetadata.route_types,
//                 frequencyType: respMetadata.frequency_type,
//                 frequencies: respMetadata.frequencies, 
//                 pickupStartTime: respMetadata.pickup_start_time ? convert24HourTo12Hour(respMetadata.pickup_start_time) : tripMetadata.pickupStartTime,
//                 pickupEndTime: respMetadata.pickup_end_time ? convert24HourTo12Hour(respMetadata.pickup_end_time) : tripMetadata.pickupEndTime,
//                 dropoffStartTime: respMetadata.dropoff_start_time ? convert24HourTo12Hour(respMetadata.dropoff_start_time) : tripMetadata.dropoffStartTime,
//                 dropoffEndTime: respMetadata.dropoff_end_time ? convert24HourTo12Hour(respMetadata.dropoff_end_time) : tripMetadata.dropoffEndTime,
                
                
//                 // pickupStartTime: respMetadata.pickup_start_time ? new Date(respMetadata.pickup_start_time) : new Date(),
//                 // pickupEndTime: respMetadata.pickup_end_time ? new Date(respMetadata.pickup_end_time) : new Date(),
//                 // dropoffStartTime: respMetadata.dropoff_start_time ? new Date(respMetadata.dropoff_start_time) : new Date(),
//                 // dropoffEndTime: respMetadata.dropoff_end_time ? new Date(respMetadata.dropoff_end_time) : new Date()
//             }

//             setTripMetadata(newTripMetadata);

//             console.log(`trip metadata after server - ${JSON.stringify(tripMetadata)}`);
//         }).catch(err => {
//             setIsLoading(false);
//         })
//     }

//     // Fetch Routes with only Ref and type
//     const fetchRoutes = () => {
//         getRoutesWithDistinctRef().then(resp => {
//             const respRoutes = resp.data.data;
//             const routes = respRoutes;
//             setRoutes(routes);            
//         }).catch(err => {
//             console.log(err);
//         })
//     }

//     // Select route from dropdown box
//     const selectRoute = (e) => {
//         const value = e.target.value;
//         console.log("selected route - ", value);
//         if (value && value.trim() !== '') {
//             setTripMetadata({
//                 ...tripMetadata,
//                 routeRef: value,
//             });
//             setSelectedRoute(value);

//             console.log(`selected route - ${JSON.stringify(value)}`);
//             //this.fetchRoute(selectedRoute);
//         }
//         else {
//             setTripMetadata({
//                 ...tripMetadata,
//                 routeRef: null,
//             });
//         }
//     }

//     // Fetch Route
//     const fetchRoute = (selectedRoute) => {
//         getRouteByRefAndType(selectedRoute.ref_no, selectedRoute.type.name).then(resp => {
//             const respRoute = resp.data.data;
//             setTrip({
//                 ...trip,
//                 route: {
//                     id: respRoute.id,
//                     type: respRoute.type.name,
//                     refNo: respRoute.ref_no
//                 }
//             });

//         }).catch(err => {
//             console.log(err);
//         })
//     }

//     // Build trip
//     // buildTrip = () => {
//     //     this.fetchRoute(this.state.selectedRoute);
//     // }

//     // Fetch Trip Frequency types
//     const fetchTripFrequencyTypes = () => {
//         getTripFrequencyTypes().then(resp => {
//             const frequencyTypes = resp.data.data;
//             setTripFrequencyTypes(frequencyTypes);            
//         }).catch(err => {
//             console.log(err);
//         })
//     }

//     // Fetch Trip Frequencies
//     const fetchTripFrequencies = () => {
//         getTripFrequencies().then(resp => {
//             const frequencies = resp.data.data;
//             setTripFrequencies(frequencies);            
//         }).catch(err => {
//             console.log(err);
//         })
//     }

//     // Select trip frequency type
//     const selectFrequencyType = (e) => {
//         const value = e.target.value;
//         console.log(`Selected Frequency Type - ${value}`);
//         if (!value || value.trim() === '') {
//             tripMetadata.frequencyType = null;
//             setTripMetadata({...tripMetadata});
//             return;
//         }  
//         tripMetadata.frequencyType = value;

//         // Set max date in case of Recurring frequency type
//         let maxDate = null;
//         console.log(`${value} === ${FrequencyType.RECURRING}`);
//         if (value === FrequencyType.RECURRING) {
//             console.log(`setting maxdate`);
//             maxDate = new Date();
//         }
//         tripMetadata.maxDate = maxDate;

//         setTripMetadata({...tripMetadata}); // spread to update ui

//         console.log(`Trip meta data - ${JSON.stringify(tripMetadata)}`);
//     }

//     // Select trip frequency
//     const selectFrequency = (e) => {
//         const value = e.target.value;
//         if (!value || value === "") {
//             return;
//         }
//         console.log(`selected frequency - ${value}`);      
//         // const tripMetadata = this.state.tripMetadata;
//         if (e.target.checked) {
//             // Add item
//             tripMetadata.frequencies.push(value);
//         } else { // for uncheck
//             tripMetadata.frequencies = tripMetadata.frequencies.filter(item => item !== value);          
//         }
//         setTripMetadata({...tripMetadata}); // spread to take effect on ui
//         console.log(`Trip meta data - ${JSON.stringify(tripMetadata)}`);
//     }

//     // Select route type
//     const selectRouteType = (e) => {
//         const value = e.target.value;
//         if (!value || value.trim() === '') {
//             return;
//         }
//         console.log(`selected route type - ${value}`);      
//         // const tripMetadata = this.state.tripMetadata;
//         if (e.target.checked) {
//             // Add item
//             tripMetadata.routeTypes.push(value);
//         } else { // for uncheck
//             tripMetadata.routeTypes = tripMetadata.routeTypes.filter(item => item !== value);          
//         }
//         setTripMetadata({...tripMetadata});
//         console.log(`Trip meta data - ${JSON.stringify(tripMetadata)}`);
//     }
    


//     // Submit to server
//     const handleSubmit = () => {
//         // Validate data before submit
//         setIsSaving(true);
//         setErrorMsg(null);
//         setShowSuccessAlert(false);

//         let isValid = true;
//         let errorMsg = null;

//         // Check if route is selected
//         //console.log(`tripMetadata.routeRef - ${tripMetadata.routeRef}`);
//         if (!tripMetadata.routeRef || tripMetadata.routeRef.trim() === '') {
//             isValid = false;
//             errorMsg = 'Route is not selected';
//         }
//         // Check Frequuncy is selected
//         else if (!tripMetadata.frequencyType || tripMetadata.frequencyType.trim() === '') {
//             isValid = false;
//             errorMsg = 'Frequency Type is not selected';
//         }
//         // Check Frequencies added - in case of RECURRING
//         else if (tripMetadata.frequencyType === FrequencyType.RECURRING 
//                     &&  (!tripMetadata.frequencies || tripMetadata.frequencies.length === 0)) {
//             isValid = false;
//             errorMsg = 'Frequencies are not added';
//         }
//         // Check Route Types
//         else if (tripMetadata.routeTypes.length === 0) {
//             isValid = false;
//             errorMsg = 'Route types are not choosen';
//         }

//         // Conver time object to Date for validation
//         const pickupStartTime = convertTimeObjToDate(tripMetadata.pickupStartTime);
//         const pickupEndTime = convertTimeObjToDate(tripMetadata.pickupEndTime);
//         const dropoffStartTime = convertTimeObjToDate(tripMetadata.dropoffStartTime);
//         const dropoffEndTime = convertTimeObjToDate(tripMetadata.dropoffEndTime);

//         console.log("pickupStartTime ", pickupStartTime);
//         console.log("pickupEndTime ", pickupEndTime);
//         console.log("dropoffStartTime ", dropoffStartTime);
//         console.log("dropoffEndTime ", dropoffEndTime);

//         // If Route Type Pickup is choose then check its start & end time
//         if (tripMetadata.routeTypes.indexOf(RouteType.PICKUP) > -1) {
//             if (!tripMetadata.pickupStartTime) {
//                 isValid = false;
//                 errorMsg = 'Pickup Start Time is not given';
//             }
//             else if (!tripMetadata.pickupEndTime) {
//                 isValid = false;
//                 errorMsg = 'Pickup End Time is not given';
//             }
//             // Check End time is not less than Start time
//             else if (pickupEndTime <= pickupStartTime) {
//                 isValid = false;
//                 errorMsg = 'Pickup End Time must be greater than Pickup Start Time';
//             }
//         }
//         // If Route Type Dropoff is choose then check its start & end time
//         if (tripMetadata.routeTypes.indexOf(RouteType.DROPOFF) > -1) {
//             if (!tripMetadata.dropoffStartTime) {
//                 isValid = false;
//                 errorMsg = 'Drop Off Start Time is not given';
//             }
//             else if (!tripMetadata.dropoffEndTime) {
//                 isValid = false;
//                 errorMsg = 'Drop Off End Time is not given';
//             }
//             // Check End time is not less than Start time
//             else if (dropoffEndTime <= dropoffStartTime) {
//                 isValid = false;
//                 errorMsg = 'Dropoff End Time must be greater than Dropoff Start Time';
//             }
//         }


//         if (!isValid) {
//             setErrorMsg(errorMsg);
//             setIsSaving(false);
//             return false;
//         }

//         // Send start and end time as per route type checked
//         if (tripMetadata.routeTypes.indexOf(RouteType.PICKUP) === -1) {
//             tripMetadata.pickupStartTime = null;
//             tripMetadata.pickupEndTime = null;
//         }
//         if (tripMetadata.routeTypes.indexOf(RouteType.DROPOFF) === -1) {
//             tripMetadata.dropoffStartTime = null;
//             tripMetadata.dropoffEndTime = null;
//         }

//         // Get the client timezone
//         const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//         console.log("userTimezone ", userTimezone);
//         const newTripMetadata = {
//             ...tripMetadata,
//             timezone: userTimezone
//         }

//         console.log(`Trip meta data - ${JSON.stringify(newTripMetadata)}`);
//         setIsSaving(true);
//         saveOrUpdateTripMetadata(newTripMetadata).then(resp => {
//             setIsSaving(false);
//             setShowSuccessAlert(true);
//             setErrorMsg(null);
//         }).catch(err => {
//             console.log(err);
//             setIsSaving(false);
//             setShowSuccessAlert(false);
//             setErrorMsg(err);
//         })

//     }

//     return (
//         <>
//             { errorMsg &&
//                 <ErrorAlertTopCenter 
//                     errorMsg={errorMsg}
//                     onClose={() => setErrorMsg(null)} 
//                 />
//             }
//             { showSuccessAlert &&
//                 <SuccessAlertTopRight
//                     successMsg={"Trip metadata saved successfully!!"}
//                     onClose={() => setShowSuccessAlert(false)} 
//                 />
//             }

//             <Box
//                 sx={{
//                     display: "flex",
//                     width: '100%',
//                     flexDirection: "column",
//                     justifyContent: "left",
//                     alignItems: "start",
//                 }}
//             >
//                 <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//                     <Typography component="h2" variant="header">
//                         New trip metadata
//                     </Typography>
//                     <Chip
//                         onClick={() => navigate(`../list`)}
//                         icon={<FormatListBulletedIcon />}
//                         label="Trip metadata"
//                         variant="outlined"
//                         color="secondary"
//                     />
//                 </Box>
//                 <Grid container spacing={2} sx={{ mt: 3 }}>
//                     {isLoading &&
//                         <Grid container>
//                             <Grid item>
//                                 <CircularProgress />
//                             </Grid>
//                         </Grid>
//                     }
//                     <Grid item xs={12} md={4}>
//                         <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
//                             <FormControl fullWidth>
//                                 <InputLabel id="route-select">Select route number/ref</InputLabel>
//                                 <Select
//                                     labelId="route-select"
//                                     id="route-select"
//                                     label="Route"
//                                     onChange={selectRoute}
//                                     value={tripMetadata.routeRef}
//                                 >
//                                     {routes.map((item, index) => (
//                                         <MenuItem key={index} value={item.ref_no}>{item.number} ({item.ref_no})</MenuItem>
//                                     ))}
//                                 </Select>
//                             </FormControl>
//                             { tripMetadata.routeRef &&
//                                 <>
//                                     <Button size="small"
//                                         onClick={() => {
//                                             setSelectedRouteType('PICKUP');
//                                             setIsRouteDetailDialogOpen(true);
//                                         }}
//                                     >
//                                         Pickup
//                                     </Button>
//                                     <Button size="small"
//                                         onClick={() => {
//                                             setSelectedRouteType('DROPOFF');
//                                             setIsRouteDetailDialogOpen(true);
//                                         }}
//                                     >
//                                         Dropoff
//                                     </Button>
//                                 </>
//                             }
//                         </Box>

//                         <FormControl fullWidth sx={{ mt: 3 }}>
//                             <InputLabel id="route-select-label">Select frequency type</InputLabel>
//                             <Select
//                                 labelId="route-select-label"
//                                 id="route-select"
//                                 label="Frequency Type"
//                                 onChange={selectFrequencyType}
//                                 value={tripMetadata.frequencyType}
//                             >
//                                 {/* For edit remove OneTime type */}
//                                 {tripFrequencyTypes
//                                     .filter(item => { 
//                                         // If its not edit then return true - render all the frequency types
//                                         if (!tripMetadata.id) {
//                                             return true;
//                                         }
//                                         // If its for Edit then only allow RECURRING to render
//                                         return item.name === FrequencyType.RECURRING;
//                                     }).map((item, index) => ( 
//                                         <MenuItem value={item.name}>{convertFrequencyType(item.name)}</MenuItem>
//                                 ))}
//                             </Select>
//                         </FormControl>
//                     </Grid>

//                 </Grid>
                
//                 {tripMetadata.frequencyType === 'RECURRING' &&
//                     <Grid container spacing={2} sx={{ mt: 3 }}>
//                         <Grid item xs={12} md={2} sx={{ display: "flex", alignItems: "center" }}>
//                             <Typography component="h5" variant="h5">
//                                 Choose frequency
//                             </Typography>
//                         </Grid>
//                         <Grid item xs={12} md={10}>
//                             {tripFrequencies.map((item, index) => (
//                                 <FormControlLabel control={
//                                     <Checkbox
//                                         value={item.name}
//                                         checked={tripMetadata.frequencies.indexOf(item.name) > -1 ? true:false}
//                                         onChange={selectFrequency}
//                                     />
//                                 } label={convertFrequency(item.name)} />
                                
//                             ))}  
//                         </Grid> 
//                     </Grid>
//                 }

//                 <Grid container spacing={2} sx={{ mt: 3 }}>
//                     <Grid item xs={12} md={2} sx={{ display: "flex", alignItems: "center" }}>
//                         <Typography component="h5" variant="h5">
//                             Trip type
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={12} md={10}>
//                         {[RouteType.PICKUP, RouteType.DROPOFF].map(type => (
//                             <FormControlLabel control={
//                                 <Checkbox
//                                     value={type}
//                                     checked={tripMetadata.routeTypes.indexOf(type) > -1 ? true : false}
//                                     onChange={selectRouteType}
//                                 />
//                             } label={convertRouteType(type)} />
//                         ))}
//                     </Grid>
//                 </Grid>

//                 {tripMetadata.routeTypes.indexOf(RouteType.PICKUP) > -1 &&
//                     <Paper sx={{ width: '40rem', padding: 2, mt: 2 }}>
//                         <Grid container spacing={2}>
//                             <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
//                                 <Typography component="h5" variant="h5">
//                                     Expected pickup start time
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={12} md={6}>
//                                 <TimeSelector
//                                     name="pickupStartTime"
//                                     values={tripMetadata.pickupStartTime}
//                                     setFieldValue={(field, value) => {
//                                         console.log(field);
//                                         console.log(value);
//                                         const updatedTripMetadata = {
//                                             ...tripMetadata,
//                                             pickupStartTime: {
//                                                 ...tripMetadata.pickupStartTime,
//                                                 [field]: value,
//                                             },
//                                         };
//                                         console.log("updatedTripMetadata ", updatedTripMetadata);
//                                         setTripMetadata(updatedTripMetadata);

//                                     }}
//                                     validate={false} // Pass conditionally
//                                 />
//                                 {/* <DateTimePicker
//                                     label="Expected pickup start time"
//                                     value={tripMetadata.pickupStartTime}
//                                     onChange={(date) => {
//                                         console.log(date);
//                                         setTripMetadata({
//                                             ...tripMetadata,
//                                             pickupStartTime: date,
//                                         });
//                                     }}
//                                     minDate={new Date()}
//                                     maxDate={tripMetadata.maxDate}
//                                     inputFormat="MMMM d, yyyy h:mm aa"
//                                     renderInput={(params) => <TextField sx={{ width: '16rem' }} {...params} />}
//                                 /> */}
//                             </Grid>
//                         </Grid>
//                         <Grid container spacing={2} sx={{ mt: 1 }}>
//                             <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
//                                 <Typography component="h5" variant="h5">
//                                     Expected pickup end time
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={12} md={6}>
//                                 <TimeSelector
//                                     name="pickupEndTime"
//                                     values={tripMetadata.pickupEndTime}
//                                     setFieldValue={(field, value) => {
//                                         console.log(field);
//                                         console.log(value);
//                                         const updatedTripMetadata = {
//                                             ...tripMetadata,
//                                             pickupEndTime: {
//                                                 ...tripMetadata.pickupEndTime,
//                                                 [field]: value,
//                                             },
//                                         };
//                                         console.log("updatedTripMetadata ", updatedTripMetadata);
//                                         setTripMetadata(updatedTripMetadata);

//                                     }}
//                                     validate={false} // Pass conditionally
//                                 />
//                                 {/* <DateTimePicker
//                                     label="Expected pickup end time"
//                                     value={tripMetadata.pickupEndTime}
//                                     onChange={(date) => {
//                                         console.log(date);
//                                         setTripMetadata({
//                                             ...tripMetadata,
//                                             pickupEndTime: date,
//                                         });
//                                     }}
//                                     minDate={new Date()}
//                                     maxDate={tripMetadata.maxDate}
//                                     inputFormat="MMMM d, yyyy h:mm aa"
//                                     renderInput={(params) => <TextField sx={{ width: '16rem' }} {...params} />}
//                                 /> */}
//                             </Grid>
//                         </Grid>
//                     </Paper>
//                 }

//                 {tripMetadata.routeTypes.indexOf(RouteType.DROPOFF) > -1 &&
//                     <Paper sx={{ width: '40rem', padding: 2, mt: 2 }}>
//                         <Grid container spacing={2} sx={{ mt: 1 }}>
//                             <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
//                                 <Typography component="h5" variant="h5">
//                                     Expected dropoff start time
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={12} md={6}>
//                                 <TimeSelector
//                                     name="dropoffStartTime"
//                                     values={tripMetadata.dropoffStartTime}
//                                     setFieldValue={(field, value) => {
//                                         console.log(field);
//                                         console.log(value);
//                                         const updatedTripMetadata = {
//                                             ...tripMetadata,
//                                             dropoffStartTime: {
//                                                 ...tripMetadata.dropoffStartTime,
//                                                 [field]: value,
//                                             },
//                                         };
//                                         console.log("updatedTripMetadata ", updatedTripMetadata);
//                                         setTripMetadata(updatedTripMetadata);

//                                     }}
//                                     validate={false} // Pass conditionally
//                                 />
//                                 {/* <DateTimePicker
//                                     label="Expected dropoff start time"
//                                     value={tripMetadata.dropoffStartTime}
//                                     onChange={(date) => {
//                                         console.log(date);
//                                         setTripMetadata({
//                                             ...tripMetadata,
//                                             dropoffStartTime: date,
//                                         });
//                                     }}
//                                     minDate={new Date()}
//                                     maxDate={tripMetadata.maxDate}
//                                     inputFormat="MMMM d, yyyy h:mm aa"
//                                     renderInput={(params) => <TextField sx={{ width: '16rem' }} {...params} />}
//                                 /> */}
//                             </Grid>
//                         </Grid>
//                         <Grid container spacing={2} sx={{ mt: 1 }}>
//                             <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
//                                 <Typography component="h5" variant="h5">
//                                     Expected dropoff end time
//                                 </Typography>
//                             </Grid>
//                             <Grid item xs={12} md={6}>
//                                 <TimeSelector
//                                     name="dropoffEndTime"
//                                     values={tripMetadata.dropoffEndTime}
//                                     setFieldValue={(field, value) => {
//                                         console.log(field);
//                                         console.log(value);
//                                         const updatedTripMetadata = {
//                                             ...tripMetadata,
//                                             dropoffEndTime: {
//                                                 ...tripMetadata.dropoffEndTime,
//                                                 [field]: value,
//                                             },
//                                         };
//                                         console.log("updatedTripMetadata ", updatedTripMetadata);
//                                         setTripMetadata(updatedTripMetadata);

//                                     }}
//                                     validate={false} // Pass conditionally
//                                 />
//                                 {/* <DateTimePicker
//                                     label="Expected dropoff end time"
//                                     value={tripMetadata.dropoffEndTime}
//                                     onChange={(date) => {
//                                         console.log(date);
//                                         setTripMetadata({
//                                             ...tripMetadata,
//                                             dropoffEndTime: date,
//                                         });
//                                     }}
//                                     minDate={new Date()}
//                                     maxDate={tripMetadata.maxDate}
//                                     inputFormat="MMMM d, yyyy h:mm aa"
//                                     renderInput={(params) => <TextField sx={{ width: '16rem' }} {...params} />}
//                                 /> */}
//                             </Grid>
//                         </Grid>
//                     </Paper>
//                 }
//                 <Box
//                     sx={{
//                         width: '100%',
//                         display: 'flex',
//                         justifyContent: 'center',
//                     }}
//                 >
//                     {isSaving &&
//                         <CircularProgress />
//                     }
//                     {!isSaving &&
//                         <Button
//                             type="button"
//                             variant="contained"
//                             sx={{ mt: 3, mb: 2 }}
//                             onClick={handleSubmit}
//                         >
//                             Submit
//                         </Button>
//                     }
//                 </Box>
//             </Box>

//             {/* Show route detail dialog */}
//             <RouteDetailDialog
//                 open={isRouteDetailDialogOpen}
//                 onClose={() => setIsRouteDetailDialogOpen(false)}
//                 routeRef={tripMetadata.routeRef}
//                 routeType={selectedRouteType}
//             />

//         </>
//     );
// }

// export default TripMetadataNew;

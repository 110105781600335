import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, Chip, Paper, Grid,
    TextField, Autocomplete, CircularProgress, List, ListItem, ListItemText, ListItemAvatar, Avatar,
    IconButton, Divider } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { saveOrUpdateRouteNumber, deleteRouteNumber } from '../../../../services/http.service';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import { CustomModal } from '../../../../common/modals';
import { ModalType } from '../../../../utils/constants';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'AddRouteNumber';  // Define logTag for this component

const AddRouteNumber = ({ route, onNext, onBack, onSubmit }) => {

    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });

    useEffect(() => {
        logger.info('AddRouteNumber component mounted', { logTag, routeId: route.id });  // Log component mount

        const formValues = {};
        if (route.number) {
            formValues.number = route.number;
        } else {
            formValues.number = '';
        }
        formik.setValues(formValues);
    }, [route]);

    // Formik handle submit
    const validationSchema = Yup.object({
        number: Yup.string().required("Route number is required."),
    });
    const formik = useFormik({
        initialValues: {
            number: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            logger.info('Submitting route number', { logTag, routeId: route.id, values });  // Log submit
            if (!values || values.number.trim().length === 0) {
                return;
            }
            setIsSaving(true);
            setErrorMsg(null);
            setShowSuccessAlert(false);
    
            const data = {
                id: route.id,
                type: route.type,
                number: values.number
            }
            saveOrUpdateRouteNumber(data).then(resp => { 
                setIsSaving(false);
                setErrorMsg(null);
                setShowSuccessAlert(true);
                const respData = resp.data.data;
                if (respData) {
                    const newRoute = {
                        ...route,
                        number: values,
                        id: respData.route_id, // set back id to update for the same in next section
                    }
                    logger.info('Route number saved successfully', { logTag, newRoute });  // Log success
                    onSubmit(newRoute);
                }
            }).catch(err => {
                logger.error('Error saving route number', err, { logTag, routeId: route.id });  // Log error
                setIsSaving(false);
                setErrorMsg(err);
                setShowSuccessAlert(false);
            });
        }   
    });

    // Delete number from route
    const deleteNumber = (id) => {
        logger.info('Delete route number requested', { logTag, routeId: id });  // Log delete request
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });
    }

    // On Delete of Route bus
    const onDelete = (data) => {
        logger.info('Confirm delete route number', { logTag, routeId: data.id });  // Log confirm delete
        setDeleteJson({
            ...deleteJson,
            show: false,
        });
        setIsSaving(true);
        deleteRouteNumber(data.id).then(resp => {
            setIsSaving(false);
            logger.info('Route number deleted successfully', { logTag, routeId: data.id });  // Log success
            onSubmit(route);
        }).catch(err => {
            logger.error('Error deleting route number', err, { logTag, routeId: data.id });  // Log error
            setIsSaving(false);          
        });
    }

    return (
        <>
            {errorMsg &&
                <ErrorAlertTopCenter
                    errorMsg={errorMsg}
                    onClose={() => {
                        logger.info('Error alert closed', { logTag });  // Log alert close
                        setErrorMsg(null);
                    }}
                />
            }
            {showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"Route number saved successfully!!"}
                    onClose={() => {
                        logger.info('Success alert closed', { logTag });  // Log success alert close
                        setShowSuccessAlert(false);
                    }}
                />
            }

            
            <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    name="number"
                    label="Route number"
                    value={formik.values.number}
                    onChange={formik.handleChange}
                    error={formik.touched.number && Boolean(formik.errors.number)}
                    helperText={formik.touched.number && formik.errors.number}
                />

                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center'
                    }}
                >
                    {isSaving &&
                        <CircularProgress />
                    }
                    {!isSaving &&
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ my: 2 }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mx: 2 }}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{ mx: 2 }}
                                    startIcon={<ArrowBackIosNewIcon />}
                                    onClick={() => {
                                        logger.info('Back button clicked', { logTag, routeId: route.id });  // Log back button click
                                        onBack();
                                    }}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{ mx: 2 }}
                                    startIcon={<ArrowForwardIosIcon />}
                                    onClick={() => {
                                        logger.info('Next button clicked', { logTag, routeId: route.id });  // Log next button click
                                        onNext();
                                    }}
                                >
                                    Next
                                </Button>
                            </Box>
                            {route.number &&
                                <Box sx={{ my: 1 }}>
                                    <Button 
                                        size="small" 
                                        color="error"
                                        onClick={() => {
                                            logger.info('Remove route number button clicked', { logTag, routeId: route.id });  // Log remove number
                                            deleteNumber(route.id);
                                        }}>
                                        Remove Number
                                    </Button>
                                </Box>
                            }
                        </Box>
                    }
                </Box>
            </Box>

            {/* For delete confirmation */}
            {deleteJson.show &&
                <CustomModal 
                    show={deleteJson.show}
                    data={deleteJson.data}
                    type={ModalType.CONFIRM_DELETE}
                    onOkay={(data) => {
                        logger.info('Delete route number confirmed', { logTag, routeId: deleteJson.data.id });  // Log delete confirmation
                        onDelete(deleteJson.data);
                        setDeleteJson({
                            ...deleteJson,
                            show: false,
                        });
                    }}
                    onHide={() => {
                        logger.info('Delete modal closed', { logTag });  // Log delete modal close
                        setDeleteJson({
                            ...deleteJson,
                            show: false,
                        });
                    }}
                >
                </CustomModal>
            }
        </>
    );

}

export default AddRouteNumber;


// import React, { useState, useEffect, useRef } from 'react';
// import { Box, Button, Typography, Chip, Paper, Grid,
//     TextField, Autocomplete, CircularProgress, List, ListItem, ListItemText, ListItemAvatar, Avatar,
//     IconButton, Divider } from '@mui/material';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import { saveOrUpdateRouteNumber, deleteRouteNumber } from '../../../../services/http.service';
// import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
// import { CustomModal } from '../../../../common/modals';
// import { ModalType } from '../../../../utils/constants';

// const AddRouteNumber = ({ route, onNext, onBack, onSubmit }) => {

//     const [isSaving, setIsSaving] = useState(false);
//     const [errorMsg, setErrorMsg] = useState(null);
//     const [showSuccessAlert, setShowSuccessAlert] = useState(false);
//     const [deleteJson, setDeleteJson] = useState({
//         show: false,
//         data: {}
//     });

//     useEffect(() => {
//         const formValues = {};
//         if (route.number) {
//             formValues.number = route.number;
//         } else {
//             formValues.number = '';
//         }
//         formik.setValues(formValues);
//     }, [route]);

//      // Formik handle submit
//     const validationSchema = Yup.object({
//         number: Yup.string().required("Route number is required."),
//     });
//     const formik = useFormik({
//         initialValues: {
//             number: '',
//         },
//         validationSchema: validationSchema,
//         onSubmit: (values) => {
//             console.log(values);
//             if (!values || values.number.trim().length === 0) {
//                 return;
//             }
//             setIsSaving(true);
//             setErrorMsg(null);
//             setShowSuccessAlert(false);
    
//             const data = {
//                 id: route.id,
//                 type: route.type,
//                 number: values.number
//             }
//             saveOrUpdateRouteNumber(data).then(resp => { 
//                 setIsSaving(false);
//                 setErrorMsg(null);
//                 setShowSuccessAlert(true);
//                 const respData = resp.data.data;
//                 if (respData) {
//                     const newRoute = {
//                         ...route,
//                         number: values,
//                         id: respData.route_id, // set back id to update for the same in next section
//                     }
//                     onSubmit(newRoute);
//                 }
//             }).catch(err => {
//                 console.log(err);
//                 setIsSaving(false);
//                 setErrorMsg(err);
//                 setShowSuccessAlert(false);
//             });
//         }   
//     });

//     // Delete number from route
//     const deleteNumber = (id) => {
//         setDeleteJson({
//             ...deleteJson,
//             show: true,
//             data: {
//                 id: id
//             }
//         });
//     }
//     // On Delete of Route bus
//     const onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));
//         // Hide delete confirm modal
//         setDeleteJson({
//             ...deleteJson,
//             show: false,
//         });
//         // Show spinner
//         setIsSaving(true);
//         deleteRouteNumber(data.id).then(resp => {
//             setIsSaving(false);
//             onSubmit(route);
//         }).catch(err => {
//             console.log(`Error in deleting number of route :: ${JSON.stringify(err)}`);
//             setIsSaving(false);          
//         })
//     }

//     return (
//         <>
//             {errorMsg &&
//                 <ErrorAlertTopCenter
//                     errorMsg={errorMsg}
//                     onClose={() => setErrorMsg(null)}
//                 />
//             }
//             {showSuccessAlert &&
//                 <SuccessAlertTopRight
//                     successMsg={"Route number saved successfully!!"}
//                     onClose={() => setShowSuccessAlert(false)}
//                 />
//             }

            
//             <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
//                 <TextField
//                     margin="normal"
//                     required
//                     name="number"
//                     label="Route number"
//                     value={formik.values.number}
//                     onChange={formik.handleChange}
//                     error={formik.touched.number && Boolean(formik.errors.number)}
//                     helperText={formik.touched.number && formik.errors.number}
//                 />

//                 <Box
//                     sx={{
//                         mt: 2,
//                         display: 'flex',
//                         justifyContent: 'start',
//                         alignItems: 'center'
//                     }}
//                 >
//                     {isSaving &&
//                         <CircularProgress />
//                     }
//                     {!isSaving &&
//                         <Box sx={{ display: 'flex', flexDirection: 'column' }}>
//                             <Box sx={{ my: 2 }}>
//                                 <Button
//                                     type="submit"
//                                     variant="contained"
//                                     sx={{ mx: 2 }}
//                                 >
//                                     Save
//                                 </Button>
//                                 <Button
//                                     variant="outlined"
//                                     sx={{ mx: 2 }}
//                                     startIcon={<ArrowBackIosNewIcon />}
//                                     onClick={onBack}
//                                 >
//                                     Back
//                                 </Button>
//                                 <Button
//                                     variant="outlined"
//                                     sx={{ mx: 2 }}
//                                     startIcon={<ArrowForwardIosIcon />}
//                                     onClick={onNext}
//                                 >
//                                     Next
//                                 </Button>
//                             </Box>
//                             { route.number &&
//                                 <Box sx={{ my: 1 }}>
//                                     <Button 
//                                         size="small" 
//                                         color="error"
//                                         onClick={() => deleteNumber(route.id)}>
//                                         Remove Number
//                                     </Button>
//                                 </Box>
//                             }
//                         </Box>
//                     }
//                 </Box>
//             </Box>
//             {/* For delete confirmation */}
//             {deleteJson.show &&
//                 <CustomModal 
//                     show={deleteJson.show}
//                     data={deleteJson.data}
//                     type={ModalType.CONFIRM_DELETE}
//                     onOkay={(data) => {
//                         onDelete(deleteJson.data);
//                         setDeleteJson({
//                             ...deleteJson,
//                             show: false,
//                         });
//                     }}
//                     onHide={() => {
//                         setDeleteJson({
//                             ...deleteJson,
//                             show: false,
//                         });
//                     }}
//                 >
//                 </CustomModal>
//             }
//         </>
//     );

// }

// export default AddRouteNumber;
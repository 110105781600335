import React from 'react';
import { Outlet, Link } from "react-router-dom";

const Section = () => {

    return (
        <>
            <Outlet />
        </>
    );
}

export default Section;
import React, { Component, useState, useEffect } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
    Button, Chip, Paper, Grid, TableBody, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";

import { getTripMetadataList, deleteTripMetadata, disableEnableTripMetadata } from '../../../../services/http.service';
import { CustomModal } from '../../../../common/modals';
import { TripStatus } from '../../../../utils/constants';
import { ModalType } from '../../../../utils/constants';
import Loader from '../../../../common/loader';
import RouteDetailDialog from '../../../../common/route-detail-dialog';
import { VertActionMenu } from '../../../../common/action-menu';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'TripMetadataList';  // Define logTag for this component

const TripMetadataList = () => {

    const [tripMetadataList, setTripMetadataList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });
    const [disableJson, setDisableJson] = useState({
        show: false,
        data: {}
    });
    const [selectedRouteRef, setSelectedRouteRef] = useState(null);
    const [selectedRouteType, setSelectedRouteType] = useState(null);
    const [isRouteDetailDialogOpen, setIsRouteDetailDialogOpen] = useState(false);

    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();

    useEffect(() => {
        logger.info('TripMetadataList component mounted', { logTag });  // Log component mount
        fetchTripMetadataList();
    }, []);

    // Fetch Trip metadata list from server
    const fetchTripMetadataList = () => {
        logger.info('Fetching trip metadata list', { logTag });  // Log fetching trip metadata
        setIsLoading(true);
        getTripMetadataList().then(resp => {
            setIsLoading(false);
            setTripMetadataList(resp.data.data);
            logger.info('Trip metadata list fetched successfully', { logTag, tripMetadataList: resp.data.data });  // Log success
        }).catch(err => {
            logger.error('Error fetching trip metadata list', err, { logTag });  // Log error
            setIsLoading(false);
        });
    }

    // Handle Delete modal   
    const handleDelete = (id) => {
        logger.info('Delete trip metadata requested', { logTag, tripMetadataId: id });  // Log delete request
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });
        console.log(`delete - ${JSON.stringify(deleteJson)}`);
    }

    // On Delete of Trip Metadata
    const onDelete = (data) => {
        logger.info('Confirm delete trip metadata', { logTag, tripMetadataId: data.id });  // Log confirm delete
        console.log('on delete data ' + JSON.stringify(data));

        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false,
        });
        // Show spinner
        setIsLoading(true);
        deleteTripMetadata(data.id).then(resp => {
            setIsLoading(false);
            logger.info('Trip metadata deleted successfully', { logTag, tripMetadataId: data.id });  // Log success
            fetchTripMetadataList();
        }).catch(err => {
            logger.error('Error deleting trip metadata', err, { logTag, tripMetadataId: data.id });  // Log error
            setIsLoading(false);          
        });
    }

    // Handle Disable modal   
    const handleDisable = (id, isDisabled) => {
        logger.info('Disable/Enable trip metadata requested', { logTag, tripMetadataId: id, isDisabled });  // Log disable/enable request
        setDisableJson({
            ...disableJson,
            show: true,
            data: {
                id: id,
                isDisabled: isDisabled
            }
        });
        console.log(`disable - ${JSON.stringify(disableJson)}`);
    }

    // On Disable of route
    const onDisable = (data) => {
        logger.info('Confirm disable/enable trip metadata', { logTag, tripMetadataId: data.id, isDisabled: data.isDisabled });  // Log confirm disable/enable
        console.log('on disable data ' + JSON.stringify(data));
        // Hide delete confirm modal
        setDisableJson({
            ...disableJson,
            show: false,
        });
        // Show spinner
        setIsLoading(true);
        disableEnableTripMetadata(data.id, data.isDisabled).then(resp => {
            setIsLoading(false);
            logger.info('Trip metadata disabled/enabled successfully', { logTag, tripMetadataId: data.id, isDisabled: data.isDisabled });  // Log success
            fetchTripMetadataList();
        }).catch(err => {
            logger.error('Error disabling/enabling trip metadata', err, { logTag, tripMetadataId: data.id });  // Log error
            setIsLoading(false); 
        });
    }

    return (
        <>
             <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        Trip metadata list
                    </Typography>
                    <Chip
                        onClick={() => {
                            logger.info('Navigating to create new metadata', { logTag });  // Log navigation to create new metadata
                            navigate(`../new`);
                        }}
                        icon={<AddCircleOutlineIcon />}
                        label="New metadata"
                        variant="outlined"
                        color="secondary"
                    />
                </Box>

                {isLoading &&
                    <Loader />
                }
                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '.8rem' }}>#</TableCell>
                                        <TableCell>Route ref</TableCell>
                                        <TableCell>Route number</TableCell>
                                        <TableCell>Route types</TableCell>
                                        <TableCell>Frequencies</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tripMetadataList.map((item, index) => (
                                        <TableRow
                                            key={item.route_ref}
                                        >
                                            <TableCell align="left">{index + 1}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.route_ref}
                                                {!item.is_active &&
                                                    <Chip
                                                        sx={{ ml: 1 }}
                                                        label="Disabled"
                                                        size="small" color="error" variant="outlined" />
                                                }
                                            </TableCell>
                                            <TableCell component="th" scope="row">{item.route_number}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.route_types.map((type) => (
                                                    <Button size="small"
                                                        onClick={() => {
                                                            logger.info('Viewing route detail', { logTag, routeRef: item.route_ref, routeType: type });  // Log view route detail
                                                            setSelectedRouteRef(item.route_ref);
                                                            setSelectedRouteType(type);
                                                            setIsRouteDetailDialogOpen(true);
                                                        }}
                                                    >
                                                        {type}
                                                    </Button>
                                                ))}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.frequencies.join(', ')}
                                            </TableCell>
                                            <TableCell align="right">
                                                <VertActionMenu
                                                    onEdit={() => {
                                                        logger.info('Editing trip metadata', { logTag, tripMetadataId: item.id });  // Log edit trip metadata
                                                        navigate(`${baseUrl}/trip/metadata/edit/${item.id}`);
                                                    }}
                                                    onDisable={() => handleDisable(item.id, true)}
                                                    onEnable={() => handleDisable(item.id, false)}
                                                    onDelete={() => handleDelete(item.id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
            {/* For delete confirmation */}
            {deleteJson.show &&
                <CustomModal
                    show={deleteJson.show}
                    data={deleteJson.data}
                    type={ModalType.CONFIRM_DELETE}
                    onOkay={(data) => { onDelete(data) }}
                    onHide={() => { setDeleteJson({ ...deleteJson, show: false }) }}
                />
            }
            {/* Disable modal */}
            {disableJson.show &&
                <CustomModal
                    show={disableJson.show}
                    data={disableJson.data}
                    type={ModalType.CONFIRM_DISABLE}
                    onOkay={(data) => { onDisable(data) }}
                    onHide={() => { setDisableJson({ ...disableJson, show: false }) }}
                ></CustomModal>
            }

            {/* Show route detail dialog */}
            <RouteDetailDialog
                open={isRouteDetailDialogOpen}
                onClose={() => setIsRouteDetailDialogOpen(false)}
                routeRef={selectedRouteRef}
                routeType={selectedRouteType}
            />
        </>
    );
}

export default TripMetadataList;


// import React, { Component, useState, useEffect } from 'react';
// import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
//     Button, Chip, Paper, Grid, TableBody, IconButton } from '@mui/material';
// import { useSelector } from 'react-redux'
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { useNavigate } from "react-router-dom";

// import { getTripMetadataList, deleteTripMetadata, disableEnableTripMetadata } from '../../../../services/http.service';
// import { CustomModal } from '../../../../common/modals';
// import { TripStatus } from '../../../../utils/constants';
// import { ModalType } from '../../../../utils/constants';
// import Loader from '../../../../common/loader';
// import RouteDetailDialog from '../../../../common/route-detail-dialog';
// import { VertActionMenu } from '../../../../common/action-menu';



// const TripMetadataList = () => {

//     const [tripMetadataList, setTripMetadataList] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);
//     const [deleteJson, setDeleteJson] = useState({
//         show: false,
//         data: {}
//     });
//     const [disableJson, setDisableJson] = useState({
//         show: false,
//         data: {}
//     });
//     const [selectedRouteRef, setSelectedRouteRef] = useState(null);
//     const [selectedRouteType, setSelectedRouteType] = useState(null);
//     const [isRouteDetailDialogOpen, setIsRouteDetailDialogOpen] = useState(false);

//     const baseUrl = useSelector(state => state.extras.baseUrl);
//     const navigate = useNavigate();

//     useEffect(() => {
//         fetchTripMetadataList();
//     }, []);

//     // Fetch Trip metadata list from server
//     const fetchTripMetadataList = () => {
//         setIsLoading(true);
//         getTripMetadataList().then(resp => {
//             setIsLoading(false);
//             setTripMetadataList(resp.data.data);
//         }).catch(err => {
//             setIsLoading(false);
//         })
//     }

//     // Handle Delete modal   
//     const handleDelete = (id) => {
//         setDeleteJson({
//             ...deleteJson,
//             show: true,
//             data: {
//                 id: id
//             }
//         });
//         console.log(`delelte - ${JSON.stringify(deleteJson)}`);
//     }

//     // On Delete of School
//     const onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));

//         // Hide delete confirm modal
//         setDeleteJson({
//             ...deleteJson,
//             show: false,
//         });
//         // Show spinner
//         setIsLoading(true);
//         deleteTripMetadata(data.id).then(resp => {
//             setIsLoading(false);
//             fetchTripMetadataList();
//         }).catch(err => {
//             setIsLoading(false);          
//         })
//     }

//     // Handle Disable modal   
//     const handleDisable = (id, isDisabled) => {
//         setDisableJson({
//             ...disableJson,
//             show: true,
//             data: {
//                 id: id,
//                 isDisabled: isDisabled
//             }
//         });
//         console.log(`disable - ${JSON.stringify(disableJson)}`);
//     }

//     // On Disable of route
//     const onDisable = (data) => {
//         console.log('on disable data ' + JSON.stringify(data));
//         // Hide delete confirm modal
//         setDisableJson({
//             ...disableJson,
//             show: false,
//         });
//         // Show spinner
//         setIsLoading(true);
//         disableEnableTripMetadata(data.id, data.isDisabled).then(resp => {
//             setIsLoading(false);
//             fetchTripMetadataList();
//         }).catch(err => {
//             console.log(`Error in disabling/enabling trip metadata id - ${data.id} - ${err}`);
//             setIsLoading(false); 
//         })
//     }

//     return (
//         <>
//              <Box
//                 sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "left",
//                     alignItems: "start",
//                 }}
//             >
//                 <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//                     <Typography component="h2" variant="header">
//                         Trip metadata list
//                     </Typography>
//                     <Chip
//                         onClick={() => navigate(`../new`)}
//                         icon={<AddCircleOutlineIcon />}
//                         label="New metadata"
//                         variant="outlined"
//                         color="secondary"
//                     />
//                 </Box>

//                 {isLoading &&
//                     <Loader />
//                 }
//                 <Grid container spacing={2} sx={{ mt: 3 }}>
//                     <Grid item xs={12}>
//                         <TableContainer component={Paper}>
//                             <Table aria-label="simple table">
//                                 <TableHead>
//                                     <TableRow>
//                                         <TableCell sx={{ width: '.8rem' }}>#</TableCell>
//                                         <TableCell>Route ref</TableCell>
//                                         <TableCell>Route number</TableCell>
//                                         <TableCell>Route types</TableCell>
//                                         <TableCell>Frequencies</TableCell>
//                                         <TableCell align="right">Action</TableCell>
//                                     </TableRow>
//                                 </TableHead>
//                                 <TableBody>
//                                     {tripMetadataList.map((item, index) => (
//                                         <TableRow
//                                             key={item.route_ref}
//                                         >
//                                             <TableCell align="left">{index + 1}</TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.route_ref}
//                                                 {!item.is_active &&
//                                                     <Chip
//                                                         sx={{ ml: 1 }}
//                                                         label="Disabled"
//                                                         size="small" color="error" variant="outlined" />
//                                                 }
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">{item.route_number}</TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 { item.route_types.map((type) => (
//                                                     <Button size="small"
//                                                         onClick={() => {
//                                                             setSelectedRouteRef(item.route_ref);
//                                                             setSelectedRouteType(type);
//                                                             setIsRouteDetailDialogOpen(true);
//                                                         }}
//                                                     >
//                                                         {type}
//                                                     </Button>
//                                                 ))}
//                                             </TableCell>
//                                             <TableCell component="th" scope="row">
//                                                 {item.frequencies.join(', ')}
//                                             </TableCell>
//                                             <TableCell align="right">
//                                                 <VertActionMenu
//                                                     onEdit={() => navigate(`${baseUrl}/trip/metadata/edit/${item.id}`)}
//                                                     onDisable={() => handleDisable(item.id, true)}
//                                                     onEnable={() => handleDisable(item.id, false)}
//                                                     onDelete={() => handleDelete(item.id)}
//                                                 />
//                                             </TableCell>
//                                         </TableRow>
//                                     ))}
//                                 </TableBody>
//                             </Table>
//                         </TableContainer>
//                     </Grid>
//                 </Grid>
//             </Box>
//             {/* For delete confirmation */}
//             {deleteJson.show &&
//                 <CustomModal
//                     show={deleteJson.show}
//                     data={deleteJson.data}
//                     type={ModalType.CONFIRM_DELETE}
//                     onOkay={(data) => { onDelete(data) }}
//                     onHide={() => { setDeleteJson({ ...deleteJson, show: false }) }}
//                 />
//             }
//             {/* Disable modal */}
//             {disableJson.show &&
//                 <CustomModal
//                     show={disableJson.show}
//                     data={disableJson.data}
//                     type={ModalType.CONFIRM_DISABLE}
//                     onOkay={(data) => { onDisable(data) }}
//                     onHide={() => { setDisableJson({ ...disableJson, show: false }) }}
//                 ></CustomModal>
//             }

//             {/* Show route detail dialog */}
//             <RouteDetailDialog
//                 open={isRouteDetailDialogOpen}
//                 onClose={() => setIsRouteDetailDialogOpen(false)}
//                 routeRef={selectedRouteRef}
//                 routeType={selectedRouteType}
//             />
//         </>
//     );
// }

// export default TripMetadataList;
import axios from 'axios';
import { URL } from './constants';

class CustomLogger {
  constructor({ logstashHost, logstashPort, applicationName }) {
    this.logstashUrl = `${URL.LOGSTASH_URL}`;
    this.applicationName = applicationName;
    this.defaultMeta = { source: applicationName };  // Set default meta data here
  }

  // Log method is asynchronous but returns immediately without blocking the code
  log(level, message, meta = {}) {
    const { source, stack, correlationId, logTag, error, ...otherMeta } = { ...this.defaultMeta, ...meta };

    const logEntry = {
      timestamp: new Date().toISOString(),
      level: level,
      message: message,
      correlationId: correlationId,
      applicationName: this.applicationName,
      source: source || this.defaultMeta.source,
      meta: {
        stack: stack || '',
        error: error || '',
        logTag: logTag || 'NA',
        data: JSON.stringify(otherMeta),  // Stringify all other meta fields
      }
    };

    console.log(`[${level.toUpperCase()}] ${message}`);

    // Send the log entry asynchronously, without blocking
    this.sendLogAsync(logEntry, otherMeta);
  }

  // This method sends logs asynchronously and returns immediately
  async sendLogAsync(logEntry, otherMeta) {
    const tasks = [];

    // Send the main log entry
    tasks.push(this.sendLog(this.logstashUrl, logEntry));

    // If a correlation ID is found, send cross-app logging
    if (logEntry.correlationId) {
      const crossLogEntry = {
        ...logEntry,
        source: 'sbt-cross',  // Change the source for cross-app logging
      };
      delete crossLogEntry.timestamp;  // Optional: remove timestamp if needed
      console.log("crossLogEntry -- ", crossLogEntry);
      tasks.push(this.sendLog(this.logstashUrl, crossLogEntry, 'sbt-cross'));
    }

    // Run all async logging tasks concurrently without waiting for them
    Promise.allSettled(tasks).then(results => {
      results.forEach((result, index) => {
        if (result.status === 'rejected') {
          console.error(`Failed to send log ${index}: ${result.reason}`);
        }
      });
    });
  }

  // Method to send the log entry to Logstash asynchronously
  async sendLog(url, logEntry, source = null) {
    try {
      await axios.post(url, logEntry, {
        headers: { 'Content-Type': 'application/json' },
      });
    } catch (error) {
      console.error(`Failed to send log to ${source || this.defaultMeta.source}:`, error.message);
    }
  }

  // Method to find correlationId within nested meta data
  findCorrelationId(obj) {
    if (typeof obj !== 'object' || obj === null) return null;
    if (obj.correlationId) return obj.correlationId;

    for (const key of Object.keys(obj)) {
      const result = this.findCorrelationId(obj[key]);
      if (result) return result;
    }
    return null;
  }

  // Info level logging
  info(message, meta = {}) {
    this.log('info', message, meta);
  }

  // Debug level logging
  debug(message, meta = {}) {
    this.log('debug', message, meta);
  }

   // Warn level logging
   warn(message, meta = {}) {
    this.log('warn', message, meta);
  }

  // Error level logging with optional error object
  error(message, errorObject = null, meta = {}) {
    if (errorObject && errorObject instanceof Error) {
      meta.stack = errorObject.stack;  // Add stack trace to meta
      meta.error = errorObject.message;  // Add error message to meta
    }
    this.log('error', message, meta);
  }
}

// Initialize the logger with your desired configuration
const logger = new CustomLogger({
  logstashHost: URL.LOGSTASH_HOST,  // Logstash server IP
  logstashPort: URL.LOGSTASH_PORT,  // Logstash port
  applicationName: 'sbt-admin-web',  // Application name
});

// Export the initialized logger as default export
export default logger;

import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Chip, Paper, Grid,
    TextField, Autocomplete, CircularProgress, List, ListItem, ListItemText, ListItemAvatar, Avatar,
    IconButton, Divider } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { updateTripDriver } from '../../../../services/http.service';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'ChangeTripDriver';  // Define logTag for this component

const ChangeTripDriver = ({ availableDrivers, trip, onNext, onBack, onSubmit }) => {

    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState(null);

    useEffect(() => {
        logger.info('ChangeTripDriver component mounted', { logTag, tripId: trip.id });  // Log component mount
        console.log("availableDrivers - ", availableDrivers);
        setSelectedDriver(trip.driver);
    }, [trip]);

    // Save assigned driver
    const handleSubmit = () => {
        console.log("Selected Driver - ", selectedDriver);
        logger.info('Submitting selected driver', { logTag, selectedDriverId: selectedDriver?.id, tripId: trip.id });  // Log driver submission
        if (!selectedDriver) {
            logger.warn('No driver selected', { logTag, tripId: trip.id });  // Log warning if no driver is selected
            return;
        }
        setIsSaving(true);
        setErrorMsg(null);
        setShowSuccessAlert(false);

        const data = {
            tripId: trip.id,
            driverId: selectedDriver.id
        }

        updateTripDriver(data).then(resp => { 
            logger.info('Driver updated successfully', { logTag, tripId: trip.id, driverId: selectedDriver.id });  // Log success
            setIsSaving(false);
            setErrorMsg(null);
            setShowSuccessAlert(true);
            const respData = resp.data.data;
            if (respData) {
                const newTrip = {
                    ...trip,
                    driver: selectedDriver
                }
                onSubmit(newTrip);
            }
        }).catch(err => {
            console.log(err);
            logger.error('Error updating driver', err, { logTag, tripId: trip.id });  // Log error
            setIsSaving(false);
            setErrorMsg(err);
            setShowSuccessAlert(false);
        });
    }

    return (
        <>
            {errorMsg &&
                <ErrorAlertTopCenter
                    errorMsg={errorMsg}
                    onClose={() => {
                        logger.info('Error alert closed', { logTag });  // Log error alert close
                        setErrorMsg(null);
                    }}
                />
            }
            {showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"Driver saved successfully!!"}
                    onClose={() => {
                        logger.info('Success alert closed', { logTag });  // Log success alert close
                        setShowSuccessAlert(false);
                    }}
                />
            }

            <Box>
                <Box sx={{ mt: 1 }}>
                    <Autocomplete
                        id="available-drivers"
                        options={availableDrivers}
                        sx={{ width: '25rem' }}
                        value={selectedDriver}
                        isOptionEqualToValue={(option, value) => {
                            if (value) {
                                return option.id === value.id
                            }
                            return false;
                        }}
                        getOptionLabel={(option) => option.user.name}
                        onChange={(event, newValue) => {
                            logger.info('Driver selected', { logTag, selectedDriverId: newValue?.id });  // Log driver selection
                            if (newValue) {
                                setSelectedDriver(newValue);
                            }
                        }}
                        renderInput={(params) => <TextField {...params} label="Assign Driver" />}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    mt: 2,
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center'
                }}
            >
                {isSaving &&
                    <CircularProgress />
                }
                {!isSaving &&
                    <Box sx={{ my: 2 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mx: 2 }}
                            onClick={() => {
                                logger.info('Save button clicked', { logTag, tripId: trip.id });  // Log save button click
                                handleSubmit();
                            }}
                        >
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{ mx: 2 }}
                            startIcon={<ArrowBackIosNewIcon />}
                            onClick={() => {
                                logger.info('Back button clicked', { logTag, tripId: trip.id });  // Log back button click
                                onBack();
                            }}
                        >
                            Back
                        </Button>
                    </Box>
                }
            </Box>
        </>
    );

}

export default ChangeTripDriver;


// import React, { useState, useEffect, useRef } from 'react';
// import { useSelector } from 'react-redux';
// import { Link, useParams, useNavigate } from 'react-router-dom';
// import { Box, Button, Typography, Chip, Paper, Grid,
//     TextField, Autocomplete, CircularProgress, List, ListItem, ListItemText, ListItemAvatar, Avatar,
//     IconButton, Divider } from '@mui/material';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import { updateTripDriver } from '../../../../services/http.service';
// import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';

// const ChangeTripDriver = ({ availableDrivers, trip, onNext, onBack, onSubmit }) => {

//     const [isSaving, setIsSaving] = useState(false);
//     const [errorMsg, setErrorMsg] = useState(null);
//     const [showSuccessAlert, setShowSuccessAlert] = useState(false);
//     const [selectedDriver, setSelectedDriver] = useState(null);

//     useEffect(() => {
//         console.log("availableDrivers - ", availableDrivers);
//         setSelectedDriver(trip.driver);
//     }, [trip]);

//     // Save assinged bus
//     const handleSubmit = () => {
//         console.log("Selected Driver - ", selectedDriver);
//         if (!selectedDriver) {
//             return;
//         }
//         setIsSaving(true);
//         setErrorMsg(null);
//         setShowSuccessAlert(false);

//         const data = {
//             tripId: trip.id,
//             driverId: selectedDriver.id
//         }

//         updateTripDriver(data).then(resp => { 
//             setIsSaving(false);
//             setErrorMsg(null);
//             setShowSuccessAlert(true);
//             const respData = resp.data.data;
//             if (respData) {
//                 const newTrip = {
//                     ...trip,
//                     driver: selectedDriver
//                 }
//                 onSubmit(newTrip);
//             }
//         }).catch(err => {
//             console.log(err);
//             setIsSaving(false);
//             setErrorMsg(err);
//             setShowSuccessAlert(false);
//         });
//     }

//     return (
//         <>
//             {errorMsg &&
//                 <ErrorAlertTopCenter
//                     errorMsg={errorMsg}
//                     onClose={() => setErrorMsg(null)}
//                 />
//             }
//             {showSuccessAlert &&
//                 <SuccessAlertTopRight
//                     successMsg={"Driver saved successfully!!"}
//                     onClose={() => setShowSuccessAlert(false)}
//                 />
//             }

//             <Box>
//                 <Box sx={{ mt: 1 }}>
//                     <Autocomplete
//                         id="available-drivers"
//                         options={availableDrivers}
//                         sx={{ width: '25rem' }}
//                         value={selectedDriver}
//                         isOptionEqualToValue={(option, value) => {
//                             if (value) {
//                                 return option.id === value.id
//                             }
//                             return false;
//                         }}
//                         getOptionLabel={(option) => option.user.name}
//                         onChange={(event, newValue) => {
//                             if (newValue) {
//                                 setSelectedDriver(newValue);
//                             }
//                         }}
//                         renderInput={(params) => <TextField {...params} label="Assign Driver" />}
//                     />
//                 </Box>
//             </Box>

//             <Box
//                 sx={{
//                     mt: 2,
//                     display: 'flex',
//                     justifyContent: 'start',
//                     alignItems: 'center'
//                 }}
//             >
//                 {isSaving &&
//                     <CircularProgress />
//                 }
//                 {!isSaving &&
//                     <Box sx={{ my: 2 }}>
//                         <Button
//                             type="submit"
//                             variant="contained"
//                             sx={{ mx: 2 }}
//                             onClick={handleSubmit}
//                         >
//                             Save
//                         </Button>
//                         <Button
//                             variant="outlined"
//                             sx={{ mx: 2 }}
//                             startIcon={<ArrowBackIosNewIcon />}
//                             onClick={onBack}
//                         >
//                             Back
//                         </Button>
//                     </Box>
//                 }
//             </Box>
//         </>
//     );

// }

// export default ChangeTripDriver;
import React, { Component, useState, useEffect } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
    Button, Chip, Paper, Grid, TableBody, IconButton, FormControl, InputLabel, Select, MenuItem, Tabs,
    Tab } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { getRoutesWithDistinctRef, getTodayTrips, deleteTrip } from '../../../../services/http.service';
import { CustomModal } from '../../../../common/modals';
import { TripStatus } from '../../../../utils/constants';
import Loader from '../../../../common/loader';
import logger from '../../../../utils/logger';  // Import your logger here

import ListTab from './list-tab';

const logTag = 'TodayTripList';  // Define logTag for this component

const TodayTripList = () => {

    const [tabValue, setTabValue] = React.useState(0);
    const [routes, setRoutes] = useState([]);
    const [trips, setTrips] = useState([]);
    const [routeLoading, setRouteLoading] = useState(false);
    const [tabKey, setTabKey] = useState('runningTrips');
    const [filter, setFilter] = useState({
        status: 'ON_RUN'
    });
    const [isLoading, setIsLoading] = useState(false);

    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();

    useEffect(() => {
        logger.info('TodayTripList component mounted', { logTag });  // Log component mount
        fetchRoutes();
        fetchTrips();
    }, []);

    // Fetch Routes with only Ref and type
    const fetchRoutes = () => {
        logger.info('Fetching routes', { logTag });  // Log route fetching
        getRoutesWithDistinctRef().then(resp => {
            const respRoutes = resp.data.data;
            const routes = respRoutes;
            setRoutes(routes);
            logger.info('Routes fetched successfully', { logTag, routes });  // Log success
        }).catch(err => {
            logger.error('Error fetching routes', err, { logTag });  // Log error
            console.log(err);
        });
    }

    // Select route from dropdown box
    const selectRoute = (e) => {
        const value = e.target.value;
        logger.info('Route selected', { logTag, routeRef: value });  // Log route selection
        if (value) {
            setFilter({ ...filter, route_ref: value });
            fetchTrips();
        }
    }

    // On select of tab action
    const onSelectTab = (key) => {
        filter.status = null;
        if (key === 'runningTrips') {
            filter.status = TripStatus.ON_RUN;
        } else if (key === 'finishedTrips') {
            filter.status = TripStatus.FINISHED;
        }
        setFilter(filter);
        logger.info('Tab selected', { logTag, tabKey: key });  // Log tab selection
        fetchTrips();
    }

    // Fetch trips
    const fetchTrips = () => {
        logger.info('Fetching trips', { logTag, filter });  // Log fetching trips
        setIsLoading(true);
        getTodayTrips(filter).then(resp => {
            setTrips(resp.data.data);
            setIsLoading(false);
            logger.info('Trips fetched successfully', { logTag, trips: resp.data.data });  // Log success
        }).catch(err => {
            logger.error('Error fetching trips', err, { logTag });  // Log error
            console.log(err);
            setIsLoading(false);
        });
    }

    // Handle tabs
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        filter.status = null;
        if (newValue === 0) { // running trips
            filter.status = TripStatus.ON_RUN;
        } else if (newValue === 2) { // finished trips
            filter.status = TripStatus.FINISHED;
        }
        setFilter(filter);
        logger.info('Tab changed', { logTag, newValue });  // Log tab change
        fetchTrips();
    };

    // Delete trip
    const onDelete = (tripId) => {
        logger.info('Delete trip requested', { logTag, tripId });  // Log delete trip request
        setIsLoading(true);
        deleteTrip(tripId).then(resp => {
            setIsLoading(false);
            logger.info('Trip deleted successfully', { logTag, tripId });  // Log success
            fetchTrips();
        }).catch(err => {
            logger.error('Error deleting trip', err, { logTag, tripId });  // Log error
            console.log(`Error in fetching Trips :: ${JSON.stringify(err)}`);
            setIsLoading(false);
        });
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        Today's Trips
                    </Typography>
                    <Box>
                        <Chip
                            onClick={() => {
                                logger.info('Navigating to trip history', { logTag });  // Log history navigation
                                navigate(`../history/list`);
                            }}
                            sx={{ mr: 3 }}
                            icon={<HistoryIcon />}
                            label="History"
                            variant="outlined"
                            color="secondary"
                        />
                        <Chip
                            onClick={() => {
                                logger.info('Navigating to trip metadata', { logTag });  // Log metadata navigation
                                navigate(`../metadata/list`);
                            }}
                            icon={<SettingsIcon />}
                            label="Metadata"
                            variant="outlined"
                            color="secondary"
                        />
                    </Box>
                </Box>

                {isLoading &&
                    <Loader />
                }

                <Box sx={{ mt: 3, width: '15rem' }}>
                    <FormControl fullWidth>
                        <InputLabel id="route-select-label">Filter with route</InputLabel>
                        <Select
                            labelId="route-select-label"
                            id="route-select"
                            label="Filter with route"
                            onChange={selectRoute}
                        >
                            {routes.map((item, index) => (
                                <MenuItem key={index} value={item.ref_no}>{item.number} ({item.ref_no})</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 3 }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Running" />
                        <Tab label="Trips" />
                        <Tab label="Finished Trips" />
                    </Tabs>
                </Box>

                <ListTab
                    trips={trips}
                    baseUrl={baseUrl}
                    onTripDelete={onDelete}
                >
                </ListTab>

            </Box>

        </>
    );

}

export default TodayTripList;


// import React, { Component, useState, useEffect } from 'react';
// import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
//     Button, Chip, Paper, Grid, TableBody, IconButton, FormControl, InputLabel, Select, MenuItem, Tabs,
//     Tab } from '@mui/material';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import HistoryIcon from '@mui/icons-material/History';
// import SettingsIcon from '@mui/icons-material/Settings';
// import { useSelector } from 'react-redux';
// import { useNavigate } from "react-router-dom";

// import { getRoutesWithDistinctRef, getTodayTrips, deleteTrip } from '../../../../services/http.service';
// import { CustomModal } from '../../../../common/modals';
// import { TripStatus } from '../../../../utils/constants';
// import Loader from '../../../../common/loader';

// import ListTab from './list-tab';



// const TodayTripList = () => {

//     const [tabValue, setTabValue] = React.useState(0);
//     const [routes, setRoutes] = useState([]);
//     const [trips, setTrips] = useState([]);
//     const [routeLoading, setRouteLoading] = useState(false);
//     const [tabKey, setTabKey] = useState('runningTrips');
//     const [filter, setFilter] = useState({
//         status: 'ON_RUN'
//     });
//     const [isLoading, setIsLoading] = useState(false);

//     const baseUrl = useSelector(state => state.extras.baseUrl);
//     const navigate = useNavigate();

//     useEffect(() => {
//         fetchRoutes(); 
//         fetchTrips();
//     }, []);

//     // Fetch Routes with only Ref and type
//     const fetchRoutes = () => {
//         getRoutesWithDistinctRef().then(resp => {
//             const respRoutes = resp.data.data;
//             const routes = respRoutes;
//             setRoutes(routes);            
//         }).catch(err => {
//             console.log(err);
//         })
//     }

//     // Select route from dropdown box
//     const selectRoute = (e) => {
//         const value = e.target.value;
//         if (value) {
//             setFilter({...filter, route_ref: value});
//             fetchTrips();
//         } 
//     }

//     // On select of tab action
//     const onSelectTab = (key) => {
//         filter.status = null;
//         if (key === 'runningTrips') { 
//             filter.status = TripStatus.ON_RUN;            
//         } else if (key === 'finishedTrips') {
//             filter.status = TripStatus.FINISHED; 
//         }
//         setFilter(filter);
//         fetchTrips();
//     }


//     // Fetch routes
//     const fetchTrips = () => {
//         setIsLoading(true);
//         getTodayTrips(filter).then(resp => {
//             setTrips(resp.data.data);
//             setIsLoading(false);
//         }).catch(err => {
//             console.log(err);
//             setIsLoading(false);
//         })
//     }

//     // Handle tabs
//     const handleTabChange = (event, newValue) => {
//         setTabValue(newValue);
//         filter.status = null;
//         if (newValue === 0) { // running trips
//             filter.status = TripStatus.ON_RUN;            
//         } else if (newValue === 2) { // finished trips
//             filter.status = TripStatus.FINISHED; 
//         }
//         setFilter(filter);
//         fetchTrips();
//     };

//     // Delete trip
//     const onDelete = (tripId) => {
//         // Show spinner
//         setIsLoading(true);
//         deleteTrip(tripId).then(resp => {
//             setIsLoading(false);
//             fetchTrips();
//         }).catch(err => {
//             console.log(`Error in fetching Trips :: ${JSON.stringify(err)}`);
//             setIsLoading(false);         
//         })
//     }


//     return (
//         <>
//              <Box
//                 sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "left",
//                     alignItems: "start",
//                 }}
//             >
//                 <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//                     <Typography component="h2" variant="header">
//                         Today's Trips
//                     </Typography>
//                     <Box>
//                         <Chip
//                             onClick={() => navigate(`../history/list`)}
//                             sx={{ mr: 3 }}
//                             icon={<HistoryIcon />}
//                             label="History"
//                             variant="outlined"
//                             color="secondary"
//                         />
//                         <Chip
//                             onClick={() => navigate(`../metadata/list`)}
//                             icon={<SettingsIcon />}
//                             label="Metadata"
//                             variant="outlined"
//                             color="secondary"
//                         />
//                     </Box>
//                 </Box>

//                 { isLoading &&
//                     <Loader />
//                 }

//                 <Box sx={{ mt: 3, width: '15rem' }}>
//                     <FormControl fullWidth>
//                         <InputLabel id="route-select-label">Filter with route</InputLabel>
//                         <Select
//                             labelId="route-select-label"
//                             id="route-select"
//                             label="Filter with route "
//                             onChange={selectRoute}
//                         >
//                             {routes.map((item, index) => (
//                                 <MenuItem value={item.ref_no}>{item.number} ({item.ref_no})</MenuItem>
//                             ))}
//                         </Select>
//                     </FormControl>
//                 </Box>

//                 <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 3 }}>
//                     <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
//                         <Tab label="Running" />
//                         <Tab label="Trips" />
//                         <Tab label="Finished Trips" />
//                     </Tabs>
//                 </Box>

//                 <ListTab 
//                     trips={trips} 
//                     baseUrl={baseUrl}
//                     onTripDelete={onDelete}
//                 >
//                 </ListTab>

//             </Box>
        
//         </>
//     );
    
// }


// export default TodayTripList;
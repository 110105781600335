import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, Chip, Paper, Grid,
    TextField, Autocomplete, CircularProgress } from '@mui/material';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { getClasses, saveOrUpdateStudentBasic, fetchSchool } from '../../../../services/http.service';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'StudentBasicDetail';  // Define logTag for this component

const StudentBasicDetail = ({ student, onNext, onSubmit }) => {

    const [classes, setClasses] = useState([]);
    const [classesLoading, setClassesLoading] = useState(false);
    const [classSections, setClassSections] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);

    useEffect(() => {
        logger.info('StudentBasicDetail component mounted', { logTag, studentId: student?.id });  // Log component mount
        fetchClasses();
    }, []);

    // update form values on edit of student
    useEffect(() => {
        console.log("Basic detail", JSON.stringify(student));
        if (student && student.id && classes.length > 0) {
            formik.setValues({
                name: student.name,
                rollNo: student.rollNo,
                classId: student.sclass?.id,
                sectionId: student.section ? student.section.id : null,
            });
            setSelectedClass(classes.find(item => item.id == student.sclass?.id));

            const filteredClass = classes.find(item => item.id === student.sclass?.id);
            if (filteredClass) {
                setClassSections(filteredClass.sections);
            }

            if (student.section && filteredClass) {
                setSelectedSection(filteredClass.sections.find(item => item.id == student.section.id));
            }
            logger.info('Student details set in form', { logTag, studentId: student?.id });  // Log setting form values
        }
    }, [student, classes]);

    // Fetch classes of school
    const fetchClasses = () => {
        logger.info('Fetching classes for the school', { logTag });  // Log fetching classes
        setClassesLoading(true);
        getClasses().then(resp => {
            setClassesLoading(false);
            setClasses(resp.data.data);
            logger.info('Classes fetched successfully', { logTag, classes: resp.data.data });  // Log success
        }).catch(err => {
            logger.error('Error fetching classes', err, { logTag });  // Log error
            setClassesLoading(false);
        });
    }

    // Formik handle submit 
    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required."),
        rollNo: Yup.string().required("Roll number is required."),
        classId: Yup.string().required("Class is required."),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            rollNo: '',
            classId: '',
            sectionId: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            logger.info('Submitting student basic details', { logTag, values });  // Log form submit
            const newStudent = {
                ...student,
                rollNo: values.rollNo,
                name: values.name,
                sclass: {
                    id: values.classId 
                },
                section: {
                    id: values.sectionId ? values.sectionId : null 
                }
            }

            setIsSaving(true);
            setErrorMsg(null);
            setShowSuccessAlert(false);

            saveOrUpdateStudentBasic(newStudent).then(resp => {
                const respData = resp.data.data;
                if (!newStudent.id) {
                    newStudent.id = respData.student_id;
                    newStudent.uniqueNumber = respData.unique_no;
                }
                onSubmit(newStudent);
                setIsSaving(false);
                setErrorMsg(null);
                setShowSuccessAlert(true);
                logger.info('Student basic details saved successfully', { logTag, studentId: newStudent.id });  // Log success
            }).catch(err => {
                logger.error('Error saving student basic details', err, { logTag, studentId: student?.id });  // Log error
                setIsSaving(false);
                setErrorMsg(err);
                setShowSuccessAlert(false);
            });
        }
    });

    return (
        <>
            {errorMsg &&
                <ErrorAlertTopCenter
                    errorMsg={errorMsg}
                    onClose={() => {
                        logger.info('Error alert closed', { logTag });  // Log alert close
                        setErrorMsg(null);
                    }}
                />
            }
            {showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"Student saved successfully!!"}
                    onClose={() => {
                        logger.info('Success alert closed', { logTag });  // Log success alert close
                        setShowSuccessAlert(false);
                    }}
                />
            }
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            fullWidth
                            required
                            name="name"
                            label="Student name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            required
                            name="rollNo"
                            label="Roll number"
                            value={formik.values.rollNo}
                            onChange={formik.handleChange}
                            error={formik.touched.rollNo && Boolean(formik.errors.rollNo)}
                            helperText={formik.touched.rollNo && formik.errors.rollNo}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                mt: 2
                            }}
                        >
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={classes}
                                name="classId"
                                value={selectedClass}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                sx={{ width: 300 }}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        formik.setFieldValue("classId", newValue.id);
                                        setClassSections(newValue.sections);
                                        setSelectedClass(newValue);
                                    }
                                    else {
                                        formik.setFieldValue("classId", '');
                                        formik.setFieldValue("sectionId", '');
                                        setClassSections([]);
                                        setSelectedClass(null);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        margin="normal"
                                        label="Select class"
                                        fullWidth
                                        value={formik.values?.classId}
                                        error={formik.touched.classId && Boolean(formik.errors.classId)}
                                        helperText={formik.touched.classId && formik.errors.classId}
                                    />
                                )}
                            />
                            {classesLoading &&
                                <CircularProgress size={25} sx={{ ml: 1 }} />
                            }
                        </Box>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            name="sectionId"
                            options={classSections}
                            value={selectedSection}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            sx={{ width: 300, mt: 2 }}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    formik.setFieldValue("sectionId", newValue.id);
                                    setSelectedSection(newValue);
                                }
                                else {
                                    formik.setFieldValue("sectionId", '');
                                    setSelectedSection(null);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="normal"
                                    label="Select section"
                                    name="sectionId"
                                    fullWidth
                                    value={formik.values?.sectionId}
                                />
                            )}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'start',
                            }}
                        >
                            {isSaving &&
                                <CircularProgress />
                            }
                            {!isSaving &&
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    { student?.id ? 'Update' : 'Save' }
                                </Button>
                            }
                            <Button
                                variant="outlined"
                                sx={{ mt: 3, mb: 2, ml: 3 }}
                                endIcon={<ArrowForwardIosIcon />}
                                onClick={() => {
                                    logger.info('Next button clicked', { logTag, studentId: student?.id });  // Log next button click
                                    onNext();
                                }}
                            >
                                Next
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default StudentBasicDetail;



// import React, { useState, useEffect, useRef } from 'react';
// import { Box, Button, Typography, Chip, Paper, Grid,
//     TextField, Autocomplete, CircularProgress } from '@mui/material';
// import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// import { useParams, useNavigate } from "react-router-dom";
// import { useFormik } from 'formik';
// import * as Yup from 'yup';

// import { getClasses, saveOrUpdateStudentBasic, fetchSchool } from '../../../../services/http.service';




// const StudentBasicDetail = ({ student, onNext, onSubmit }) => {

//     const [classes, setClasses] = useState([]);
//     const [classesLoading, setClassesLoading] = useState(false);
//     const [classSections, setClassSections] = useState([]);
//     const [isSaving, setIsSaving] = useState(false);
//     const [errorMsg, setErrorMsg] = useState(null);
//     const [showSuccessAlert, setShowSuccessAlert] = useState(false);
//     const [selectedClass, setSelectedClass] = useState(null);
//     const [selectedSection, setSelectedSection] = useState(null);

//     useEffect(() => {
//         fetchClasses();
//     }, []);

//     // update form values on edit of student
//     useEffect(() => {
//         console.log("Basic detail", JSON.stringify(student));
//         // if its for edit then init formik values and also check classes are loaded
//         if (student && student.id && classes.length > 0) {
//             formik.setValues({
//                 name: student.name,
//                 rollNo: student.rollNo,
//                 classId: student.sclass?.id,
//                 sectionId: student.section ? student.section.id : null,
//             });
//             // set selected class for dropdown
//             setSelectedClass(classes.find(item => item.id == student.sclass?.id));
//             // set class sections
//             console.log("Classes loaded - ", JSON.stringify(classes));
//             const filteredClass = classes
//                 .find(item => item.id === student.sclass?.id);
//             if (filteredClass) {
//                 setClassSections(filteredClass.sections);
//             }
//             // set selected section if not null
//             if (student.section && filteredClass) {
//                 setSelectedSection(filteredClass.sections.find(item => item.id == student.section.id));
//             }
//         }
//     }, [student, classes]);

//     // Fetch classes of school
//     const fetchClasses = () => {
//         setClassesLoading(true);
//         getClasses().then(resp => {
//             setClassesLoading(false);
//             setClasses(resp.data.data);
//         }).catch(err => {
//             console.log(err);
//             setClassesLoading(false);
//         })
//     }

//     // Formik handle submit 
//     const validationSchema = Yup.object({
//         name: Yup.string().required("Name is required."),
//         rollNo: Yup.string().required("Roll number is required."),
//         classId: Yup.string().required("Class is required."),
//     });
//     const formik = useFormik({
//         initialValues: {
//             name: '',
//             rollNo: '',
//             classId: '',
//             sectionId: '',
//         },
//         validationSchema: validationSchema,
//         onSubmit: (values) => {
//             console.log(values);
//             const newStudent = {
//                 ...student,
//                 rollNo: values.rollNo,
//                 name: values.name,
//                 sclass: {
//                     id: values.classId 
//                 },
//                 section: {
//                     id: values.sectionId ? values.sectionId : null 
//                 }
//             }
//             //setStudent(newStudent);
//             setIsSaving(true);
//             setErrorMsg(null);
//             setShowSuccessAlert(false);
//             // Save to Database
//             saveOrUpdateStudentBasic(newStudent).then(resp => {
//                 const respData = resp.data.data;
//                 if (!newStudent.id) { // for new student
//                     newStudent.id = respData.student_id;
//                     newStudent.uniqueNumber = respData.unique_no;
//                 }
//                 onSubmit(newStudent); // send student back to parent component
//                 setIsSaving(false);
//                 setErrorMsg(null);
//                 setShowSuccessAlert(true);
//             }).catch(err => {
//                 setIsSaving(false);
//                 setErrorMsg(err);
//                 setShowSuccessAlert(false);
//                 console.log(err);
//             })
//         }
//     });



//     return (
//         <>
//             {errorMsg &&
//                 <ErrorAlertTopCenter
//                     errorMsg={errorMsg}
//                     onClose={() => setErrorMsg(null)}
//                 />
//             }
//             {showSuccessAlert &&
//                 <SuccessAlertTopRight
//                     successMsg={"School saved successfully!!"}
//                     onClose={() => setShowSuccessAlert(false)}
//                 />
//             }
//             <Grid container spacing={2}>
//                 <Grid item xs={12} md={6}>
//                     <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
//                         <TextField
//                             margin="normal"
//                             fullWidth
//                             required
//                             name="name"
//                             label="Student name"
//                             value={formik.values.name}
//                             onChange={formik.handleChange}
//                             error={formik.touched.name && Boolean(formik.errors.name)}
//                             helperText={formik.touched.name && formik.errors.name}
//                         />
//                         <TextField
//                             margin="normal"
//                             fullWidth
//                             required
//                             name="rollNo"
//                             label="Roll number"
//                             value={formik.values.rollNo}
//                             onChange={formik.handleChange}
//                             error={formik.touched.rollNo && Boolean(formik.errors.rollNo)}
//                             helperText={formik.touched.rollNo && formik.errors.rollNo}
//                         />
//                         <Box
//                             sx={{
//                                 display: "flex",
//                                 justifyContent: "left",
//                                 alignItems: "center",
//                                 mt: 2
//                             }}
//                         >
//                             <Autocomplete
//                                 disablePortal
//                                 id="combo-box-demo"
//                                 options={classes}
//                                 name="classId"
//                                 value={selectedClass}
//                                 isOptionEqualToValue={(option, value) => option?.id === value?.id}
//                                 sx={{ width: 300 }}
//                                 getOptionLabel={(option) => option.name}
//                                 onChange={(event, newValue) => {
//                                     if (newValue) {
//                                         formik.setFieldValue("classId", newValue.id);
//                                         setClassSections(newValue.sections);
//                                         setSelectedClass(newValue);
//                                     }
//                                     else {
//                                         formik.setFieldValue("classId", '');
//                                         // also clear section dropdown
//                                         formik.setFieldValue("sectionId", '');
//                                         setClassSections([]);
//                                         setSelectedClass(null);
//                                     }
//                                 }}
//                                 renderInput={(params) => (
//                                     <TextField
//                                         {...params}
//                                         required
//                                         margin="normal"
//                                         label="Select class"
//                                         fullWidth
//                                         value={formik.values?.classId}
//                                         error={formik.touched.classId && Boolean(formik.errors.classId)}
//                                         helperText={formik.touched.classId && formik.errors.classId}
//                                     />
//                                 )}
                                
//                             />
//                             {classesLoading &&
//                                 <CircularProgress size={25} sx={{ ml: 1 }} />
//                             }
//                         </Box>
//                         <Autocomplete
//                             disablePortal
//                             id="combo-box-demo"
//                             name="sectionId"
//                             options={classSections}
//                             value={selectedSection}
//                             isOptionEqualToValue={(option, value) => option?.id === value?.id}
//                             sx={{ width: 300, mt: 2 }}
//                             getOptionLabel={(option) => option.name}
//                             onChange={(event, newValue) => {
//                                 if (newValue) {
//                                     formik.setFieldValue("sectionId", newValue.id);
//                                     setSelectedSection(newValue);
//                                 }
//                                 else {
//                                     formik.setFieldValue("sectionId", '');
//                                     setSelectedSection(null);
//                                 }
//                             }}
//                             renderInput={(params) => (
//                                 <TextField
//                                     {...params}
//                                     margin="normal"
//                                     label="Select section"
//                                     name="sectionId"
//                                     fullWidth
//                                     value={formik.values?.sectionId}
//                                 />
//                             )}
//                         />
//                         <Box
//                             sx={{
//                                 display: 'flex',
//                                 justifyContent: 'start',
//                             }}
//                         >
//                             {isSaving &&
//                                 <CircularProgress />
//                             }
//                             {!isSaving &&
//                                 <Button
//                                     type="submit"
//                                     variant="contained"
//                                     sx={{ mt: 3, mb: 2 }}
//                                 >
//                                     { student?.id ? 'Update' : 'Save' }
//                                 </Button>
//                             }
//                             <Button
//                                 variant="outlined"
//                                 sx={{ mt: 3, mb: 2, ml: 3 }}
//                                 endIcon={<ArrowForwardIosIcon />}
//                                 onClick={onNext}
//                             >
//                                 Next
//                             </Button>
//                         </Box>
//                     </Box>
//                 </Grid>
//             </Grid>
//         </>
//     );
// }

// export default StudentBasicDetail;
import React, { Component, useState, useRef, useEffect } from 'react';
import { Box, Typography, Grid, Link as MuiLink, Chip, TextField,
    Button, Autocomplete, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell,
    TableBody, Paper, IconButton } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { saveOrUpdateClass, getSections, getClass } from '../../../../services/http.service';
import { CustomModal } from '../../../../common/modals';
import { ModalType } from '../../../../utils/constants';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';

import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import logger from '../../../../utils/logger';  // Import your logger here

const logTag = 'NewClass';  // Define logTag for this component

const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
});

const NewClass = () => {

    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sectionsLoading, setSectionsLoading] = useState(false);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });
    const [sections, setSections] = useState([]);
    const [sclass, setSclass] = useState({
        id: null,
        name: '',
        sections: []
    });
    const [selectedSection, setSelectedSection] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const { classId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        logger.info('NewClass component mounted', { logTag, classId });  // Log component mount
        fetchSections();
        if (classId) {
            setSclass({
                ...sclass,
                id: classId
            });
            fetchClass(classId);
        }   
    }, [classId]);

    // Fetch class by id from server
    const fetchClass = (id) => {
        logger.info('Fetching class details', { logTag, classId: id });  // Log fetching class
        setIsLoading(true);
        getClass(id).then(resp => {
            const sclass = resp.data.data;
            setIsLoading(false);
            setSclass(sclass);
            formik.setValues({ ...sclass });  // get copy of class
            logger.info('Class details fetched successfully', { logTag, classId: id });  // Log success
        }).catch(err => {
            logger.error('Error fetching class', err, { logTag, classId: id });  // Log error
            setIsLoading(false);
        });
    }

    // Fetch sections of schools for dropdown
    const fetchSections = () => {
        logger.info('Fetching sections', { logTag });  // Log fetching sections
        setSectionsLoading(true);
        getSections().then(resp => {
            setSections(resp.data.data);
            setSectionsLoading(false);
            logger.info('Sections fetched successfully', { logTag, sections: resp.data.data });  // Log success
        }).catch(err => {
            logger.error('Error fetching sections', err, { logTag });  // Log error
            setSectionsLoading(false);
        });
    }

    // Add section to class
    const addSectionToClass = () => {
        logger.info('Adding section to class', { logTag, section: selectedSection });  // Log adding section
        if (!selectedSection) {
            console.log('section not selected');
            return;
        }

        const arr = sclass.sections.filter(item => item.name === selectedSection.name);
        if (arr && arr.length > 0) {
            console.log('Section already exists');
            return;
        }

        setSclass({
            ...sclass,
            sections: [...sclass.sections, selectedSection]
        });
        logger.info('Section added to class', { logTag, sections: sclass.sections });  // Log section addition
    }

    // Handle Delete modal   
    const handleDelete = (id) => {
        logger.info('Delete section requested', { logTag, sectionId: id });  // Log delete request
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });

        console.log(`delete - ${JSON.stringify(deleteJson)}`);
    }

    // On Delete of School
    const onDelete = (data) => {
        logger.info('Confirm delete section from class', { logTag, sectionId: data.id });  // Log confirm delete
        console.log('on delete data ' + JSON.stringify(data));

        setDeleteJson({
            ...deleteJson,
            show: false
        });
        const sections = sclass.sections.filter(item => item.id !== data.id);
        setSclass({
            ...sclass,
            sections: sections
        });
    }

    // Formik handle submit
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const sectionsIds = sclass.sections.map(item => item.id);
            const data = {
                id: sclass.id,
                name: values.name,
                sections: sectionsIds
            };

            logger.info('Submitting class details', { logTag, classData: data });  // Log form submit
            setIsSaving(true);
            setShowSuccessAlert(false);
            saveOrUpdateClass(data).then(resp => {
                setSelectedSection('');
                setIsSaving(false);
                setShowSuccessAlert(true);
                setErrorMsg(null);
                logger.info('Class saved successfully', { logTag, classId: sclass.id });  // Log success
                if (!sclass.id) {
                    setSclass({
                        ...sclass,
                        id: null,
                        name: '',
                        sections: [],
                    });
                }
            }).catch(err => {
                logger.error('Error saving class', err, { logTag, classId: sclass.id });  // Log error
                setIsSaving(false);
                setShowSuccessAlert(false);
                setErrorMsg(err);
            });
        }
    });

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                {errorMsg &&
                    <ErrorAlertTopCenter
                        errorMsg={errorMsg}
                        onClose={() => {
                            logger.info('Error alert closed', { logTag });  // Log alert close
                            setErrorMsg(null);
                        }}
                    />
                }
                {showSuccessAlert &&
                    <SuccessAlertTopRight
                        successMsg={"Class saved successfully!!"}
                        onClose={() => {
                            logger.info('Success alert closed', { logTag });  // Log success alert close
                            setShowSuccessAlert(false);
                        }}
                    />
                }
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        {sclass && sclass.id ? "Update class" : "Create new class"}
                    </Typography>
                    <Box>
                        {sclass && sclass.id &&
                            <Chip
                                onClick={() => {
                                    logger.info('Navigating to create new class', { logTag });  // Log navigation to create new class
                                    navigate("../new");
                                }}
                                icon={<AddCircleOutlineIcon />}
                                label="New class"
                                variant="outlined"
                                color="secondary"
                                sx={{ mr: 3 }}
                            />
                        }
                        <Chip
                            onClick={() => {
                                logger.info('Navigating to class list', { logTag });  // Log navigation to class list
                                navigate(`../list`);
                            }}
                            icon={<FormatListBulletedIcon />}
                            label="Classes"
                            variant="outlined"
                            color="secondary"
                        />
                    </Box>
                </Box>
                <Grid container spacing={2} sx={{ mt: 3 }}>
                    {isLoading &&
                        <Grid container>
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={12} md={6}>
                        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                name="name"
                                label="Class name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    alignItems: "center",
                                    mt: 3
                                }}
                            >
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={sections}
                                    sx={{ width: 300 }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label="Select section" />}
                                    onChange={(event, newValue) => {
                                        setSelectedSection(newValue);
                                    }}
                                />
                                {sectionsLoading &&
                                    <CircularProgress size="30" />
                                }
                                <Chip
                                    onClick={addSectionToClass}
                                    icon={<AddCircleOutlineIcon />}
                                    label="Add"
                                    variant="outlined"
                                    color="secondary"
                                    sx={{ ml: 3 }}
                                />
                            </Box>

                            {sclass.sections && sclass.sections.length > 0 &&
                                <TableContainer component={Paper}
                                    sx={{ mt: 3 }}
                                >
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: '.8rem' }}>#</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell align="right">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sclass.sections.map((item, index) => (
                                                <TableRow
                                                    key={item.name}
                                                >
                                                    <TableCell align="left">{index + 1}</TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {item.name}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <IconButton aria-label="delete" color="error"
                                                            onClick={() => handleDelete(item.id)}
                                                        >
                                                            <DeleteIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {isSaving &&
                                    <CircularProgress />
                                }
                                {!isSaving &&
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Save
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {deleteJson.show &&
                <CustomModal
                    show={deleteJson.show}
                    data={deleteJson.data}
                    type={ModalType.CONFIRM_DELETE}
                    onOkay={(data) => {
                        logger.info('Delete section confirmed', { logTag, sectionId: deleteJson.data.id });  // Log delete confirmation
                        onDelete(deleteJson.data);
                        setDeleteJson({
                            ...deleteJson,
                            show: false,
                        });
                    }}
                    onHide={() => {
                        logger.info('Delete modal closed', { logTag });  // Log modal close
                        setDeleteJson({
                            ...deleteJson,
                            show: false,
                        });
                    }}
                >
                </CustomModal>
            }
        </>
    );
}

export default NewClass;



// import React, { Component, useState, useRef, useEffect } from 'react';
// import { Box, Typography, Grid, Link as MuiLink, Chip, TextField,
//     Button, Autocomplete, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell,
//     TableBody, Paper, IconButton } from '@mui/material';
// import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';

// import { saveOrUpdateClass, getSections, getClass } from '../../../../services/http.service';
// import { CustomModal } from '../../../../common/modals';
// import { ModalType } from '../../../../utils/constants';
// import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';

// import { useParams, useNavigate } from "react-router-dom";
// import { useFormik } from 'formik';
// import * as Yup from 'yup';

// const validationSchema = Yup.object({
//     name: Yup.string().required("Name is required."),
// });



// const NewClass = () => {

//     const [validated, setValidated] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);
//     const [sectionsLoading, setSectionsLoading] = useState(false);
//     const [deleteJson, setDeleteJson] = useState({
//         show: false,
//         data: {}
//     });
//     const [sections, setSections] = useState([]);
//     const [sclass, setSclass] = useState({
//         id: null,
//         name: '',
//         sections: []
//     });
//     const [selectedSection, setSelectedSection] = useState('');
//     const [isSaving, setIsSaving] = useState(false);
//     const [errorMsg, setErrorMsg] = useState(null);
//     const [showSuccessAlert, setShowSuccessAlert] = useState(false);

//     const { classId } = useParams();
//     const navigate = useNavigate();

//     useEffect(() => {
//         fetchSections();
//         if (classId) {
//             setSclass({
//                 ...sclass,
//                 id: classId
//             });
//             fetchClass(classId);
//         }   
//     }, []);

//     // Fetch class by id from server
//     const fetchClass = (id) => {
//         setIsLoading(true);
//         getClass(id).then(resp => {
//             const sclass = resp.data.data;
//             setIsLoading(false);
//             setSclass(sclass);
//             formik.setValues({ ...sclass }); // get copy of school
//         }).catch(err => {
//             console.log(err);
//             setIsLoading(false);
//         })
//     }

//     // Fetch sections of schools for dropdown
//     const fetchSections = () => {
//         setSectionsLoading(true);
//         getSections().then(resp => {
//             setSections(resp.data.data);
//             setSectionsLoading(false);
//         }).catch(err => {
//             console.log(err);
//             setSectionsLoading(false);
//         })
//     }
    
//     // Add section to class
//     const addSectionToClass = () => {
//         console.log('adding section')
//         // const sclass = this.state.sclass;
//         // let selectedSection = this.state.selectedSection;
//         console.log(`selected section - ${selectedSection}`);
//         if (!selectedSection) {
//             console.log('section not selected');
//             return;
//         }

//         // let selectedSectionJson = JSON.parse(selectedSection);

//         // console.log(`after parse - ${JSON.stringify(selectedSectionJson)}`);

//         // Check if section already added
//         const arr = sclass.sections.filter(item => item.name === selectedSection.name);
//         if (arr && arr.length > 0) {
//             console.log(`Section already exists`);
//             return;
//         }

//         //sclass.sections.push(selectedSectionJson);
//         setSclass({
//             ...sclass,
//             sections: [...sclass.sections, selectedSection]
//         })
//         console.log(sclass);
//         //setSclass(sclass);
//     }

//     // Handle Delete modal   
//     const handleDelete = (id) => {
//         setDeleteJson({
//             ...deleteJson,
//             show: true,
//             data: {
//                 id: id
//             }
//         });

//         console.log(`delelte - ${JSON.stringify(deleteJson)}`);
//     }

//     // On Delete of School
//     const onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));

//         // Hide delete confirm modal
//         setDeleteJson({
//             ...deleteJson,
//             show: false
//         });
//         // const sclass = this.state.sclass;
//         const sections = sclass.sections.filter(item => item.id !== data.id);
//         // sclass.sections = sections;
//         // this.setState({sclass});
//         setSclass({
//             ...sclass,
//             sections: sections
//         });
//     }

//     // Formik handle submit
//     const formik = useFormik({
//         initialValues: {
//             name: '',
//         },
//         validationSchema: validationSchema,
//         onSubmit: (values) => {
//             const sectionsIds = sclass.sections.map(item => item.id);
//             const data = {
//                 id: sclass.id,
//                 name: values.name,
//                 sections: sectionsIds
//             };

//             setIsSaving(true);
//             setShowSuccessAlert(false);
//             saveOrUpdateClass(data).then(resp => {
//                 setSelectedSection('');
//                 setIsSaving(false);
//                 setShowSuccessAlert(true);
//                 setErrorMsg(null);
//                 // if for new then do reset
//                 if (!sclass.id) {
//                     setSclass({
//                         ...sclass,
//                         id: null,
//                         name: '',
//                         sections: [],
//                     });
//                 }

//             }).catch(err => {
//                 console.log(err);
//                 //this.setState({isSaving: false, errorMsg: err, showSuccessAlert: false}); 
//                 setIsSaving(false);
//                 setShowSuccessAlert(false);
//                 setErrorMsg(err);
//             })

//         }
//     });


//     return (
//         <>
//             <Box
//                 sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "left",
//                     alignItems: "start",
//                 }}
//             >
//                 {errorMsg &&
//                     <ErrorAlertTopCenter
//                         errorMsg={errorMsg}
//                         onClose={() => setErrorMsg(null)}
//                     />
//                 }
//                 {showSuccessAlert &&
//                     <SuccessAlertTopRight
//                         successMsg={"School saved successfully!!"}
//                         onClose={() => setShowSuccessAlert(false)}
//                     />
//                 }
//                 <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//                     <Typography component="h2" variant="header">
//                         {sclass && sclass.id ? "Update class" : "Create new class"}
//                     </Typography>
//                     <Box>
//                         {sclass && sclass.id &&
//                             <Chip
//                                 onClick={() => navigate("../new")}
//                                 icon={<AddCircleOutlineIcon />}
//                                 label="New class"
//                                 variant="outlined"
//                                 color="secondary"
//                                 sx={{ mr: 3 }}
//                             />
//                         }
//                         <Chip
//                             onClick={() => navigate(`../list`)}
//                             icon={<FormatListBulletedIcon />}
//                             label="Classes"
//                             variant="outlined"
//                             color="secondary"
//                         />
//                     </Box>
//                 </Box>
//                 <Grid container spacing={2} sx={{ mt: 3 }}>
//                     {isLoading &&
//                         <Grid container>
//                             <Grid item>
//                                 <CircularProgress />
//                             </Grid>
//                         </Grid>
//                     }
//                     <Grid item xs={12} md={6}>
//                         <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
//                             <TextField
//                                 margin="normal"
//                                 fullWidth
//                                 required
//                                 name="name"
//                                 label="Class name"
//                                 value={formik.values.name}
//                                 onChange={formik.handleChange}
//                                 error={formik.touched.name && Boolean(formik.errors.name)}
//                                 helperText={formik.touched.name && formik.errors.name}
//                             />
//                             <Box
//                                 sx={{
//                                     display: "flex",
//                                     justifyContent: "left",
//                                     alignItems: "center",
//                                     mt: 3
//                                 }}
//                             >
//                                 <Autocomplete
//                                     disablePortal
//                                     id="combo-box-demo"
//                                     options={sections}
//                                     sx={{ width: 300 }}
//                                     getOptionLabel={(option) => option.name}
//                                     renderInput={(params) => <TextField {...params} label="Select section" />}
//                                     onChange={(event, newValue) => {
//                                         setSelectedSection(newValue);
//                                     }}
//                                 />
//                                 {sectionsLoading &&
//                                     <CircularProgress size="30" />
//                                 }
//                                 <Chip
//                                     onClick={addSectionToClass}
//                                     icon={<AddCircleOutlineIcon />}
//                                     label="Add"
//                                     variant="outlined"
//                                     color="secondary"
//                                     sx={{ ml: 3 }}
//                                 />
//                             </Box>

//                             {sclass.sections && sclass.sections.length > 0 &&
//                                 <TableContainer component={Paper}
//                                     sx={{ mt: 3 }}
//                                 >
//                                     <Table aria-label="simple table">
//                                         <TableHead>
//                                             <TableRow>
//                                                 <TableCell sx={{ width: '.8rem' }}>#</TableCell>
//                                                 <TableCell>Name</TableCell>
//                                                 <TableCell align="right">Action</TableCell>
//                                             </TableRow>
//                                         </TableHead>
//                                         <TableBody>
//                                             {sclass.sections.map((item, index) => (
//                                                 <TableRow
//                                                     key={item.name}
//                                                 >
//                                                     <TableCell align="left">{index + 1}</TableCell>
//                                                     <TableCell component="th" scope="row">
//                                                         {item.name}
//                                                     </TableCell>
//                                                     <TableCell align="right">
//                                                         <IconButton aria-label="delete" color="error"
//                                                             onClick={() => handleDelete(item.id)}
//                                                         >
//                                                             <DeleteIcon fontSize="inherit" />
//                                                         </IconButton>
//                                                     </TableCell>
//                                                 </TableRow>
//                                             ))}
//                                         </TableBody>
//                                     </Table>
//                                 </TableContainer>
//                             }
//                             <Box
//                                 sx={{
//                                     display: 'flex',
//                                     justifyContent: 'center',
//                                 }}
//                             >
//                                 {isSaving &&
//                                     <CircularProgress />
//                                 }
//                                 {!isSaving &&
//                                     <Button
//                                         type="submit"
//                                         fullWidth
//                                         variant="contained"
//                                         sx={{ mt: 3, mb: 2 }}
//                                     >
//                                         Save
//                                     </Button>
//                                 }
//                             </Box>
//                         </Box>
//                     </Grid>
//                 </Grid>
//             </Box>


//             {deleteJson.show &&
//                 <CustomModal
//                     show={deleteJson.show}
//                     data={deleteJson.data}
//                     type={ModalType.CONFIRM_DELETE}
//                     onOkay={(data) => {
//                         onDelete(deleteJson.data);
//                         setDeleteJson({
//                             ...deleteJson,
//                             show: false,
//                         });
//                     }}
//                     onHide={() => {
//                         setDeleteJson({
//                             ...deleteJson,
//                             show: false,
//                         });
//                     }}
//                 >
//                 </CustomModal>
//             }

//         </>

//     );
// }

// export default NewClass;
import React from 'react';
import { Outlet, Link } from "react-router-dom";

const Class = () => {
    return (
        <>
            <Outlet />
        </>
    );
}

export default Class;
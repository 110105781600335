import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';

import { Avatar, Box, ButtonBase, useMediaQuery, Popper, Paper, ClickAwayListener, Grid, Typography, Stack,
    Divider, CardActions, Button, List, ListItem, ListItemText, Badge,
    ListItemAvatar } from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GroupIcon from '@mui/icons-material/Group';
import { IconBell } from '@tabler/icons';

import Transitions from '../../../../../common/extended/transitions';
import MainCard from '../../../../../common/cards/main-card';
import { getNotifications } from '../../../../../services/http.service';
import { receiveNotificationsObservable,
    updateNotificationReadObservable } from '../../../../../services/observable.service';
import { Role, NotificationType, formatDateTimeRev } from '../../../../../utils/constants';
import Loader from '../../../../../common/loader';


const Notification = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [notifications, setNotifications] = useState({
        unReadTotalCount: 0,
        notifications: [],
    });

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const pageRef = useRef(0);
    const limitRef = useRef(10);

    const schoolId = useSelector(state => state.extras.schoolId);

    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
     const anchorRef = useRef(null);

     const handleToggle = () => {
         setOpen((prevOpen) => !prevOpen);
     };
 
     const handleClose = (event) => {
         if (anchorRef.current && anchorRef.current.contains(event.target)) {
             return;
         }
         setOpen(false);
     };
 
     const prevOpen = useRef(open);
     useEffect(() => {
         if (prevOpen.current === true && open === false) {
             anchorRef.current.focus();
         }
         prevOpen.current = open;
         onShowNotificationPopup(open);
     }, [open]);
 
     const handleChange = (event) => {
         if (event?.target.value) setValue(event?.target.value);
     };

     // On notification popup open
    const onShowNotificationPopup = (isOpen) => {
        console.log("notification open status - ", open);
        setNotifications({
            ...notifications,
            notifications: [],
        });
        if (isOpen) {
            updateNotificationRead();
            pageRef.current = 0;
            fetchNotifications();
        }
    }

    // Update notification read flag
    const updateNotificationRead = () => {
        // Reset unread count
        setNotifications({
            ...notifications,
            unReadTotalCount: 0,
        })
        updateNotificationReadObservable().next();
    }

    // Fetch Notifications
    const fetchNotifications = () => {
        console.log(`fetchNotifications`);
        setIsLoading(true);
        // const { notificationModal } = this.state;
        getNotifications(pageRef.current, limitRef.current).then(resp => {
            setIsLoading(false);
            console.log("resp.data.data ", resp.data.data);
            setNotifications({
                ...notifications,
                notifications: [...notifications.notifications, ...resp.data.data],
                unReadTotalCount: 0 // make 0 while opening modal
            })
        }).catch(err => {
            console.log(`Error in getting notifications - ${JSON.stringify(err)}`);
            setIsLoading(false);
        });
    }

    // On Load More of Notifications
    const onLoadMoreNotifications = () => {
        // Increment page and call api
        pageRef.current = pageRef.current + 1;
        fetchNotifications();
    }

    useEffect(() => {
        // Receive Notifications
        const receiveNotificationsObservableRef = receiveNotificationsObservable()
            .subscribe(value => {
                console.log(`Notification received - ${JSON.stringify(value)}`);
                setNotifications({
                    ...notifications,
                    unReadTotalCount: value.unReadCount,
                });
            });

        // cleanup - unsubscribe before component unmount
        return () => {
            receiveNotificationsObservableRef.unsubscribe();
        }

    }, []);
   
    return (
        <>
            <Box
                sx={{
                    mx: 1
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Badge badgeContent={notifications.unReadTotalCount} color="error">
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                '&[aria-controls="menu-list-grow"],&:hover': {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light
                                }
                            }}
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                            color="inherit"
                        >
                            <IconBell stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </Badge>
                </ButtonBase>
            </Box>

            {/* Notification popup dropdown */}
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Grid container direction="column" spacing={2} sx={{ pt: 2, px: 2}}>
                                        <Grid item xs={12}>
                                            { isLoading &&
                                                <Loader />
                                            }
                                           <Stack spacing={2}>
                                                <Typography variant="subtitle1">All Notification</Typography>
                                                <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper', maxHeight: 400, overflow: 'auto' }}>
                                                {notifications.notifications.filter(item => (item.to_user && item.to_user.role === Role.ADMIN)).map((item, index) => (
                                                     <NotificationItemContent notification={item}/>                          
                                                ))}
                                                </List>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                        <Button size="small" disableElevation
                                            onClick={onLoadMoreNotifications}
                                        >
                                            Load more
                                        </Button>
                                    </CardActions>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
}

export default Notification;

// Build Notification content based on NotifucationType
const NotificationItemContent = (props) => {
    const notification = props.notification;
    if (notification.type === NotificationType.START_TRIP) {
        console.log('Build StartTrip notification content');
        return (
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar>
                        <PlayCircleFilledIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={<a href={`/school/${notification.school_id}/admin/trip/${notification.data.tripId}/running/map-view`}>{notification.text}</a>}
                    secondary={
                        <Typography
                            variant="caption"
                        >
                            {formatDateTimeRev(notification.created_at)}
                        </Typography>
                    }
                >
                </ListItemText>
            </ListItem>
        )
    }
    else if (notification.type === NotificationType.FINISH_TRIP) {
        console.log('Build FinishTrip notification content');
        return (
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar>
                        <CheckCircleIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={<a href={`/school/${notification.school_id}/admin/trip/${notification.data.tripId}/detail-view`}>{notification.text}</a>}
                    secondary={
                        <Typography
                            variant="caption"
                        >
                            {formatDateTimeRev(notification.created_at)}
                        </Typography>
                    }
                >
                </ListItemText>
            </ListItem>
        )
    }
    else if (notification.type === NotificationType.STUDENT_PICK_UP 
        || notification.type === NotificationType.STUDENT_DROP_OFF
        || notification.type === NotificationType.STUDENT_ABSENT) {
        return (
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar>
                        <GroupIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={<a href={`/school/${notification.school_id}/admin/trip/${notification.data.tripId}/running/map-view`}>{notification.text}</a>}
                    secondary={
                        <Typography
                            variant="caption"
                        >
                            {formatDateTimeRev(notification.created_at)}
                        </Typography>
                    }
                >
                </ListItemText>
            </ListItem>
        )
    }
    else {
        return <div className="d-flex justify-content-between"></div>
    }
}
import React, { useState, useEffect } from 'react';
import { Container, Button, Box, Avatar, Typography, TextField, Grid, Link, Paper, CircularProgress } from '@mui/material';

import { resendOTP, getOtpExpiryTime } from '../../services/http.service';
import { verifyOTP } from '../../services/auth.service';
import { convertSecsToHms } from '../../utils/constants';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../common/snackbars';

import { useNavigate, useParams } from 'react-router-dom';
import logger from '../../utils/logger';


const RegisterOTPVerification = (props) => {

    const [validated, setValidated] = useState(false);
    const [isResending, setIsResending] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [resendSuccess, setResendSuccess] = useState(false);
    const [otpExpiryTime, setOtpExpiryTime] = useState('');

    let navigate = useNavigate();
    const { id } = useParams();
    const userId = id ? parseInt(id) : null;

    // Get Otp expiry time
    const fetchOtpExpiryTime = () => {
        getOtpExpiryTime().then(resp => {
            let seconds = parseInt(resp.data);  
            setOtpExpiryTime(convertSecsToHms(seconds) );  
            logger.info('Fetched OTP expiry time', { expiryTime: seconds });      

            // TODO - will into count down timer later
            // let timer = setInterval(() => {
            //     seconds = seconds - 1;
            //     console.log(`remaining - ${seconds}`);
            //     this.setState({ otpExpiryTime: convertSecsToHms(seconds) });
            //     if (seconds === 0) {
            //         clearInterval(timer);
            //     }
            // }, 1000);

        }).catch(err => {
            logger.error('Failed to fetch OTP expiry time', err);
        });
    }

    // Handle Submit
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        setIsSaving(true);
        const data = new FormData(event.currentTarget);
        const otp = data.get('otp');
        logger.info(`Submitting OTP verification for user: ${userId}`, { otp });
        verifyOTP(userId, otp).then(resp => {
            setIsSaving(false);
            setErrorMsg(null);
            logger.info(`OTP verification successful for user: ${userId}`);
            navigate('/school/list');
        }).catch(err => {
            logger.error(`OTP verification failed for user: ${userId}`, err);
            setIsSaving(false);
            setErrorMsg(err);
        })
    }

    // Resend OTP code
    const resendOtpCode = () => {
        setIsSaving(true);
        setResendSuccess(false);
        logger.info(`Resending OTP for user: ${userId}`);
        resendOTP(userId).then(resp => {
            setIsSaving(false);
            setResendSuccess(true);
            setErrorMsg(null);     
            logger.info(`OTP resent successfully for user: ${userId}`);       
        }).catch(err => {
            logger.error(`Failed to resend OTP for user: ${userId}`, err);
            setIsSaving(false);
            setResendSuccess(false);
            setErrorMsg(err);
        })
    }


    // run on compnonent init
    useEffect(() => {
        logger.info('RegisterOTPVerification component mounted');
        fetchOtpExpiryTime();
    }, []); // runs once similar to componentDidMount
    

    return (
        <>
            { errorMsg &&
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
            { resendSuccess &&
                <SuccessAlertTopRight 
                    successMsg={"Otp sent successfully"}
                    onClose={() => setResendSuccess(false)} 
                />
            }

            <Container component="main" maxWidth="xs">
                <Paper elevation={10}>
                    <Box
                        padding={2}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            OTP verification
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="otp"
                                label="Enter Otp"
                                name="otp"
                                autoComplete="otp"
                                autoFocus
                            /> 
                            <Typography variant="body2">OTP expires in {otpExpiryTime}</Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: 3
                                }}
                            >
                                {isSaving &&
                                    <CircularProgress />
                                }
                                {!isSaving &&
                                    <>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                    >
                                       Submit
                                    </Button>
                                    <Button
                                        onClick={resendOtpCode}
                                        type="submit"
                                        fullWidth
                                        variant="outlined"
                                        sx={{ ml: 5 }}
                                    >
                                        Resend
                                    </Button>
                                    </>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </>
    );
}

export default RegisterOTPVerification;
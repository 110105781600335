import React, { Component, useState } from 'react';
import { Container, Button, Box, Avatar, Typography, TextField, FormControlLabel, 
  Checkbox, Grid, Link, Paper, Snackbar, Alert, CircularProgress } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';

import { getSchoolByUniqueNumber, adminLogin } from '../../services/http.service';
import { saveAuth } from '../../services/auth.service';
import { ErrorAlertTopCenter } from '../../common/snackbars';
import logger from '../../utils/logger';


const AdminLogin = () => {
  const [schoolUniqueNumber, setSchoolUniqueNumber] = useState('');
  const [validated, setValidated] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [school, setSchool] = useState(null);

  let navigate = useNavigate();

  // Submit School Uniquer number and get school detail
  const fetchSchoolByUniqueNumber = () => {
    logger.info('Fetching school by unique number', { schoolUniqueNumber });
    // Reset flags
    setIsSaving(true);
    setErrorMsg(null);
    // If unique number field is blank
    if (!schoolUniqueNumber || schoolUniqueNumber === "") {
      logger.error('School unique number is empty');
      setIsSaving(false);
      setErrorMsg("Please provide School Identification Number");
      return;
    }

    getSchoolByUniqueNumber(schoolUniqueNumber).then(resp => {
      setIsSaving(false);
      setErrorMsg(null);
      setSchool(resp.data.data );
      logger.info('Fetched school details successfully', { school: resp.data.data });
    }).catch(err => {
      logger.error('Failed to fetch school by unique number', err);
      setIsSaving(false);
      setErrorMsg(err);
    });
  }

    
  // Handle Submit
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.currentTarget);
    // Reset flags
    setIsSaving(true);
    setErrorMsg(null);
    const data = {
      schoolId: school.id,
      mobile: form.get('mobile'),
      password: form.get('password')
    }
    logger.info('Admin login attempt', { schoolId: school.id, mobile: data.mobile });
    adminLogin(data).then(resp => {
      setIsSaving(false);
      setErrorMsg(null);
      // Save auth and refirect to school Admin screen
      const auth = resp.data.data
      auth.user.school = school;
      logger.info('Admin login successful', { user: auth.user });
      saveAuth(auth);
      // Navigate to school Admin
      navigate(`/school/${school.id}/admin/trip/today/list`);

    }).catch(err => {
      logger.error('Admin login failed', err);
      setIsSaving(false);
      setErrorMsg(err);
    })
  }


  return (
    <>
      {errorMsg &&
        <ErrorAlertTopCenter
          errorMsg={errorMsg}
          onClose={() => setErrorMsg(null)}
        />
      }
      <Container component="main" maxWidth="xs">
        <Paper elevation={10}>
          <Box
            padding={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Admin Sign In
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, px:2, width: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="schoolUniqueNumber"
                label="School Identification Number"
                name="schoolUniqueNumber"
                autoComplete="schoolUniqueNumber"
                autoFocus
                onChange = {(e) => {
                  setSchoolUniqueNumber(e.target.value);
                }}
              />
              {school && school.id &&
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Mobile/Email"
                    name="mobile"
                    autoComplete="mobile"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                </>
              }

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {isSaving &&
                  <CircularProgress />
                }
                { !isSaving &&
                  <>
                    {!(school && school.id) &&
                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={fetchSchoolByUniqueNumber}
                      >
                        Continue
                      </Button>
                    }
                    {school && school.id &&
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Sign In
                      </Button>
                    }
                  </>
                }
              </Box>
            </Box>
          </Box>
        </Paper>
      </Container>
    </>
  );

}

export default AdminLogin;